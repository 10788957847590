import { Injectable } from "@angular/core";
import {
  FullProductType,
  MatchType,
  Product,
  ProductProperty,
  ProductPropertyTemplate,
  ProductPropertyType,
  ProductType,
  Relationship,
  RelationshipType,
} from "@domain/product/product";

@Injectable({
  providedIn: "root",
})
export class ProductMapperService {
  mapProductAndProperties(productsJson: any, productPropertiesJson: any): Product[] {
    const propertyTemplates = this.mapPropertyTemplates(productPropertiesJson);

    return productsJson.map((productJson: any) => {
      const properties: ProductProperty[] = [];

      Object.entries<string>(productJson.properties.data).forEach(([key, value]) => {
        const template = propertyTemplates.get(key);
        if (!template) {
          console.warn("No property found for key " + key);
          return;
        }
        try {
          properties.push(template.createInstance(value));
        } catch (error) {
          console.error(`Error creating property from template. Type: ${template.type}, value: ${value}`, error);
        }
      });
      const id = productJson.id;
      const name = productJson.name.trim();
      const type = this.mapProductType(productJson.type);
      const fullType = this.mapFullType(productJson.typeFull);
      const isModular = productJson.isModular;
      const isAvailable = productJson.isAvailable;
      const relationships = this.mapRelationships(productJson.relationships);
      const price = productJson.priceData ? productJson.priceData[0]?.price | 0 : 0;
      const countries: string[] = productJson.country ? [productJson.country] : [];

      return new Product(id, name, type, fullType, isModular, isAvailable, relationships, properties, price, countries);
    });
  }

  private mapPropertyTemplates(json: any): Map<string, ProductPropertyTemplate> {
    const properties: any[] = json;
    return new Map(
      properties.map((propJson) => {
        const productProperty = this.mapPropertyTemplate(propJson);
        return [productProperty.key, productProperty];
      }),
    );
  }

  private mapPropertyTemplate(json: any): ProductPropertyTemplate {
    let name = "";
    let info = undefined;
    let description = undefined;

    json.translation.forEach((t: any) => {
      if (t.translationType === "Criterium" || t.translationType === "Cluster") {
        name = t.translation;
      } else if (t.translationType === "CallOutInfoHead") {
        info = t.translation;
      } else if (t.translationType === "CallOutInfoText") {
        description = t.translation;
      }
    });

    return new ProductPropertyTemplate(json.propertyName, this.mapPropertyType(json.dataType), name, info, description);
  }

  private mapProductType(type: string): ProductType {
    switch (type) {
      case "transmitter":
        return ProductType.TRANSMITTER;
      case "attachment":
        return ProductType.ATTACHMENT;
      case "sensor":
        return ProductType.SENSOR;
      case "gwz":
        return ProductType.GASWARNINGCENTER;
      case "alarmDevice":
        return ProductType.ALARMDEVICE;
      case "miscDevice":
        return ProductType.MISCDEVICE;
      case "service":
        return ProductType.SERVICE;
      case "warnSignLuminous":
        return ProductType.SIGNALELEMENT;
      case "warnSignPlastic":
        return ProductType.PLASTICSIGN;
      default:
        console.warn("Unknown product type " + type);
        return ProductType.MISCDEVICE;
    }
  }

  private mapFullType(fullType: string): FullProductType {
    switch (fullType) {
      case "t-ec-m":
        return FullProductType.TRANSMITTER_EC_M;
      case "t-ir-m":
        return FullProductType.TRANSMITTER_IR_M;
      case "t-mec-m":
        return FullProductType.TRANSMITTER_MEC_M;
      case "t-cat-m":
        return FullProductType.TRANSMITTER_CAT_M;
      case "t-cat-k":
        return FullProductType.TRANSMITTER_CAT_K;
      case "t-ir-k-bus":
        return FullProductType.TRANSMITTER_IR_K_BUS;
      case "t-ir-k":
        return FullProductType.TRANSMITTER_IR_K;
      case "t-ec-k-bus":
        return FullProductType.TRANSMITTER_EC_K_BUS;
      case "z-o":
        return FullProductType.ATTACHMENT_OPTIONAL;
      case "z-n":
        return FullProductType.ATTACHMENT_REQ;
      case "z-ga":
        return FullProductType.ATTACHMENT_INSTRUCTIONS;
      case "z-m":
        return FullProductType.ATTACHMENT_MODULE;
      case "gwz-m":
        return FullProductType.GASWARNINGCENTER_M;
      case "gwz-k":
        return FullProductType.GASWARNINGCENTER_K;
      case "gwz-k-vg":
        return FullProductType.GASWARNINGCENTER_K_VG;
      case "a":
        return FullProductType.ALARMDEVICE;
      case "sz":
        return FullProductType.MISCDEVICE;
      case "dl":
        return FullProductType.SERVICE;
      case "s-ec":
        return FullProductType.SENSOR_EC;
      case "s-mec":
        return FullProductType.SENSOR_MEC;
      case "s-ir":
        return FullProductType.SENSOR_IR;
      case "sz-lt":
        return FullProductType.SIGNALELEMENT;
      case "sz-ks":
        return FullProductType.PLASTICSIGN;
      case "a-24":
        return FullProductType.ALARMDEVICE_A24;
      case "a-230":
        return FullProductType.ALARMDEVICE_A230;
      case "a-24-mod":
        return FullProductType.ALARMDEVICE_A24_MOD;
      case "a-230-mod":
        return FullProductType.ALARMDEVICE_A230_MOD;
      case "a-ex-24":
        return FullProductType.ALARMDEVICE_A_EX_24;
      case "a-ex-230":
        return FullProductType.ALARMDEVICE_A_EX_230;
      default:
        console.warn("Unknown full product type " + fullType);
        return FullProductType.MISCDEVICE;
    }
  }

  private mapPropertyType(type: string): ProductPropertyType {
    switch (type) {
      case "Bool":
        return ProductPropertyType.BOOLEAN;
      case "Text":
        return ProductPropertyType.TEXT;
      case "TemperatureRangeC":
        return ProductPropertyType.TEMPERATUR_RANGE_IN_CELSIUS;
      case "Number":
        return ProductPropertyType.NUMBER;
      case "NumberRange":
        return ProductPropertyType.NUMBER_RANGE;
      case "WeightKG":
        return ProductPropertyType.WEIGHT_IN_KG;
      case "DimensionsM":
        return ProductPropertyType.DIMENSIONS_IN_METER;
      default:
        console.warn("Unknown property dataType " + type);
        return ProductPropertyType.TEXT;
    }
  }

  mapRelationships(json: any): Relationship[] {
    const relationships: Relationship[] = [];
    json.forEach((relationship: any) => {
      const relationshipType = this.mapRelationshipType(relationship.relationshipType);
      const matchType = this.mapMatchType(relationship.matchType);
      if (!relationshipType || !matchType) {
        return;
      }
      relationships.push(new Relationship(relationship.id, relationshipType, matchType));
    });
    return relationships;
  }

  private mapRelationshipType(type: string): RelationshipType | undefined {
    switch (type) {
      case "extra":
        return RelationshipType.EXTRA;
      case "extraGuide":
        return RelationshipType.EXTRAGUIDE;
      case "extraReq":
        return RelationshipType.EXTRAREQ;
      case "sensor":
        return RelationshipType.SENSOR;
      case "module":
        return RelationshipType.MODULE;
      default:
        console.warn("Unknown relationship type " + type);
        return undefined;
    }
  }

  private mapMatchType(type: string): MatchType | undefined {
    switch (type) {
      case "direct":
        return MatchType.DIRECT;
      case "sensortype":
        return MatchType.SENSORTYPE;
      default:
        console.warn("Unknown match type " + type);
        return undefined;
    }
  }
}

// Criterium, CallOutInfoHead, CallOutInfoText, Cluster

<app-files-overlay
  (file$)="onFileUploaded($event)"
  [MAX_FILE_SIZE]="MAX_FILE_SIZE"
  [TOTAL_FILE_SIZE]="TOTAL_FILE_SIZE"
  [projectId]="project.cloudId"
>
  <div class="container scroll h-100 p-0-24-24">
    <!-- prettier-ignore -->
    <div class="size-container">
      <p i18n="fileUpload.maxFileSize">Maximale Dateigröße: {{MAX_FILE_SIZE / 1024 / 1024}} MB</p>
      <p i18n="fileUpload.totalFileSize">Maximaler Speicherplatz pro Projekt: {{TOTAL_FILE_SIZE / Math.pow(2, 30)}} GB</p>
    </div>

    <div *ngIf="navigator.onLine; else filesOffline">
      <h6 class="odx-subtitle-6" *ngIf="files.length === 0" i18n="@@documentUpload.noDocumentYet">
        Sie haben noch keine Dokumente hinzugefügt.
      </h6>
      <div odxLayout="grid 3@desktop 4@tablet 6@phone">
        <app-project-detail-file-card
          *ngFor="let file of files"
          (delete$)="onDelete(file)"
          (download$)="onDownload(file)"
          (click$)="onDownload(file)"
          [fileName]="file.name"
          [fileType]="file.type"
        >
        </app-project-detail-file-card>
      </div>
    </div>
  </div>
</app-files-overlay>

<ng-template #filesOffline>
  <div class="offline-warning-container">
    <odx-inline-message variant="warning" i18n="fileUpload.inlineMessage.offline"
      >Dateien können nur online geladen werden.</odx-inline-message
    >
  </div>
</ng-template>

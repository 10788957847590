import { Injectable } from "@angular/core";
import { Content, TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { HalfPageContainer } from "@pdf/helpers/container/half-page/half-page-container";
import { HalfPageContainerService } from "@pdf/helpers/container/half-page/half-page-container.service";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import { FontNames, Headlines, PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PdfTableOfContentsService {
  private headlines = new Headlines();

  constructor(private containerService: HalfPageContainerService) {}

  generate(
    pdf: jsPDF,
    tableOfContents: TableOfContents,
  ): Observable<{ pdf: jsPDF; numberOfAdditionalPagesNeeded: number; container: HalfPageContainer }> {
    return new Observable((observer) => {
      this.addFonts(pdf);
      pdf.setFont(FontNames.DRAEGER_PANGEA, "normal");
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);
      const container = new HalfPageContainer();
      const numberOfAdditionalPagesNeeded: number = this.drawTable(pdf, container, tableOfContents);
      observer.next({ pdf, numberOfAdditionalPagesNeeded, container });
      observer.complete();
    });
  }

  private drawTable(pdf: jsPDF, container: HalfPageContainer, tableOfContents: TableOfContents): number {
    pdf.setFontSize(PdfProperties.TABLE_OF_CONTENTS_FONT_SIZE);
    const pageHeadline = this.headlines.TABLE_OF_CONTENTS;
    pdf.setPage(2);
    this.containerService.addOnCurrentPage(pdf, container, pageHeadline);
    container.yPosition += 60;
    let pageNumber: number = 2;
    let currentLine: number = 1;
    const maxLinesPerContainer: number = 24;
    const groupedTableOfContents: Content[][] = tableOfContents.groupedByMain();
    const numberOfContainersNeeded: number = this.calculateNumberOfContainers(
      groupedTableOfContents,
      currentLine,
      maxLinesPerContainer,
    );
    const numberOfAdditionalPagesNeeded: number = this.getNumberOfAdditionalPagesNeeded(numberOfContainersNeeded);
    if (numberOfAdditionalPagesNeeded > 0) {
      this.insertAdditionalPages(pdf, numberOfAdditionalPagesNeeded);
      pageNumber += numberOfAdditionalPagesNeeded;
    }
    pdf.setPage(2);
    groupedTableOfContents
      .filter((contentGroup) => contentGroup[0].main.length)
      .forEach((contentGroup) => {
        const necessaryLines =
          new Set(contentGroup.filter((content) => content.sub !== undefined).map((content) => content.sub)).size + 1;
        const isEnoughSpace = this.checkIfIsEnoughSpace(necessaryLines, currentLine, maxLinesPerContainer);
        if (!isEnoughSpace) {
          this.containerService.next(pdf, container, pageHeadline, tableOfContents);
          container.yPosition += 60;
          currentLine = 1;
        } else {
          currentLine += necessaryLines;
        }
        this.addGroup(pdf, container, contentGroup, pageNumber);
        pageNumber += contentGroup.length;
      });
    return numberOfAdditionalPagesNeeded;
  }

  private insertAdditionalPages(pdf: jsPDF, numberOfAdditionalPagesNeeded: number) {
    const pageToInsertAfter = 2;
    const totalPages = pdf.getNumberOfPages();

    for (let i = 0; i < numberOfAdditionalPagesNeeded; i++) {
      pdf.insertPage(pageToInsertAfter + 1 + i);
    }

    for (let i = totalPages; i > pageToInsertAfter; i--) {
      pdf.setPage(i);
      const content = pdf.internal.pages[i];

      pdf.setPage(i + numberOfAdditionalPagesNeeded);
      pdf.internal.pages[i + numberOfAdditionalPagesNeeded - 1] = content;
    }
  }

  private getNumberOfAdditionalPagesNeeded(numberOfContainersNeeded: number) {
    return Math.ceil((numberOfContainersNeeded + 1) / 2) - 1;
  }

  private calculateNumberOfContainers(
    groupedTableOfContents: Content[][],
    currentLine: number,
    maxLinesPerContainer: number,
  ): number {
    let numberOfContainers: number = 1;
    groupedTableOfContents.forEach((contentGroup) => {
      const necessaryLines =
        new Set(contentGroup.filter((content) => content.sub !== undefined).map((content) => content.sub)).size + 1;
      const isEnoughSpace = this.checkIfIsEnoughSpace(necessaryLines, currentLine, maxLinesPerContainer);
      if (!isEnoughSpace) {
        numberOfContainers++;
        currentLine = 1;
      } else {
        currentLine += necessaryLines;
      }
    });
    return numberOfContainers;
  }

  private addGroup(pdf: jsPDF, container: HalfPageContainer, content: Content[], page: number) {
    const seenEntries = new Map<string, number>(); // To track seen entries and their first page number

    if (content.length > 0) {
      this.addBoldRow(pdf, container, content[0].main, page);
    }

    let currentPage = page;

    content.forEach((entry) => {
      if (!entry.sub) {
        return;
      }
      if (!seenEntries.has(entry.sub)) {
        seenEntries.set(entry.sub, currentPage);
        this.addRow(pdf, container, entry.sub, currentPage);
      }
      currentPage++;
    });
  }

  private addBoldRow(pdf: jsPDF, container: HalfPageContainer, content: string, page: number) {
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(PdfProperties.TABLE_OF_CONTENTS_FONT_SIZE_LARGE);

    this.drawText(pdf, container, content, page, container.xPosition + 60, container.xPosition + 1420);

    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
    container.yPosition += 80;
  }

  private addRow(pdf: jsPDF, container: HalfPageContainer, content: string, page: number) {
    pdf.setFontSize(PdfProperties.TABLE_OF_CONTENTS_FONT_SIZE);

    this.drawText(pdf, container, content, page, container.xPosition + 120, container.xPosition + 1360);

    container.yPosition += 80;
  }

  private drawText(
    pdf: jsPDF,
    container: HalfPageContainer,
    content: string,
    page: number,
    xPositionContent: number,
    xPositionPageNumber: number,
  ) {
    let linkDimensions = pdf.getTextDimensions(content);
    pdf.text(content, xPositionContent, container.yPosition, { baseline: "top" });
    pdf.link(xPositionContent, container.yPosition, linkDimensions.w, linkDimensions.h, { baseline: "top", pageNumber: page });

    linkDimensions = pdf.getTextDimensions(page.toString());
    pdf.text(page.toString(), xPositionPageNumber, container.yPosition, { baseline: "top", align: "right" });
    pdf.link(xPositionPageNumber - linkDimensions.w, container.yPosition, linkDimensions.w, linkDimensions.h, {
      baseline: "top",
      align: "right",
      pageNumber: page,
    });
  }

  private checkIfIsEnoughSpace(necessaryLines: number, currentLine: number, maxLinesPerContainer: number) {
    return necessaryLines + currentLine <= maxLinesPerContainer;
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }
}

import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Role } from "@app/role";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfirmDeleteProjectModalComponent } from "@components/confirm-delete-project-modal/confirm-delete-project-modal.component";
import { InvitePersonToProjectModalComponent } from "@components/invite-person-to-project-modal/invite-person-to-project-modal.component";
import { ProjectVersionConflictModalComponent } from "@components/project-version-conflict-modal/project-version-conflict-modal.component";
import { ProjectVersionModalComponent } from "@components/project-version-modal/project-version-modal.component";
import { ProjectApiService } from "@domain/project/api/project-api.service";
import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { ChipComponent } from "@odx/angular/components/chip";
import { ModalService, ModalSize } from "@odx/angular/components/modal";
import { ReadAccessModalService } from "@utils/modals/read-access-modal.service";
import { ToastHelper } from "@utils/toast-helper.service";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { filter, forkJoin, map, of, switchMap } from "rxjs";
import { take } from "rxjs/operators";
import { LocalizeDatePipe } from "src/locale/localize-date";
import { LocalizeTimePipe } from "src/locale/localize-time";
import { v4 as uuidv4 } from "uuid";

@Component({
  selector: "app-project-card",
  templateUrl: "./project-card.component.html",
  styleUrls: ["./project-card.component.scss"],
  standalone: true,
  imports: [UiKitModule, LocalizeDatePipe, LocalizeTimePipe, ChipComponent],
})
export class ProjectCardComponent implements OnInit {
  @Input() project!: Project;

  @Input() showMenu: boolean = true;

  @Input() linkToProject: boolean = true;

  @Output() deleteEmitter$ = new EventEmitter();

  private readonly modalService = inject(ModalService);
  protected isCollaborationProject = false;
  private isProjectOwner = false;
  protected readonly navigator = navigator;

  constructor(
    private projectService: ProjectService,
    private toastHelper: ToastHelper,
    private router: Router,
    private projectApiService: ProjectApiService,
    private readAccessModalService: ReadAccessModalService,
  ) {}

  ngOnInit(): void {
    this.fetchUserRole();
    if (this.project.cloudId) {
      this.projectService
        .getMetadataById(this.project.cloudId)
        .pipe(
          take(1),
          map((metadata) => {
            this.isCollaborationProject = metadata ? metadata.users.length > 1 : false;
          }),
        )
        .subscribe();
    }
  }

  protected exportProjectToFile(project: Project) {
    this.projectService.exportProjectToFile(project);
    this.toastHelper.success(
      $localize`:@@toast.projectExport.successful:Projekt '${project.name}:projectName:' wurde erfolgreich auf Ihren Computer exportiert`,
    );
  }

  protected exportProjectToPdf(project: Project) {
    this.modalService.open(ProjectVersionModalComponent, { data: { project: project } }).onClose$.subscribe();
  }

  protected onClick() {
    if (!this.project.cloudId || !navigator.onLine) {
      this.navigateToProject();
      return;
    }
    if (navigator.onLine) {
      this.handleMetadataSync()
        .pipe(take(1))
        .subscribe({
          next: () => this.navigateToProject(),
          error: (err) => console.error("Error occurred:", err),
        });
    }
  }

  protected onDelete() {
    this.modalService
      .open(ConfirmDeleteProjectModalComponent, { data: { isProjectOwner: this.isProjectOwner } })
      .onClose$.pipe(
        switchMap((shouldDeleteFromCloud) => {
          if (shouldDeleteFromCloud && this.project.cloudId) {
            this.projectApiService.deleteProjectFromCloud(this.project.cloudId).subscribe();
          }
          return forkJoin([
            this.projectService.deleteLocalMetadata(this.project.cloudId),
            this.projectService.deleteProject(this.project),
          ]);
        }),
      )
      .subscribe(() => this.deleteEmitter$.emit());
  }

  protected openInvitePersonModal() {
    this.modalService
      .open(InvitePersonToProjectModalComponent, {
        size: ModalSize.MEDIUM,
      })
      .onClose$.pipe(
        filter((value) => !!value && !!this.project?.cloudId),
        switchMap((value) => this.projectApiService.inviteCollaborators(value, this.project.cloudId)),
      )
      .subscribe((response) => {
        const errors = response
          .filter((res: { success: boolean; message: string; to?: string }) => {
            return !res.success;
          })
          .map((res: { success: boolean; message: string; to?: string }) => res.to);

        if (errors.length >= 1) {
          this.toastHelper.error(
            $localize`:@@toast.invitation.error:Einladung konnte nicht verschickt werden an: ` + errors.join(", "),
          );
        } else {
          this.toastHelper.success($localize`:@@toast.invitation.successful:Einladung wurde erfolgreich verschickt`);
        }
      });
  }

  protected openVersionConflictModal(localMetadata: CloudProjectMetadata, cloudMetadata: CloudProjectMetadata) {
    return this.modalService
      .open(ProjectVersionConflictModalComponent, {
        data: {
          projectName: cloudMetadata.name,
          updatedBy: cloudMetadata.updatedBy,
          lastModifiedOn: cloudMetadata.updatedAt,
        },
      })
      .onClose$.pipe(
        switchMap((value: string) => {
          switch (value) {
            case "saveCopy": {
              const plainProject = instanceToPlain(this.project);
              delete plainProject["cloudId"];
              delete plainProject["lastCloudSync"];
              plainProject["id"] = uuidv4();
              plainProject["name"] = this.project.name + " Copy";
              plainProject["lastModified"] = new Date();

              const projectCopy = plainToInstance(Project, plainProject);

              return this.projectService.createProject(projectCopy).pipe(
                switchMap(() => {
                  return this.projectService.updateLocalVersion(cloudMetadata);
                }),
                map((updatedProject) => {
                  return (this.project = updatedProject);
                }),
              );
            }
            case "openNewVersion": {
              return this.projectService.updateLocalVersion(cloudMetadata).pipe(
                map((updatedProject) => {
                  return (this.project = updatedProject);
                }),
              );
            }
            case "overwriteNewVersion": {
              return this.projectService.exportProjectToCloud(this.project, true);
            }
            default:
              return of(undefined);
          }
        }),
        switchMap(() => of(undefined)),
      );
  }

  private fetchUserRole() {
    if (this.project.cloudId) {
      this.projectService.getUserRoleInProject(this.project.cloudId).subscribe((userData) => {
        this.isProjectOwner = userData.assignedRole === Role.OWNER;
      });
    } else {
      this.isProjectOwner = true;
    }
  }

  private navigateToProject(): void {
    if (this.linkToProject) {
      const navigationPath = `/projects/${this.project.id}`;
      this.router.navigate([navigationPath]);
    }
  }

  private handleMetadataSync() {
    return forkJoin([
      this.projectService.getMetadataById(this.project.cloudId!),
      this.projectApiService.getMetadata(this.project.cloudId!),
    ]).pipe(
      switchMap(([localMetadata, cloudMetadata]) => {
        if (!localMetadata && !cloudMetadata) {
          return this.projectService.exportProjectToCloud(this.project);
        }
        if (!localMetadata && cloudMetadata) {
          localMetadata = cloudMetadata;
        }
        return this.isMetadataEqual(localMetadata, cloudMetadata!)
          ? this.projectService.updateLocalMetadata(cloudMetadata!)
          : this.openVersionConflictModal(localMetadata, cloudMetadata!);
      }),
    );
  }

  private isMetadataEqual(localMetadata: CloudProjectMetadata, cloudMetadata: CloudProjectMetadata): boolean {
    return localMetadata.projectHash === cloudMetadata.projectHash;
  }

  get offlineToolTipMessage() {
    return !navigator.onLine
      ? $localize`:@@project.online.requiredOnline:Einige Funktionen sind nur im Online-Modus verfügbar.`
      : null;
  }

  openShareLinkModal() {
    this.readAccessModalService.openModal(this.project);
  }
}

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ProjectFormsService } from "@components/project-forms/project-forms.service";
import { Project } from "@domain/project/project";
import { Countries, Country, MapCountryNamePipe } from "@domain/user/country";
import { User } from "@domain/user/user";
import { UserService } from "@domain/user/user.service";
import { AreaHeaderComponent } from "@odx/angular/components/area-header";
import { FormFieldComponent, FormGroupComponent } from "@odx/angular/components/form-field";
import { first, map, Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-project-form",
  templateUrl: "./project-form.component.html",
  styleUrls: ["./project-form.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, AreaHeaderComponent, FormFieldComponent, FormGroupComponent, MapCountryNamePipe],
})
export class ProjectFormComponent implements OnInit, OnDestroy {
  @Input() isReadonly: boolean = false;
  @Output() projectFormEmitter$ = new EventEmitter<FormGroup>();
  projectForm!: FormGroup;

  @ViewChild("submitButton")
  submitButton?: ElementRef;

  protected countries: Country[] = Countries.COUNTRIES;
  protected projectInitialized: boolean = false;
  protected projectCountry?: string;
  protected isReadOnlyUser$: Observable<boolean>;

  private user!: User;

  private readonly _destroying = new Subject<void>();

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private projectFormsService: ProjectFormsService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));

    this.userService.user$.pipe(first()).subscribe((user) => {
      this.user = user;
      this.projectForm = this.formBuilder.group({
        name: [null, Validators.required],
        version: [],
        forzaId: [],
        placeName: [null, Validators.required],
        country: [this.user.country, Validators.required],
        notes: [],
      });
      this.projectFormsService.projectInitialized$.pipe(takeUntil(this._destroying)).subscribe((project) => {
        this.projectInitialized = true;
        this.projectCountry = project.country;
        return this.initForm(project);
      });

      this.projectFormsService.projectReset$.pipe(takeUntil(this._destroying)).subscribe((project) => {
        this.projectForm.reset();
        this.initForm(project);
      });

      this.projectForm.statusChanges
        .pipe(takeUntil(this._destroying))
        .subscribe(() => this.projectFormEmitter$.emit(this.projectForm));
    });
  }

  ngOnInit(): void {
    this.projectFormEmitter$.emit(this.projectForm);
  }

  ngOnDestroy(): void {
    this._destroying.next();
    this._destroying.complete();
  }

  initForm(project: Project) {
    this.projectForm.patchValue({
      name: project.name,
      version: project.version,
      forzaId: project.forzaId,
      placeName: project.placeName,
      country: project.country,
      notes: project.notes,
    });
  }

  triggerSubmit() {
    this.submitButton?.nativeElement.click();
  }
}

import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { CoreModule } from "@odx/angular";
import { DynamicTextContent } from "@odx/angular/cdk/dynamic-view";
import { FormFieldModule } from "@odx/angular/components/form-field";
import { IconComponent } from "@odx/angular/components/icon";
import { PageChangeEvent, PaginatorModule } from "@odx/angular/components/paginator";
import { SortStatus, TableComponent, TableHeaderCell, TableModule, TableSortVariant } from "@odx/angular/components/table";
import { TooltipDirective } from "@odx/angular/components/tooltip";

interface TableData {
  displayName: string;
  lastModifiedOn: string;
  cloudProjectMetadata: CloudProjectMetadata;
}

@Component({
  selector: "app-cloud-projects-odx-table",
  standalone: true,
  templateUrl: "cloud-projects-odx-table.component.html",
  imports: [
    CoreModule,
    TableModule,
    ReactiveFormsModule,
    FormFieldModule,
    TableComponent,
    PaginatorModule,
    IconComponent,
    TooltipDirective,
  ],
  styleUrls: ["cloud-projects-odx-table.component.scss"],
})
export class CloudProjectsOdxTableComponent {
  private sortParams!: SortStatus;
  private filterParams!: Record<string, string>;

  protected selected?: CloudProjectMetadata;
  protected totalData: TableData[] = [];
  protected dataToDisplay: TableData[] = [];
  protected total = 0;
  protected pageIndex = 0;
  protected readonly tableSize = 10;
  protected readonly paginationParams = {
    startIndex: 0,
    endIndex: this.tableSize,
  };
  protected readonly headerData: TableHeaderCell[] = [
    { name: "displayName", title: $localize`:@@cloudProjectsTable.column1.header:Projektname`, sortable: true, filter: true },
    {
      name: "lastModifiedOn",
      title: $localize`:@@cloudProjectsTable.column2.header:Zuletzt synchronisiert`,
      sortable: true,
      filter: true,
    },
    { name: "cloudProjectMetadata", title: "", sortable: false, filter: false }, // Workaround to display no header above the last column as otherwise odx automatically generates the header.
  ];

  @Input({ required: true }) variant!: string;

  @Input() set cloudProjectMetadata(data: CloudProjectMetadata[]) {
    this.totalData = data.map((cloudProjectMetadata) => {
      return {
        displayName: cloudProjectMetadata.displayName,
        lastModifiedOn: cloudProjectMetadata.lastModifiedOn,
        cloudProjectMetadata: cloudProjectMetadata,
      };
    });
    this.sortParams = { column: "lastModifiedOn", sortVariant: TableSortVariant.DESC };
    this.sortData();
    this.dataToDisplay = this.generateDataToDisplay();
  }

  @Output() projectSelected = new EventEmitter<CloudProjectMetadata>();

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  public sorted(sortParams: SortStatus): void {
    this.sortParams = sortParams;
    this.dataToDisplay = this.generateDataToDisplay();
  }

  public filter(filterParams: Record<string, string>): void {
    this.pageIndex = 0;
    this.filterParams = filterParams;
    this.paginationParams.startIndex = 0;
    this.paginationParams.endIndex = this.tableSize;
    this.dataToDisplay = this.generateDataToDisplay();
  }

  public pageChanged(event: PageChangeEvent): void {
    this.pageIndex = event.pageIndex;
    this.paginationParams.startIndex = event.pageIndex * this.tableSize;
    this.paginationParams.endIndex = (event.pageIndex + 1) * this.tableSize;
    this.dataToDisplay = this.generateDataToDisplay();
  }

  protected onClickRow(cloudProjectMetadata: CloudProjectMetadata) {
    if (this.variant !== "import") {
      return;
    }
    this.selected = cloudProjectMetadata;
    this.projectSelected.emit(cloudProjectMetadata);
  }

  protected onClickIcon(cloudProjectMetadata: CloudProjectMetadata) {
    this.projectSelected.emit(cloudProjectMetadata);
  }

  protected getSummaryContentFn(startItemIndex: number, endItemIndex: number, length: number): DynamicTextContent {
    const strongStart = "<strong>";
    const strongEnd = "</strong>";
    return $localize`:@@cloudProjectsTable.summaryContent:${strongStart}${startItemIndex}:startItemIndex:${strongEnd} bis ${strongStart}${endItemIndex}:endItemIndex:${strongEnd} von ${strongStart}${length}:length:${strongEnd}`;
  }

  protected getRangeContentFn(pageIndex: number, totalPages: number): DynamicTextContent {
    const strongStart = "<strong>";
    const strongEnd = "</strong>";
    return $localize`:@@cloudProjectsTable.pagination:Seite ${strongStart}${pageIndex + 1}:pageIndex:${strongEnd} von ${strongStart}${totalPages}:totalPages:${strongEnd}`;
  }

  private generateDataToDisplay(): TableData[] {
    this.sortData();
    const convertedData = this.convertLastModifiedOn(this.totalData);
    const filteredData = this.filterData(convertedData);
    this.total = filteredData.length;
    return filteredData.slice(this.paginationParams.startIndex, this.paginationParams.endIndex);
  }

  protected convertTimestampToDate(timestamp: string) {
    if (timestamp.startsWith("0")) {
      return "--";
    }
    const date = new Date(timestamp);
    return `${date.toLocaleDateString(this.localeId)}, ${date.toLocaleTimeString(this.localeId)}`;
  }

  private sortData(): void {
    if (!this.sortParams) return;

    this.totalData.sort((a, b) => {
      const { column } = this.sortParams;
      const key = column as keyof TableData;
      switch (this.sortParams.sortVariant) {
        case TableSortVariant.ASC:
          return (a[key] < b[key] && -1) || (a[key] > b[key] && 1) || 0;

        case TableSortVariant.DESC:
          return (a[key] < b[key] && 1) || (a[key] > b[key] && -1) || 0;

        default:
          return 0;
      }
    });
  }

  private filterData(data: TableData[]): TableData[] {
    if (!this.filterParams) return data;

    return data.filter((item: TableData) => {
      return Object.keys(this.filterParams)
        .map(
          (key) =>
            key in item &&
            item[key as keyof TableData].toString().toLowerCase().indexOf(this.filterParams[key]?.toLowerCase().trim()) !== -1,
        )
        .every(Boolean);
    });
  }

  private convertLastModifiedOn(data: TableData[]): TableData[] {
    return data.map((tableData) => {
      const convertedTableData: TableData = { ...tableData };
      if (!convertedTableData.lastModifiedOn.startsWith("2")) {
        convertedTableData.lastModifiedOn = "--";
        return convertedTableData;
      }
      const durationInDays = this.calculateDurationInDays(convertedTableData.lastModifiedOn);
      switch (true) {
        case durationInDays === 0:
          convertedTableData.lastModifiedOn = $localize`:@@global.today:heute`;
          break;
        case durationInDays === 1:
          convertedTableData.lastModifiedOn = $localize`:@@global.yesterday:gestern`;
          break;
        default:
          convertedTableData.lastModifiedOn = $localize`:@@cloudProjectsTable.lastModifiedDuration:vor ${durationInDays.toString()}:duration: Tagen`;
          break;
      }
      return convertedTableData;
    });
  }

  private calculateDurationInDays(timestamp: string): number {
    const timeDifference: number = new Date().getTime() - new Date(timestamp).getTime();
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  }
}

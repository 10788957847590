import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { ProjectDetailHeaderComponent } from "@components/project-detail-header/project-detail-header.component";
import { Project } from "@domain/project/project";
import { User } from "@domain/user/user";
import { UserService } from "@domain/user/user.service";
import { ChecklistComponent } from "@project/project-details/checklist/checklist.component";
import { FilesComponent } from "@project/project-details/files/files.component";
import { FloorplanUploadComponent } from "@project/project-details/floorplan-upload/floorplan-upload.component";
import { ImageUploadComponent } from "@project/project-details/image-upload/image-upload.component";
import { ProductListComponent } from "@project/project-details/product-list/product-list.component";
import { ProjectCollaborationComponent } from "@project/project-details/project-collaboration/project-collaboration.component";
import { ProjectInformationComponent } from "@project/project-details/project-information/project-information.component";
import { first, map, Observable } from "rxjs";

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    ProjectDetailHeaderComponent,
    FloorplanUploadComponent,
    ImageUploadComponent,
    ProductListComponent,
    ProjectInformationComponent,
    ChecklistComponent,
    ProjectCollaborationComponent,
    FilesComponent,
  ],
})
export class ProjectDetailsComponent implements OnInit {
  protected project!: Project;
  protected tabIndex: number = 0;
  protected isReadOnlyUser$: Observable<boolean>;
  protected hideBackArrow = false;

  private user!: User;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
  }

  ngOnInit(): void {
    this.project = this.activeRoute.snapshot.data["project"];
    this.project.setAllPositionIds();

    this.userService.user$.pipe(first()).subscribe((user) => {
      this.user = user;
      if (user.readOnlyAccess) this.hideBackArrow = true;
    });
  }

  navigateBack() {
    this.router.navigate(["/"]);
  }

  setTabIndex(defaultTabIndex: number, reduceTabIndex: number) {
    return this.user["readOnlyAccess"] ? defaultTabIndex - reduceTabIndex : defaultTabIndex;
  }
}

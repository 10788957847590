import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { Project } from "@domain/project/project";
import { IsInstanceOf } from "@utils/class-validator/class-validator-constraints";
import { Type } from "class-transformer";

export class ProjectResponseDto {
  @Type(() => Project)
  @IsInstanceOf(Project)
  project: Project | undefined;

  @Type(() => CloudProjectMetadata)
  @IsInstanceOf(CloudProjectMetadata)
  metadata: CloudProjectMetadata | undefined;
}

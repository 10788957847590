import { HttpClient, HttpResponse } from "@angular/common/http";
import { Project } from "@domain/project/project";
import { catchError, map, Observable, of, ReplaySubject, throwError } from "rxjs";

import { Injectable } from "@angular/core";
import { ProjectResponseDto } from "@domain/project/api/project-response.dto";
import { UserDataDto } from "@domain/project/api/user-data.dto";
import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { ProjectFile } from "@domain/project/project-file";
import { environment } from "@environments/environment";
import { ObservableInstanceMapper } from "@utils/observable-instance-mapper";
import { instanceToPlain, plainToInstance } from "class-transformer";

@Injectable({
  providedIn: "root",
})
export class ProjectApiService {
  private readonly _projects$ = new ReplaySubject<Project[]>(1);
  public static PROJECTS_URL = environment.apiUrl + "/projects";
  constructor(private http: HttpClient) {}

  exportProjectToCloud(project: Project, metadata?: CloudProjectMetadata, forceUpdate?: boolean): Observable<ProjectResponseDto> {
    return this.createExportToCloudRequest(project, metadata, forceUpdate).pipe(
      map((response) => {
        return plainToInstance(ProjectResponseDto, response);
      }),
    );
  }

  getMetadata(cloudId: string): Observable<CloudProjectMetadata | undefined> {
    return ObservableInstanceMapper.valueToInstance(
      this.http.get(`${ProjectApiService.PROJECTS_URL}/${cloudId}/metadata`),
      CloudProjectMetadata,
    ).pipe(
      map((metadata) => {
        if (metadata.name) {
          metadata.displayName = metadata.name;
        }
        return metadata;
      }),
      catchError((error) => {
        if (error.status === 404) {
          return of(undefined);
        }
        return throwError(() => error);
      }),
    );
  }

  inviteCollaborators(emailAddresses: string[], cloudId: string | undefined): Observable<any> {
    if (!cloudId) {
      throw new Error("cloudId is undefined");
    }
    const invitedAt: Date = new Date();
    const payload = {
      invitedAt: invitedAt,
      collaboratorMailAddresses: emailAddresses,
    };
    return this.http.post(`${ProjectApiService.PROJECTS_URL}/${cloudId}/collaboration/invite`, payload).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  deleteProjectFromCloud(cloudId: string): Observable<any> {
    return this.http.delete(`${ProjectApiService.PROJECTS_URL}/${cloudId}`);
  }

  completeInvitation(cloudId: string, invitationId: string): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(
      `${ProjectApiService.PROJECTS_URL}/${cloudId}/invitations/${invitationId}/confirm`,
      {},
      { observe: "response" },
    );
  }

  removeCollaborator(cloudId: string | undefined, collaboratorId: string): Observable<CloudProjectMetadata> {
    if (!cloudId) {
      throw new Error("cloudId is undefined");
    }
    return this.http
      .patch(`${ProjectApiService.PROJECTS_URL}/${cloudId}/collaboration/remove`, { collaboratorId: collaboratorId })
      .pipe(map((response) => plainToInstance(CloudProjectMetadata, response)));
  }

  mapAndCleanProjectForExport(project: Project): any {
    const plainProject = instanceToPlain(project);
    plainProject["cloudId"] = undefined; // unset cloudId to not save it to cloud
    plainProject["lastCloudSync"] = undefined; // unset lastCloudSync to not save it to cloud
    return plainProject;
  }

  getUserData(cloudId: string): Observable<UserDataDto> {
    return this.http
      .get(`${ProjectApiService.PROJECTS_URL}/${cloudId}/user`)
      .pipe(map((response) => plainToInstance(UserDataDto, response)));
  }

  deleteFile(cloudId: string, fileId: string): Observable<CloudProjectMetadata> {
    return this.http
      .delete(`${ProjectApiService.PROJECTS_URL}/${cloudId}/files/${fileId}`)
      .pipe(map((response) => plainToInstance(CloudProjectMetadata, response)));
  }

  addFiles(cloudId: string, blobs: Blob[]): Observable<CloudProjectMetadata> {
    const formData = new FormData();
    for (const blob of blobs) {
      formData.append("files", blob);
    }
    return this.http
      .post(`${ProjectApiService.PROJECTS_URL}/${cloudId}/files`, formData)
      .pipe(map((response) => plainToInstance(CloudProjectMetadata, response)));
  }

  loadProjectFiles(cloudId: string) {
    return this.http.get<any[]>(`${ProjectApiService.PROJECTS_URL}/${cloudId}/files`).pipe(
      map((response) => {
        response.map(() => {
          return plainToInstance(ProjectFile, response);
        });
        return response;
      }),
    );
  }

  downloadFile(file: ProjectFile) {
    return this.http.get(`${ProjectApiService.PROJECTS_URL}/${file.projectId}/files/${file.id}`, { responseType: "blob" });
  }

  getReadAccessProject(cloudId: string) {
    return this.http.get(`${ProjectApiService.PROJECTS_URL}/${cloudId}/read-access`);
  }

  private createExportToCloudRequest(project: Project, metadata?: CloudProjectMetadata, forceUpdate?: boolean) {
    const payload = this.createPayload(project, metadata);
    if (project.cloudId && metadata) {
      if (forceUpdate) {
        return this.http.put(`${ProjectApiService.PROJECTS_URL}/${project.cloudId}?forceUpdate=${forceUpdate}`, payload);
      }
      return this.http.put(`${ProjectApiService.PROJECTS_URL}/${project.cloudId}`, payload);
    }
    return this.http.post(ProjectApiService.PROJECTS_URL, payload);
  }

  private createPayload(project: Project, metadata?: CloudProjectMetadata) {
    if (metadata) {
      const plainMetadata = instanceToPlain(metadata);
      return {
        project: this.mapAndCleanProjectForExport(project),
        metadata: plainMetadata,
      };
    }
    return { project: this.mapAndCleanProjectForExport(project) };
  }

  shareProjectLink(payload: { mailAddresses: string[]; showPrices: boolean }, cloudId: string): Observable<any> {
    return this.http.post(`${ProjectApiService.PROJECTS_URL}/${cloudId}/read-access`, payload);
  }
}

<odx-area-header
  ><h6 class="odx-title-6" i18n="@@newProject.goodsRecipientForm.header.goodsRecipient">Warenempfänger</h6></odx-area-header
>
<form odxForm [formGroup]="goodsRecipientForm" class="form" [readonly]="isReadOnlyUser$ | async">
  <odx-form-field i18n-label="@@newProject.goodsRecipientForm.label.information" label="Informationen">
    <textarea
      type="text"
      formControlName="information"
      odxFormFieldControl
      i18n-placeholder="@@newProject.goodsRecipientForm.label.information"
      placeholder="Informationen"
      class="textarea-large"
      [readonly]="isReadonly"
    ></textarea>
  </odx-form-field>
  <!--Needed to show validation errors on submit without userinput-->
  <button type="submit" class="hidden" #submitButton></button>
</form>

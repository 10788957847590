<odx-card [menuIcon]="'more'" [variant]="'launch-tile'" odxLayout="flex" class="search-item">
  <odx-avatar [ngClass]="'avatar'">
    <app-standard-image [imageUrl]="imageUrl" [fallbackImageUrl]="fallbackImageUrl" [iconSize]="'56px'"></app-standard-image>
  </odx-avatar>
  <div class="title" [odxTooltip]="product | localizeProductName" odxTooltipSize="medium">
    {{ product | localizeProductName }}
  </div>
  <odx-launch-card-subtitle>
    <p class="odx-text--small" [odxTooltip]="product.id" odxTooltipSize="medium">
      {{ product.id }}
    </p>
  </odx-launch-card-subtitle>
</odx-card>

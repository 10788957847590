import { CurrencyPipe } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Inject, Input, LOCALE_ID, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { FloorplanAlarmDevice } from "@domain/project/floorplan/floorplan-alarm-device";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanPlasticSign } from "@domain/project/floorplan/floorplan-plastic-sign";
import { FloorplanSignalElement } from "@domain/project/floorplan/floorplan-signal-element";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { ProductData } from "@domain/project/product-data/product-data";
import { ProductDataService } from "@domain/project/product-data/product-data.service";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { UserService } from "@domain/user/user.service";
import { ProductTableComponent } from "@project/project-details/product-list/product-table/product-table.component";
import { ProjectCostService } from "@project/project-details/product-list/project-cost.service";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-project-products",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, ProductTableComponent],
})
export class ProductListComponent implements AfterViewInit {
  @Input() set projectData(project: Project) {
    this.productListDataInitialized = false;
    this.project = project;
    this.initServiceForm();
    this.init();
  }

  @ViewChild("assemblyCosts")
  assemblyCosts!: ElementRef;

  @ViewChild("installationCosts")
  installationCosts!: ElementRef;

  @ViewChild("documentationCosts")
  documentationCosts!: ElementRef;

  @ViewChild("engineeringCosts")
  engineeringCosts!: ElementRef;

  @ViewChild("additionalServicesCosts")
  additionalServicesCosts!: ElementRef;

  project!: Project;
  productListDataInitialized: boolean = false;

  gasWarningCenters!: GasWarningCenterConfiguration[];
  floorplanGasWarningCenters!: FloorplanGasWarningCenter[];
  floorplanGasWarningCenterPlaceholders!: FloorplanGasWarningCenterPlaceholder[];
  gasWarningCenterData!: ProductData[][];
  gasWarningCenterPlaceholders!: ProductData[][];
  gasWarningCenterSubtotal$!: Observable<number>;

  transmitters!: TransmitterConfiguration[];
  floorplanTransmitters!: FloorplanTransmitter[];
  floorplanTransmitterPlaceholders!: FloorplanTransmitterPlaceholder[];
  transmitterData!: ProductData[][];
  transmitterPlaceholders!: ProductData[][];
  transmitterSubtotal$!: Observable<number>;

  alarmDevices!: AlarmDeviceConfiguration[];
  floorplanAlarmDevices!: FloorplanAlarmDevice[];
  floorplanAlarmDevicePlaceholders!: FloorplanPlaceholder[];
  alarmDeviceData!: ProductData[][];
  alarmDevicePlaceholders!: ProductData[][];
  alarmDeviceSubtotal$!: Observable<number>;

  signalElements!: SignalElementConfiguration[];
  floorplanSignalElements!: FloorplanSignalElement[];
  floorplanSignalElementPlaceholders!: FloorplanPlaceholder[];
  signalElementsData!: ProductData[][];
  signalElementPlaceholders!: ProductData[][];
  signalElementSubtotal$!: Observable<number>;

  plasticSigns!: PlasticSignConfiguration[];
  floorplanPlasticSigns!: FloorplanPlasticSign[];
  floorplanPlasticSignPlaceholders!: FloorplanPlaceholder[];
  plasticSignsData!: ProductData[][];
  plasticSignPlaceholders!: ProductData[][];
  plasticSignSubtotal$!: Observable<number>;

  allProductsSubtotal$!: Observable<number>;
  projectTotalNetCosts$!: Observable<number>;

  servicesForm!: FormGroup;
  protected isReadOnlyUser$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private currencyPipe: CurrencyPipe,
    private projectCostService: ProjectCostService,
    private productDataService: ProductDataService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
  }

  ngAfterViewInit(): void {
    this.assemblyCosts.nativeElement.value = this.currencyPipe.transform(
      this.project.assembly.costs,
      "EUR",
      "symbol",
      "1.2-2",
      this.locale,
    );
    this.installationCosts.nativeElement.value = this.currencyPipe.transform(
      this.project.installation.costs,
      "EUR",
      "symbol",
      "1.2-2",
      this.locale,
    );
    this.documentationCosts.nativeElement.value = this.currencyPipe.transform(
      this.project.documentation.costs,
      "EUR",
      "symbol",
      "1.2-2",
      this.locale,
    );
    this.engineeringCosts.nativeElement.value = this.currencyPipe.transform(
      this.project.engineering.costs,
      "EUR",
      "symbol",
      "1.2-2",
      this.locale,
    );
    this.additionalServicesCosts.nativeElement.value = this.currencyPipe.transform(
      this.project.additionalServices.costs,
      "EUR",
      "symbol",
      "1.2-2",
      this.locale,
    );
  }

  getAssemblyPosition(): number {
    return this.projectCostService.getAssemblyPosition(this.project);
  }

  getInstallationPosition(): number {
    return this.projectCostService.getInstallationPosition(this.project);
  }

  getDocumentationPosition(): number {
    return this.projectCostService.getDocumentationPosition(this.project);
  }

  getEngineeringPosition(): number {
    return this.projectCostService.getEngineeringPosition(this.project);
  }

  getAdditionalServicesPosition(): number {
    return this.projectCostService.getAdditionalServicesPosition(this.project);
  }

  protected processValue(event: any, formControlName: string) {
    const value = this.transformAmount(event.target.value, this.locale);
    this.servicesForm.patchValue({ [formControlName]: value });
    event.target.value = this.currencyPipe.transform(value, "EUR", "symbol", "1.2-2", this.locale);
  }

  protected calcServiceSubtotal(): number {
    return this.projectCostService.calculateServicesCosts(this.project);
  }

  private init() {
    this.productDataService.collectProjectData(this.project).subscribe((results: any[]) => {
      this.gasWarningCenterData = results[0];
      this.gasWarningCenterPlaceholders = results[1];
      this.gasWarningCenters = this.project.gasWarningCenters.filter(
        (gasWarningCenter) => gasWarningCenter.countPlacedProducts() > 0,
      );
      this.floorplanGasWarningCenters = this.project.floorplans.reduce(
        (acc: FloorplanGasWarningCenter[], floorplan) => acc.concat(floorplan.gasWarningCenters),
        [],
      );
      this.floorplanGasWarningCenterPlaceholders = this.project.floorplans.reduce(
        (acc: FloorplanGasWarningCenterPlaceholder[], floorplan) => acc.concat(floorplan.gasWarningCenterPlaceholders),
        [],
      );
      this.gasWarningCenterSubtotal$ = this.projectCostService.calculateProductSubtotal(this.gasWarningCenters);

      this.transmitterData = results[2];
      this.transmitterPlaceholders = results[3];
      this.transmitters = this.project.transmitters.filter((transmitter) => transmitter.countPlacedProducts() > 0);
      this.floorplanTransmitters = this.project.floorplans.reduce(
        (acc: FloorplanTransmitter[], floorplan) => acc.concat(floorplan.transmitters),
        [],
      );
      this.floorplanTransmitterPlaceholders = this.project.floorplans.reduce(
        (acc: FloorplanTransmitterPlaceholder[], floorplan) => acc.concat(floorplan.transmitterPlaceholders),
        [],
      );
      this.transmitterSubtotal$ = this.projectCostService.calculateProductSubtotal(this.transmitters);

      this.alarmDeviceData = results[4];
      this.alarmDevicePlaceholders = results[5];
      this.alarmDevices = this.project.alarmDevices.filter((alarmDevice) => alarmDevice.countPlacedProducts() > 0);
      this.floorplanAlarmDevices = this.project.floorplans.reduce(
        (acc: FloorplanAlarmDevice[], floorplan) => acc.concat(floorplan.alarmDevices),
        [],
      );
      this.floorplanAlarmDevicePlaceholders = this.project.floorplans.reduce(
        (acc: FloorplanPlaceholder[], floorplan) => acc.concat(floorplan.alarmDevicePlaceholders),
        [],
      );
      this.alarmDeviceSubtotal$ = this.projectCostService.calculateProductSubtotal(this.alarmDevices);

      this.signalElementsData = results[6];
      this.signalElementPlaceholders = results[7];
      this.signalElements = this.project.signalElements.filter((signalElement) => signalElement.countPlacedProducts() > 0);
      this.floorplanSignalElements = this.project.floorplans.reduce(
        (acc: FloorplanSignalElement[], floorplan) => acc.concat(floorplan.signalElements),
        [],
      );
      this.floorplanSignalElementPlaceholders = this.project.floorplans.reduce(
        (acc: FloorplanPlaceholder[], floorplan) => acc.concat(floorplan.signalElementPlaceholders),
        [],
      );
      this.signalElementSubtotal$ = this.projectCostService.calculateProductSubtotal(this.signalElements);

      this.plasticSignsData = results[8];
      this.plasticSignPlaceholders = results[9];
      this.plasticSigns = this.project.plasticSigns.filter((plasticSign) => plasticSign.countPlacedProducts() > 0);
      this.floorplanPlasticSigns = this.project.floorplans.reduce(
        (acc: FloorplanPlasticSign[], floorplan) => acc.concat(floorplan.plasticSigns),
        [],
      );
      this.floorplanPlasticSignPlaceholders = this.project.floorplans.reduce(
        (acc: FloorplanPlaceholder[], floorplan) => acc.concat(floorplan.plasticSignPlaceholders),
        [],
      );
      this.plasticSignSubtotal$ = this.projectCostService.calculateProductSubtotal(this.plasticSigns);

      this.allProductsSubtotal$ = this.projectCostService.calculateAllProductsTotal(this.project);
      this.projectTotalNetCosts$ = this.projectCostService.calculateTotalNetCosts(this.project);

      this.productListDataInitialized = true;
    });
  }

  private transformAmount(value: string, locale: string): number {
    const decimalSeparator: string = locale.startsWith("en") ? "." : ",";
    let decimalJoined: string = "00";
    if (value.indexOf(decimalSeparator) > -1) {
      const decimalValue = value.split(decimalSeparator)[1].split("");
      const decimalFiltered = this.removeNaN(decimalValue);
      decimalJoined = decimalFiltered.join("");
    }

    const integerValue = value.split(decimalSeparator)[0].split("");
    const integerFiltered = this.removeNaN(integerValue);
    const integerJoined = integerFiltered.join("");

    return +(integerJoined + "." + decimalJoined);
  }

  private removeNaN(array: string[]) {
    return array.filter((element: any) => !isNaN(element));
  }

  private initServiceForm() {
    this.servicesForm = this.formBuilder.group({
      assembly: [this.project.assembly.required],
      assemblyCosts: [this.project.assembly.costs],
      assemblyNotes: [this.project.assembly.notes],
      installation: [this.project.installation.required],
      installationCosts: [this.project.installation.costs],
      installationNotes: [this.project.installation.notes],
      documentation: [this.project.documentation.required],
      documentationCosts: [this.project.documentation.costs],
      documentationNotes: [this.project.documentation.notes],
      engineering: [this.project.engineering.required],
      engineeringCosts: [this.project.engineering.costs],
      engineeringNotes: [this.project.engineering.notes],
      additionalServices: [this.project.additionalServices.required],
      additionalServicesCosts: [this.project.additionalServices.costs],
      additionalServicesNotes: [this.project.additionalServices.notes],
    });

    this.servicesForm.valueChanges.subscribe(() => {
      this.mapServices();
      this.projectService.update(this.project).subscribe();
      this.projectTotalNetCosts$ = this.projectCostService.calculateTotalNetCosts(this.project);
    });
  }

  private mapServices() {
    this.project.assembly.required = this.servicesForm.value.assembly;
    this.project.assembly.costs = this.servicesForm.value.assemblyCosts;
    this.project.assembly.notes = this.servicesForm.value.assemblyNotes;
    this.project.installation.required = this.servicesForm.value.installation;
    this.project.installation.costs = this.servicesForm.value.installationCosts;
    this.project.installation.notes = this.servicesForm.value.installationNotes;
    this.project.documentation.required = this.servicesForm.value.documentation;
    this.project.documentation.costs = this.servicesForm.value.documentationCosts;
    this.project.documentation.notes = this.servicesForm.value.documentationNotes;
    this.project.engineering.required = this.servicesForm.value.engineering;
    this.project.engineering.costs = this.servicesForm.value.engineeringCosts;
    this.project.engineering.notes = this.servicesForm.value.engineeringNotes;
    this.project.additionalServices.required = this.servicesForm.value.additionalServices;
    this.project.additionalServices.costs = this.servicesForm.value.additionalServicesCosts;
    this.project.additionalServices.notes = this.servicesForm.value.additionalServicesNotes;
    this.project.lastModified = new Date();
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { AttachmentSelectorComponent } from "@components/attachment-selector/attachment-selector.component";
import { ConfigurationWizardBackButtonComponent } from "@components/configuration-wizard-back-button/configuration-wizard-back-button.component";
import { ProductConfigStepperBarComponent } from "@components/stepper/product-config-stepper-bar/product-config-stepper-bar.component";
import { Gas } from "@domain/gas/gas";
import { LocalizeProductNamePipe, Product } from "@domain/product/product";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { Project } from "@domain/project/project";
import { WizardComponent } from "@odx/angular/components/wizard";
import { FloorplanMenuType } from "@project/floorplanner/floorplan-menu/floorplan-menu-type";
import { OriginalProductConfigurationFactory } from "@project/original-product-configuration-factory";
import { ProductConfigurationService } from "@project/product-configuration.service";
import { GasSelectorComponent } from "@project/transmitter-configuration/gas-selector/gas-selector.component";
import { OriginalTransmitterConfiguration } from "@project/transmitter-configuration/original-transmitter-configuration";
import { SensorSelectorComponent } from "@project/transmitter-configuration/sensor-selector/sensor-selector.component";
import { TransmitterSelectorComponent } from "@project/transmitter-configuration/transmitter-selector/transmitter-selector.component";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-transmitter-configuration",
  templateUrl: "./transmitter-configuration.component.html",
  styleUrls: ["./transmitter-configuration.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    ConfigurationWizardBackButtonComponent,
    GasSelectorComponent,
    TransmitterSelectorComponent,
    SensorSelectorComponent,
    AttachmentSelectorComponent,
    ProductConfigStepperBarComponent,
    LocalizeProductNamePipe,
  ],
  providers: [OriginalProductConfigurationFactory, LocalizeProductNamePipe],
})
export class TransmitterConfigurationComponent implements OnInit {
  protected showCosts = false;
  protected project!: Project;
  protected selectedGas?: Gas;
  protected selectedTransmitter?: Product;
  protected needsSensor = false;
  protected selectedSensor?: Product;
  protected selectedAttachments: Product[] = [];
  protected activeStep = 0;
  protected steps = 3;
  protected originalConfiguration?: OriginalTransmitterConfiguration;
  protected isIncompleteConfiguration: boolean = false;

  protected readonly FloorplanMenuType = FloorplanMenuType;

  @ViewChild("configurationWizard")
  private configurationWizard!: WizardComponent;

  @ViewChild("backButton")
  private backButton!: ConfigurationWizardBackButtonComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private originalProductConfigurationService: OriginalProductConfigurationFactory,
    private productConfigurationService: ProductConfigurationService,
    private localizeProductNamePipe: LocalizeProductNamePipe,
  ) {}

  ngOnInit(): void {
    this.project = this.activeRoute.parent!.snapshot.data["project"];
    this.showCosts = this.project.showCostFlag;
    if (this.activeRoute.snapshot.queryParams["config_id"]) {
      const configId = this.activeRoute.snapshot.queryParams["config_id"];
      const originalConfiguration$ = this.originalProductConfigurationService.createOriginalTransmitterConfiguration(
        this.project,
        configId,
      );
      const incompleteConfigurationIds$ = this.productConfigurationService.getIncompleteConfigIds(this.project);
      forkJoin([originalConfiguration$, incompleteConfigurationIds$]).subscribe(
        ([originalConfiguration, incompleteConfigurationIds]) => {
          this.originalConfiguration = originalConfiguration;
          this.isIncompleteConfiguration = incompleteConfigurationIds.includes(configId);
        },
      );
    }
  }

  onSubmit() {
    if (this.activeStep === 0) {
      if (!this.selectedGas) return;
    } else if (this.activeStep === 1) {
      if (!this.selectedTransmitter) return;
      if (!this.needsSensor) {
        this.configurationWizard.setActiveStepValid();
        this.configurationWizard.nextStep();
      }
    }
    if (this.activeStep === 2 && this.needsSensor) {
      if (!this.selectedSensor) return;
    } else if (this.activeStep === 3) {
      if (this.originalConfiguration) {
        this.updateTransmitterConfig(this.originalConfiguration.productConfiguration!);
      } else {
        this.createNewTransmitterConfig();
      }
    }
    this.configurationWizard.setActiveStepValid();
    this.configurationWizard.nextStep();
  }

  protected setActiveStep(event: number) {
    if (event === 0 && this.originalConfiguration && !this.isIncompleteConfiguration) {
      this.configurationWizard.setActiveStepValid();
      this.configurationWizard.nextStep();
    } else {
      this.activeStep = event;
    }
  }

  protected selectTransmitter(transmitter?: Product) {
    this.selectedTransmitter = transmitter;
    this.steps = 3;
    if (this.selectedTransmitter && this.needsSensor) {
      this.steps = 4;
    }
  }

  protected selectGas(gas: Gas) {
    if (!gas) return;
    if (this.originalConfiguration && !this.isIncompleteConfiguration) {
      this.configurationWizard.setActiveStepValid();
      this.configurationWizard.nextStep();
    }
    this.needsSensor = false;
    this.steps = 3;
    this.selectedGas = gas;
  }

  protected onPrevious() {
    if (this.activeStep === 3 && !this.needsSensor) {
      this.configurationWizard.previousStep();
    }
    this.configurationWizard.previousStep();
  }

  private createNewTransmitterConfig() {
    const transmitterConfig = TransmitterConfiguration.create(
      this.localizeProductNamePipe.transform(this.selectedTransmitter!),
      this.project,
      this.selectedTransmitter!.id,
      this.selectedAttachments.map((attachment) => attachment.id),
      this.selectedGas!.id,
      this.selectedSensor?.id,
    );
    this.project.addTransmitters(transmitterConfig);
    this.backButton.navigateBack();
  }

  private updateTransmitterConfig(originalConfig: TransmitterConfiguration) {
    const attachmentIds = this.selectedAttachments.map((attachment) => attachment.id);
    originalConfig.update(this.selectedGas!.id, this.selectedTransmitter!, attachmentIds, this.selectedSensor?.id);
    this.backButton.navigateBack();
  }
}

<odx-modal-header>
  <odx-area-header i18n="@@modal.projectVersion.header1">Versionskonflikt</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p i18n="@@modal.projectVersion.header2">
    Eine neuere Version des Projekts <strong>{{ modalRef.data.projectName }}</strong> ist verfügbar. <br />
    Sie haben ungespeicherte Änderungen in ihrer Projektversion.
  </p>

  <table odxTable [headerData]="headerData" class="table-margin">
    <thead>
      <th></th>
    </thead>
    <tr odx-table-row>
      <td odx-table-cell>{{ modalRef.data.updatedBy }}</td>
      <td odx-table-cell>{{ modalRef.data.lastModifiedOn | localizeDate }}, {{ modalRef.data.lastModifiedOn | localizeTime }}</td>
    </tr>
  </table>

  <form [formGroup]="versionConflictForm">
    <p i18n="@@modal.projectVersion.continue">Wie möchten Sie fortfahren?</p>
    <odx-radio-group formControlName="versionConflict" required="true">
      <odx-radio-button i18n="@@modal.projectVersion.radioButton1" class="radio-button-margin" value="saveCopy"
        >Meine Version als Kopie speichern und die neuere Version öffnen</odx-radio-button
      >
      <odx-radio-button i18n="@@modal.projectVersion.radioButton2" class="radio-button-margin" value="openNewVersion"
        >Meine Änderungen verwerfen und die neuere Version öffnen</odx-radio-button
      >
      <odx-radio-button i18n="@@modal.projectVersion.radioButton3" class="radio-button-margin" value="overwriteNewVersion"
        >Die neue Version ignorieren und mit meiner Version überschreiben</odx-radio-button
      >
    </odx-radio-group>
    <p i18n="@@modal.projectVersion.warning">
      Bitte beachten Sie, dass ungespeicherte oder überschriebene Versionen gelöscht werden. <br />
      Dieser Vorgang kann nicht rückgängig gemacht werden.
    </p>
  </form>
</odx-modal-content>

<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@modal.projectVersion.dismiss">Abbrechen</button>
  <button odxButton variant="primary" (click)="onConfirm()" i18n="@@modal.projectVersion.confirm">Fortfahren</button>
</odx-modal-footer>

<div odxLayout="flex gap-small" class="flex-column h-100">
  <odx-area-header>
    <h5 class="odx-title-5" i18n="@@documentUpload.documentOverview.header">Dateien</h5>
    <odx-area-header-content>
      <odx-action-group [odxTooltip]="offlineToolTipMessage" *ngIf="(isReadOnlyUser$ | async) === false">
        <button
          odxButton
          variant="primary"
          [disabled]="!navigator.onLine"
          (click)="fileUpload.click()"
          data-testid="btn.uploadDocument"
        >
          <odx-icon name="upload" alignleft></odx-icon>
          <span i18n="@@documentUpload.button.uploadDocument">Datei hochladen</span>
        </button>
      </odx-action-group>
      <input type="file" (change)="onFileSelected($event)" class="hidden" multiple #fileUpload data-testid="input.documentName" />
    </odx-area-header-content>
  </odx-area-header>
  <ng-content></ng-content>
</div>

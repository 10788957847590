<div class="odx-bar-parent h-100" odxLayout="flex">
  <app-configuration-wizard-back-button #backButton [project]="project" [floorplanGroup]="FloorplanMenuType.TRANSMITTER">
  </app-configuration-wizard-back-button>
  <odx-wizard #configurationWizard [vertical]="true" (activeStepChanged)="setActiveStep($event)">
    <odx-wizard-step i18n="@@transmitterConfiguration.wizard.step1">Gasart</odx-wizard-step>
    <odx-wizard-step i18n="@@transmitterConfiguration.wizard.step2">Transmitter</odx-wizard-step>
    <odx-wizard-step i18n="@@transmitterConfiguration.wizard.step3" [class.odx-wizard-step--visible]="needsSensor"
      >Sensor</odx-wizard-step
    >
    <odx-wizard-step i18n="@@transmitterConfiguration.wizard.step4">Zubehör</odx-wizard-step>
  </odx-wizard>

  <app-gas-selector
    [hidden]="activeStep !== 0 || (originalConfiguration && !isIncompleteConfiguration)"
    [project]="project"
    [originalGas]="originalConfiguration?.gas"
    [originalTransmitter]="originalConfiguration?.transmitter"
    (gasSelect$)="selectGas($event)"
  ></app-gas-selector>

  <app-transmitter-selector
    class="w-100"
    [project]="project"
    [hidden]="activeStep !== 1"
    [showCosts]="project.showCostFlag"
    [selectedGasData]="selectedGas"
    [originalConfiguration]="originalConfiguration"
    (transmitterSelect$)="selectTransmitter($event)"
  ></app-transmitter-selector>

  <app-sensor-selector
    class="w-100"
    *ngIf="selectedTransmitter"
    [hidden]="activeStep !== 2 || !needsSensor"
    [selectedProducts]="
      selectedTransmitter.id === originalConfiguration?.transmitter?.id
        ? {
            selectedGas: selectedGas,
            selectedTransmitter: selectedTransmitter,
            preselectedSensor: originalConfiguration!.sensor,
          }
        : { selectedGas: selectedGas, selectedTransmitter: selectedTransmitter }
    "
    [showCosts]="project.showCostFlag"
    (sensorSelect$)="selectedSensor = $event"
    (needsSensor$)="needsSensor = $event"
  ></app-sensor-selector>
  <app-attachment-selector
    *ngIf="selectedTransmitter"
    class="w-100"
    [hidden]="activeStep !== 3"
    [headerProducts]="
      selectedSensor ? [selectedGas!, selectedTransmitter!, selectedSensor] : [selectedGas!, selectedTransmitter!]
    "
    [products]="
      selectedTransmitter.id === originalConfiguration?.transmitter?.id
        ? { selectedProducts: [selectedTransmitter], preselectedAttachments: originalConfiguration!.attachments }
        : { selectedProducts: [selectedTransmitter] }
    "
    [showCosts]="project.showCostFlag"
    (attachmentsSelect$)="selectedAttachments = $event"
  >
  </app-attachment-selector>
  <app-product-config-stepper-bar
    (dismiss)="backButton.navigateBack()"
    (next)="onSubmit()"
    (previous)="onPrevious()"
    [activeStep]="!needsSensor && activeStep === 3 ? activeStep - 1 : activeStep"
    [steps]="steps"
    [isPreviousButtonDisabled]="activeStep === 1 && originalConfiguration !== undefined && !isIncompleteConfiguration"
  >
  </app-product-config-stepper-bar>
</div>

import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { ToggleVisibilityButtonComponent } from "@components/toggle-visibility-button/toggle-visibility-button.component";
import { FloorplanState } from "@domain/project/floorplan/floorplan-state";
import { DustExZoneType } from "@domain/project/floorplan/zones/dust-ex-zone";
import { ButtonComponent } from "@odx/angular/components/button";
import { IconComponent } from "@odx/angular/components/icon";
import { FloorplanMenuItemButtonsComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-item-buttons/floorplan-menu-item-buttons.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-dust-ex-zone-menu",
  standalone: true,
  imports: [
    ButtonComponent,
    FloorplanMenuItemButtonsComponent,
    IconComponent,
    ToggleLockButtonComponent,
    ToggleVisibilityButtonComponent,
    UiKitModule,
  ],
  templateUrl: "./floorplan-dust-ex-zone-menu.component.html",
  styleUrl: "./floorplan-dust-ex-zone-menu.component.scss",
})
export class FloorplanDustExZoneMenuComponent {
  protected floorplanState: FloorplanState;
  protected readonly tooltipTextAddDustExZone0: string = $localize`:@@floorplan.button.addDustExZone:Staub-Ex-Zone ${"20"}:dustExZoneType: platzieren`;
  protected readonly tooltipTextAddDustExZone1: string = $localize`:@@floorplan.button.addDustExZone:Staub-Ex-Zone ${"21"}:dustExZoneType: platzieren`;
  protected readonly tooltipTextAddDustExZone2: string = $localize`:@@floorplan.button.addDustExZone:Staub-Ex-Zone ${"22"}:dustExZoneType: platzieren`;

  constructor(private floorplanService: FloorplanService) {
    this.floorplanState = floorplanService.selectedFloorplan.floorplanState;
  }

  addDustExZone(dustExZoneType: number) {
    if (dustExZoneType == 20) this.addToFloorplan(DustExZoneType.ZONE_20);
    if (dustExZoneType == 21) this.addToFloorplan(DustExZoneType.ZONE_21);
    if (dustExZoneType == 22) this.addToFloorplan(DustExZoneType.ZONE_22);
  }

  private addToFloorplan(type: DustExZoneType) {
    this.floorplanService.addDustExZone(type);
  }
}

import { Component, ElementRef, inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { FormEmailChipListComponent } from "@components/form-email-chip-list/form-email-chip-list.component";
import {
  AutocompleteComponent,
  AutocompleteOptionComponent,
  AutocompleteSearchFilterPipe,
} from "@odx/angular/components/autocomplete";
import { Modal, ModalContentDirective, ModalRef } from "@odx/angular/components/modal";

@Component({
  selector: "app-invite-person-to-project-modal",
  standalone: true,
  imports: [
    UiKitModule,
    ModalContentDirective,
    AutocompleteSearchFilterPipe,
    AutocompleteOptionComponent,
    AutocompleteComponent,
    FormsModule,
    ReactiveFormsModule,
    FormEmailChipListComponent,
  ],
  templateUrl: "./invite-person-to-project-modal.component.html",
  styleUrl: "./invite-person-to-project-modal.component.scss",
})
export class InvitePersonToProjectModalComponent implements Modal<any, boolean>, OnInit {
  @ViewChild("submitMails")
  submitMails?: ElementRef;

  public readonly modalRef = inject(ModalRef<any, string>);
  protected readonly tooltipAddEmail: string = $localize`:@@modal.invitePerson.addEmail:E-Mail-Adresse hinzufügen`;

  protected addedMails: string[] = [];
  protected addMailFormGroup!: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.addMailFormGroup = this.formBuilder.group({
      value: [
        "",
        [
          Validators.pattern(
            /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
          ),
        ],
      ],
    });
  }

  protected onConfirm() {
    this.modalRef.close(this.addedMails);
  }

  onMailsChanged(mails: string[]) {
    this.addedMails = mails;
  }
}

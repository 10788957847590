import { Injectable } from "@angular/core";
import { ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { AttachmentDataService } from "@domain/project/product-data/attachment-data.service";
import { ProductData } from "@domain/project/product-data/product-data";
import { Observable, forkJoin, map, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TransmitterDataService {
  constructor(
    private productService: ProductService,
    private attachmentDataService: AttachmentDataService,
  ) {}

  getTransmitterData(
    transmitters: readonly TransmitterConfiguration[],
    incompleteConfigIds: string[],
    localeId: string,
  ): Observable<ProductData[][]> {
    if (transmitters.length == 0) {
      return of([]);
    }
    return forkJoin(transmitters.map((transmitter) => this.getProducts(transmitter, incompleteConfigIds, localeId)));
  }

  private getProducts(
    transmitterConfig: TransmitterConfiguration,
    incompleteConfigIds: string[],
    localeId: string,
  ): Observable<ProductData[]> {
    const attachmentStartingIndex = transmitterConfig.sensorId ? 1 : 0;
    const productPlacements = transmitterConfig.countPlacedProducts();
    const transmitter$ = this.getTransmitterProductData(transmitterConfig, incompleteConfigIds, productPlacements, localeId);

    const sensor$ = this.getSensorData(transmitterConfig, productPlacements, localeId);
    const attachments$ = this.attachmentDataService.getProductData(
      transmitterConfig,
      transmitterConfig.attachmentIds,
      localeId,
      productPlacements,
      attachmentStartingIndex,
    );

    return forkJoin([transmitter$, sensor$, attachments$]).pipe(
      map((results: any[]) => {
        const transmitterData = results[0];
        const attachmentData = results[2];
        const sensorData = results[1];

        const productData = [transmitterData];
        if (sensorData) {
          productData.push(sensorData);
        }
        productData.push(...attachmentData);
        return productData;
      }),
    );
  }

  private getTransmitterProductData(
    transmitterConfig: TransmitterConfiguration,
    incompleteConfigIds: string[],
    productPlacements: number,
    localeId: string,
  ) {
    return this.productService.getProductById(transmitterConfig.productId).pipe(
      map((product) => {
        if (!product || product.type !== ProductType.TRANSMITTER) {
          throw new Error(`Transmitter with product id '${transmitterConfig.productId}' not found.`);
        }
        const productData = ProductData.create(product, productPlacements, localeId);
        productData.position = transmitterConfig.positionNumber!;
        productData.isIncompleteConfiguration = incompleteConfigIds.includes(transmitterConfig.id);
        return productData;
      }),
    );
  }

  private getSensorData(transmitterConfig: TransmitterConfiguration, productPlacements: number, localeId: string) {
    if (!transmitterConfig.sensorId) {
      return of(undefined);
    }

    return this.productService.getProductById(transmitterConfig.sensorId).pipe(
      map((sensor) => {
        if (!sensor || sensor.type !== ProductType.SENSOR) {
          throw new Error(`Der Sensor mit der Sachnummer '${transmitterConfig.sensorId}' konnte nicht gefunden werden.`);
        }
        const sensorData = ProductData.create(sensor, productPlacements, localeId);
        sensorData.position = transmitterConfig.positionNumber! + ".a";
        return sensorData;
      }),
    );
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Languages } from "@domain/user/language";
import { User } from "@domain/user/user";
import { UserService } from "@domain/user/user.service";
import { environment } from "@environments/environment";
import { first, interval, Observable, of, switchMap, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private static readonly UPDATE_INTERVAL_MILLIS: number = 24 * 60 * 60 * 1000; // 24 hours
  private languageCodes: string[] = Languages.LANGUAGES.map((language) => language.languageCode);

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {
    interval(LanguageService.UPDATE_INTERVAL_MILLIS)
      .pipe(switchMap(() => this.updateTranslations()))
      .subscribe();
  }

  updateTranslations(): Observable<void> {
    if (!navigator.onLine) {
      return of(undefined);
    }
    // set high limit to avoid pagination
    const url = `${environment.apiUrl}/language`;
    return this.http.get<any>(url).pipe(
      tap((response: any[]) => {
        this.languageCodes.forEach((localeString) => {
          this.updateLocallyStoredTranslationFile(response, localeString);
        });
      }),
      switchMap(() => of(undefined)),
    );
  }

  switchLanguage(newLanguageString: string): Observable<User | void> {
    if (!this.languageCodes.includes(newLanguageString)) {
      return of(undefined);
    }
    return this.userService.user$.pipe(
      first(),
      switchMap((user) => {
        if (user.language === newLanguageString) {
          return of(undefined);
        }
        return this.userService.updateLanguage(newLanguageString).pipe(tap(() => location.reload()));
      }),
    );
  }

  private updateLocallyStoredTranslationFile(keys: any, localeString: string) {
    const translations = this.mapTranslations(keys, localeString);
    localStorage.setItem(`${localeString}_translations`, JSON.stringify(translations));
  }

  private mapTranslations(inputArray: any[], localeString: string): { [key: string]: string } {
    const result: { [key: string]: string } = {};

    inputArray.forEach((keyObject: any) => {
      const keyName = keyObject.key_name.web;
      const translationObj = keyObject.translations.find(
        (translation: { language_iso: string }) =>
          translation.language_iso === localeString || translation.language_iso === localeString.split("-")[0],
      );

      if (translationObj && translationObj.is_untranslated === 0) {
        result[keyName] = translationObj.translation;
      }
    });

    return result;
  }
}

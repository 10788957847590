<odx-modal-header>
  <odx-area-header i18n="@@modal.projectVersion.header">Projekt exportieren</odx-area-header>
</odx-modal-header>

<odx-modal-content>
  <p i18n="@@modal.projectVersion.header2">Bitte überprüfen Sie die Versionsnummer vor dem Export.</p>

  <form odxForm [formGroup]="projectVersionForm" class="form">
    <odx-form-field i18n-label="@@modal.projectVersion.label.projectVersion" label="Version">
      <input
        type="text"
        formControlName="version"
        odxFormFieldControl
        i18n-placeholder="@@modal.projectVersion.placeholder.projectVersion"
        placeholder="Version"
        data-testid="modal.projectVersion"
      />
    </odx-form-field>
  </form>
</odx-modal-content>
<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@global.dismiss">Abbrechen</button>
  <button
    odxButton
    variant="primary"
    (click)="onConfirm()"
    i18n="@@modal.projectVersion.button.export"
    data-testid="modal.projectVersion.button.export"
  >
    Exportieren
  </button>
</odx-modal-footer>

import { IsInstanceOf } from "@utils/class-validator/class-validator-constraints";
import { Transform, Type } from "class-transformer";
import { IsBoolean, IsOptional, IsString, ValidateNested } from "class-validator";

export class ChecklistItem {
  @IsBoolean()
  checked: boolean = false;

  @IsOptional()
  @IsString()
  notes?: string;
}

export class Checklist {
  @IsOptional()
  @IsString()
  operator?: string;

  @IsOptional()
  @IsString()
  client?: string;

  @IsOptional()
  @IsString()
  personToInstruct?: string;

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  cableType: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  sil: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  workingHeight: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  deliverySpecifics: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  otherSpecifics: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  calibrationGas: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  calibrationInstructions: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  deadlineRisks: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  technicalRisks: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  commercialRisks: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  @Transform((params) => params.value || [], { toClassOnly: true })
  otherRisks: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  sensorGasAndMeasurementRangeConsidered: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  peturbationsExist: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  relayOutputsConsidered: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  operatingVoltageOfMeansOfAlarmDefined: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  sensorsGasAlarmDeviceAndMeansOfAlarmSet: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  installationRequirementsDefined: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  specialStandardsExist: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  scaffoldingRequired: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  exZonesExist: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  clearanceMeasurementRequired: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  electricalGroundingConceptExists: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  ventilationExists: ChecklistItem = new ChecklistItem();
}

import { Component } from "@angular/core";
import { $localize } from "@angular/localize/init";
import { Router } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { CloudProjectSelectModalComponent } from "@components/project-import/cloud-project-select-modal/cloud-project-select-modal.component";
import {
  ProjectImportConfirmModalComponent,
  ProjectImportConfirmResult,
} from "@components/project-import/confirm-modal/project-import-confirm-modal.component";
import { ProductCountryService } from "@domain/product/product-country.service";
import { ProductService } from "@domain/product/product.service";
import { ProjectApiService } from "@domain/project/api/project-api.service";
import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { ProductDataService } from "@domain/project/product-data/product-data.service";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { ModalService } from "@odx/angular/components/modal";
import { ToastHelper } from "@utils/toast-helper.service";
import { forkJoin, map, switchMap, tap } from "rxjs";

@Component({
  selector: "app-project-import",
  templateUrl: "./project-import.component.html",
  styleUrls: ["./project-import.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class ProjectImportComponent {
  protected readonly navigator = navigator;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private toastHelper: ToastHelper,
    private projectService: ProjectService,
    private productDataService: ProductDataService,
    private apiService: ProjectApiService,
    private productCountryService: ProductCountryService,
    private productService: ProductService,
  ) {}

  importCloudProject() {
    this.modalService
      .open(CloudProjectSelectModalComponent)
      .onClose$.subscribe((cloudData: { project: Project; metadata: CloudProjectMetadata }) =>
        this.projectService
          .findByCloudId(cloudData.project.cloudId!)
          .subscribe((existingProject) => this.importProject(cloudData.project, false, existingProject, cloudData.metadata)),
      );
  }

  importProjectAfterInvitationComplete(projectId: string) {
    return forkJoin({
      project: this.projectService.loadProjectFromCloud(projectId),
      metadata: this.apiService.getMetadata(projectId),
    }).pipe(
      map(({ project: project, metadata: metadata }) => {
        return this.importProject(project, true, undefined, metadata);
      }),
    );
  }

  onFileSelected(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = event.target.files[0];
    event.target.value = null; // must be set to be able to upload the same file multiple times

    this.projectService.loadProjectFromFile(file).subscribe({
      next: (project) => {
        this.productService.updateFilterCountry(project.country);
        this.productDataService
          .collectProjectData(project, false)
          .pipe(switchMap(() => this.projectService.getById(project.id)))
          .subscribe({
            next: (existingProject) => this.importProject(project, true, existingProject),
            error: (error: Error) => this.handleError(error, project.name),
          });
      },
      error: (error: Error) => {
        this.handleError(error);
      },
    });
  }

  private handleError(error: Error, projectName?: string) {
    console.error(error);
    if (projectName) {
      this.toastHelper.error(
        $localize`:@@toast.importProject.errorInclProjectName:Das Projekt '${projectName}:projectName:' konnte nicht importiert werden. Bitte wenden Sie sich an das Navinta-Team. ${error.message || ""}:errorMessage:`,
      );
    } else {
      this.toastHelper.error(
        $localize`:@@toast.importProject.error:Das Projekt konnte nicht importiert werden. Bitte wenden Sie sich an das Navinta-Team. ${error.message || ""}:errorMessage:`,
      );
    }
  }

  private importProject(
    project: Project,
    newProjectCreationEnabled: boolean,
    existingProject?: Project,
    metadata?: CloudProjectMetadata,
  ) {
    if (!existingProject) {
      this.createNewProject(project, metadata);
      return;
    }

    this.modalService
      .open(ProjectImportConfirmModalComponent, {
        data: { newProjectCreationEnabled },
      })
      .onClose$.subscribe((result: ProjectImportConfirmResult) => {
        if (result == ProjectImportConfirmResult.OVERRIDE_PROJECT) {
          this.overrideProject(project, metadata);
        } else if (result == ProjectImportConfirmResult.NEW_PROJECT) {
          // copy project in order to get a new id and name
          this.createNewProject(project.copy());
        } else {
          console.error(`Unexpected project import confirm result: ${result}`);
        }
      });
  }

  private overrideProject(importProject: Project, metadata?: CloudProjectMetadata) {
    this.projectService.update(importProject, metadata).subscribe(() => {
      this.goToProjectDetails(importProject);
      this.toastHelper.success(
        $localize`:@@toast.projectImport.overwriteSuccessful:Projekt '${importProject.name}:projectName:' wurde erfolgreich überschrieben`,
      );
    });
  }

  private createNewProject(project: Project, metadata?: CloudProjectMetadata) {
    this.projectService
      .createProject(project, metadata)
      .pipe(tap((project) => this.productCountryService.selectCountry(project.country)))
      .subscribe((project) => {
        this.goToProjectDetails(project);
        this.toastHelper.success(
          $localize`:@@toast.projectImport.importSuccessful:Projekt '${project.name}:projectName:' wurde erfolgreich importiert`,
        );
      });
  }

  private goToProjectDetails(project: Project) {
    this.router.navigate(["projects", project.id]);
  }
}

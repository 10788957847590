<div odxLayout="flex gap-small" class="flex-column h-100">
  <odx-area-header>
    <h5 class="odx-title-5" i18n="@@imageUpload.imageOverview.header">Fotos</h5>
    <odx-area-header-content>
      <odx-action-group *ngIf="(isReadOnlyUser$ | async) === false">
        <button odxButton (click)="openCamera()">
          <odx-icon name="picture" alignleft></odx-icon>
          <span i18n="@@imageUpload.button.openCamera">Foto aufnehmen</span>
        </button>
        <button odxButton variant="primary" (click)="fileUpload.click()" data-testid="btn.uploadImage">
          <odx-icon name="upload" alignleft></odx-icon>
          <span i18n="@@imageUpload.button.uploadImage">Foto hochladen</span>
        </button>
      </odx-action-group>
      <input
        type="file"
        (change)="onFileSelected($event)"
        accept="image/png, image/jpg, image/jpeg, image/gif, image/svg, image/svg+xml"
        class="hidden"
        multiple
        #fileUpload
        data-testid="input.imageName"
      />
    </odx-area-header-content>
  </odx-area-header>
  <ng-content></ng-content>
</div>

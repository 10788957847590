import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ChecklistItem } from "@domain/project/checklist";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { UserService } from "@domain/user/user.service";
import { ChecklistItemComponent } from "@project/project-details/checklist/checklist-item/checklist-item.component";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-checklist",
  templateUrl: "./checklist.component.html",
  styleUrls: ["./checklist.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, ChecklistItemComponent],
})
export class ChecklistComponent implements OnInit {
  @Input()
  project!: Project;

  checklistForm!: FormGroup;
  protected isReadOnlyUser$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
  }

  ngOnInit() {
    this.checklistForm = this.formBuilder.group(
      {
        operator: [this.project.checklist.operator],
        client: [this.project.checklist.client],
        personToInstruct: [this.project.checklist.personToInstruct],
        sensorGasAndMeasurementRangeConsidered: this.buildChecklistItemFormGroup(
          this.project.checklist.sensorGasAndMeasurementRangeConsidered,
        ),
        peturbationsExist: this.buildChecklistItemFormGroup(this.project.checklist.peturbationsExist),
        relayOutputsConsidered: this.buildChecklistItemFormGroup(this.project.checklist.relayOutputsConsidered),
        operatingVoltageOfMeansOfAlarmDefined: this.buildChecklistItemFormGroup(
          this.project.checklist.operatingVoltageOfMeansOfAlarmDefined,
        ),
        sensorsGasAlarmDeviceAndMeansOfAlarmSet: this.buildChecklistItemFormGroup(
          this.project.checklist.sensorsGasAlarmDeviceAndMeansOfAlarmSet,
        ),
        installationRequirementsDefined: this.buildChecklistItemFormGroup(this.project.checklist.installationRequirementsDefined),
        specialStandardsExist: this.buildChecklistItemFormGroup(this.project.checklist.specialStandardsExist),
        scaffoldingRequired: this.buildChecklistItemFormGroup(this.project.checklist.scaffoldingRequired),
        exZonesExist: this.buildChecklistItemFormGroup(this.project.checklist.exZonesExist),
        clearanceMeasurementRequired: this.buildChecklistItemFormGroup(this.project.checklist.clearanceMeasurementRequired),
        electricalGroundingConceptExists: this.buildChecklistItemFormGroup(
          this.project.checklist.electricalGroundingConceptExists,
        ),
        ventilationExists: this.buildChecklistItemFormGroup(this.project.checklist.ventilationExists),
        cableType: this.buildChecklistItemFormGroup(this.project.checklist.cableType),
        sil: this.buildChecklistItemFormGroup(this.project.checklist.sil),
        workingHeight: this.buildChecklistItemFormGroup(this.project.checklist.workingHeight),
        deliverySpecifics: this.buildChecklistItemFormGroup(this.project.checklist.deliverySpecifics),
        otherSpecifics: this.buildChecklistItemFormGroup(this.project.checklist.otherSpecifics),
        calibrationGas: this.buildChecklistItemFormGroup(this.project.checklist.calibrationGas),
        calibrationInstructions: this.buildChecklistItemFormGroup(this.project.checklist.calibrationInstructions),
        deadlineRisks: this.buildChecklistItemFormGroup(this.project.checklist.deadlineRisks),
        technicalRisks: this.buildChecklistItemFormGroup(this.project.checklist.technicalRisks),
        commercialRisks: this.buildChecklistItemFormGroup(this.project.checklist.commercialRisks),
        otherRisks: this.buildChecklistItemFormGroup(this.project.checklist.otherRisks),
      },
      { updateOn: "blur" },
    );

    this.checklistForm.valueChanges.subscribe((value) => this.save(value));
  }

  private buildChecklistItemFormGroup(checklistItem: ChecklistItem) {
    return this.formBuilder.group({
      checked: [checklistItem.checked],
      notes: [checklistItem.notes],
    });
  }

  private save(value: any) {
    this.project.checklist.operator = value.operator;
    this.project.checklist.client = value.client;
    this.project.checklist.personToInstruct = value.personToInstruct;

    this.mapChecklistItem(this.project.checklist.cableType, value.cableType);
    this.mapChecklistItem(this.project.checklist.sil, value.sil);
    this.mapChecklistItem(this.project.checklist.workingHeight, value.workingHeight);
    this.mapChecklistItem(this.project.checklist.cableType, value.cableType);
    this.mapChecklistItem(this.project.checklist.deliverySpecifics, value.deliverySpecifics);
    this.mapChecklistItem(this.project.checklist.otherSpecifics, value.otherSpecifics);
    this.mapChecklistItem(this.project.checklist.calibrationGas, value.calibrationGas);
    this.mapChecklistItem(this.project.checklist.calibrationInstructions, value.calibrationInstructions);
    this.mapChecklistItem(this.project.checklist.deadlineRisks, value.deadlineRisks);
    this.mapChecklistItem(this.project.checklist.technicalRisks, value.technicalRisks);
    this.mapChecklistItem(this.project.checklist.commercialRisks, value.commercialRisks);
    this.mapChecklistItem(this.project.checklist.otherRisks, value.otherRisks);

    this.mapChecklistItem(
      this.project.checklist.sensorGasAndMeasurementRangeConsidered,
      value.sensorGasAndMeasurementRangeConsidered,
    );
    this.mapChecklistItem(this.project.checklist.peturbationsExist, value.peturbationsExist);
    this.mapChecklistItem(this.project.checklist.relayOutputsConsidered, value.relayOutputsConsidered);
    this.mapChecklistItem(
      this.project.checklist.operatingVoltageOfMeansOfAlarmDefined,
      value.operatingVoltageOfMeansOfAlarmDefined,
    );
    this.mapChecklistItem(
      this.project.checklist.sensorsGasAlarmDeviceAndMeansOfAlarmSet,
      value.sensorsGasAlarmDeviceAndMeansOfAlarmSet,
    );
    this.mapChecklistItem(this.project.checklist.installationRequirementsDefined, value.installationRequirementsDefined);
    this.mapChecklistItem(this.project.checklist.specialStandardsExist, value.specialStandardsExist);
    this.mapChecklistItem(this.project.checklist.scaffoldingRequired, value.scaffoldingRequired);
    this.mapChecklistItem(this.project.checklist.exZonesExist, value.exZonesExist);
    this.mapChecklistItem(this.project.checklist.clearanceMeasurementRequired, value.clearanceMeasurementRequired);
    this.mapChecklistItem(this.project.checklist.electricalGroundingConceptExists, value.electricalGroundingConceptExists);
    this.mapChecklistItem(this.project.checklist.ventilationExists, value.ventilationExists);

    this.project.lastModified = new Date();
    this.projectService.update(this.project).subscribe();
  }

  private mapChecklistItem(checklistItem: ChecklistItem, formValue: any) {
    checklistItem.checked = formValue.checked;
    checklistItem.notes = formValue.notes;
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-toggle-lock-button",
  templateUrl: "./toggle-lock-button.component.html",
  styleUrl: "./toggle-lock-button.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class ToggleLockButtonComponent {
  @Input({ required: true }) locked!: boolean;
  @Input() isSingleItem: boolean = false;
  @Output() toggleEvent = new EventEmitter<boolean>();

  protected readonly tooltipTextLockElement: string = $localize`:@@floorplan.button.lockCategory:Element sperren`;
  protected readonly tooltipTextUnlockElement: string = $localize`:@@floorplan.button.unlockCategory:Element entsperren`;
  protected readonly tooltipTextLockCategory: string = $localize`:@@floorplan.button.lockCategory:Kategorie sperren`;
  protected readonly tooltipTextUnlockCategory: string = $localize`:@@floorplan.button.unlockCategory:Kategorie entsperren`;

  toggleLock() {
    this.locked = !this.locked;
    this.toggleEvent.emit(this.locked);
  }

  setToolTipText() {
    if (this.isSingleItem) {
      return this.locked ? this.tooltipTextUnlockElement : this.tooltipTextLockElement;
    } else {
      return this.locked ? this.tooltipTextUnlockCategory : this.tooltipTextLockCategory;
    }
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { STORE_NAME_METADATA, STORE_NAME_PROJECTS } from "@app/indexed-db-config";
import { ProjectApiService } from "@domain/project/api/project-api.service";
import { ProjectResponseDto } from "@domain/project/api/project-response.dto";
import { ReadAccessProjectResponseDto } from "@domain/project/api/read-access-project-response.dto";
import { UserDataDto } from "@domain/project/api/user-data.dto";
import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { Collaborator } from "@domain/project/collaborator";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { Project } from "@domain/project/project";
import { ProjectFile } from "@domain/project/project-file";
import { ProjectValidationService } from "@domain/project/project-validation.service";
import { UserService } from "@domain/user/user.service";
import { ObservableInstanceMapper } from "@utils/observable-instance-mapper";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { NgxIndexedDBService } from "ngx-indexed-db";
import {
  catchError,
  EMPTY,
  first,
  forkJoin,
  interval,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectService implements OnDestroy {
  private static readonly READ_ACCESS_PROJECT_STORAGE_KEY = "read_access_project_id";
  private readonly _projects$ = new ReplaySubject<Project[]>(1);
  private _projects!: Project[];
  private readonly destroyed$ = new Subject<void>();
  private isReadOnlyUser!: boolean;

  constructor(
    private http: HttpClient,
    private dbService: NgxIndexedDBService,
    private projectValidationService: ProjectValidationService,
    private projectApiService: ProjectApiService,
    private userService: UserService,
  ) {
    this.userService.user$.subscribe((user) => {
      this.isReadOnlyUser = user.readOnlyAccess;
    });
    this._projects$.subscribe((projects) => (this._projects = projects));

    this.loadProjectsFromDB().subscribe((projects) => {
      this._projects$.next(projects);
    });

    interval(60000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.syncProjectsWithCloud());
  }

  getMetadataById(id: string): Observable<CloudProjectMetadata> {
    return this.dbService.getByKey<CloudProjectMetadata>(STORE_NAME_METADATA, id).pipe(
      catchError((error) => {
        console.error(`Error fetching record from ${STORE_NAME_METADATA}:`, error);
        return throwError(() => new Error(`Failed to fetch record: ${error}`));
      }),
    );
  }

  ngOnDestroy(): void {
    this._projects$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get projects$() {
    return this._projects$.asObservable();
  }

  getById(id: string): Observable<Project | undefined> {
    return this.projects$.pipe(
      first(),
      map((projects) => projects.find((project) => project.id === id)),
    );
  }

  createProject(project: Project, metadata?: CloudProjectMetadata): Observable<Project> {
    return this.dbService.add(STORE_NAME_PROJECTS, instanceToPlain(project)).pipe(
      switchMap(() => {
        if (metadata) {
          return this.dbService.add(STORE_NAME_METADATA, instanceToPlain(metadata)).pipe(map(() => project));
        }
        return of(project);
      }),
      map(() => {
        this._projects.push(project);
        this._projects$.next(this._projects);
        return project;
      }),
      catchError((error) => {
        throw error;
      }),
    );
  }

  update(project: Project, metadata?: CloudProjectMetadata): Observable<void> {
    if (this.isReadOnlyUser) {
      return EMPTY;
    }
    if (metadata) {
      project.updateCloudSync(metadata.id);
    }
    const projectUpdate$ = this.dbService.update(STORE_NAME_PROJECTS, instanceToPlain(project));
    const metadataUpdate$ = metadata ? this.dbService.update(STORE_NAME_METADATA, instanceToPlain(metadata)) : of(undefined);

    return forkJoin([projectUpdate$, metadataUpdate$]).pipe(
      map(() => {
        if (this._projects.indexOf(project) === -1) {
          this.replaceProjectInstance(project);
        }
      }),
      catchError((error) => {
        console.error("Failed to update project or metadata:", error);
        throw error;
      }),
    );
  }

  findByCloudId(cloudId: string): Observable<Project | undefined> {
    return this.projects$.pipe(
      first(),
      map((projects) => projects.find((project) => project.cloudId === cloudId)),
    );
  }

  deleteProject(project: Project): Observable<boolean> {
    return this.dbService.deleteByKey(STORE_NAME_PROJECTS, project.id).pipe(
      tap(() => {
        this.deleteProjectById(project.id);
        this._projects$.next(this._projects);
        return project;
      }),
    );
  }

  exportProjectToFile(project: Project) {
    const plainProject = this.projectApiService.mapAndCleanProjectForExport(project);
    const blob = new Blob([JSON.stringify(plainProject)], {
      type: "application/json",
    });
    this.saveFile(blob, project.name + ".navinta");
  }

  loadProjectFromFile(file: File): Observable<Project> {
    const reader = new FileReader();
    const observable = new Observable<Project>((observer: any) => {
      reader.onload = (event) => {
        if (!event.target) {
          return;
        }
        const project = plainToInstance(Project, [JSON.parse(<string>event.target.result)])[0];

        this.projectValidationService.validateTypes(project).subscribe((errors) => {
          if (errors.length > 0) {
            observer.error(errors);
          } else {
            try {
              this.projectValidationService.validateContent(project);
              observer.next(project);
            } catch (error) {
              observer.error(new Error(`ContentValidationError ${error}`));
            }
          }
          observer.complete();
        });
      };
    });
    reader.readAsText(file);
    return observable;
  }

  updateLocalVersion(cloudMetadata: CloudProjectMetadata) {
    return this.loadProjectFromCloud(cloudMetadata.id).pipe(
      switchMap((project) => {
        return forkJoin([this.updateLocalMetadata(cloudMetadata), this.update(project)]).pipe(
          map(() => {
            project.updateCloudSync(cloudMetadata.id);
            return project;
          }),
        );
      }),
    );
  }

  exportProjectToCloud(project: Project, forceUpdate?: boolean): Observable<ProjectResponseDto> {
    if (this.isReadOnlyUser) {
      return EMPTY;
    }
    if (project.cloudId) {
      return this.getMetadataById(project.cloudId).pipe(
        switchMap((metadata) => this.projectApiService.exportProjectToCloud(project, metadata, forceUpdate)),
        switchMap((response: ProjectResponseDto) => {
          if (!response.metadata) {
            throw new Error("Metadata is undefined in the response");
          }
          return this.updateLocalMetadata(response.metadata).pipe(
            switchMap(() => {
              project.updateCloudSync(response.metadata!.id);
              return of(response); // Return response wrapped in an observable
            }),
          );
        }),
        catchError((error) => {
          console.error("Error exporting project to the cloud:", error);
          throw error; // Re-throw or handle the error as needed
        }),
      );
    }

    return this.projectApiService.exportProjectToCloud(project).pipe(
      switchMap((response: ProjectResponseDto) => {
        if (!response.metadata) {
          throw new Error("Metadata is undefined in the response");
        }
        return this.updateLocalMetadata(response.metadata).pipe(
          map(() => {
            project.updateCloudSync(response.metadata!.id);
            return response;
          }),
        );
      }),
      catchError((error) => {
        console.error("Error exporting project to the cloud:", error);
        throw error; // Re-throw or handle the error as needed
      }),
    );
  }

  loadCollaborators(cloudId: string) {
    return ObservableInstanceMapper.valuesToInstance(
      this.dbService.getByKey(STORE_NAME_METADATA, cloudId).pipe(
        map((metadata: any) => {
          if (!metadata) {
            return [];
          }

          return metadata.users.map((user: any) => {
            return {
              userId: user.id,
              email: user.email,
              assignedRole: user.role,
              invitationCompletedAt: user.invitationCompletedAt,
              invitedBy: user.invitedBy,
            };
          });
        }),
      ),
      Collaborator,
    );
  }

  removeCollaboratorFromProject(cloudId: string | undefined, collaboratorId: string): Observable<any> {
    if (!cloudId) {
      throw new Error("cloudId is undefined");
    }
    return this.projectApiService.removeCollaborator(cloudId, collaboratorId).pipe(
      map((response) => {
        this.updateLocalMetadata(plainToInstance(CloudProjectMetadata, response)).subscribe();
      }),
    );
  }

  loadCloudProjectsMetadata(): Observable<CloudProjectMetadata[]> {
    if (!navigator.onLine) {
      return of([]);
    }
    return ObservableInstanceMapper.valuesToInstance(
      this.http.get(ProjectApiService.PROJECTS_URL).pipe(
        map((result: any) => {
          return result.map((item: any) => {
            return {
              id: item.id,
              displayName: item.name,
              lastModifiedOn: item.updatedAt,
              invitationCompletedAt: item.invitationCompletedAt,
              invitedBy: item.invitedBy,
            };
          });
        }),
      ),
      CloudProjectMetadata,
    );
  }

  loadProjectFromCloud(cloudId: string): Observable<Project> {
    return ObservableInstanceMapper.valueToInstance(
      this.http.get(`${ProjectApiService.PROJECTS_URL}/${cloudId}`).pipe(
        map((project: any) => {
          project.cloudId = cloudId;
          return project;
        }),
      ),
      Project,
    );
  }

  loadReadAccessProjectFromCloud(cloudId: string) {
    return ObservableInstanceMapper.valueToInstance(
      this.projectApiService.getReadAccessProject(cloudId),
      ReadAccessProjectResponseDto,
    ).pipe(
      map((response: ReadAccessProjectResponseDto) => {
        response.project!.cloudId = cloudId;
        response.project!.showCostFlag = response.showPrices;
        response.project!.floorplans.forEach((floorplan: Floorplan) => {
          floorplan.floorplanState.setLockForAll(true);
        });
        return response.project!;
      }),
    );
  }

  deleteLocalMetadata(id: string | undefined) {
    if (id) {
      return this.dbService.deleteByKey(STORE_NAME_METADATA, id);
    }
    return of(false);
  }

  updateLocalMetadata(metadata: CloudProjectMetadata) {
    return this.dbService.update(STORE_NAME_METADATA, instanceToPlain(metadata));
  }

  getUserRoleInProject(cloudId: string): Observable<UserDataDto> {
    return this.projectApiService.getUserData(cloudId);
  }

  removeFile(cloudId: string, fileId: string) {
    return this.projectApiService.deleteFile(cloudId, fileId);
  }

  addFiles(blobs: Blob[], cloudId?: string): Observable<CloudProjectMetadata> {
    if (cloudId) {
      return this.projectApiService.addFiles(cloudId, blobs);
    } else {
      throw new Error("cloudId is undefined");
    }
  }

  loadFiles(cloudId: string) {
    if (!this.isReadOnlyUser) return this.projectApiService.loadProjectFiles(cloudId);
    return EMPTY;
  }

  downloadFile(file: ProjectFile) {
    this.projectApiService.downloadFile(file).subscribe((blob) => {
      this.saveFile(blob, file.name);
    });
  }

  clearAll(): Observable<boolean> {
    return this.dbService.clear(STORE_NAME_PROJECTS);
  }

  public setReadAccessProjectId(projectId: string) {
    sessionStorage.setItem(ProjectService.READ_ACCESS_PROJECT_STORAGE_KEY, projectId);
  }

  public getReadAccessProjectId(): string | null {
    return sessionStorage.getItem(ProjectService.READ_ACCESS_PROJECT_STORAGE_KEY);
  }

  private syncProjectsWithCloud() {
    if (!navigator.onLine || this.isReadOnlyUser) {
      return;
    }
    this.projects$.pipe(first()).subscribe((projects) => {
      const unsyncedProjects = projects.filter((project) => !project.inSyncWithCloud);
      unsyncedProjects.forEach((project) => {
        this.syncProjectWithCloud(project).subscribe();
      });
    });
  }

  private syncProjectWithCloud(project: Project): Observable<void> {
    if (this.isReadOnlyUser) {
      return EMPTY;
    }
    if (!project.cloudId) {
      return this.projectApiService.exportProjectToCloud(project).pipe(
        tap((response) => project.updateCloudSync(response.metadata!.id)),
        switchMap((response) => this.handleProjectExportResponse(response)),
      );
    }

    return this.getMetadataById(project.cloudId).pipe(
      switchMap((metadata) => this.projectApiService.exportProjectToCloud(project, metadata)),
      tap((response) => project.updateCloudSync(response.metadata!.id)),
      switchMap((response) => this.handleProjectExportResponse(response)),
    );
  }

  private handleProjectExportResponse(response: ProjectResponseDto) {
    if (response?.project) {
      return this.update(response.project, response.metadata);
    }
    return throwError(() => new Error("Response did not contain project."));
  }

  private saveFile = async (blob: any, suggestedName: any) => {
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobURL;
    a.download = suggestedName;
    a.style.display = "none";
    document.body.append(a);
    a.click();
    setTimeout(() => {
      URL.revokeObjectURL(blobURL);
      a.remove();
    }, 1000);
  };

  public getProjectCountries(): Observable<string[]> {
    return this.loadProjectsFromDB().pipe(
      map((projects) => {
        const countries: string[] = [];
        projects.forEach((project) => {
          if (!countries.includes(project.country)) {
            countries.push(project.country);
          }
        });
        return countries;
      }),
    );
  }

  private loadProjectsFromDB(): Observable<Project[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Project>(STORE_NAME_PROJECTS), Project);
  }

  private replaceProjectInstance(project: Project): void {
    this.deleteProjectById(project.id);
    this._projects.push(project);
    this._projects$.next(this._projects);
  }

  private deleteProjectById(id: string) {
    const index = this._projects.findIndex((p) => p.id === id);
    if (index > -1) {
      this._projects.splice(index, 1);
    }
  }
}

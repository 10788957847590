import { Component, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { FilterProductComponent } from "@components/filter-product/filter-product.component";
import { SelectPlaceholderModalComponent } from "@components/select-placeholder-modal/select-placeholder-modal.component";
import {
  LocalizeProductNamePipe,
  MatchType,
  Product,
  ProductType,
  Relationship,
  RelationshipType,
} from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { PlaceholderProduct } from "@domain/project/floorplan/placeholder-product";
import { Project } from "@domain/project/project";
import { ModalService, ModalSize } from "@odx/angular/components/modal";
import { ProductSearchItemComponent } from "@project/floorplanner/floorplan-menu/product-search-item/product-search-item.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { first, map } from "rxjs";

@Component({
  selector: "app-floorplan-product-search-menu",
  templateUrl: "./floorplan-product-search-menu.component.html",
  styleUrls: ["./floorplan-product-search-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FilterProductComponent, ProductSearchItemComponent],
  providers: [LocalizeProductNamePipe],
})
export class FloorplanProductSearchMenuComponent {
  private readonly project: Project;
  private readonly productTypes: ProductType[] = [
    ProductType.ALARMDEVICE,
    ProductType.GASWARNINGCENTER,
    ProductType.TRANSMITTER,
    ProductType.PLASTICSIGN,
    ProductType.SIGNALELEMENT,
  ];
  private products: Product[] = [];
  protected filteredProducts: Product[] = [];
  protected searchValue: string = "";

  private readonly modalService = inject(ModalService);

  constructor(
    private productService: ProductService,
    private activeRoute: ActivatedRoute,
    private floorplanService: FloorplanService,
    private localizeProductNamePipe: LocalizeProductNamePipe,
  ) {
    this.project = this.activeRoute.parent!.snapshot.data["project"];
    this.collectProducts();
  }

  protected search(value: string) {
    this.searchValue = value;
    if (!this.searchValue.length) {
      this.filteredProducts = [];
      return;
    }
    this.filteredProducts = this.products.filter((product) => this.productService.searchFilter(product, value));
  }

  protected addProductToFloorplan(product: Product) {
    let config;
    const name = this.localizeProductNamePipe.transform(product);
    const requiredAttachmentIds = product.relationships
      .filter((relationship) => this.isRequiredAttachment(relationship))
      .map((attachment) => attachment.productId);
    switch (product.type) {
      case ProductType.ALARMDEVICE:
        config = AlarmDeviceConfiguration.create(this.project, [product], requiredAttachmentIds);
        this.project.addAlarmDevices(config);
        this.floorplanService.addAlarmDevice(config);
        break;
      case ProductType.GASWARNINGCENTER:
        config = GasWarningCenterConfiguration.create(name, this.project, product.id, requiredAttachmentIds);
        this.project.addGasWarningCenters(config);
        this.floorplanService.addGasWarningCenter(config);
        break;
      case ProductType.TRANSMITTER:
        config = TransmitterConfiguration.create(name, this.project, product.id, requiredAttachmentIds);
        this.project.addTransmitters(config);
        this.floorplanService.addTransmitter(config);
        break;
      case ProductType.PLASTICSIGN:
        config = PlasticSignConfiguration.create(name, this.project, product.id, "", "", "");
        this.project.addPlasticSigns(config);
        this.floorplanService.addPlasticSign(config);
        break;
      case ProductType.SIGNALELEMENT:
        config = SignalElementConfiguration.create(name, this.project, product.id);
        this.project.addSignalElements(config);
        this.floorplanService.addSignalElement(config);
        break;
      default:
        return;
    }
  }

  protected openSelectPlaceholderMenu() {
    this.modalService
      .open(SelectPlaceholderModalComponent, {
        data: this.productTypes,
        size: ModalSize.SMALL,
      })
      .onClose$.subscribe((productType) => {
        switch (productType) {
          case ProductType.GASWARNINGCENTER: {
            this.floorplanService.addGasWarningCenterPlaceholder();
            const newGasWarningPlaceholder =
              this.floorplanService.selectedFloorplan.gasWarningCenterPlaceholders[
                this.floorplanService.selectedFloorplan.gasWarningCenterPlaceholders.length - 1
              ];
            this.setPlaceholderProductId(newGasWarningPlaceholder);
            break;
          }
          case ProductType.TRANSMITTER: {
            this.floorplanService.addTransmitterPlaceholder();
            const newTransmitterPlaceholder =
              this.floorplanService.selectedFloorplan.transmitterPlaceholders[
                this.floorplanService.selectedFloorplan.transmitterPlaceholders.length - 1
              ];
            this.setPlaceholderProductId(newTransmitterPlaceholder);
            break;
          }
          case ProductType.SIGNALELEMENT: {
            this.floorplanService.addSignalElementPlaceholder();
            const newSignalElementPlaceholder =
              this.floorplanService.selectedFloorplan.signalElementPlaceholders[
                this.floorplanService.selectedFloorplan.signalElementPlaceholders.length - 1
              ];
            this.setPlaceholderProductId(newSignalElementPlaceholder);
            break;
          }
          case ProductType.PLASTICSIGN: {
            this.floorplanService.addPlasticSignPlaceholder();
            const newPlasticSignPlaceholder =
              this.floorplanService.selectedFloorplan.plasticSignPlaceholders[
                this.floorplanService.selectedFloorplan.plasticSignPlaceholders.length - 1
              ];
            this.setPlaceholderProductId(newPlasticSignPlaceholder);
            break;
          }
          case ProductType.ALARMDEVICE: {
            this.floorplanService.addAlarmDevicePlaceholder();
            const newAlarmDevicePlaceholder =
              this.floorplanService.selectedFloorplan.alarmDevicePlaceholders[
                this.floorplanService.selectedFloorplan.alarmDevicePlaceholders.length - 1
              ];
            this.setPlaceholderProductId(newAlarmDevicePlaceholder);
            break;
          }
        }
      });
  }

  private collectProducts() {
    this.productService
      .getAvailableProducts(...this.productTypes)
      .pipe(
        map((products) => Product.sort(products)),
        first(),
      )
      .subscribe((products: Product[]) => (this.products = products));
  }

  private isRequiredAttachment(relationship: Relationship): boolean {
    const isMatchTypeDirect = relationship.matchType == MatchType.DIRECT;
    const isRequired = relationship.relationshipType == RelationshipType.EXTRAREQ;
    const isGuide = relationship.relationshipType == RelationshipType.EXTRAGUIDE;
    return isMatchTypeDirect && (isRequired || isGuide);
  }

  private setPlaceholderProductId(
    placeholder: FloorplanPlaceholder | FloorplanTransmitterPlaceholder | FloorplanGasWarningCenterPlaceholder,
  ) {
    placeholder.products = [new PlaceholderProduct("", this.searchValue)];
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-new-project-file-card",
  templateUrl: "./new-project-file-card.component.html",
  styleUrls: ["./new-project-file-card.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule],
})
export class NewProjectFileCardComponent implements OnInit {
  @Input() title!: string;
  @Input() fileUrl?: string;
  @Input() fileName?: string;

  @Output() delete$ = new EventEmitter();
  @Output() submit$ = new EventEmitter<FormGroup>();

  @ViewChild("submitButton")
  submitButton?: ElementRef;

  newFileForm!: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.newFileForm = this.formBuilder.group({
      name: [this.fileName, Validators.required],
    });
  }

  triggerSubmit() {
    this.submitButton?.nativeElement.click();
    this.submit$.emit(this.newFileForm);
  }

  onDelete() {
    this.delete$.emit();
  }
}

<app-floorplan-item-details [title]="title" [floorplanItem]="floorplanItem" [imageUrl]="imageUrl" (editClicked)="onEdit()">
  <form odxForm [formGroup]="placeholderProductsForm" [readonly]="floorplanItem.locked">
    <div formArrayName="placeholderProducts">
      <div *ngFor="let product of getProductsControls(); let index = index" [formGroupName]="index">
        <hr />
        <odx-form-field
          i18n-label="@@floorplan.details.form.placeholderProduct.name.label"
          label="Name (optional)"
          [readonly]="floorplanItem.locked"
        >
          <input
            formControlName="name"
            odxFormFieldControl
            i18n-placeholder="@@floorplan.details.form.placeholderProduct.name.placeholder"
            placeholder="Name"
          />
        </odx-form-field>
        <odx-form-field
          i18n-label="@@floorplan.details.form.placeholderProduct.id.label"
          label="Sachnummer (optional)"
          [readonly]="floorplanItem.locked"
        >
          <input
            formControlName="id"
            odxFormFieldControl
            i18n-placeholder="@@floorplan.details.form.placeholderProduct.id.placeholder"
            placeholder="Sachnummer"
          />
        </odx-form-field>
      </div>
    </div>

    <odx-inline-message *ngIf="showWarning" variant="warning" i18n="@@floorplan.details.warning.placeholderProductsAvailable"
      >Alle Sachnummern dieses Platzhalters sind in den Produktdaten vorhanden. Es wird empfohlen, das Produkt direkt zu
      konfigurieren.</odx-inline-message
    >

    <div *ngIf="(isReadOnlyUser$ | async) === false">
      <a
        odxLink
        href="#"
        i18n="floorplan.details.form.placeholderProduct.btnAdd"
        (click)="addFormGroup(); $event.preventDefault()"
      >
        <odx-icon name="plus"></odx-icon>
        Produkt hinzufügen
      </a>
    </div>
  </form>

  <div *ngIf="isTransmitterPlaceholder(floorplanItem)">
    <app-floorplan-item-detail-transmitter-form
      [transmitter]="getTransmitterPlaceholder(floorplanItem)"
      [editEnabled]="editEnabled"
    ></app-floorplan-item-detail-transmitter-form>
  </div>

  <div *ngIf="isGasWarningCenterPlaceholder(floorplanItem)">
    <app-floorplan-gas-warning-center-detail-form
      [gasWarningCenter]="getGasWarningCenterPlaceholder(floorplanItem)"
      [editEnabled]="editEnabled"
    ></app-floorplan-gas-warning-center-detail-form>
  </div>
</app-floorplan-item-details>

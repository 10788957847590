import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProcessInvitationModalComponent } from "@components/process-invitation-modal/process-invitation-modal.component";
import { ButtonComponent } from "@odx/angular/components/button";
import {
  ModalContentDirective,
  ModalDismissDirective,
  ModalFooterComponent,
  ModalHeaderComponent,
  ModalService,
} from "@odx/angular/components/modal";

@Component({
  selector: "app-confirm-project-invitation",
  standalone: true,
  imports: [
    ButtonComponent,
    ModalContentDirective,
    ModalDismissDirective,
    ModalFooterComponent,
    ModalHeaderComponent,
    ProcessInvitationModalComponent,
  ],
  template: "",
  styles: "",
})
export class ConfirmProjectInvitationComponent implements OnInit {
  private readonly modalService: ModalService = inject(ModalService);

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const projectId = params.get("projectId");
      const invitationId = params.get("invitationId");

      this.modalService.open(ProcessInvitationModalComponent, {
        interactiveBackdrop: false,
        dismissable: false,
        data: {
          projectId: projectId,
          invitationId: invitationId,
        },
      });
    });
  }
}

import { Injectable } from "@angular/core";
import { Checklist } from "@domain/project/checklist";
import { Project } from "@domain/project/project";
import { ChecklistRow } from "@pdf/export-services/checklist-page/checklist-row";
import { ChecklistTable } from "@pdf/export-services/checklist-page/checklist-table";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { HalfPageContainer } from "@pdf/helpers/container/half-page/half-page-container";
import { HalfPageContainerService } from "@pdf/helpers/container/half-page/half-page-container.service";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import {
  ContainerProperties,
  FontNames,
  Headlines,
  ImagePaths,
  PdfProperties,
  ProductListProperties,
  TableProperties,
} from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { Observable } from "rxjs";

interface ChecklistTableRowData {
  title: string;
  text: string;
  checked?: boolean;
}

export class ChecklistHeadlines {
  public readonly GENERAL = $localize`:@@pdfExport.checklist.general:Allgemein`;
  public readonly INSTALLATION_INSTRUCTIONS = $localize`:@@pdfExport.checklist.installationInstructions:Installationsanweisungen`;
  public readonly SERVICE_INSTRUCTIONS = $localize`:@@pdfExport.checklist.serviceInstructions:Wartungsanweisungen`;
  public readonly RISK_ASSESSMENT = $localize`:@@pdfExport.checklist.riskAssessment:Risikobewertung`;
}

@Injectable({
  providedIn: "root",
})
export class ChecklistPagePdfService {
  private headlines = new Headlines();
  readonly checklistHeadlines = new ChecklistHeadlines();

  constructor(private containerService: HalfPageContainerService) {}

  generate(pdf: jsPDF, project: Project, tableOfContents: TableOfContents, container: HalfPageContainer): Observable<jsPDF> {
    return new Observable((observer) => {
      this.addFonts(pdf);
      pdf.setFont(FontNames.DRAEGER_PANGEA);
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);
      pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
      pdf.setFont(FontNames.DRAEGER_PANGEA, "normal");
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);
      this.addChecklistTables(pdf, container, tableOfContents, project.checklist);
      observer.next(pdf);
      observer.complete();
    });
  }

  private addChecklistTables(pdf: jsPDF, container: HalfPageContainer, tableOfContents: TableOfContents, checklist: Checklist) {
    const checklistTableRows: ChecklistTableRowData[] = this.getChecklistTableRowData(checklist);
    const checklistTableRowsInstallationInstructions: ChecklistTableRowData[] =
      this.getChecklistTableRowDataInstallationInstructions(checklist);
    const checklistTableRowsServiceInstructions: ChecklistTableRowData[] =
      this.getChecklistTableRowDataServiceInstructions(checklist);
    const checklistTableRowsRiskAssessment: ChecklistTableRowData[] = this.getChecklistTableRowDataRiskAssessment(checklist);

    const checklistTables = [
      new ChecklistTable(this.checklistHeadlines.GENERAL, pdf, checklistTableRows),
      new ChecklistTable(this.checklistHeadlines.INSTALLATION_INSTRUCTIONS, pdf, checklistTableRowsInstallationInstructions),
      new ChecklistTable(this.checklistHeadlines.SERVICE_INSTRUCTIONS, pdf, checklistTableRowsServiceInstructions),
      new ChecklistTable(this.checklistHeadlines.RISK_ASSESSMENT, pdf, checklistTableRowsRiskAssessment),
    ];
    this.drawChecklist(pdf, container, tableOfContents, checklistTables);
  }

  private drawChecklist(
    pdf: jsPDF,
    container: HalfPageContainer,
    tableOfContents: TableOfContents,
    checklistTables: ChecklistTable[],
  ) {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    const pageHeadline = this.headlines.CHECKLIST;
    const showContainerHeadLine = true;
    this.containerService.add(pdf, container, pageHeadline, tableOfContents, showContainerHeadLine);
    container.yPosition += TableProperties.HEADLINE_OFFSET_Y;

    checklistTables.forEach((table) => {
      this.drawChecklistTable(table, pdf, container, pageHeadline, tableOfContents);
    });
  }

  private drawChecklistTable(
    checklistTable: ChecklistTable,
    pdf: jsPDF,
    container: HalfPageContainer,
    pageHeadline: string,
    tableOfContents: TableOfContents,
  ) {
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(TableProperties.HEADER_FONT_SIZE);
    pdf.text(checklistTable.headline, container.xPosition + TableProperties.PADDING_X, container.yPosition, { baseline: "top" });
    container.yPosition += TableProperties.PADDING_48;
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);

    checklistTable.rows.forEach((row) => {
      const isEnoughSpace = this.checkIfIsEnoughSpace(row.textHeight + TableProperties.ROW_PADDING_Y, container);
      if (!isEnoughSpace) {
        this.containerService.add(pdf, container, pageHeadline, tableOfContents);
        container.yPosition += TableProperties.PADDING_48;
        pdf.setFont(FontNames.DRAEGER_PANGEA);
        pdf.setFontSize(TableProperties.HEADER_FONT_SIZE);
        pdf.text(checklistTable.headline, container.xPosition + TableProperties.PADDING_X, container.yPosition, {
          baseline: "top",
        });
        container.yPosition += TableProperties.PADDING_48;
        pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
      }
      this.addChecklistRow(pdf, container, checklistTable.columns, row);
    });
    container.yPosition += TableProperties.GAP_BETWEEN_TABLES;
  }

  private addChecklistRow(pdf: jsPDF, container: HalfPageContainer, columns: TableColumn[], row: ChecklistRow) {
    container.yPosition += TableProperties.ROW_PADDING_TOP;
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    row.title.forEach((line, index) => {
      pdf.text(
        line,
        container.xPosition + columns[0].xPosition,
        container.yPosition + index * pdf.getTextDimensions(line).h * PdfProperties.POINT_TO_PIXEL_RATIO,
        { baseline: "top" },
      );
    });
    if (row.checked !== undefined) {
      const icon = row.checked ? ImagePaths.SWITCH_CHECKED : ImagePaths.SWITCH_UNCHECKED;
      pdf.addImage(icon, "PNG", container.xPosition + columns[1].xPosition, container.yPosition - 3, 52, 30);
    }
    row.text.forEach((line, index) => {
      pdf.text(
        line,
        container.xPosition + columns[2].xPosition,
        container.yPosition + index * pdf.getTextDimensions(line).h * PdfProperties.POINT_TO_PIXEL_RATIO,
        { baseline: "top" },
      );
    });
    container.yPosition += row.textHeight;
    container.yPosition += TableProperties.ROW_PADDING_BOTTOM;
  }

  private checkIfIsEnoughSpace(height: number, container: HalfPageContainer) {
    return (
      ContainerProperties.Y + ContainerProperties.HEIGHT - ProductListProperties.FOOTER_HEIGHT - container.yPosition > height
    );
  }

  private getChecklistTableRowData(checklist: Checklist): ChecklistTableRowData[] {
    return [
      {
        title: $localize`:@@checklist.operator.label:Wer ist Betreiber der Anlage?`,
        text: checklist.operator || "",
      },
      { title: $localize`:@@checklist.client.label:Wer ist Auftraggeber?`, text: checklist.client || "" },
      {
        title: $localize`:@@checklist.switch1.label:Sind alle Sensoren mit Gasart und Messbereich berücksichtigt?`,
        checked: checklist.sensorGasAndMeasurementRangeConsidered.checked,
        text: checklist.sensorGasAndMeasurementRangeConsidered.notes || "",
      },
      {
        title: $localize`:@@checklist.switch2.label:Gibt es Störeinflüsse (Sensorgifte, etc.)?`,
        checked: checklist.peturbationsExist.checked,
        text: checklist.peturbationsExist.notes || "",
      },
      {
        title: $localize`:@@checklist.switch3.label:Sind alle benötigten Relaisausgänge berücksichtigt?`,
        checked: checklist.relayOutputsConsidered.checked,
        text: checklist.relayOutputsConsidered.notes || "",
      },
      {
        title: $localize`:@@checklist.switch4.label:Ist die Betriebsspannung der Alarmmittel definiert?`,
        checked: checklist.operatingVoltageOfMeansOfAlarmDefined.checked,
        text: checklist.operatingVoltageOfMeansOfAlarmDefined.notes || "",
      },
      {
        title: $localize`:@@checklist.switch5.label:Sind alle Sensoren / Gaswarnzentrale / Alarmmittel positioniert?`,
        checked: checklist.sensorsGasAlarmDeviceAndMeansOfAlarmSet.checked,
        text: checklist.sensorsGasAlarmDeviceAndMeansOfAlarmSet.notes || "",
      },
      {
        title: $localize`:@@checklist.switch6.label:Anforderungen Installation / Montage`,
        checked: checklist.installationRequirementsDefined.checked,
        text: checklist.installationRequirementsDefined.notes || "",
      },
      {
        title: $localize`:@@checklist.switch7.label:Gibt es Werksnormen / besondere Auflagen?`,
        checked: checklist.specialStandardsExist.checked,
        text: checklist.specialStandardsExist.notes || "",
      },
      {
        title: $localize`:@@checklist.switch8.label:Werden Steiger / Gerüste benötigt / bereitgestellt?`,
        checked: checklist.scaffoldingRequired.checked,
        text: checklist.scaffoldingRequired.notes || "",
      },
      {
        title: $localize`:@@checklist.switch9.label:Gibt es Ex-Zonen?`,
        checked: checklist.exZonesExist.checked,
        text: checklist.exZonesExist.notes || "",
      },
      {
        title: $localize`:@@checklist.switch10.label:Freimessung notwendig? Wenn ja, durch wen?`,
        checked: checklist.clearanceMeasurementRequired.checked,
        text: checklist.clearanceMeasurementRequired.notes || "",
      },
      {
        title: $localize`:@@checklist.switch11.label:Ist ein Erdungskonzept vorhanden?`,
        checked: checklist.electricalGroundingConceptExists.checked,
        text: checklist.electricalGroundingConceptExists.notes || "",
      },
      {
        title: $localize`:@@checklist.switch12.label:Ist eine Be-/Entlüftung vorhanden?`,
        checked: checklist.ventilationExists.checked,
        text: checklist.ventilationExists.notes || "",
      },
      {
        title: $localize`:@@checklist.personToInstruct.label:Wer ist bei der IBN zugegen, bzw. wer soll eingewiesen werden?`,
        text: checklist.personToInstruct || "",
      },
    ];
  }

  private getChecklistTableRowDataInstallationInstructions(checklist: Checklist) {
    return [
      {
        title: $localize`:@@checklist.installationInstructions.cableType.label:Kabeltyp?`,
        checked: checklist.cableType.checked,
        text: checklist.cableType.notes || "",
      },
      {
        title: $localize`:@@checklist.installationInstructions.sil.label:SIL?`,
        checked: checklist.sil.checked,
        text: checklist.sil.notes || "",
      },

      {
        title: $localize`:@@checklist.installationInstructions.workingHeight.label:Arbeitshöhe?`,
        checked: checklist.workingHeight.checked,
        text: checklist.workingHeight.notes || "",
      },
      {
        title: $localize`:@@checklist.installationInstructions.deliverySpecifics.label:Lieferhinweise?`,
        checked: checklist.deliverySpecifics.checked,
        text: checklist.deliverySpecifics.notes || "",
      },
      {
        title: $localize`:@@checklist.installationInstructions.otherSpecifics.label:Weitere Angaben?`,
        checked: checklist.otherSpecifics.checked,
        text: checklist.otherSpecifics.notes || "",
      },
    ];
  }

  private getChecklistTableRowDataServiceInstructions(checklist: Checklist) {
    return [
      {
        title: $localize`:@@checklist.serviceInstructions.calibrationGas.label:Kalibriergas?`,
        checked: checklist.calibrationGas.checked,
        text: checklist.calibrationGas.notes || "",
      },
      {
        title: $localize`:@@checklist.serviceInstructions.calibrationInstructions.label:Kalibrierungsanweisungen?`,
        checked: checklist.calibrationInstructions.checked,
        text: checklist.calibrationInstructions.notes || "",
      },
    ];
  }

  private getChecklistTableRowDataRiskAssessment(checklist: Checklist) {
    return [
      {
        title: $localize`:@@checklist.riskAssessment.deadlineRisks.label:Deadline-Risiken?`,
        checked: checklist.deadlineRisks.checked,
        text: checklist.deadlineRisks.notes || "",
      },
      {
        title: $localize`:@@checklist.riskAssessment.technicalRisks.label:Technische Risiken?`,
        checked: checklist.technicalRisks.checked,
        text: checklist.technicalRisks.notes || "",
      },
      {
        title: $localize`:@@checklist.riskAssessment.commercialRisks.label:Geschäftliche Risiken?`,
        checked: checklist.commercialRisks.checked,
        text: checklist.commercialRisks.notes || "",
      },
      {
        title: $localize`:@@checklist.riskAssessment.otherRisks.label:Weitere Risiken?`,
        checked: checklist.otherRisks.checked,
        text: checklist.otherRisks.notes || "",
      },
    ];
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }
}

import { Component, inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { FormEmailChipListComponent } from "@components/form-email-chip-list/form-email-chip-list.component";
import { AreaHeaderComponent } from "@odx/angular/components/area-header";
import { ButtonComponent } from "@odx/angular/components/button";
import {
  ModalContentDirective,
  ModalDismissDirective,
  ModalFooterComponent,
  ModalHeaderComponent,
  ModalRef,
} from "@odx/angular/components/modal";

@Component({
  selector: "app-share-project-link-modal",
  standalone: true,
  imports: [
    AreaHeaderComponent,
    ButtonComponent,
    FormEmailChipListComponent,
    ModalContentDirective,
    ModalHeaderComponent,
    ReactiveFormsModule,
    ModalFooterComponent,
    ModalDismissDirective,
    UiKitModule,
  ],
  templateUrl: "./share-project-link-modal.component.html",
  styleUrl: "./share-project-link-modal.component.scss",
})
export class ShareProjectLinkModalComponent implements OnInit {
  public readonly modalRef = inject(ModalRef<any, string>);
  protected addedMails: string[] = [];
  protected shareLinkFormGroup!: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.shareLinkFormGroup = this.formBuilder.group({
      showPrice: [false],
      value: [
        "",
        [
          Validators.pattern(
            /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
          ),
        ],
      ],
    });
  }

  protected onConfirm() {
    const formData = {
      mailAddresses: this.addedMails,
      showPrices: this.shareLinkFormGroup.get("showPrice")!.value,
    };

    this.modalRef.close(formData);
  }

  onMailsChanged(mails: string[]) {
    this.addedMails = mails;
  }
}

import { Project } from "@domain/project/project";
import { IsInstanceOf } from "@utils/class-validator/class-validator-constraints";
import { Type } from "class-transformer";
import { IsBoolean } from "class-validator";

export class ReadAccessProjectResponseDto {
  @Type(() => Project)
  @IsInstanceOf(Project)
  project: Project | undefined;

  @IsBoolean()
  showPrices: boolean = false;
}

import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MasterDataService } from "@app/master-data.service";
import { UiKitModule } from "@app/ui-kit.module";
import { CloudProjectDeleteModalComponent } from "@components/cloud-project-delete-modal/cloud-project-delete-modal.component";
import { UserService } from "@domain/user/user.service";
import { ModalService } from "@odx/angular/components/modal";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-main-menu",
  standalone: true,
  imports: [UiKitModule, RouterModule],
  templateUrl: "./main-menu.component.html",
  styleUrl: "./main-menu.component.scss",
})
export class MainMenuComponent {
  protected isReadOnlyUser$: Observable<boolean>;
  constructor(
    private masterDataService: MasterDataService,
    private modalService: ModalService,
    private userService: UserService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
  }
  protected onClickRefresh() {
    this.masterDataService.refresh();
  }

  protected onClickDeleteFromCloud() {
    this.modalService.open(CloudProjectDeleteModalComponent, {
      interactiveBackdrop: false,
    });
  }
}

<div odxLayout="flex gap-none" class="h-100 flex-column">
  <app-project-detail-header
    class="w-100"
    [project]="activeFloorplan.project"
    [activeFloorplan]="activeFloorplan"
    (navigateBack)="navigateBack()"
  ></app-project-detail-header>

  <div class="planner-wrapper h-100">
    <div
      id="floorplan-container"
      #floorplanContainer
      class="h-100 w-100"
      (mouseenter)="onEnterFloorplan()"
      (mouseleave)="onLeaveFloorplan()"
    ></div>

    <div id="zoomBar">
      <odx-button-group [block]="true" [vertical]="true" [reverse]="false" [alignRight]="true">
        <button odxButton (click)="onZoomIn()" [odxTooltip]="tooltipTextZoomIn">
          <odx-icon name="zoom-in"></odx-icon>
        </button>
        <button odxButton (click)="onZoomOut()" [odxTooltip]="tooltipTextZoomOut">
          <odx-icon name="zoom-out"></odx-icon>
        </button>
        <button odxButton (click)="onResetZoom()" [odxTooltip]="tooltipTextResetZoom">
          <odx-icon name="zoom-original"></odx-icon>
        </button>
      </odx-button-group>
    </div>
  </div>
</div>

<div class="menu-container w-100" odxLayout="flex">
  <app-floorplan-menu [activeFloorplan]="activeFloorplan" *ngIf="(isReadOnlyUser$ | async) === false"></app-floorplan-menu>

  <app-floorplan-additional-notes [floorplan]="activeFloorplan"></app-floorplan-additional-notes>
</div>

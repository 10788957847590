<odx-modal-header>
  <odx-area-header i18n="@@modal.shareLink.header1">Lesefreigabe erteilen</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p i18n="@@modal.shareLink.header2">
    Laden Sie Personen ein, die eine Lesefreigabe erhalten sollen. Diese <strong>schreibgeschützte Kopie</strong> ist für 60 Tage
    ab der Linkerstellung verfügbar.
  </p>

  <ng-container [formGroup]="shareLinkFormGroup">
    <odx-checkbox formControlName="showPrice" class="checkbox-padding"> Preise anzeigen </odx-checkbox>
    <app-form-email-chip-list (updatedMailList)="onMailsChanged($event)"></app-form-email-chip-list>
  </ng-container>
</odx-modal-content>

<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@modal.invitePerson.dismiss">Abbrechen</button>
  <button
    odxButton
    variant="primary"
    [disabled]="addedMails.length === 0"
    (click)="onConfirm()"
    i18n="@@modal.invitePerson.confirm"
  >
    Links senden
  </button>
</odx-modal-footer>

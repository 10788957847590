import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { ProjectDetailHeaderComponent } from "@components/project-detail-header/project-detail-header.component";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { UserService } from "@domain/user/user.service";
import { FloorplanAdditionalNotesComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-additional-notes.component";
import { FloorplanMenuType, LocalizeFloorplanMenuTypePipe } from "@project/floorplanner/floorplan-menu/floorplan-menu-type";
import { FloorplanMenuComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu.component";
import { FloorplanWorkspace } from "@project/floorplanner/floorplan-workspace";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { ToastHelper } from "@utils/toast-helper.service";
import { first, map, Observable } from "rxjs";

@Component({
  selector: "app-floorplanner",
  templateUrl: "./floorplanner.component.html",
  styleUrls: ["./floorplanner.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    RouterModule,
    ProjectDetailHeaderComponent,
    FloorplanMenuComponent,
    FloorplanAdditionalNotesComponent,
    LocalizeFloorplanMenuTypePipe,
  ],
  providers: [
    FloorplanService,
    {
      provide: FloorplanWorkspace,
      useClass: FloorplanWorkspaceKonvaAdapter,
    },
  ],
})
export class FloorplannerComponent implements AfterViewInit, OnDestroy {
  activeFloorplan: Floorplan;

  private inFloorplan: boolean = false;
  private copyEvent = this.copyShortcut.bind(this);
  private pasteEvent = this.pasteShortcut.bind(this);

  protected readonly tooltipTextZoomIn: string = $localize`:@@floorplan.button.zoomIn:Reinzoomen`;
  protected readonly tooltipTextZoomOut: string = $localize`:@@floorplan.button.zoomOut:Rauszoomen`;
  protected readonly tooltipTextResetZoom: string = $localize`:@@floorplan.button.resetZoom:Zoom zurücksetzen`;

  protected isReadOnlyUser$: Observable<boolean>;

  @ViewChild("floorplanContainer")
  private floorplanContainer!: ElementRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastHelper: ToastHelper,
    private floorplanService: FloorplanService,
    private userService: UserService,
  ) {
    this.activeFloorplan = this.activatedRoute.snapshot.data["floorplan"];

    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));

    this.userService.user$.pipe(first()).subscribe((user) => {
      if (user.readOnlyAccess) {
        this.activeFloorplan.floorplanState.setLockForAll(true);
      }
    });
  }

  ngAfterViewInit(): void {
    addEventListener("copy", this.copyEvent);
    addEventListener("paste", this.pasteEvent);
    this.floorplanService.init(this.floorplanContainer.nativeElement).subscribe();
  }

  ngOnDestroy(): void {
    removeEventListener("copy", this.copyEvent);
    removeEventListener("paste", this.pasteEvent);
  }

  @HostListener("window:keydown.delete", ["$event"])
  deleteShortcut(event: KeyboardEvent): void {
    if (!this.inFloorplan) return;
    event.preventDefault();
    this.floorplanService.deleteSelectedItem();
  }

  copyShortcut(event: any): void {
    if (!this.inFloorplan) return;
    event.preventDefault();
    if (this.floorplanService.copySelectedItem()) {
      this.toastHelper.message($localize`:@@toast.floorplanElementCopySuccessful:Element wurde erfolgreich kopiert!`);
    }
  }

  pasteShortcut(event: any): void {
    if (!this.inFloorplan) return;
    event.preventDefault();
    this.floorplanService.pasteCopiedItem();
  }

  onEnterFloorplan() {
    this.inFloorplan = true;
  }

  onLeaveFloorplan() {
    this.inFloorplan = false;
  }

  onZoomOut() {
    this.floorplanService.zoomOut();
  }

  onZoomIn() {
    this.floorplanService.zoomIn();
  }

  onResetZoom() {
    this.floorplanService.resetZoom();
  }

  navigateBack() {
    this.router.navigate([`/projects/${this.activeFloorplan.project.id}`]);
  }

  protected readonly FloorplanMenuType = FloorplanMenuType;
}

import { Injectable } from "@angular/core";
import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { Project } from "@domain/project/project";
import { FlexContainer } from "@pdf/helpers/container/image-container/flex-container";
import { FlexContainerService } from "@pdf/helpers/container/image-container/flex-container.service";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import { ContainerProperties, FontNames, Headlines, PdfProperties, TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { catchError, concatMap, filter, finalize, from, map, Observable, of, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PdfFloorplanImageService {
  private headlines = new Headlines();
  private readonly MAX_WIDTH = 3241;
  private readonly MAX_HEIGHT = 1975 - 2 * TableProperties.PADDING_32;
  private readonly MAX_CONTAINER_WIDTH = this.MAX_WIDTH * 0.75;

  constructor(private containerService: FlexContainerService) {}

  generate(pdf: jsPDF, project: Project): Observable<jsPDF> {
    return new Observable((observer) => {
      pdf.setFont(FontNames.DRAEGER_PANGEA, "normal");
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);
      this.addFonts(pdf);
      if (project.floorplans.length === 0) {
        finalize(() => {
          observer.next(pdf);
          observer.complete();
        });
      }
      from(project.floorplans)
        .pipe(
          concatMap((floorplan) => from(floorplan.images)),
          concatMap((floorplanImage) =>
            this.addImage(pdf, floorplanImage).pipe(
              map((containerWidth) => ({ floorplanImage, containerWidth })),
              filter(({ containerWidth }) => containerWidth !== undefined),
            ),
          ),
          concatMap(({ floorplanImage, containerWidth }) =>
            containerWidth && floorplanImage.notes ? this.addNotes(pdf, floorplanImage.notes, containerWidth) : of(null),
          ),
          finalize(() => {
            observer.next(pdf);
            observer.complete();
          }),
        )
        .subscribe();
    });
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }

  addImage(pdf: jsPDF, floorplanImage: FloorplanImage): Observable<number | undefined> {
    return this.getImageDimensions(floorplanImage.projectImage.fileUrl).pipe(
      switchMap((dimensions) => {
        const useMaxWidth = !floorplanImage.notes;
        const [fittedWidth, fittedHeight] = this.fitImageToFrame(dimensions, useMaxWidth);
        const containerWidth = this.calculateContainerWidth(fittedWidth, useMaxWidth);
        const container = new FlexContainer(ContainerProperties.LEFT_X, containerWidth);
        const pageHeadline = floorplanImage.projectImage.name;
        this.containerService.add(pdf, container, pageHeadline, true, true);

        container.xPosition += TableProperties.PADDING_32;
        container.yPosition += TableProperties.PADDING_32;
        pdf.setFont(FontNames.DRAEGER_PANGEA);
        pdf.addImage(
          floorplanImage.projectImage.fileUrl,
          "JPEG",
          container.xPosition,
          container.yPosition,
          fittedWidth,
          fittedHeight,
        );
        return of(containerWidth);
      }),
      catchError((error) => {
        console.error("Error adding image to PDF:", error);
        return of(undefined);
      }),
    );
  }

  private calculateContainerWidth(fittedWidth: number, useMaxWidth: boolean) {
    const containerWidth = fittedWidth + 2 * TableProperties.PADDING_32;
    return useMaxWidth ? this.MAX_WIDTH : containerWidth;
  }

  private getImageDimensions(imageUrl: string): Observable<{ width: number; height: number }> {
    return new Observable((observer) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        observer.next({ width: img.width, height: img.height });
        observer.complete();
      };

      img.onerror = () => {
        observer.error(new Error("Failed to load image"));
      };
    });
  }

  private fitImageToFrame(dimensions: { width: number; height: number }, useMaxWidth: boolean) {
    const maxWidth = useMaxWidth ? this.MAX_WIDTH : this.MAX_CONTAINER_WIDTH;
    let ratio: number = Math.min(maxWidth / dimensions.width, this.MAX_HEIGHT / dimensions.height);
    ratio = ratio > 1 ? 1 : ratio;
    return [dimensions.width * ratio, dimensions.height * ratio];
  }

  private addNotes(pdf: jsPDF, notes: string, imageContainerWidth: number): Observable<any> {
    let xPosition: number = this.calculateNotesContainerPosition(imageContainerWidth);
    const notesContainerWidth: number = this.MAX_WIDTH - xPosition + ContainerProperties.LEFT_X;
    const container = new FlexContainer(xPosition, notesContainerWidth);
    let yPosition = container.yPosition + TableProperties.HEADLINE_OFFSET_Y;
    container.addFrame(pdf);
    xPosition += TableProperties.PADDING_32;

    this.addNotesHeadline(pdf, xPosition, yPosition);

    yPosition += TableProperties.PADDING_48;
    this.addNotesText(pdf, xPosition, yPosition, container.width, notes);
    return of();
  }
  private addNotesText(pdf: jsPDF, xPosition: number, yPosition: number, containerWidth: number, notes: string) {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    const text = pdf.splitTextToSize(notes, containerWidth - 2 * TableProperties.PADDING_32);
    pdf.text(text, xPosition, yPosition, { baseline: "top" });
  }

  private addNotesHeadline(pdf: jsPDF, xPosition: number, yPosition: number) {
    pdf.setFontSize(PdfProperties.TABLE_OF_CONTENTS_FONT_SIZE_LARGE);
    pdf.text(this.headlines.NOTES, xPosition, yPosition, { baseline: "top" });
  }

  private calculateNotesContainerPosition(containerWidth: number) {
    return ContainerProperties.LEFT_X + containerWidth + TableProperties.PADDING_64;
  }
}

import { Pipeline } from "@domain/project/floorplan/pipeline";
import { FloorplanItemTextKonva } from "@project/floorplanner/konva/floorplan-item-text-konva";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import Konva from "konva";
import { Text } from "konva/lib/shapes/Text";
import Group = Konva.Group;
import Ellipse = Konva.Ellipse;
import Rect = Konva.Rect;
import Path = Konva.Path;

export class PipelineKonva {
  static readonly COLOR_CODE = "#CC6600";

  static init(pipeline: Pipeline): Group {
    const group = new Group({
      x: pipeline.x,
      y: pipeline.y,
      rotation: pipeline.rotation,
      diameter: pipeline.diameter,
      medium: pipeline.medium,
      temperature: pipeline.temperature,
      material: pipeline.material,
      draggable: !pipeline.locked,
    });

    const mid = new Rect({
      fill: PipelineKonva.COLOR_CODE,
      opacity: 0.5,
    });

    const front = new Ellipse({
      radiusX: 0, // actual value set in setShapeDimensions
      radiusY: 0, // actual value set in setShapeDimensions
      fill: "white",
      stroke: PipelineKonva.COLOR_CODE,
      strokeWidth: 0.5,
    });

    const back = new Path({
      fill: PipelineKonva.COLOR_CODE,
      opacity: 0.5,
    });

    this.setShapeDimensions(pipeline.width, pipeline.height, group, mid, front, back);

    const positionId = FloorplanItemTextKonva.init(pipeline);

    this.setTextPosition(positionId, group);

    group.on("transformstart", () => {
      positionId.hide();
    });

    group.on("transform", () => {
      const width = Math.max(50, group.width() * group.scaleX());
      const height = Math.max(20, group.height() * group.scaleY());

      this.setShapeDimensions(width, height, group, mid, front, back);

      group.scaleX(1);
      group.scaleY(1);
    });

    group.on("transformend", () => {
      pipeline.updateForm(group.width(), group.height(), group.rotation(), group.x(), group.y());
      this.setTextPosition(positionId, group);
      positionId.show();
    });

    group.add(mid, front, back, positionId);

    group.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, pipeline);
    return group;
  }

  static refreshItemText(pipeline: Pipeline, group: Group) {
    const positionId = group.getChildren()[3] as Text;
    positionId.text(`${pipeline.positionId!} ${pipeline.displayName}`);
    this.setTextPosition(positionId, group);
  }

  private static setShapeDimensions(width: number, height: number, group: Group, mid: Rect, front: Ellipse, back: Path): void {
    group.width(width);
    group.height(height);

    mid.width(width);
    mid.height(height);

    front.radiusX(height / 4);
    front.radiusY(height / 2 - 0.5);
    front.offsetX(-width);
    front.offsetY(-height / 2);

    const radius = height / 2;
    back.offsetY(-radius);
    back.data(`M 0 ${-radius} A ${-radius} ${radius * 1.2} 0 0 1 0 ${radius}`);
  }

  static setTextPosition(itemText: Text, group: Group): void {
    itemText.align("left");
    itemText.width(200);
    itemText.rotation(-group.rotation());
    itemText.offsetX(-15);
    itemText.offsetY(itemText.height() / 2);

    if (group.rotation() <= -90 && group.rotation() >= -135) {
      itemText.x(group.width());
      itemText.y(group.height() - 0.5 * itemText.height());
      return;
    }
    if (group.rotation() > -90 && group.rotation() <= 45) {
      itemText.x(group.width());
      itemText.y(group.height() - 0.5 * itemText.height());
      return;
    }
    if (group.rotation() >= 45 && group.rotation() <= 90) {
      itemText.x(group.width());
      itemText.y(0);
      return;
    }
    itemText.x(0);
    itemText.y(0);
  }
}

import { AsyncPipe, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { DisabledController, ReadonlyController } from "@odx/angular";
import { ButtonComponent } from "@odx/angular/components/button";
import { CheckboxComponent } from "@odx/angular/components/checkbox";
import { FormDirective, FormFieldComponent, FormFieldControlDirective } from "@odx/angular/components/form-field";

@Component({
  selector: "app-floorplan-item-detail-transmitter-form",
  standalone: true,
  imports: [
    FormDirective,
    FormFieldComponent,
    FormsModule,
    FormFieldControlDirective,
    ReactiveFormsModule,
    CheckboxComponent,
    ButtonComponent,
    NgIf,
    AsyncPipe,
    DisabledController,
    ReadonlyController,
  ],
  templateUrl: "./floorplan-transmitter-detail-form.component.html",
  styleUrl: "./floorplan-transmitter-detail-form.component.scss",
})
export class FloorplanTransmitterDetailFormComponent implements OnInit {
  @Input({ required: true }) transmitter!: FloorplanTransmitter | FloorplanTransmitterPlaceholder;
  @Input() editEnabled: boolean = false;

  editTransmitterForm!: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.editTransmitterForm = this.formBuilder.group({
      bmk: [this.transmitter.bmk],
      sector: [this.transmitter.sector],
      installationHeight: [this.transmitter.installationHeight],
      exProtection: [this.transmitter.exProtection],
    });

    this.editTransmitterForm.valueChanges.subscribe(() => {
      if (!this.editTransmitterForm.valid || this.editTransmitterForm.pristine) return;
      this.update();
    });
  }
  private update() {
    this.transmitter.bmk = this.editTransmitterForm.value.bmk;
    this.transmitter.sector = this.editTransmitterForm.value.sector;
    this.transmitter.installationHeight = this.editTransmitterForm.value.installationHeight;
    this.transmitter.exProtection = this.editTransmitterForm.value.exProtection;
  }
}

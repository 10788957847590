import { Component, Input, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ProductImage } from "@domain/product/product-image";
import { ProductService } from "@domain/product/product.service";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanGasWarningCenterDetailFormComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-gas-warning-center-detail-form/floorplan-gas-warning-center-detail-form.component";
import { FloorplanItemAttachmentsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-attachments/floorplan-item-attachments.component";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";
import { ProjectStateService } from "@project/project-state.service";
import { Observable, first, map } from "rxjs";

@Component({
  selector: " app-gaswarningcenter-notes",
  templateUrl: "./gas-warning-center-notes.component.html",
  styleUrls: ["./gas-warning-center-notes.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    FloorplanItemDetailsComponent,
    FloorplanItemAttachmentsComponent,
    FloorplanGasWarningCenterDetailFormComponent,
  ],
})
export class GasWarningCenterNotesComponent implements OnInit {
  @Input({ required: true }) set floorplanItem(item: FloorplanItem) {
    this.gasWarningCenter = item as FloorplanGasWarningCenter;
  }

  protected gasWarningCenter!: FloorplanGasWarningCenter;
  protected imageUrl!: string;
  protected fallbackImageUrl!: string;
  protected gasWarningCenterName$!: Observable<string>;
  protected isDiscontinued$!: Observable<boolean>;

  protected editEnabled = false;

  constructor(
    private productService: ProductService,
    private projectStateService: ProjectStateService,
  ) {}

  ngOnInit() {
    this.imageUrl = ProductImage.getSourceUrl(this.gasWarningCenter.config.productId);
    this.productService
      .getProductById(this.gasWarningCenter.config.mainProductId)
      .pipe(first())
      .subscribe((product) => {
        this.fallbackImageUrl = ProductImage.getPlaceholderSourceUrl(
          GasWarningCenterConfiguration.getProductDataName(product?.getLocalizedName("de-DE") || ""),
        );
      });
    this.fallbackImageUrl = ProductImage.getPlaceholderSourceUrl(this.gasWarningCenter.config.productDataName);
    this.gasWarningCenterName$ = this.resolveGasWarningCenterName();
    this.isDiscontinued$ = this.projectStateService.isConfigDiscontinued(this.gasWarningCenter.config.id);
  }

  onEdit() {
    this.editEnabled = !this.editEnabled;
  }

  private resolveGasWarningCenterName(): Observable<string> {
    return this.productService
      .getProductNameById(this.gasWarningCenter.config.productId)
      .pipe(map((name) => name || this.gasWarningCenter.config.name));
  }
}

import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { ReadOnlyTokenStorage } from "@app/read-only-token.storage";
import { DefaultOAuthInterceptor, OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";

@Injectable()
export class TokenInterceptor extends DefaultOAuthInterceptor {
  constructor(
    private readOnlyTokenStorage: ReadOnlyTokenStorage,
    oAuthService: OAuthService,
    errorHandler: OAuthResourceServerErrorHandler,
    @Optional() moduleConfig: OAuthModuleConfig,
  ) {
    super(oAuthService, errorHandler, moduleConfig);
  }

  override intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.readOnlyTokenStorage.get()) {
      return this.addReadOnlyToken(req, next);
    }
    return super.intercept(req, next);
  }

  private addReadOnlyToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.readOnlyTokenStorage.get();

    if (token) {
      const headers = req.headers.set("x-read-access-token", token);
      req = req.clone({ headers });
    }

    return next.handle(req);
  }
}

<app-image-upload-overlay (image$)="onImageUploaded($event)">
  <form odxForm [formGroup]="imageForm" class="container scroll h-100" style="padding: 24px">
    <!-- prettier-ignore -->
    <h6 class="odx-subtitle-6" *ngIf="images.length === 0" i18n="@@imageUpload.noImageYet">Sie haben noch keine Fotos hinzugefügt.</h6>
    <div odxLayout="grid 3@desktop 4@tablet 6@phone" class="mb-36">
      <app-new-project-file-card
        *ngFor="let image of images"
        i18n-title="@@imageUpload.newImage"
        title="Neues Foto"
        [fileName]="image.name"
        [fileUrl]="image.fileUrl"
        (delete$)="onDelete(image)"
        (submit$)="onCardSubmit($event, image)"
        #imageCard
      ></app-new-project-file-card>
    </div>
  </form>
</app-image-upload-overlay>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService, UserInfo } from "@app/auth.service";
import { Countries } from "@domain/user/country";
import { Languages } from "@domain/user/language";
import { User } from "@domain/user/user";
import { environment } from "@environments/environment";
import { ObservableInstanceMapper } from "@utils/observable-instance-mapper";
import { Observable, of, ReplaySubject, switchMap, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public static readonly USER_URL = environment.apiUrl + "/user";
  private readonly _user$ = new ReplaySubject<User>(1);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.initUser().subscribe({
      next: (user) => this._user$.next(user),
      error: (err) => this._user$.error(err),
    });
  }

  get user$(): Observable<User> {
    return this._user$.asObservable();
  }

  private initUser(): Observable<User> {
    return this.authService.userInfo$.pipe(
      switchMap((userInfo: UserInfo) => {
        if (!userInfo.id) {
          return this.initReadAccessUser();
        } else {
          return this.loadUserFromApi().pipe(
            switchMap((user) => {
              const isValidLanguage = this.isValidLanguage(user);
              const isValidCountry = this.isValidCountry(user);
              if (!isValidLanguage || !isValidCountry) {
                const updateData: Record<string, string> = {
                  language: isValidLanguage ? user.language : this.getDefaultLanguage(),
                  country: isValidCountry ? user.country : Countries.COUNTRIES[0].countryCode,
                };
                return this.updateUserViaApi(updateData);
              }
              return of(user);
            }),
          );
        }
      }),
    );
  }

  updateLanguage(newLanguage: string): Observable<User> {
    return this.updateUserViaApi({ language: newLanguage }).pipe(
      tap((user) => {
        this._user$.next(user);
      }),
    );
  }

  updateCountry(newCountry: string): Observable<User> {
    return this.updateUserViaApi({ country: newCountry }).pipe(
      tap((user) => {
        this._user$.next(user);
      }),
    );
  }

  private getDefaultLanguage(): string {
    const browserLanguage = this.getBrowserLanguage();
    return Languages.LANGUAGES.map((language) => language.languageCode).includes(browserLanguage)
      ? browserLanguage
      : Languages.LANGUAGES[0].languageCode;
  }

  private getBrowserLanguage(): string {
    let browserLanguage: string = navigator.language;
    if (browserLanguage.length === 2) {
      switch (true) {
        case browserLanguage === "en":
          browserLanguage += "-US";
          break;
        case browserLanguage === "de":
          browserLanguage += "-DE";
          break;
      }
    }
    return browserLanguage;
  }

  private initReadAccessUser() {
    const user = new User();
    user.language = this.getDefaultLanguage();
    user.readOnlyAccess = true;
    return of(user);
  }

  private loadUserFromApi(): Observable<User> {
    return ObservableInstanceMapper.valueToInstance(this.http.get<User>(UserService.USER_URL), User).pipe(
      tap((user) => (user.readOnlyAccess = false)),
    );
  }

  private updateUserViaApi(updatedData: Record<string, string>): Observable<User> {
    return ObservableInstanceMapper.valueToInstance(this.http.patch<User>(UserService.USER_URL, updatedData), User).pipe(
      tap((user) => (user.readOnlyAccess = false)),
    );
  }

  private isValidLanguage(user: User) {
    return Languages.LANGUAGES.map((language) => language.languageCode).includes(user.language);
  }

  private isValidCountry(user: User) {
    return Countries.COUNTRIES.map((country) => country.countryCode).includes(user.country);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { DeleteButtonComponent } from "@components/delete-button/delete-button.component";
import { DuplicateButtonComponent } from "@components/duplicate-button/duplicate-button.component";
import { EditButtonComponent } from "@components/edit-button/edit-button.component";
import { StandardImageComponent } from "@components/standard-image/standard-image.component";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { UserService } from "@domain/user/user.service";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { map, Observable } from "rxjs";
import { LocalizeDatePipe } from "src/locale/localize-date";
import { LocalizeTimePipe } from "src/locale/localize-time";

@Component({
  selector: "app-floorplan-item-details",
  templateUrl: "./floorplan-item-details.component.html",
  styleUrl: "./floorplan-item-details.component.scss",
  standalone: true,
  imports: [
    UiKitModule,
    ReactiveFormsModule,
    StandardImageComponent,
    ToggleLockButtonComponent,
    DeleteButtonComponent,
    DuplicateButtonComponent,
    LocalizeDatePipe,
    LocalizeTimePipe,
    EditButtonComponent,
  ],
})
export class FloorplanItemDetailsComponent implements OnInit {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) floorplanItem!: FloorplanItem;
  @Input() showDiscontinuedWarning: boolean = false;
  @Input() showIncompleteWarning: boolean = false;
  @Input() duplicateEnabled = true;
  @Input() imageUrl?: string;
  @Input() fallbackImageUrl?: string;

  @Output() editClicked = new EventEmitter<void>();

  protected displayNameForm!: FormGroup;
  protected notesForm!: FormGroup;
  protected isReadOnlyUser$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private floorplanService: FloorplanService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
  }

  ngOnInit(): void {
    this.initNotesForm();
    this.initDisplayNameForm();
  }

  close() {
    this.floorplanService.deselectItem();
  }

  delete() {
    this.floorplanService.deleteSelectedItem();
  }

  duplicate() {
    this.floorplanService.duplicateSelectedItem();
  }

  isTransmitter(item: FloorplanItem) {
    return item instanceof FloorplanTransmitter || item instanceof FloorplanTransmitterPlaceholder;
  }

  isGasWarningCenter(item: FloorplanItem) {
    return item instanceof FloorplanGasWarningCenter || item instanceof FloorplanGasWarningCenterPlaceholder;
  }

  private initDisplayNameForm() {
    this.displayNameForm = this.formBuilder.group({
      name: [this.floorplanItem.displayName],
    });

    this.displayNameForm.valueChanges.subscribe(() => {
      this.update();
    });
  }

  private initNotesForm() {
    this.notesForm = this.formBuilder.group({
      notes: [this.floorplanItem.notes, Validators.maxLength(2000)],
    });

    this.notesForm.valueChanges.subscribe(() => {
      if (!this.notesForm.valid || this.notesForm.pristine) return;
      this.update();
    });
  }

  private update() {
    this.floorplanItem.displayName = this.displayNameForm.value.name;
    this.floorplanItem.notes = this.notesForm.value.notes;
  }

  edit() {
    this.editClicked.emit();
  }
}

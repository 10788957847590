import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { ToggleVisibilityButtonComponent } from "@components/toggle-visibility-button/toggle-visibility-button.component";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { Project } from "@domain/project/project";
import { UserService } from "@domain/user/user.service";
import { FloorplanMenuItemButtonsComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-item-buttons/floorplan-menu-item-buttons.component";
import { ProjectStateService } from "@project/project-state.service";
import { first } from "rxjs";

@Component({
  selector: "app-floorplan-main-menu",
  templateUrl: "./floorplan-main-menu.component.html",
  styleUrls: ["./floorplan-main-menu.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    FloorplanMenuItemButtonsComponent,
    ToggleLockButtonComponent,
    ToggleVisibilityButtonComponent,
    ReactiveFormsModule,
  ],
})
export class FloorplanMainMenuComponent implements OnInit {
  @Input({ required: true }) set activeFloorplan(floorplan: Floorplan) {
    this.project = floorplan.project;
    this.floorplanForm.patchValue({ activeFloorplan: floorplan });
    this.visible = this.checkIfAllVisible();
    this.locked = this.checkIfAllLocked();
  }

  protected project!: Project;
  protected visible!: boolean;
  protected locked!: boolean;
  protected floorplanForm: FormGroup;

  constructor(
    private router: Router,
    formBuilder: FormBuilder,
    private projectStateService: ProjectStateService,
    private userService: UserService,
  ) {
    this.floorplanForm = formBuilder.group({
      activeFloorplan: [],
    });
  }

  ngOnInit(): void {
    this.projectStateService.floorplanEvents$.subscribe((event) => {
      if (event.type === FloorplanEventType.FLOORPLAN_STATE_LOCK_CHANGED) {
        this.locked = this.checkIfAllLocked();
      }
      if (event.type === FloorplanEventType.FLOORPLAN_STATE_VISIBILITY_CHANGED) {
        this.visible = this.checkIfAllVisible();
      }
    });

    this.floorplanForm.valueChanges.subscribe(() => {
      this.router.navigate(["/projects", this.project.id, "floorplans", this.activeFloorplan.id], {
        onSameUrlNavigation: "reload",
      });
    });

    this.userService.user$.pipe(first()).subscribe((user) => {
      if (user.readOnlyAccess) {
        this.lockUpdated(true);
      }
    });
  }

  get activeFloorplan() {
    return this.floorplanForm.value.activeFloorplan;
  }

  visibilityUpdated(visible: boolean) {
    this.visible = visible;
    this.activeFloorplan.floorplanState.setVisibilityForAll(this.visible);
  }

  lockUpdated(locked: boolean) {
    this.locked = locked;
    this.activeFloorplan.floorplanState.setLockForAll(this.locked);
  }

  private checkIfAllVisible() {
    const floorplanState = this.activeFloorplan.floorplanState;
    return (
      floorplanState.airPathsVisible &&
      floorplanState.alarmDevicesVisible &&
      floorplanState.dangerAreasVisible &&
      floorplanState.exZonesVisible &&
      floorplanState.dustExZonesVisible &&
      floorplanState.floorplanTextsVisible &&
      floorplanState.gasWarningCentersVisible &&
      floorplanState.imagesVisible &&
      floorplanState.measurementLinesVisible &&
      floorplanState.pipelinesVisible &&
      floorplanState.plasticSignsVisible &&
      floorplanState.signalElementsVisible &&
      floorplanState.transmittersVisible &&
      floorplanState.monitoringAreasVisible
    );
  }

  private checkIfAllLocked(): boolean {
    const floorplanState = this.activeFloorplan.floorplanState;
    return (
      floorplanState.airPathsLocked &&
      floorplanState.alarmDevicesLocked &&
      floorplanState.dangerAreasLocked &&
      floorplanState.exZonesLocked &&
      floorplanState.dustExZonesLocked &&
      floorplanState.floorplanTextsLocked &&
      floorplanState.gasWarningCentersLocked &&
      floorplanState.imagesLocked &&
      floorplanState.measurementLinesLocked &&
      floorplanState.pipelinesLocked &&
      floorplanState.plasticSignsLocked &&
      floorplanState.signalElementsLocked &&
      floorplanState.transmittersLocked
    );
  }
}

import { inject, Injectable } from "@angular/core";
import { ShareProjectLinkModalComponent } from "@components/share-project-link-modal/share-project-link-modal.component";
import { ProjectApiService } from "@domain/project/api/project-api.service";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { ModalService, ModalSize } from "@odx/angular/components/modal";
import { ToastHelper } from "@utils/toast-helper.service";
import { filter, switchMap, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReadAccessModalService {
  private readonly modalService = inject(ModalService);

  constructor(
    private projectApiService: ProjectApiService,
    private toastHelper: ToastHelper,
    private projectService: ProjectService,
  ) {}

  public openModal(project: Project) {
    this.modalService
      .open(ShareProjectLinkModalComponent, { size: ModalSize.MEDIUM })
      .onClose$.pipe(
        filter((value) => !!value && !!project?.cloudId),
        switchMap((value) => this.shareProjectLinkAndHandleErrors(value, project)),
        switchMap((response) => this.updateProjectMetadata(response)),
      )
      .subscribe();
  }
  private shareProjectLinkAndHandleErrors(value: any, project: Project) {
    return this.projectApiService
      .shareProjectLink(value, project.cloudId!)
      .pipe(tap((response) => this.handleShareLinkResponse(response)));
  }

  private updateProjectMetadata(response: any) {
    return this.projectService.updateLocalMetadata(response.metadata);
  }

  private handleShareLinkResponse(response: any) {
    const errors: string[] = this.filterFailedShareResults(response.results) || [""]; // Default to empty string if no errors
    if (errors.length) {
      this.showErrorToast(errors);
    } else {
      this.showSuccessToast();
    }
  }

  private filterFailedShareResults(results: { success: boolean; to?: string }[]) {
    return results
      .filter((res) => !res.success) // Filter for unsuccessful results
      .map((res) => res.to) // Get the 'to' field
      .filter((to): to is string => to !== undefined); // Exclude 'undefined' values
  }

  private showErrorToast(errors: string[]) {
    this.toastHelper.error($localize`:@@toast.shareLink.error:Projekt konnte nicht geteilt werden an: ` + errors.join(", "));
  }

  private showSuccessToast() {
    this.toastHelper.success($localize`:@@toast.shareLink.successful:Link wurde erfolgreich verschickt`);
  }
}

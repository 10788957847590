import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { FilterProductComponent } from "@components/filter-product/filter-product.component";
import { ProductCardComponent } from "@components/product-card/product-card.component";
import { FullProductType, Product, ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { Project } from "@domain/project/project";
import { untilDestroyed } from "@odx/angular/utils";
import { SafetyClass, VoltageClass } from "@project/alarm-device-configuration/device-class";
import { map, Observable, of } from "rxjs";

@Component({
  selector: "app-alarm-device-selector",
  templateUrl: "./alarm-device-selector.component.html",
  styleUrls: ["./alarm-device-selector.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, ProductCardComponent, FilterProductComponent],
})
export class AlarmDeviceSelectorComponent implements OnInit {
  static FULL_TYPES_BY_DEVICE_CLASS = new Map<string, FullProductType[]>([
    [SafetyClass.EX + VoltageClass.VOLT_24, [FullProductType.ALARMDEVICE_A_EX_24]],
    [SafetyClass.EX + VoltageClass.VOLT_230, [FullProductType.ALARMDEVICE_A_EX_230]],
    [SafetyClass.NON_EX + VoltageClass.VOLT_230, [FullProductType.ALARMDEVICE_A230, FullProductType.ALARMDEVICE_A230_MOD]],
    [SafetyClass.NON_EX + VoltageClass.VOLT_24, [FullProductType.ALARMDEVICE_A24, FullProductType.ALARMDEVICE_A24_MOD]],
  ]);

  @Input()
  project!: Project;

  @Input()
  set selectedSafetyClass(selectedSafetyClass: SafetyClass) {
    this.safetyClass = selectedSafetyClass;
    this.collectAlarmDevices();
  }

  @Input()
  set selectedVoltage(selectedVoltage: VoltageClass) {
    this.voltageClass = selectedVoltage;
    this.collectAlarmDevices();
  }

  @Input()
  set originalAlarmDevice(alarmDevice: Product[] | undefined) {
    if (!alarmDevice) {
      return;
    }
    const availableAlarmDevices = alarmDevice.filter((product) => product.isAvailable);
    switch (this.safetyClass) {
      case SafetyClass.EX:
        this.radioButtonForm.patchValue({
          selectedAlarmDevice: availableAlarmDevices[0] || null,
        });
        break;
      case SafetyClass.NON_EX:
        availableAlarmDevices.map((product) => this.checkboxSelectionChanged(true, product));
        break;
    }
  }

  @Output()
  alarmDeviceSelect$ = new EventEmitter<Product[]>();

  protected radioButtonForm = this.formBuilder.group({
    selectedAlarmDevice: new FormControl<Product | null>(null, Validators.required),
  });

  @Output()
  formReady = of(this.radioButtonForm);

  protected safetyClass?: string;
  protected voltageClass?: string;
  protected alarmDevices$!: Observable<Product[]>;
  protected filteredAlarmDevices$!: Observable<Product[]>;
  protected checkedAlarmDevices: Product[] = [];

  private readonly takeUntilDestroyed = untilDestroyed();

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.radioButtonForm.valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((formValue) =>
        this.alarmDeviceSelect$.emit(formValue.selectedAlarmDevice ? [formValue.selectedAlarmDevice!] : []),
      );
  }

  protected search(value: string) {
    this.filteredAlarmDevices$ = this.productService.filter(this.alarmDevices$, value);
  }

  protected checkboxSelectionChanged(checked: boolean, alarmDevice: Product) {
    const index = this.checkedAlarmDevices.map((checked) => checked.id).indexOf(alarmDevice.id);
    if (checked) {
      if (index < 0) {
        this.checkedAlarmDevices.push(alarmDevice);
      }
    } else {
      if (index > -1) {
        this.checkedAlarmDevices.splice(index, 1);
      }
    }
    this.alarmDeviceSelect$.emit([...this.checkedAlarmDevices]);
  }

  protected checkIfSelected(alarmDevice: Product) {
    return this.checkedAlarmDevices.map((alarmDevice) => alarmDevice.id).indexOf(alarmDevice.id) >= 0;
  }

  private collectAlarmDevices() {
    this.radioButtonForm.patchValue({
      selectedAlarmDevice: null,
    });
    this.checkedAlarmDevices = [];
    this.alarmDevices$ = this.productService
      .getAvailableProducts(ProductType.ALARMDEVICE)
      .pipe(map((products) => products.filter((product) => this.filterAlarmDevice(product))));
    this.filteredAlarmDevices$ = this.alarmDevices$;
  }

  private filterAlarmDevice(alarmDevice: Product): boolean {
    if (!this.safetyClass || !this.voltageClass) {
      return true;
    }
    const productFullTypes = AlarmDeviceSelectorComponent.FULL_TYPES_BY_DEVICE_CLASS.get(this.safetyClass + this.voltageClass);
    return productFullTypes?.indexOf(alarmDevice.fullType) !== -1;
  }

  protected readonly SafetyClass = SafetyClass;
}

<app-floorplan-item-details
  [title]="(alarmDeviceName$ | async) || alarmDevice.config.name"
  [floorplanItem]="alarmDevice"
  [showDiscontinuedWarning]="(isDiscontinued$ | async) || false"
  imageUrl="./assets/alarmmittel_odx.svg"
>
  <div class="feature">
    <p class="odx-text--small" i18n="@@floorplan.details.alarmDevice">Alarmmittel</p>
    <ul *ngFor="let alarmDevice of alarmDevices$ | async">
      <li class="odx-text">{{ alarmDevice }}</li>
    </ul>
  </div>

  <app-floorplan-item-attachments class="feature" [attachmentIds]="alarmDevice.config.attachmentIds">
  </app-floorplan-item-attachments>
</app-floorplan-item-details>

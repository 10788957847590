import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import Konva from "konva";

export class FloorplanItemTextKonva {
  static init(element: FloorplanItem): Konva.Text {
    return new Konva.Text({
      text: `${element.positionId} ${element.displayName}`,
      fontSize: 16,
      fill: "#002766",
    });
  }
}

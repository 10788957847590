import { Injectable } from "@angular/core";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { Project } from "@domain/project/project";
import { ChecklistPagePdfService } from "@pdf/export-services/checklist-page/pdf-checklist.service";
import { PdfFloorplanImageService } from "@pdf/export-services/floorplan-images-page/pdf-floorplan-image.service";
import { LegendPagePdfService } from "@pdf/export-services/legend-page/legend-page-pdf.service";
import { PdfProjectInformationService } from "@pdf/export-services/project-information-page/pdf-project-information.service";
import { PdfTableOfContentsService } from "@pdf/export-services/table-of-contents/pdf-table-of-contents.service";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { Headlines } from "@pdf/pdf-properties";
import { format } from "date-fns";
import { jsPDF } from "jspdf";
import { concatMap, from, map, Observable, of, reduce, switchMap } from "rxjs";
import { FloorplanPagePdfService } from "./export-services/floorplan-page/floorplan-page-pdf.service";
import { PdfFooterService } from "./export-services/footer/pdf-footer.service";
import { ProductInformationPagesService } from "./export-services/product-information-pages/product-information-pages.service";
import { TitlePagePdfService } from "./export-services/title-page/title-page-pdf.service";

@Injectable({
  providedIn: "root",
})
export class PdfService {
  private readonly PPI = 300;
  private readonly WIDTH: number = this.calculateWidth(this.PPI);
  private readonly HEIGHT: number = this.calculateHeight(this.PPI);
  private readonly RESOLUTION = [this.WIDTH, this.HEIGHT];
  private readonly HEADLINES = new Headlines();

  constructor(
    private titlePagePdfService: TitlePagePdfService,
    private floorplanPagePdfService: FloorplanPagePdfService,
    private legendPagePdfService: LegendPagePdfService,
    private productList: ProductInformationPagesService,
    private checklistPdfService: ChecklistPagePdfService,
    private projectInformationService: PdfProjectInformationService,
    private projectImageService: PdfFloorplanImageService,
    private footerPdfService: PdfFooterService,
    private tableOfContentsPdfService: PdfTableOfContentsService,
  ) {}

  exportProjectToPdf(project: Project): Observable<{ pdf: jsPDF; fileName: string }> {
    const { pdf, tableOfContents, exportTime, fileName } = this.setupPdf(project, project.name);
    return from(project.floorplans).pipe(
      concatMap((floorplan: Floorplan) => this.addFloorplanToPdf(pdf, floorplan, tableOfContents)),
      reduce((acc, result) => result, { pdf, tableOfContents }),
      concatMap(({ pdf, tableOfContents }) =>
        this.productList
          .generateProductList(pdf, project, tableOfContents)
          .pipe(map((updatedPdf) => ({ pdf: updatedPdf, tableOfContents }))),
      ),
      concatMap(({ pdf, tableOfContents }) =>
        this.projectInformationService
          .generate(pdf, project)
          .pipe(
            concatMap(({ pdf, container }) =>
              this.checklistPdfService
                .generate(pdf, project, tableOfContents, container)
                .pipe(map((updatedPdf) => ({ pdf: updatedPdf, tableOfContents }))),
            ),
          ),
      ),
      concatMap(({ pdf, tableOfContents }) =>
        this.projectImageService.generate(pdf, project).pipe(map((updatedPdf) => ({ pdf: updatedPdf, tableOfContents }))),
      ),

      concatMap(({ pdf, tableOfContents }) =>
        this.tableOfContentsPdfService.generate(pdf, tableOfContents).pipe(
          concatMap(({ pdf, numberOfAdditionalPagesNeeded, container }) => {
            pdf.setPage(2 + numberOfAdditionalPagesNeeded);
            this.legendPagePdfService.generate(pdf, container);
            this.footerPdfService.addToEachPage(pdf, project, fileName, exportTime);
            return of({ pdf, fileName });
          }),
        ),
      ),
    );
  }

  exportFloorplanToPdf(floorplan: Floorplan): Observable<{ pdf: jsPDF; fileName: string }> {
    const { pdf, tableOfContents, exportTime, fileName } = this.setupPdf(floorplan.project, floorplan.name);
    return this.addFloorplanToPdf(pdf, floorplan, tableOfContents).pipe(
      concatMap(({ pdf }) =>
        this.tableOfContentsPdfService.generate(pdf, tableOfContents).pipe(
          concatMap(({ pdf, numberOfAdditionalPagesNeeded, container }) => {
            pdf.setPage(2 + numberOfAdditionalPagesNeeded);
            this.legendPagePdfService.generate(pdf, container);
            this.footerPdfService.addToEachPage(pdf, floorplan.project, fileName, exportTime);
            return of({ pdf, fileName });
          }),
        ),
      ),
    );
  }

  private setupPdf(project: Project, fileNamePrefix: string) {
    const pdf = new jsPDF({
      orientation: "l",
      unit: "px",
      format: this.RESOLUTION,
      putOnlyUsedFonts: true,
      compress: true,
    });
    const tableOfContents = new TableOfContents();
    tableOfContents.appendToTableOfContents("");
    tableOfContents.appendToTableOfContents(this.HEADLINES.LEGEND);

    const exportTime = new Date();
    const fileName = fileNamePrefix + "_" + this.formatDateForFileName(exportTime) + ".pdf";

    this.titlePagePdfService.generate(pdf, project, fileName, exportTime);
    pdf.addPage();
    return { pdf, tableOfContents, exportTime, fileName };
  }

  private addFloorplanToPdf(
    pdf: jsPDF,
    floorplan: Floorplan,
    tableOfContents: TableOfContents,
  ): Observable<{ pdf: jsPDF; tableOfContents: TableOfContents }> {
    return new Observable<{ pdf: jsPDF; tableOfContents: TableOfContents }>((observer) => {
      pdf.addPage();

      this.floorplanPagePdfService
        .generate(pdf, floorplan)
        .pipe(
          switchMap(() => {
            tableOfContents.appendToTableOfContents(this.HEADLINES.FLOORPLAN + " | " + floorplan.name);
            return this.productList.generateForFloorplan(pdf, floorplan, tableOfContents);
          }),
        )
        .subscribe({
          next: (result) => {
            observer.next(result);
            observer.complete();
          },
          error: (err) => observer.error(err),
        });
    });
  }

  private formatDateForFileName(date: Date) {
    return format(date, "yyyy-MM-dd_HHmmss");
  }

  private calculateWidth(ppi: number) {
    const inchInMm = 25.4;
    const a4WidthInMm = 297;
    return (a4WidthInMm / inchInMm) * ppi;
  }

  private calculateHeight(ppi: number) {
    const inchInMm = 25.4;
    const a4WHeightInMm = 210;
    return (a4WHeightInMm / inchInMm) * ppi;
  }
}

import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { jsPDF } from "jspdf";
import { ProductListPlaceholderRow } from "./product-list-placeholder-row";
import { ProductListProductRow } from "./product-list-product-row";

export class ProductListRowHeaders {
  public readonly NUMBER = $localize`:@@pdfExport.productList.tableHeader.number:Nr.`;
  public readonly QUANTITY = $localize`:@@pdfExport.productList.tableHeader.quantity:Menge`;
  public readonly DESCRIPTION = $localize`:@@pdfExport.productList.tableHeader.description:Beschreibung`;
  public readonly ID = $localize`:@@pdfExport.productList.tableHeader.partNumber:Sachnummer`;
  public readonly UNIT_PRICE = $localize`:@@pdfExport.productList.tableHeader.pricePerPiece:Stückpreis`;
  public readonly TOTAL_PRICE = $localize`:@@pdfExport.productList.tableHeader.totalPrice:Gesamtpreis`;
}

interface RowData {
  position: string;
  quantity: string;
  name: string;
  id: string;
  productCosts: number;
  totalCosts: number;
  showWarning: boolean;
  isPlaceholder: boolean;
}

export class ProductListTable {
  readonly rowHeaders = new ProductListRowHeaders();
  readonly columns: TableColumn[];
  readonly rows: (ProductListProductRow | ProductListPlaceholderRow)[];

  constructor(pdf: jsPDF, localeId: string, productTableRows: RowData[], placeholderTableRows: RowData[]) {
    this.columns = [
      new TableColumn(this.rowHeaders.NUMBER, 60, 96),
      new TableColumn(this.rowHeaders.QUANTITY, 180, 96),
      new TableColumn(this.rowHeaders.DESCRIPTION, 300, 600),
      new TableColumn(this.rowHeaders.ID, 924, 176),
      new TableColumn(this.rowHeaders.UNIT_PRICE, 1270, 146),
      new TableColumn(this.rowHeaders.TOTAL_PRICE, 1440, 146),
    ];

    this.rows = productTableRows.map(
      (productTableRow) => new ProductListProductRow(pdf, localeId, this.columns, productTableRow),
    );

    placeholderTableRows.map((placeholderTableRow: RowData) =>
      this.rows.push(new ProductListPlaceholderRow(placeholderTableRow)),
    );
  }
}

import { Floorplan } from "@domain/project/floorplan/floorplan";
import { Project } from "@domain/project/project";

export enum FloorplanEventType {
  IMAGE_ADDED = "IMAGE_ADDED",
  IMAGE_DELETED = "IMAGE_DELETED",
  ALARM_DEVICE_ADDED = "ALARM_DEVICE_ADDED",
  ALARM_DEVICE_DELETED = "ALARM_DEVICE_DELETED",
  ALARM_DEVICE_PLACEHOLDER_ADDED = "ALARM_DEVICE_PLACEHOLDER_ADDED",
  ALARM_DEVICE_PLACEHOLDER_DELETED = "ALARM_DEVICE_PLACEHOLDER_DELETED",
  TRANSMITTER_ADDED = "TRANSMITTER_ADDED",
  TRANSMITTER_DELETED = "TRANSMITTER_DELETED",
  TRANSMITTER_PLACEHOLDER_ADDED = "TRANSMITTER_PLACEHOLDER_ADDED",
  TRANSMITTER_PLACEHOLDER_DELETED = "TRANSMITTER_PLACEHOLDER_DELETED",
  GAS_WARNING_CENTER_ADDED = "GAS_WARNING_CENTER_ADDED",
  GAS_WARNING_CENTER_DELETED = "GAS_WARNING_CENTER_DELETED",
  GAS_WARNING_CENTER_PLACEHOLDER_ADDED = "GAS_WARNING_CENTER_PLACEHOLDER_ADDED",
  GAS_WARNING_CENTER_PLACEHOLDER_DELETED = "GAS_WARNING_CENTER_ADDED_PLACEHOLDER_DELETED",
  SIGNAL_ELEMENT_ADDED = "SIGNAL_ELEMENT_ADDED",
  SIGNAL_ELEMENT_DELETED = "SIGNAL_ELEMENT_DELETED",
  SIGNAL_ELEMENT_PLACEHOLDER_ADDED = "SIGNAL_ELEMENT_PLACEHOLDER_ADDED",
  SIGNAL_ELEMENT_PLACEHOLDER_DELETED = "SIGNAL_ELEMENT_PLACEHOLDER_DELETED",
  PLASTIC_SIGN_ADDED = "PLASTIC_SIGN_ADDED",
  PLASTIC_SIGN_DELETED = "PLASTIC_SIGN_DELETED",
  PLASTIC_SIGN_PLACEHOLDER_ADDED = "PLASTIC_SIGN_PLACEHOLDER_ADDED",
  PLASTIC_SIGN_PLACEHOLDER_DELETED = "PLASTIC_SIGN_PLACEHOLDER_DELETED",
  TEXT_ADDED = "TEXT_ADDED",
  TEXT_DELETED = "TEXT_DELETED",
  EX_ZONE_ADDED = "EX_ZONE_ADDED",
  EX_ZONE_DELETED = "EX_ZONE_DELETED",
  DUST_EX_ZONE_ADDED = "DUST_EX_ZONE_ADDED",
  DUST_EX_ZONE_DELETED = "DUST_EX_ZONE_DELETED",
  AIR_PATH_ADDED = "AIR_PATH_ADDED",
  AIR_PATH_DELETED = "AIR_PATH_DELETED",
  PIPELINE_ADDED = "PIPELINE_ADDED",
  PIPELINE_DELETED = "PIPELINE_DELETED",
  MEASUREMENT_LINE_ADDED = "MEASUREMENT_LINE_ADDED",
  MEASUREMENT_LINE_DELETED = "MEASUREMENT_LINE_DELETED",
  DANGER_AREA_ADDED = "DANGER_AREA_ADDED",
  DANGER_AREA_DELETED = "DANGER_AREA_DELETED",
  ITEM_LOCK_CHANGED = "ITEM_LOCK_CHANGED",
  ITEM_POSITION_ID_CHANGED = "ITEM_POSITION_ID_CHANGED",
  ITEM_DISPLAY_NAME_CHANGED = "ITEM_DISPLAY_NAME_CHANGED",
  ITEM_SIZE_CHANGED = "ITEM_SIZE_CHANGED",
  ITEM_UPDATED = "ITEM_UPDATED",
  MEASUREMENT_LINE_UPDATED = "MEASUREMENT_LINE_UPDATED",
  FLOORPLAN_STATE_LOCK_CHANGED = "FLOORPLAN_STATE_LOCK_CHANGED",
  FLOORPLAN_STATE_VISIBILITY_CHANGED = "FLOORPLAN_STATE_VISIBILITY_CHANGED",
  FLOORPLAN_STATE_POSITION_UPDATED = "FLOORPLAN_STATE_POSITION_UPDATED",
  FLOORPLAN_STATE_SCALE_UPDATED = "FLOORPLAN_STATE_SCALE_UPDATED",
  FLOORPLAN_STATE_BORDER_SHAPE_UPDATED = "FLOORPLAN_STATE_BORDER_SHAPE_UPDATED",
}

export interface FloorplanEvent {
  project: Project;
  floorplan: Floorplan;
  type: FloorplanEventType;
  subject: any;
}

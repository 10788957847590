import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { AirPath, AirPathDirection } from "@domain/project/floorplan/air-path";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanAlarmDevice } from "@domain/project/floorplan/floorplan-alarm-device";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanPlasticSign } from "@domain/project/floorplan/floorplan-plastic-sign";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { FloorplanSignalElement } from "@domain/project/floorplan/floorplan-signal-element";
import { FloorplanState } from "@domain/project/floorplan/floorplan-state";
import { FloorplanText } from "@domain/project/floorplan/floorplan-text";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { MeasurementLine } from "@domain/project/floorplan/measurement-line";
import { Pipeline } from "@domain/project/floorplan/pipeline";
import { DangerArea } from "@domain/project/floorplan/zones/danger-area";
import { DustExZone, DustExZoneType } from "@domain/project/floorplan/zones/dust-ex-zone";
import { ExZone, ExZoneType } from "@domain/project/floorplan/zones/ex-zone";
import { ProjectImage } from "@domain/project/project-image";
import { FloorplanWorkspace } from "@project/floorplanner/floorplan-workspace";
import { FloorplanWorkspaceItemService } from "@project/floorplanner/floorplan-workspace-item.service";
import { FloorplanWorkspaceWarningSignService } from "@project/floorplanner/floorplan-workspace-warning-sign.service";
import { ProjectStateService } from "@project/project-state.service";
import { Point } from "@utils/point";
import { Observable, Subject, filter, switchMap, takeUntil, tap } from "rxjs";
import { take } from "rxjs/operators";

@Injectable()
export class FloorplanService implements OnDestroy {
  private static readonly DEFAULT_ICON_SIZE = 4;

  readonly selectedFloorplan: Floorplan;
  private selectedFloorplanItem?: FloorplanItem;
  private copiedFloorplanItem?: FloorplanItem;

  private currentIconSize = FloorplanService.DEFAULT_ICON_SIZE;
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private floorplanWorkspace: FloorplanWorkspace,
    private projectStateService: ProjectStateService,
    private floorplanWorkspaceItemService: FloorplanWorkspaceItemService,
    private floorplanWorkspaceWarningSignService: FloorplanWorkspaceWarningSignService,
    route: ActivatedRoute,
  ) {
    this.selectedFloorplan = route.snapshot.data["floorplan"];
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  init(container: HTMLDivElement): Observable<void> {
    return this.floorplanWorkspace.init(this.selectedFloorplan, container).pipe(
      switchMap(() => this.floorplanWorkspaceItemService.addItems(this.selectedFloorplan, this.floorplanWorkspace)),
      switchMap(() =>
        this.floorplanWorkspaceWarningSignService.updateWarningSigns(this.selectedFloorplan, this.floorplanWorkspace),
      ),
      tap(() => {
        this.initFloorplanEventHandler();
        this.floorplanWorkspace.selectedItemChanged$.pipe(takeUntil(this.destroyed$)).subscribe((element) => {
          this.selectedFloorplanItem = element;
        });
      }),
    );
  }

  get selectedItem$(): Observable<FloorplanItem | null> {
    return this.floorplanWorkspace.selectedItemChanged$;
  }

  duplicateSelectedItem() {
    if (!this.selectedFloorplanItem) {
      return;
    }
    const position = this.calculateNextItemPosition(this.selectedFloorplanItem.relatedItems);
    this.selectedFloorplanItem.duplicate(position.x, position.y);
  }

  deleteSelectedItem() {
    if (!this.selectedFloorplanItem || this.selectedFloorplanItem.locked) {
      return;
    }
    this.selectedFloorplanItem.delete();
  }

  copySelectedItem(): FloorplanItem | undefined {
    this.copiedFloorplanItem = this.selectedFloorplanItem;
    return this.copiedFloorplanItem;
  }

  pasteCopiedItem() {
    if (!this.copiedFloorplanItem) {
      return;
    }
    const pointerPosition = this.floorplanWorkspace.getPointerPosition();
    this.copiedFloorplanItem.duplicate(pointerPosition.x, pointerPosition.y);
  }

  deselectItem() {
    this.floorplanWorkspace.deselect();
  }

  addExZone(type: ExZoneType) {
    this.addItem((x, y) => this.selectedFloorplan.addExZone(type, x, y), this.selectedFloorplan.exZones);
  }

  addDustExZone(type: DustExZoneType) {
    this.addItem((x, y) => this.selectedFloorplan.addDustExZone(type, x, y), this.selectedFloorplan.dustExZones);
  }

  addDangerArea() {
    this.addItem((x, y) => this.selectedFloorplan.addDangerArea(x, y), this.selectedFloorplan.dangerAreas);
  }

  addAirPath(direction: AirPathDirection) {
    this.addItem((x, y) => this.selectedFloorplan.addAirPath(direction, x, y), this.selectedFloorplan.airPaths);
  }

  addAlarmDevice(config: AlarmDeviceConfiguration) {
    this.addItem((x, y, size) => this.selectedFloorplan.addAlarmDevice(config, x, y, size), this.selectedFloorplan.alarmDevices);
  }

  addAlarmDevicePlaceholder() {
    this.addItem(
      (x, y, size) => this.selectedFloorplan.addAlarmDevicePlaceholder(x, y, size),
      this.selectedFloorplan.alarmDevicePlaceholders,
    );
  }

  addGasWarningCenter(config: GasWarningCenterConfiguration) {
    this.addItem(
      (x, y, size) => this.selectedFloorplan.addGasWarningCenter(config, x, y, size),
      this.selectedFloorplan.gasWarningCenters,
    );
  }

  addGasWarningCenterPlaceholder() {
    this.addItem(
      (x, y, size) => this.selectedFloorplan.addGasWarningCenterPlaceholder(x, y, size),
      this.selectedFloorplan.gasWarningCenterPlaceholders,
    );
  }

  addPlasticSign(config: PlasticSignConfiguration) {
    this.addItem((x, y, size) => this.selectedFloorplan.addPlasticSign(config, x, y, size), this.selectedFloorplan.plasticSigns);
  }

  addPlasticSignPlaceholder() {
    this.addItem(
      (x, y, size) => this.selectedFloorplan.addPlasticSignPlaceholder(x, y, size),
      this.selectedFloorplan.plasticSignPlaceholders,
    );
  }

  addSignalElement(config: SignalElementConfiguration) {
    this.addItem(
      (x, y, size) => this.selectedFloorplan.addSignalElement(config, x, y, size),
      this.selectedFloorplan.signalElements,
    );
  }

  addSignalElementPlaceholder() {
    this.addItem(
      (x, y, size) => this.selectedFloorplan.addSignalElementPlaceholder(x, y, size),
      this.selectedFloorplan.signalElementPlaceholders,
    );
  }

  addTransmitter(config: TransmitterConfiguration) {
    this.addItem(
      (x, y, size) => this.selectedFloorplan.addTransmitter(config, x, y, size, size * 50),
      this.selectedFloorplan.transmitters,
    );
  }

  addTransmitterPlaceholder() {
    this.addItem(
      (x, y, size) => this.selectedFloorplan.addTransmitterPlaceholder(x, y, size, size * 50),
      this.selectedFloorplan.transmitterPlaceholders,
    );
  }

  addImage(projectImage: ProjectImage) {
    this.addItem((x, y) => this.selectedFloorplan.addImage(projectImage.id, x, y), this.selectedFloorplan.images);
  }

  addMeasurementLine() {
    this.addItem(
      (x, y) => this.selectedFloorplan.addMeasurementLine(x, y, $localize`:@@floorplan.measurementLine.defaultText:1 Meter`),
      this.selectedFloorplan.measurementLines,
    );
  }

  addText() {
    this.addItem((x, y, size) => this.selectedFloorplan.addFloorplanText(x, y, size), this.selectedFloorplan.floorplanTexts);
  }

  addPipeline() {
    this.addItem((x, y) => this.selectedFloorplan.addPipeline(x, y), this.selectedFloorplan.pipelines);
  }

  zoomOut() {
    this.selectedFloorplan.floorplanState.zoomOut(this.calculateStageCenter());
  }

  zoomIn() {
    this.selectedFloorplan.floorplanState.zoomIn(this.calculateStageCenter());
  }

  resetZoom() {
    const { width: stageWidth, height: stageHeight } = this.floorplanWorkspace.getStageDimensions();
    const { width: borderWidth, height: borderHeight } = this.floorplanWorkspace.getBorderDimensions();

    const scaleWidth = stageWidth / borderWidth;
    const scaleHeight = stageHeight / borderHeight;
    const scale = Math.min(scaleWidth, scaleHeight) * 0.9;

    this.selectedFloorplan.floorplanState.updateScaleAndPosition(
      scale,
      (stageWidth - borderWidth * scale) / 2,
      (stageHeight - borderHeight * scale) / 2,
    );
  }

  getBorderDimensions() {
    return this.floorplanWorkspace.getBorderDimensions();
  }

  private initFloorplanEventHandler() {
    this.projectStateService.floorplanEvents$
      .pipe(
        filter((event) => event.floorplan === this.selectedFloorplan),
        takeUntil(this.destroyed$),
      )
      .subscribe((event) => {
        switch (event.type) {
          case FloorplanEventType.EX_ZONE_ADDED:
            this.floorplanWorkspace.addExZone(event.subject as ExZone, true);
            break;
          case FloorplanEventType.DUST_EX_ZONE_ADDED:
            this.floorplanWorkspace.addDustExZone(event.subject as DustExZone, true);
            break;
          case FloorplanEventType.DANGER_AREA_ADDED:
            this.floorplanWorkspace.addDangerArea(event.subject as DangerArea, true);
            break;
          case FloorplanEventType.IMAGE_ADDED:
            this.floorplanWorkspace.addImage(event.subject as FloorplanImage, true).subscribe();
            break;
          case FloorplanEventType.MEASUREMENT_LINE_ADDED:
            this.floorplanWorkspace.addMeasurementLine(event.subject as MeasurementLine, true);
            break;
          case FloorplanEventType.TEXT_ADDED:
            this.floorplanWorkspace.addText(event.subject as FloorplanText, true);
            break;
          case FloorplanEventType.AIR_PATH_ADDED:
            this.floorplanWorkspace.addAirPath(event.subject as AirPath, true);
            break;
          case FloorplanEventType.PIPELINE_ADDED:
            this.floorplanWorkspace.addPipeline(event.subject as Pipeline, true);
            break;
          case FloorplanEventType.TRANSMITTER_ADDED:
          case FloorplanEventType.TRANSMITTER_PLACEHOLDER_ADDED:
            this.floorplanWorkspace.addTransmitter(event.subject as FloorplanTransmitterPlaceholder | FloorplanTransmitter, true);
            this.floorplanWorkspaceWarningSignService
              .updateWarningSigns(event.floorplan, this.floorplanWorkspace)
              .pipe(take(1))
              .subscribe();
            break;
          case FloorplanEventType.ALARM_DEVICE_ADDED:
          case FloorplanEventType.ALARM_DEVICE_PLACEHOLDER_ADDED:
            this.floorplanWorkspace.addAlarmDevice(event.subject as FloorplanPlaceholder | FloorplanAlarmDevice, true);
            break;
          case FloorplanEventType.GAS_WARNING_CENTER_ADDED:
          case FloorplanEventType.GAS_WARNING_CENTER_PLACEHOLDER_ADDED:
            this.floorplanWorkspace.addGasWarningCenter(
              event.subject as FloorplanGasWarningCenterPlaceholder | FloorplanGasWarningCenter,
              true,
            );
            break;
          case FloorplanEventType.SIGNAL_ELEMENT_ADDED:
          case FloorplanEventType.SIGNAL_ELEMENT_PLACEHOLDER_ADDED:
            this.floorplanWorkspace.addSignalElement(event.subject as FloorplanPlaceholder | FloorplanSignalElement, true);
            break;
          case FloorplanEventType.PLASTIC_SIGN_ADDED:
          case FloorplanEventType.PLASTIC_SIGN_PLACEHOLDER_ADDED:
            this.floorplanWorkspace.addPlasticSign(event.subject as FloorplanPlaceholder | FloorplanPlasticSign, true);
            this.floorplanWorkspaceWarningSignService
              .updateWarningSigns(event.floorplan, this.floorplanWorkspace)
              .pipe(take(1))
              .subscribe();
            break;
          case FloorplanEventType.EX_ZONE_DELETED:
          case FloorplanEventType.DUST_EX_ZONE_DELETED:
          case FloorplanEventType.DANGER_AREA_DELETED:
          case FloorplanEventType.IMAGE_DELETED:
          case FloorplanEventType.MEASUREMENT_LINE_DELETED:
          case FloorplanEventType.TEXT_DELETED:
          case FloorplanEventType.AIR_PATH_DELETED:
          case FloorplanEventType.PIPELINE_DELETED:
          case FloorplanEventType.TRANSMITTER_DELETED:
          case FloorplanEventType.TRANSMITTER_PLACEHOLDER_DELETED:
          case FloorplanEventType.ALARM_DEVICE_DELETED:
          case FloorplanEventType.ALARM_DEVICE_PLACEHOLDER_DELETED:
          case FloorplanEventType.GAS_WARNING_CENTER_DELETED:
          case FloorplanEventType.GAS_WARNING_CENTER_PLACEHOLDER_DELETED:
          case FloorplanEventType.SIGNAL_ELEMENT_DELETED:
          case FloorplanEventType.SIGNAL_ELEMENT_PLACEHOLDER_DELETED:
          case FloorplanEventType.PLASTIC_SIGN_DELETED:
          case FloorplanEventType.PLASTIC_SIGN_PLACEHOLDER_DELETED:
            this.floorplanWorkspace.deleteItem(event.subject as FloorplanItem);
            break;
          case FloorplanEventType.ITEM_LOCK_CHANGED:
            this.floorplanWorkspace.setLockState(event.subject as FloorplanItem);
            break;
          case FloorplanEventType.ITEM_POSITION_ID_CHANGED:
            this.floorplanWorkspace.refreshItemText(event.subject as FloorplanItem);
            break;
          case FloorplanEventType.ITEM_DISPLAY_NAME_CHANGED:
            this.floorplanWorkspace.refreshDisplayName(event.subject as FloorplanItem);
            break;
          case FloorplanEventType.FLOORPLAN_STATE_VISIBILITY_CHANGED:
            this.floorplanWorkspace.setVisibilityState(event.subject as FloorplanState);
            break;
          case FloorplanEventType.MEASUREMENT_LINE_UPDATED:
            this.floorplanWorkspace.refreshMeasurementLine(event.subject as MeasurementLine);
            break;
          case FloorplanEventType.FLOORPLAN_STATE_POSITION_UPDATED: {
            const floorplanState = event.subject as FloorplanState;
            this.floorplanWorkspace.setStagePosition({ x: floorplanState.x, y: floorplanState.y });
            break;
          }
          case FloorplanEventType.FLOORPLAN_STATE_SCALE_UPDATED:
            this.floorplanWorkspace.setScale((event.subject as FloorplanState).scale);
            break;
          case FloorplanEventType.ITEM_SIZE_CHANGED:
            if (event.subject instanceof FloorplanProductItem || event.subject instanceof FloorplanText) {
              this.currentIconSize = event.subject.size;
            }
            break;
          case FloorplanEventType.ITEM_UPDATED:
            this.floorplanWorkspaceWarningSignService
              .updateWarningSigns(event.floorplan, this.floorplanWorkspace)
              .pipe(take(1))
              .subscribe();
            break;
        }
      });
  }

  private addItem(addFunction: (x: number, y: number, iconSize: number) => void, existingItems: FloorplanItem[]) {
    const position = this.calculateNextItemPosition(existingItems);
    addFunction(position.x, position.y, this.currentIconSize);
  }

  private calculateNextItemPosition(existingItems: FloorplanItem[]): Point {
    const position = this.floorplanWorkspace.getCenterPoint();
    const randomMax = 100;
    do {
      position.x += Math.floor(Math.random() * randomMax) - randomMax / 2;
      position.y += Math.floor(Math.random() * randomMax) - randomMax / 2;
    } while (existingItems.some((floorplanItem) => floorplanItem.x == position.x && floorplanItem.y == position.x));
    return position;
  }

  private calculateStageCenter(): Point {
    const stageDimensions = this.floorplanWorkspace.getStageDimensions();
    return new Point(stageDimensions.width / 2, stageDimensions.height / 2);
  }
}

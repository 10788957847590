import { Collaborator } from "@domain/project/collaborator";
import { ReadAccess } from "@domain/project/read-access";
import { Type } from "class-transformer";
import { IsArray, IsDate, IsNumber, IsOptional, IsString } from "class-validator";

export class CloudProjectMetadata {
  @IsString()
  public id: string;

  @IsString()
  users: Collaborator[];

  @IsString()
  public displayName: string;

  @IsString()
  public lastModifiedOn: string;

  @IsString()
  projectHash: string;

  @IsString()
  updatedBy: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsDate()
  @Type(() => Date)
  @IsOptional()
  updatedAt?: Date;

  @IsNumber()
  totalFileSize: number = 0;

  @IsArray()
  readAccesses: ReadAccess[] = [];

  constructor(
    id: string,
    displayName: string,
    lastModifiedOn: string,
    users: Collaborator[],
    projectHash: string,
    updatedBy: string,
    name?: string,
    updatedAt?: Date,
  ) {
    this.id = id;
    this.displayName = displayName;
    this.lastModifiedOn = lastModifiedOn;
    this.users = users;
    this.projectHash = projectHash;
    this.updatedBy = updatedBy;
    this.name = name;
    this.updatedAt = updatedAt;
  }
}

import { Injectable } from "@angular/core";
import { ProductService } from "@domain/product/product.service";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { PlaceholderProduct } from "@domain/project/floorplan/placeholder-product";
import { FloorplanWorkspace } from "@project/floorplanner/floorplan-workspace";
import { ProductConfigurationService } from "@project/product-configuration.service";
import { forkJoin, map, Observable, switchMap, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FloorplanWorkspaceWarningSignService {
  constructor(
    private productService: ProductService,
    private productConfigurationService: ProductConfigurationService,
  ) {}

  public updateWarningSigns(floorplan: Floorplan, floorplanWorkspace: FloorplanWorkspace): Observable<void> {
    const allProductIds$ = this.productService.getAllProductIds();
    const discontinuedProductIds$ = this.productService.getDiscontinuedProductIds();

    return forkJoin([allProductIds$, discontinuedProductIds$]).pipe(
      switchMap(([allProductIds, discontinuedProductIds]) => {
        this.updatePlaceholderWarningSigns(floorplan, floorplanWorkspace, allProductIds);
        return this.updateProductWarningSigns(floorplan, floorplanWorkspace, discontinuedProductIds);
      }),
      map(() => undefined),
    );
  }

  private updateProductWarningSigns(
    floorplan: Floorplan,
    floorplanWorkspace: FloorplanWorkspace,
    discontinuedProductIds: string[],
  ): Observable<void> {
    const discontinuedConfigIds = floorplan.project.productConfigurations
      .filter((config) =>
        config.configuredProductIds.some((configuredProductId) => discontinuedProductIds.includes(configuredProductId)),
      )
      .map((config) => config.id);
    return this.productConfigurationService.getIncompleteConfigIds(floorplan.project).pipe(
      tap((incompleteConfigIds) => {
        const configIdsThatNeedWarningSign = discontinuedConfigIds.concat(incompleteConfigIds);
        [
          floorplan.plasticSigns,
          floorplan.signalElements,
          floorplan.transmitters,
          floorplan.gasWarningCenters,
          floorplan.alarmDevices,
        ]
          .flat()
          .forEach((item) => {
            floorplanWorkspace.refreshWarningSignVisibility(item, configIdsThatNeedWarningSign.includes(item.config.id));
          });
      }),
      map(() => undefined),
    );
  }

  private updatePlaceholderWarningSigns(floorplan: Floorplan, floorplanWorkspace: FloorplanWorkspace, allProductIds: string[]) {
    [
      floorplan.transmitterPlaceholders,
      floorplan.gasWarningCenterPlaceholders,
      floorplan.alarmDevicePlaceholders,
      floorplan.plasticSignPlaceholders,
      floorplan.signalElementPlaceholders,
    ]
      .flat()
      .forEach((item) => {
        floorplanWorkspace.refreshWarningSignVisibility(
          item,
          item.products.length > 0 && item.products.every((product: PlaceholderProduct) => allProductIds.includes(product.id)),
        );
      });
  }
}

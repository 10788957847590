import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { OperateOnConfigModalComponent } from "@components/operate-on-config-modal/operate-on-config-modal.component";
import { StandardImageComponent } from "@components/standard-image/standard-image.component";
import { ProductType } from "@domain/product/product";
import { ProductImage } from "@domain/product/product-image";
import { ProductService } from "@domain/product/product.service";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { NavigationService } from "@domain/project/navigation.service";
import { ModalService } from "@odx/angular/components/modal";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { ProjectStateService } from "@project/project-state.service";
import { first, map, Observable, of } from "rxjs";

@Component({
  selector: "app-configured-product",
  templateUrl: "./configured-product.component.html",
  styleUrl: "./configured-product.component.scss",
  standalone: true,
  imports: [UiKitModule, StandardImageComponent],
})
export class ConfiguredProductComponent<T extends ProductConfiguration> implements OnInit {
  @Input({ required: true }) productConfiguration!: T;
  @Input({ required: true }) numberOfFloorplanItems!: number;
  @Input({ required: true }) editPath!: string;
  @Input() imageUrl!: string;
  @Input() fallbackImageUrl!: string;
  @Input() attachmentIds?: string[];
  @Input() subtitlePrefix?: string;
  @Output() add = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  protected localizedName$!: Observable<string>;
  protected subtitle$!: Observable<string>;
  protected isDiscontinued$!: Observable<boolean>;
  protected isIncompleteConfiguration$!: Observable<boolean>;

  private readonly modalService = inject(ModalService);

  constructor(
    private productService: ProductService,
    private navigationService: NavigationService,
    private floorplanService: FloorplanService,
    private projectStateService: ProjectStateService,
  ) {}

  ngOnInit() {
    this.subtitle$ = this.resolveSubtitle();
    this.localizedName$ = this.resolveLocalizedName();
    if (!this.imageUrl) {
      this.imageUrl = ProductImage.getSourceUrl(this.productConfiguration.mainProductId);
    }
    if (!this.fallbackImageUrl) {
      this.fallbackImageUrl = ProductImage.getPlaceholderSourceUrl(this.productConfiguration.productDataName);
    }
    this.isDiscontinued$ = this.projectStateService.isConfigDiscontinued(this.productConfiguration.id);
    this.isIncompleteConfiguration$ = this.projectStateService.isConfigIncomplete(this.productConfiguration.id);
  }

  onEditConfig(event: any) {
    event.stopPropagation();
    this.modalService
      .open(OperateOnConfigModalComponent, { data: "edit" })
      .onClose$.subscribe(() =>
        this.navigationService.toDeviceConfiguration(
          this.editPath,
          this.floorplanService.selectedFloorplan.id,
          this.productConfiguration.id,
        ),
      );
  }

  onDeleteConfig(event: any) {
    event.stopPropagation();
    this.modalService.open(OperateOnConfigModalComponent, { data: "delete" }).onClose$.subscribe(() => {
      this.delete.emit();
    });
  }

  onAdd(event: any) {
    // prevent event to be emitted when clicked on odx-menu icon or odx-menu div
    // event.stopPropagation() can't be applied to the elements directly as they are part of an odx component
    if (
      event.target.localName === "odx-icon" ||
      event.target.localName === "odx-action-group" ||
      event.target.classList[0] === "odx-dropdown__inner"
    ) {
      return;
    }
    this.isDiscontinued$.pipe(first()).subscribe((isDiscontinued) => {
      if (!isDiscontinued) this.add.emit();
    });
  }

  private resolveLocalizedName(): Observable<string> {
    if (this.productConfiguration instanceof AlarmDeviceConfiguration) {
      if (this.productConfiguration.productIds.length > 1) {
        return of(
          $localize`:@@alarmDeviceConfiguration.combination.name:Alarm-Kombi (${this.productConfiguration.productIds.join(", ")}:listOfAlarmDeviceIds:)`,
        );
      }
    }
    return this.productService
      .getProductNameById(this.productConfiguration.mainProductId)
      .pipe(map((name) => name || this.productConfiguration.name));
  }

  private resolveSubtitle(): Observable<string> {
    let subtitle = "";
    if (this.subtitlePrefix) {
      subtitle = this.subtitlePrefix;
    }

    if (!this.attachmentIds?.length) {
      return of(subtitle);
    }

    return this.productService.getProductNamesByIds(this.attachmentIds, ProductType.ATTACHMENT).pipe(
      map((attachments) => {
        attachments.forEach((attachment: string) => (subtitle += `${attachment}, `));
        return subtitle?.slice(0, -2);
      }),
    );
  }
}

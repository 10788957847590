<app-floorplan-item-details
  [title]="(plasticSignName$ | async) || plasticSign.config.name"
  [floorplanItem]="plasticSign"
  [showDiscontinuedWarning]="(isDiscontinued$ | async) || false"
  [showIncompleteWarning]="(isIncompleteConfiguration$ | async) || false"
  imageUrl="./assets/kunststoffschild.svg"
>
  <div class="feature">
    <p class="odx-text--small" i18n="@@floorplan.details.plasticSign.text">Text</p>
    <p class="odx-text">{{ plasticSign.config.text }}</p>
  </div>
  <div class="feature">
    <p class="odx-text--small" i18n="@@floorplan.details.plasticSign.textColor">Textfarbe</p>
    <p class="odx-text">{{ plasticSign.config.textColor }}</p>
  </div>

  <div class="feature">
    <p class="odx-text--small" i18n="@@floorplan.details.plasticSign.backgroundColor">Hintergrundfarbe</p>
    <p class="odx-text">{{ plasticSign.config.backgroundColor }}</p>
  </div>
</app-floorplan-item-details>

import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { UserService } from "@domain/user/user.service";
import { interval, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ServiceWorkerUpdateService {
  private newUpdateVersion: Subject<string> = new Subject<string>();
  private UPDATE_INTERVAL = 60000;

  constructor(
    public updateService: SwUpdate,
    private userService: UserService,
  ) {
    this.init();
  }

  isUpdateAvailable() {
    return this.newUpdateVersion.asObservable();
  }

  async executeUpdate(latestVersion: string) {
    await this.clearUnusedCache(latestVersion);
    window.location.reload();
  }

  private init() {
    if (this.updateService.isEnabled) {
      this.startUpdateCheck();
      this.listenForVersionUpdates();
      this.handleUnrecoverableErrors();
    }
  }

  private startUpdateCheck(): void {
    interval(this.UPDATE_INTERVAL).subscribe(async () => {
      try {
        await this.updateService.checkForUpdate();
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    });
  }

  private listenForVersionUpdates(): void {
    this.updateService.versionUpdates.subscribe(async (versionEvent) => {
      switch (versionEvent.type) {
        case "VERSION_READY":
          this.newUpdateVersion.next(versionEvent.latestVersion.hash);
          break;
        case "VERSION_INSTALLATION_FAILED":
          console.error(`Failed to install app version '${versionEvent.version.hash}': ${versionEvent.error}`);
          await this.clearCacheAndReload();
          break;
        default:
          console.warn("Unhandled version event type:", versionEvent.type);
          break;
      }
    });
  }

  private handleUnrecoverableErrors(): void {
    this.updateService.unrecoverable.subscribe(async (unrecoverableStateEvent) => {
      if (unrecoverableStateEvent) {
        console.error("Unrecoverable state detected:", unrecoverableStateEvent);
        await this.clearCacheAndReload();
      }
    });
  }

  private async clearCacheAndReload() {
    const cacheKeys = await caches.keys();
    await Promise.all(cacheKeys.map((key) => caches.delete(key)));
    document.location.reload();
  }

  private async clearUnusedCache(latestVersion: string) {
    const cacheKeys = await caches.keys();
    const cachesToKeep = ["db:control", "dynamicResources", latestVersion];
    const cacheKeysToDelete = cacheKeys.filter((key) => !cachesToKeep.some((cache) => key.includes(cache)));
    return Promise.all(cacheKeysToDelete.map((key) => caches.delete(key)));
  }
}

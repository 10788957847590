import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { PlaceholderProduct } from "@domain/project/floorplan/placeholder-product";
import { ProjectEventType } from "@domain/project/project-event";
import { IsArrayOfInstancesOf } from "@utils/class-validator/class-validator-constraints";
import { Expose, Transform, Type } from "class-transformer";
import { IsNumber, IsString, ValidateNested } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class FloorplanPlaceholder extends FloorplanItem {
  @Expose({ name: "size" })
  @IsNumber()
  private _size: number;

  @IsString()
  readonly type: string;

  @ValidateNested({ each: true })
  @IsArrayOfInstancesOf(PlaceholderProduct)
  @Type(() => PlaceholderProduct)
  @Expose({ name: "products" })
  @Transform((params) => params.value || [], { toClassOnly: true })
  private _products: PlaceholderProduct[];

  constructor(floorplan: Floorplan, id: string, x: number, y: number, size: number, type: string, notes?: string) {
    super(floorplan, id, x, y, notes);
    this.type = type;
    this._size = size;
    this._products = [];
  }

  override duplicate(x: number, y: number): FloorplanItem {
    let item;
    switch (this.type) {
      case "alarmDevice":
        item = this._floorplan.addAlarmDevicePlaceholder(x, y, this._size);
        break;
      case "plasticSign":
        item = this._floorplan.addPlasticSignPlaceholder(x, y, this._size);
        break;
      case "signalElement":
        item = this._floorplan.addSignalElementPlaceholder(x, y, this._size);
        break;
    }
    return item!;
  }

  override delete(): void {
    switch (this.type) {
      case "alarmDevice":
        this._floorplan.deleteAlarmDevicePlaceholder(this);
        break;
      case "plasticSign":
        this._floorplan.deletePlasticSignPlaceholder(this);
        break;
      case "signalElement":
        this._floorplan.deleteSignalElementPlaceholder(this);
        break;
    }
  }

  override get relatedItems(): FloorplanItem[] {
    let items: FloorplanPlaceholder[] = [];
    switch (this.type) {
      case "alarmDevice":
        items = this._floorplan.alarmDevicePlaceholders;
        break;
      case "plasticSign":
        items = this._floorplan.plasticSignPlaceholders;
        break;
      case "signalElement":
        items = this._floorplan.signalElementPlaceholders;
        break;
    }
    return items;
  }

  protected override refreshFloorplanLockState(): void {
    switch (this.type) {
      case "alarmDevice":
        this._floorplan.floorplanState.refreshAlarmDevicesLock();
        break;
      case "plasticSign":
        this._floorplan.floorplanState.refreshPlasticSignsLock();
        break;
      case "signalElement":
        this._floorplan.floorplanState.refreshSignalElementsLock();
        break;
    }
  }

  static create(floorplan: Floorplan, x: number, y: number, size: number, type: string): FloorplanPlaceholder {
    return new FloorplanPlaceholder(floorplan, uuidv4(), x, y, size, type);
  }

  get size(): number {
    return this._size;
  }

  get products(): PlaceholderProduct[] {
    return this._products;
  }

  set products(products: PlaceholderProduct[]) {
    this._products = products;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_UPDATED, this);
    this._floorplan.project.publishUpdate(ProjectEventType.PLACEHOLDER_PRODUCTS_UPDATED, this);
  }

  updateSize(value: number, x: number, y: number) {
    this._size = value;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_SIZE_CHANGED, this);
    this.updatePosition(x, y);
  }
}

import { Injectable } from "@angular/core";
import { GasService } from "@domain/gas/gas.service";
import { Relationship, RelationshipType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { Project } from "@domain/project/project";
import { forkJoin, map, Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class ProductConfigurationService {
  constructor(
    private productService: ProductService,
    private gasService: GasService,
  ) {}

  public getIncompleteConfigIds(project: Project): Observable<string[]> {
    const incompletePlasticSignIds = project.plasticSigns
      .filter((config) => !config.backgroundColor.length || !config.textColor.length || !config.text.length)
      .map((config) => config.id);

    const transmitterObservables$ = project.transmitters.map((transmitterConfiguration) => {
      if (!transmitterConfiguration.gasId) {
        return of(transmitterConfiguration.id);
      }
      if (transmitterConfiguration.sensorId) {
        return of(null);
      }
      const product$ = this.productService.getProductById(transmitterConfiguration.productId);
      const gas$ = this.gasService.getGasById(transmitterConfiguration.gasId!);
      return forkJoin([product$, gas$]).pipe(
        map(([product, gas]) => {
          if (!gas || !product) {
            return transmitterConfiguration.id;
          }
          const needsSensor = product.relationships
            .filter((relationship: Relationship) => relationship.relationshipType === RelationshipType.SENSOR)
            .some((sensor) => gas.productSensors.some((gasSensor) => gasSensor.productId === sensor.productId));
          return needsSensor ? transmitterConfiguration.id : null;
        }),
      );
    });
    const incompleteTransmitterObservables$ = transmitterObservables$.filter((obs) => obs !== null);
    if (incompleteTransmitterObservables$.length === 0) {
      return of(incompletePlasticSignIds);
    }
    return forkJoin(incompleteTransmitterObservables$).pipe(
      map((results) => {
        const incompleteTransmitterIds = results.filter((id) => id !== null) as string[];
        return incompletePlasticSignIds.concat(incompleteTransmitterIds);
      }),
    );
  }
}

import { Component, inject } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { AreaHeaderComponent } from "@odx/angular/components/area-header";
import { ButtonComponent } from "@odx/angular/components/button";
import { FormDirective, FormFieldComponent, FormFieldControlDirective } from "@odx/angular/components/form-field";
import { ModalRef } from "@odx/angular/components/modal";
import { PdfService } from "@pdf/pdf.service";
import { switchMap, tap } from "rxjs";

@Component({
  selector: "app-project-version-modal",
  standalone: true,
  imports: [
    AreaHeaderComponent,
    FormDirective,
    FormsModule,
    FormFieldComponent,
    FormFieldControlDirective,
    ReactiveFormsModule,
    ButtonComponent,
    UiKitModule,
  ],
  templateUrl: "./project-version-modal.component.html",
  styleUrl: "./project-version-modal.component.scss",
})
export class ProjectVersionModalComponent {
  public readonly modalRef = inject(ModalRef<any, string>);
  projectVersionForm!: FormGroup;
  project!: Project;

  constructor(
    private formBuilder: FormBuilder,
    private pdfService: PdfService,
    private projectService: ProjectService,
  ) {
    this.project = this.modalRef.data.project;
    this.projectVersionForm = this.formBuilder.group({
      version: [this.project.version],
    });
  }

  onConfirm() {
    this.project.version = this.projectVersionForm.controls["version"].value;
    this.exportToPdf().subscribe(() => {
      this.modalRef.close(true);
    });
  }

  exportToPdf() {
    return this.pdfService.exportProjectToPdf(this.project).pipe(
      tap(({ pdf, fileName }) => pdf.save(fileName)),
      switchMap(() => this.projectService.update(this.project)),
    );
  }
}

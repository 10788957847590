import { Injectable } from "@angular/core";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { Project } from "@domain/project/project";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { HalfPageContainer } from "@pdf/helpers/container/half-page/half-page-container";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import { FontNames, PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { Observable, map } from "rxjs";
import { FloorplanInformationPdfService } from "src/domain/pdf/export-services/product-information-pages/floorplan-information-pages/floorplan-information-pdf-service";
import { ProductListPdfService } from "./product-list-pages/product-list-pdf.service";
import { ProductNotesPdfService } from "./product-notes-pages/product-notes-pdf.service";

@Injectable({
  providedIn: "root",
})
export class ProductInformationPagesService {
  constructor(
    private productListPdfService: ProductListPdfService,
    private productNotesPdfService: ProductNotesPdfService,
    private floorplanInformationService: FloorplanInformationPdfService,
  ) {}

  generateProductList(pdf: jsPDF, project: Project, tableOfContents: TableOfContents): Observable<jsPDF> {
    return new Observable((observer) => {
      this.addFonts(pdf);
      pdf.setFont(FontNames.DRAEGER_PANGEA, "normal");
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);

      const container = new HalfPageContainer();

      this.productListPdfService
        .generateFullProductList(pdf, project, container, tableOfContents)
        .pipe(map(() => pdf))
        .subscribe((pdf) => {
          observer.next(pdf);
          observer.complete();
        });
    });
  }

  generateForFloorplan(
    pdf: jsPDF,
    floorplan: Floorplan,
    tableOfContents: TableOfContents,
  ): Observable<{ pdf: jsPDF; tableOfContents: TableOfContents }> {
    return new Observable((observer) => {
      this.addFonts(pdf);
      pdf.setFont(FontNames.DRAEGER_PANGEA, "normal");
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);

      const container = new HalfPageContainer();

      this.productListPdfService
        .generateForFloorplan(pdf, floorplan, container, tableOfContents)
        .pipe(
          map(() => {
            this.productNotesPdfService.generatePages(pdf, floorplan, container, tableOfContents);
            this.floorplanInformationService.generatePages(pdf, floorplan, container, tableOfContents);
            return { pdf, tableOfContents };
          }),
        )
        .subscribe(({ pdf, tableOfContents }) => {
          observer.next({ pdf, tableOfContents });
          observer.complete();
        });
    });
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ProductCardComponent } from "@components/product-card/product-card.component";
import { Product, ProductPropertyBoolean, ProductPropertyDimension, ProductPropertyText } from "@domain/product/product";

import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";

@Component({
  selector: "app-transmitter-card",
  templateUrl: "./transmitter-card.component.html",
  styleUrls: ["./transmitter-card.component.scss"],
  standalone: true,
  imports: [UiKitModule, ProductCardComponent],
})
export class TransmitterCardComponent {
  @Input({ required: true }) set transmitterData(transmitter: Product) {
    this.transmitter = transmitter;
    this.mapProperties();
    this.productDataName = TransmitterConfiguration.getProductDataName(transmitter.getLocalizedName("de-DE"));
  }

  @Input() showCosts!: boolean;
  @Input() isSelectable: boolean = true;
  @Input() isSelected: boolean = false;
  @Output() transmitterSelected = new EventEmitter<Product>();

  protected productDataName?: string;
  protected transmitter!: Product;

  protected cableEntryProperty?: ProductPropertyText;
  protected dimensionsProperty?: ProductPropertyDimension;
  protected displayProperty?: ProductPropertyBoolean;
  protected flameproofProperty?: ProductPropertyBoolean;
  protected wirelessProperty?: ProductPropertyBoolean;

  // Properties
  private readonly DISPLAY = "Display_";
  private readonly FLAMEPROOF = "Flameproof_";
  private readonly WIRELESS = "Wireless_";
  private readonly CABLEENTRY = "Cable_entry_";
  private readonly DIMENSIONS = "Dimensions_";

  private mapProperties() {
    this.cableEntryProperty = this.transmitter!.getTextPropertyByKey(this.CABLEENTRY);
    this.dimensionsProperty = this.transmitter!.getDimensionsPropertyByKey(this.DIMENSIONS);
    this.displayProperty = this.transmitter!.getBooleanPropertyByKey(this.DISPLAY);
    this.flameproofProperty = this.transmitter!.getBooleanPropertyByKey(this.FLAMEPROOF);
    this.wirelessProperty = this.transmitter!.getBooleanPropertyByKey(this.WIRELESS);
  }

  protected select(transmitter: Product) {
    this.transmitterSelected.emit(transmitter);
  }
}

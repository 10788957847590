import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfigurationWizardHeaderComponent } from "@components/configuration-wizard-header/configuration-wizard-header.component";
import { Gas, LocalizeGasNamePipe } from "@domain/gas/gas";
import { GasService } from "@domain/gas/gas.service";
import { Product } from "@domain/product/product";
import { Project } from "@domain/project/project";
import { Observable, debounceTime, first, fromEvent, map, of } from "rxjs";

@Component({
  selector: "app-gas-selector",
  templateUrl: "./gas-selector.component.html",
  styleUrls: ["./gas-selector.component.scss"],
  standalone: true,
  imports: [UiKitModule, LocalizeGasNamePipe, ConfigurationWizardHeaderComponent],
  providers: [LocalizeGasNamePipe],
})
export class GasSelectorComponent implements AfterViewInit {
  @Input() project!: Project;

  @Input() set originalGas(gas: Gas | undefined) {
    if (gas) {
      this.select(gas);
    }
  }

  @Input() set originalTransmitter(transmitter: Product | undefined) {
    this.transmitter = transmitter;
    if (transmitter) {
      this.listMatchingGasesOnly(transmitter).subscribe();
    }
  }

  @Output() gasSelect$ = new EventEmitter<Gas>();

  protected filteredGases$!: Observable<Gas[]>;
  protected selectedGas?: Gas;
  protected transmitter?: Product;

  @ViewChild("searchField")
  private readonly searchField!: ElementRef;
  private gases$!: Observable<Gas[]>;

  constructor(
    private gasService: GasService,
    private localizeGasNamePipe: LocalizeGasNamePipe,
  ) {
    this.gases$ = this.gasService.loadAll().pipe(
      first(),
      map((results) =>
        results.sort((a, b) => {
          if (this.localizeGasNamePipe.transform(a) < this.localizeGasNamePipe.transform(b)) {
            return -1;
          }
          if (this.localizeGasNamePipe.transform(a) > this.localizeGasNamePipe.transform(b)) {
            return 1;
          }
          return 0;
        }),
      ),
    );
    this.filteredGases$ = this.gases$;
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchField.nativeElement, "input")
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.filteredGases$ = this.gases$.pipe(
          map((gases) => gases.filter((item) => this.searchFilter(item, this.searchField.nativeElement.value))),
        );
      });
  }

  protected select(selectedGas: Gas) {
    this.selectedGas = selectedGas;
    this.gasSelect$.emit(this.selectedGas);
  }

  private searchFilter(item: Gas, query: string): boolean {
    return (
      this.localizeGasNamePipe.transform(item).toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0 ||
      item.id.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0 ||
      item.definition.formula.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0
    );
  }

  private listMatchingGasesOnly(transmitter: Product) {
    return this.gases$.pipe(
      map((gases) => {
        const matchingGases = gases.filter((gas) =>
          gas.products.map((gasProduct) => gasProduct.productId).includes(transmitter.id),
        );
        this.gases$ = of(matchingGases);
        this.filteredGases$ = of(matchingGases);
      }),
    );
  }
}

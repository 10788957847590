import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import {
  LocalizeCableInletTypePipe,
  LocalizeDoorstopTypePipe,
} from "@domain/project/floorplan/floorplan-gas-warning-center-cabinet";
import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";

interface NotesData {
  position: string;
  notes: string[];
  name?: string;
}

export class TransmitterAdditionalNotes {
  public readonly BMK = $localize`:@@pdfExport.productNotes.transmitter.bmk:BMK`;
  public readonly SECTOR = $localize`:@@pdfExport.productNotes.transmitter.sector:Sektor`;
  public readonly INSTALLATION_HEIGHT = $localize`:@@pdfExport.productNotes.transmitter.installationHeight:Montagehöhe`;
  public readonly EX_PROTECTION = $localize`:@@pdfExport.productNotes.transmitter.exProtection:Ex-Schutz`;
}

export class GasWarningCenterCabinetNotes {
  public readonly CABINET = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet:Schaltschrank`;
  public readonly MAX_HEIGHT = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.maxHeight:Maximale Höhe`;
  public readonly MAX_WIDTH = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.maxWidth:Maximale Breite`;
  public readonly MAX_DEPTH = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.maxDepth:Maximale Tiefe`;
  public readonly MATERIAL = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.material:Material`;
  public readonly DOORSTOP = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.doorstop:Türausrichtung`;
  public readonly CABLE_INLET = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.cableInlet:Kabeleingang`;
  public readonly OUTDOOR_INSTALLATION = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.outdoorInstallation:UV-gechützt/Außeninstallation`;
  public readonly MAX_ENVIRONMENTAL_TEMPERATURE = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.maxEnvironmentalTemperature:Höchstumgebungstemperatur`;
  public readonly MIN_ENVIRONMENTAL_TEMPERATURE = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.minEnvironmentalTemperature:Mindestumgebungstemperatur`;
  public readonly IP_PROTECTION_CATEGORY = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.ipProtectionCategory:IP-Sicherheitskategorie`;
  public readonly CABINET_NOTES = $localize`:@@pdfExport.productNotes.gasWarningCenter.cabinet.notes:Schaltschranknotizen`;
}

export class ProductNotes {
  private _gasWarningCenterNotes: NotesData[] = [];
  private _transmitterNotes: NotesData[] = [];
  private _alarmDeviceNotes: NotesData[] = [];
  private _signalElementNotes: NotesData[] = [];
  private _plasticSignNotes: NotesData[] = [];
  private _gasWarningCenterPlaceholderNotes: NotesData[] = [];
  private _transmitterPlaceholderNotes: NotesData[] = [];
  private _alarmDevicePlaceholderNotes: NotesData[] = [];
  private _signalElementPlaceholderNotes: NotesData[] = [];
  private _plasticSignPlaceholderNotes: NotesData[] = [];
  private _areNotesAvailable = false;

  readonly transmitterAdditionalNotes = new TransmitterAdditionalNotes();
  readonly gasWarningCenterCabinetNotes = new GasWarningCenterCabinetNotes();

  public constructor(floorplan: Floorplan) {
    this.initData(floorplan);
    this._areNotesAvailable = this.checkIfNotesAreAvailable();
  }

  private checkIfNotesAreAvailable(): boolean {
    return [
      this._gasWarningCenterNotes,
      this._gasWarningCenterPlaceholderNotes,
      this._transmitterNotes,
      this._transmitterPlaceholderNotes,
      this._alarmDeviceNotes,
      this._alarmDevicePlaceholderNotes,
      this._signalElementNotes,
      this._signalElementPlaceholderNotes,
      this._plasticSignNotes,
      this._plasticSignPlaceholderNotes,
    ].some((notes) => notes.length);
  }

  private initData(floorplan: Floorplan) {
    this.initGasWarningNotes(floorplan);
    this.initTransmitterNotes(floorplan);
    this.initAlarmDeviceNotes(floorplan);
    this.initSignalElementNotes(floorplan);
    this.initPlasticSignNotes(floorplan);
    this._gasWarningCenterPlaceholderNotes = this.initGasWarningCenterPlaceholderNotes(floorplan.gasWarningCenterPlaceholders);
    this._transmitterPlaceholderNotes = this.initTransmitterPlaceholderNotes(floorplan.transmitterPlaceholders);
    this._alarmDevicePlaceholderNotes = this.initPlaceholderNotes(floorplan.alarmDevicePlaceholders);
    this._signalElementPlaceholderNotes = this.initPlaceholderNotes(floorplan.signalElementPlaceholders);
    this._plasticSignPlaceholderNotes = this.initPlaceholderNotes(floorplan.plasticSignPlaceholders);
  }

  private initGasWarningNotes(floorplan: Floorplan) {
    this._gasWarningCenterNotes = floorplan.gasWarningCenters
      .filter((item) => this.gasWarningCenterNotesShouldBeInitialized(item))
      .map((item) => {
        const notes = this.buildGasWarningCenterNotes(item);
        return {
          position: item.positionId!,
          notes: notes,
          name: item.displayName ? `${item.displayName} \n${item.config.name}` : `${item.config.name}`,
        };
      });
  }

  private initTransmitterNotes(floorplan: Floorplan) {
    this._transmitterNotes = floorplan.transmitters
      .filter((item) => this.transmitterNotesShouldBeInitialized(item))
      .map((item) => {
        const notes = this.buildTransmitterNotes(item);
        return {
          position: item.positionId!,
          notes: notes,
          name: item.displayName ? `${item.displayName} \n${item.config.name}` : `${item.config.name}`,
        };
      });
  }

  private buildTransmitterNotes(item: FloorplanTransmitter | FloorplanTransmitterPlaceholder) {
    const result = [];

    if (item.notes.length) result.push(item.notes);
    if (item.bmk) result.push(this.transmitterAdditionalNotes.BMK + ": " + item.bmk);
    if (item.sector) result.push(this.transmitterAdditionalNotes.SECTOR + ": " + item.sector);
    if (item.installationHeight)
      result.push(this.transmitterAdditionalNotes.INSTALLATION_HEIGHT + ": " + item.installationHeight);
    if (item.exProtection) result.push(this.transmitterAdditionalNotes.EX_PROTECTION);

    return result;
  }

  private transmitterNotesShouldBeInitialized(transmitter: FloorplanTransmitter | FloorplanTransmitterPlaceholder) {
    return (
      transmitter.notes.length > 0 ||
      transmitter.bmk ||
      transmitter.sector ||
      transmitter.installationHeight ||
      transmitter.exProtection
    );
  }

  private buildGasWarningCenterNotes(item: FloorplanGasWarningCenter | FloorplanGasWarningCenterPlaceholder) {
    const result = [];

    const localizeDoorstopType = new LocalizeDoorstopTypePipe();
    const localizeCableInletType = new LocalizeCableInletTypePipe();

    if (item.notes.length) result.push(item.notes);
    if (item.cabinet) {
      const cabinet = item.cabinet;
      result.push(this.gasWarningCenterCabinetNotes.CABINET + ":");
      if (cabinet.maxHeight) result.push(this.gasWarningCenterCabinetNotes.MAX_HEIGHT + ": " + cabinet.maxHeight);
      if (cabinet.maxWidth) result.push(this.gasWarningCenterCabinetNotes.MAX_WIDTH + ": " + cabinet.maxWidth);
      if (cabinet.maxDepth) result.push(this.gasWarningCenterCabinetNotes.MAX_DEPTH + ": " + cabinet.maxDepth);
      if (cabinet.material) result.push(this.gasWarningCenterCabinetNotes.MATERIAL + ": " + cabinet.material);
      if (cabinet.doorstop)
        result.push(this.gasWarningCenterCabinetNotes.DOORSTOP + ": " + localizeDoorstopType.transform(cabinet.doorstop));
      if (cabinet.cableInlet)
        result.push(this.gasWarningCenterCabinetNotes.CABLE_INLET + ": " + localizeCableInletType.transform(cabinet.cableInlet));
      if (cabinet.outdoorInstallation) result.push(this.gasWarningCenterCabinetNotes.OUTDOOR_INSTALLATION);
      if (cabinet.minEnvironmentalTemperature)
        result.push(this.gasWarningCenterCabinetNotes.MIN_ENVIRONMENTAL_TEMPERATURE + ": " + cabinet.minEnvironmentalTemperature);
      if (cabinet.maxEnvironmentalTemperature)
        result.push(this.gasWarningCenterCabinetNotes.MAX_ENVIRONMENTAL_TEMPERATURE + ": " + cabinet.maxEnvironmentalTemperature);
      if (cabinet.ipProtectionCategory)
        result.push(this.gasWarningCenterCabinetNotes.IP_PROTECTION_CATEGORY + ": " + cabinet.ipProtectionCategory);
      if (cabinet.notes) result.push(this.gasWarningCenterCabinetNotes.CABINET_NOTES + ": " + cabinet.notes);
    }
    return result;
  }

  private gasWarningCenterNotesShouldBeInitialized(
    gasWarningCenter: FloorplanGasWarningCenter | FloorplanGasWarningCenterPlaceholder,
  ) {
    return (
      gasWarningCenter.notes.length > 0 ||
      (gasWarningCenter.cabinet &&
        (gasWarningCenter.cabinet.maxHeight ||
          gasWarningCenter.cabinet.maxWidth ||
          gasWarningCenter.cabinet.maxDepth ||
          gasWarningCenter.cabinet.material ||
          gasWarningCenter.cabinet.doorstop ||
          gasWarningCenter.cabinet.cableInlet ||
          gasWarningCenter.cabinet.outdoorInstallation ||
          gasWarningCenter.cabinet.minEnvironmentalTemperature ||
          gasWarningCenter.cabinet.maxEnvironmentalTemperature ||
          gasWarningCenter.cabinet.ipProtectionCategory ||
          gasWarningCenter.cabinet.notes))
    );
  }

  private initAlarmDeviceNotes(floorplan: Floorplan) {
    this._alarmDeviceNotes = floorplan.alarmDevices
      .filter((item) => item.notes.length)
      .map((item) => {
        return {
          position: item.positionId!,
          notes: [item.notes],
          name: item.displayName ? `${item.displayName} \n${item.config.name}` : `${item.config.name}`,
        };
      });
  }

  private initSignalElementNotes(floorplan: Floorplan) {
    this._signalElementNotes = floorplan.signalElements
      .filter((item) => item.notes.length)
      .map((item) => {
        return {
          position: item.positionId!,
          notes: [item.notes],
          name: item.displayName ? `${item.displayName} \n${item.config.name}` : `${item.config.name}`,
        };
      });
  }

  private initPlasticSignNotes(floorplan: Floorplan) {
    this._plasticSignNotes = floorplan.plasticSigns.map((item) => {
      return {
        position: item.positionId!,
        notes: [item.notes],
        name: item.displayName ? `${item.displayName} \n${item.config.name}` : `${item.config.name}`,
        text: item.config.text,
        textColor: item.config.textColor,
        backgroundColor: item.config.backgroundColor,
      };
    });
  }

  private initPlaceholderNotes(
    placeholders: (FloorplanPlaceholder | FloorplanTransmitterPlaceholder | FloorplanGasWarningCenterPlaceholder)[],
  ) {
    return placeholders
      .filter((item) => item.notes.length)
      .map((item) => {
        return {
          position: item.positionId!,
          name: item.displayName,
          notes: [item.notes],
        };
      });
  }

  get areNotesAvailable() {
    return this._areNotesAvailable;
  }

  private initTransmitterPlaceholderNotes(placeholders: FloorplanTransmitterPlaceholder[]) {
    return placeholders
      .filter((item) => this.transmitterNotesShouldBeInitialized(item))
      .map((item) => {
        const notes = this.buildTransmitterNotes(item);
        return {
          position: item.positionId!,
          notes: notes,
        };
      });
  }

  private initGasWarningCenterPlaceholderNotes(placeholders: FloorplanGasWarningCenterPlaceholder[]) {
    return placeholders
      .filter((item) => this.gasWarningCenterNotesShouldBeInitialized(item))
      .map((item) => {
        const notes = this.buildGasWarningCenterNotes(item);
        return {
          position: item.positionId!,
          notes: notes,
        };
      });
  }

  get gasWarningCenterNotes() {
    return this._gasWarningCenterNotes;
  }

  get transmitterNotes() {
    return this._transmitterNotes;
  }

  get alarmDeviceNotes() {
    return this._alarmDeviceNotes;
  }

  get signalElementNotes() {
    return this._signalElementNotes;
  }

  get plasticSignNotes() {
    return this._plasticSignNotes;
  }

  get gasWarningCenterPlaceholderNotes() {
    return this._gasWarningCenterPlaceholderNotes;
  }

  get transmitterPlaceholderNotes() {
    return this._transmitterPlaceholderNotes;
  }

  get alarmDevicePlaceholderNotes() {
    return this._alarmDevicePlaceholderNotes;
  }

  get signalElementPlaceholderNotes() {
    return this._signalElementPlaceholderNotes;
  }

  get plasticSignPlaceholderNotes() {
    return this._plasticSignPlaceholderNotes;
  }
}

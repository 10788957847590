import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { Expose, Transform, Type } from "class-transformer";

export class Product {
  @Expose({ name: "localizedName" })
  private _localizedName!: Record<string, string>;

  @Expose({ name: "type" })
  private readonly _type: ProductType;

  @Expose({ name: "fullType" })
  private readonly _fullType: FullProductType;

  @Type(() => Relationship)
  @Expose({ name: "relationships" })
  private readonly _relationships: Relationship[] = [];

  @Expose({ name: "properties" })
  private readonly _properties: ProductProperty[] = [];

  @Transform(({ obj }) => obj.countries ?? (obj.country ? [obj.country] : []), { toClassOnly: true })
  @Expose({ name: "countries" })
  public _countries: string[] = [];

  constructor(
    public id: string,
    public name: string,
    type: ProductType,
    fullType: FullProductType,
    public isModular: boolean,
    public isAvailable: boolean,
    relationships: Relationship[],
    properties: ProductProperty[],
    public price: number,
    countries: string[],
  ) {
    this._type = type;
    this._fullType = fullType;
    this._relationships = relationships;
    this._properties = properties;
    this._countries = countries;
  }

  get type(): ProductType {
    return this._type;
  }

  get fullType(): FullProductType {
    return this._fullType;
  }

  get relationships(): readonly Relationship[] {
    return this._relationships;
  }

  get properties(): readonly ProductProperty[] {
    return this._properties;
  }

  get countries(): readonly string[] {
    return this._countries;
  }

  isAvailableInCountry(country: string): boolean {
    return this._countries.includes(country);
  }

  getLocalizedName(locale: string): string {
    return this._localizedName ? this._localizedName[locale] : "";
  }

  addCountry(country: string) {
    if (!this._countries.includes(country)) {
      this._countries.push(country);
    }
  }

  addLocalizedName(language: string, name: string) {
    if (!this._localizedName) {
      this._localizedName = {};
    }
    if (!this._localizedName[language]) {
      this._localizedName[language] = name;
    }
  }

  getTextPropertyByKey(key: string): ProductPropertyText {
    return this.getPropertyByKey(key) as ProductPropertyText;
  }

  getBooleanPropertyByKey(key: string): ProductPropertyBoolean {
    return this.getPropertyByKey(key) as ProductPropertyBoolean;
  }

  getDimensionsPropertyByKey(key: string): ProductPropertyDimension {
    return this.getPropertyByKey(key) as ProductPropertyDimension;
  }

  private getPropertyByKey(key: string): ProductProperty | undefined {
    if (this.properties.length == 0) return;
    return this.properties.find((property) => property.key.startsWith(key));
  }

  static sort(products: Product[]): Product[] {
    return products.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
}

export class Relationship {
  constructor(
    public productId: string,
    public relationshipType: RelationshipType,
    public matchType: MatchType,
  ) {}
}

export enum RelationshipType {
  EXTRAGUIDE = "EXTRAGUIDE",
  EXTRA = "EXTRA",
  EXTRAREQ = "EXTRAREQ",
  SENSOR = "SENSOR",
  MODULE = "MODULE",
}

export enum MatchType {
  DIRECT = "DIRECT",
  SENSORTYPE = "SENSORTYPE",
}

export enum ProductType {
  TRANSMITTER = "TRANSMITTER",
  ATTACHMENT = "ATTACHMENT",
  SENSOR = "SENSOR",
  GASWARNINGCENTER = "GASWARNINGCENTER",
  ALARMDEVICE = "ALARMDEVICE",
  MISCDEVICE = "MISCDEVICE",
  PLASTICSIGN = "PLASTICSIGN",
  SIGNALELEMENT = "SIGNALELEMENT",
  SERVICE = "SERVICE",
}

export enum FullProductType {
  ALARMDEVICE_A24 = "a-24", //Alarmmittel, non-ex, 24 V
  ALARMDEVICE_A230 = "a-230", //Alarmmittel, non-ex, 230 V
  ALARMDEVICE_A24_MOD = "a-24-mod", //Alarmmittel, non-ex, 24 V, modular
  ALARMDEVICE_A230_MOD = "a-230-mod", //Alarmmittel, non-ex, 230 V, modular
  ALARMDEVICE_A_EX_24 = "a-ex-24", //Alarmmittel, ex geschützt, 24 V
  ALARMDEVICE_A_EX_230 = "a-ex-230", //Alarmmittel, ex geschützt, 230 V
  TRANSMITTER_EC_M = "t-ec-m", // Transmitter (Sensor nicht verbaut?)
  TRANSMITTER_IR_M = "t-ir-m", // Transmitter PIR (Sensor nicht verbaut?)
  TRANSMITTER_MEC_M = "t-mec-m", // Transmitter (Sensor nicht verbaut?)
  TRANSMITTER_CAT_M = "t-cat-m", // Transmitter (Sensor nicht verbaut?)
  TRANSMITTER_CAT_K = "t-cat-k", // Transmitter (Sensor verbaut?)
  TRANSMITTER_IR_K_BUS = "t-ir-k-bus", // Transmitter PIR (Sensor verbaut?)
  TRANSMITTER_IR_K = "t-ir-k", // Transmitter PIR (Sensor verbaut?)
  TRANSMITTER_EC_K_BUS = "t-ec-k-bus", // Transmitter (Sensor verbaut?)
  ATTACHMENT_OPTIONAL = "z-o", // Zubehör Optional
  ATTACHMENT_REQ = "z-n", // Zubehör Notwendig
  ATTACHMENT_INSTRUCTIONS = "z-ga", // Zubehör Gebrauchsanweisung
  ATTACHMENT_MODULE = "z-m", // Zubehör Modul für Gaswarnzentrale?
  GASWARNINGCENTER_M = "gwz-m", // Gaswarnzentrale (Modular?)
  GASWARNINGCENTER_K = "gwz-k", // Gaswarnzentrale (Nicht modular?)
  GASWARNINGCENTER_K_VG = "gwz-k-vg", // Gaswarnzentrale (Nicht modular?)
  ALARMDEVICE = "a", // Alarmmittel
  PLASTICSIGN = "sz-ks", // Kunststoffschilder
  SIGNALELEMENT = "sz-lt", // Leuchttransparente
  MISCDEVICE = "sz", // Leuchttransparente,
  SERVICE = "dl", // Dienstleistung
  SENSOR_EC = "s-ec", // Sensor
  SENSOR_MEC = "s-mec", // Sensor
  SENSOR_IR = "s-ir", // Sensor
}

export enum ProductPropertyType {
  BOOLEAN = "BOOLEAN",
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  NUMBER_RANGE = "NUMBER_RANGE",
  WEIGHT_IN_KG = "WEIGHT_IN_KG",
  TEMPERATUR_RANGE_IN_CELSIUS = "TEMPERATUR_RANGE_IN_CELSIUS",
  DIMENSIONS_IN_METER = "DIMENSIONS_IN_METER",
}

export class ProductPropertyTemplate {
  constructor(
    public key: string,
    public type: ProductPropertyType,
    public name: string,
    public info?: string,
    public description?: string,
  ) {}

  createInstance(value: string): ProductProperty {
    value = value.trim();

    switch (this.type) {
      case ProductPropertyType.BOOLEAN:
        return ProductPropertyBoolean.create(this, value);
      case ProductPropertyType.TEXT:
        return ProductPropertyText.create(this, value);
      case ProductPropertyType.NUMBER:
      case ProductPropertyType.WEIGHT_IN_KG:
      case ProductPropertyType.TEMPERATUR_RANGE_IN_CELSIUS:
        return ProductPropertyNumber.create(this, value);
      case ProductPropertyType.NUMBER_RANGE:
        return ProductPropertyNumberRange.create(this, value);
      case ProductPropertyType.DIMENSIONS_IN_METER:
        return ProductPropertyDimension.create(this, value);
      default:
        throw new Error("Unknown property dataType " + this.type);
    }
  }
}

export interface ProductProperty {
  readonly key: string;
  readonly type: ProductPropertyType;
  readonly name: string;
  readonly info?: string;
  readonly decription?: string;
}

export class ProductPropertyText implements ProductProperty {
  public readonly type = ProductPropertyType.TEXT;

  constructor(
    public readonly key: string,
    public readonly name: string,
    public readonly value: string,
    public readonly info?: string,
    public readonly description?: string,
  ) {}

  static create(template: ProductPropertyTemplate, value: string): ProductPropertyText {
    return new ProductPropertyText(template.key, template.name, value, template.info, template.description);
  }
}

export class ProductPropertyNumber implements ProductProperty {
  constructor(
    public readonly key: string,
    public readonly type: ProductPropertyType,
    public readonly name: string,
    public readonly value: number,
    public readonly info?: string,
    public readonly description?: string,
  ) {}

  static create(template: ProductPropertyTemplate, value: string): ProductPropertyNumber {
    return new ProductPropertyNumber(template.key, template.type, template.name, +value, template.info, template.description);
  }
}

export class ProductPropertyBoolean implements ProductProperty {
  public readonly type = ProductPropertyType.BOOLEAN;

  constructor(
    public readonly key: string,
    public readonly name: string,
    public readonly value: boolean,
    public readonly info?: string,
    public readonly description?: string,
  ) {}

  static create(template: ProductPropertyTemplate, value: string): ProductPropertyBoolean {
    return new ProductPropertyBoolean(
      template.key,
      template.name,
      value.indexOf("true") > -1,
      template.info,
      template.description,
    );
  }
}

export class ProductPropertyNumberRange implements ProductProperty {
  constructor(
    public readonly key: string,
    public readonly type: ProductPropertyType,
    public readonly name: string,
    public readonly startValue: number,
    public readonly endValue: number,
    public readonly info?: string,
    public readonly description?: string,
  ) {}

  static create(template: ProductPropertyTemplate, value: string): ProductPropertyNumberRange {
    const range = value.split(" ");
    return new ProductPropertyNumberRange(
      template.key,
      template.type,
      template.name,
      +range[0],
      +range[1],
      template.info,
      template.description,
    );
  }
}

export class ProductPropertyDimension implements ProductProperty {
  constructor(
    public readonly key: string,
    public readonly type: ProductPropertyType,
    public readonly name: string,
    public readonly height: number,
    public readonly width: number,
    public readonly depth: number,
    public readonly info?: string,
    public readonly description?: string,
  ) {}

  static create(template: ProductPropertyTemplate, value: string): ProductPropertyDimension {
    const range = value.split(" ");
    return new ProductPropertyDimension(
      template.key,
      template.type,
      template.name,
      +range[0],
      +range[1],
      +range[2],
      template.info,
      template.description,
    );
  }
}

@Pipe({
  standalone: true,
  name: "localizeProductName",
})
export class LocalizeProductNamePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  transform(product: Product): string {
    return product.getLocalizedName(this.localeId);
  }
}

@Pipe({
  standalone: true,
  name: "displayName",
})
export class DisplayNamePipe implements PipeTransform {
  transform(value: string, limit: number = 100): string {
    if (!value) return "";
    return value.length > limit ? value.substring(0, limit) + "..." : value;
  }
}

@Pipe({
  standalone: true,
  name: "localizeProductType",
})
export class LocalizeProductTypePipe implements PipeTransform {
  private readonly productTypeToLocalizedStringMap = {
    [ProductType.TRANSMITTER]: $localize`:@@productType.transmitters:Transmitter`,
    [ProductType.ATTACHMENT]: $localize`:@@productType.attachments:Zubehör`,
    [ProductType.SENSOR]: $localize`:@@productType.sensors:Sensoren`,
    [ProductType.GASWARNINGCENTER]: $localize`:@@productType.gasWarningCenters:Gaswarnzentralen`,
    [ProductType.ALARMDEVICE]: $localize`:@@productType.alarmDevices:Alarmmittel`,
    [ProductType.MISCDEVICE]: $localize`:@@productType.miscDevices:Verschiedene Geräte`,
    [ProductType.PLASTICSIGN]: $localize`:@@productType.plastigSigns:Kunststoffschilder`,
    [ProductType.SIGNALELEMENT]: $localize`:@@productType.signalElements:Leuchttransparente`,
    [ProductType.SERVICE]: $localize`:@@productType.services:Dienstleistungen`,
  };

  transform(value: ProductType): string {
    return this.productTypeToLocalizedStringMap[value];
  }
}

<div odxLayout="flex gap-small" class="flex-column h-100">
  <odx-area-header class="info-header">
    <h5 class="odx-title-5" i18n="@@collaboration.header">Kollaboration</h5>
    <odx-area-header-content>
      <odx-action-group [odxTooltip]="offlineToolTipMessage" *ngIf="(isReadOnlyUser$ | async) === false">
        <button odxButton odxMenuItem [disabled]="!navigator.onLine" (click)="$event.stopPropagation(); openShareLinkModal()">
          <odx-icon name="link" alignleft></odx-icon>
          <span i18n="@@projectCard.button.shareProjectLink">Lesefreigabe erteilen</span>
        </button>
        <button odxButton variant="primary" [disabled]="!navigator.onLine" (click)="openModal()">
          <odx-icon name="user-add" alignLeft></odx-icon>
          <span i18n="@@collaboration.button.addUser">Person einladen</span>
        </button>
      </odx-action-group>
    </odx-area-header-content>
  </odx-area-header>
  <div class="collaboration-container">
    <p i18n="@@modal.invitePerson.header2">
      Laden Sie Kollegen ein, um das Projekt und alle seine Inhalte sehen und bearbeiten zu dürfen.
    </p>
    <p i18n="@@modal.invitePerson.header3"><strong>Nur Personen mit einem Drägerzugang können freigeschaltet werden.</strong></p>

    <div *ngIf="projectOwner" class="padding-top" i18n="@@collaboration.projectOwner">
      Projekteigentümer: {{ projectOwner.email }}
    </div>

    <div *ngIf="collaborators.length; else noCollaborators" class="table-container">
      <table odxTable [headerData]="headerData">
        <thead>
          <th></th>
        </thead>
        @for (c of collaborators; track $index) {
          <tr odx-table-row>
            <td odx-table-cell>{{ c.email }}</td>
            <td odx-table-cell>{{ c.invitedBy }}</td>
            <td odx-table-cell i18n="@@collaboration.table.invitationCompletedAt">
              {{
                c.invitationCompletedAt
                  ? (c.invitationCompletedAt | localizeDate) + ", " + (c.invitationCompletedAt | localizeTime)
                  : ""
              }}
            </td>
            <td odx-table-cell class="center-icon" [odxTooltip]="offlineToolTipMessage">
              <app-delete-button (delete)="deleteCollaborator(c.userId, c.email)" [disabled]="!navigator.onLine">
              </app-delete-button>
            </td>
          </tr>
        }
      </table>
    </div>
  </div>

  <ng-template #noCollaborators>
    <div class="padding-top" i18n="@@collaboration.noCollaboratorsYet">
      Diesem Projekt wurden noch keine Kollaboratoren hinzugefügt.
    </div>
  </ng-template>
</div>

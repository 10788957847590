<odx-area-header class="header">
  <button (click)="onNavigateBack()" odxNavigationBack *ngIf="!hideBackArrow"></button>

  <span data-testid="projectDetails.header.projectName">{{ project.name }}</span>

  <odx-chip
    class="read-access-chip"
    removable="false"
    variant="secondary"
    i18n="@@chip.readOnly"
    *ngIf="(isReadOnlyUser$ | async) === true"
  >
    <odx-icon name="view" alignleft></odx-icon> Lesemodus
  </odx-chip>

  <odx-area-header-subtitle>
    <span i18n="@@projectDetailHeader.lastModified"
      >Letzte Änderung: {{ (project.lastModified | localizeDate) + ", " + (project.lastModified | localizeTime) }}</span
    >
    <span class="cloud-save" *ngIf="(isReadOnlyUser$ | async) === false">
      <odx-icon name="cloud" inline></odx-icon>
      <span i18n="@@projectDetailHeader.lastSavedToCloud"
        >Letzte Speicherung in der Cloud:
        {{
          project.lastCloudSync ? (project.lastCloudSync | localizeDate) + ", " + (project.lastCloudSync | localizeTime) : "--"
        }}</span
      >
    </span>
  </odx-area-header-subtitle>
  <odx-area-header-content>
    <odx-action-group *ngIf="(isReadOnlyUser$ | async) === false">
      <button odxButton [odxMenu]="menu" odxMenuPosition="bottom-end" data-testid="btn.export">
        <span i18n="@@projectDetailHeader.button.export">Exportieren</span>
        <odx-icon name="chevron-down" alignRight></odx-icon>
      </button>
    </odx-action-group>
  </odx-area-header-content>
</odx-area-header>

<ng-template #menu>
  <odx-menu>
    <button odxButton odxMenuItem (click)="exportProjectToFile(project)" data-testid="btn.exportProjectCopy">
      <odx-icon name="copy-file" alignLeft></odx-icon>
      <span i18n="@@projectDetailHeader.button.exportProjectCopy">Kopie auf meinen Computer exportieren</span>
    </button>
    <button odxButton odxMenuItem (click)="exportProductList(project)" data-testid="btn.exportProductList">
      <odx-icon name="export" alignLeft></odx-icon>
      <span i18n="@@projectDetailHeader.button.exportProductList">Produktliste exportieren</span>
    </button>
    <button
      odxButton
      odxMenuItem
      (click)="exportProjectToPdf(project)"
      *ngIf="!floorplanExportEnabled"
      data-testid="btn.exportProjectAsPdf"
    >
      <odx-icon name="file-pdf" alignLeft></odx-icon>
      <span i18n="@@projectDetailHeader.button.exportProjectPdf">Projekt als PDF exportieren</span>
    </button>
    <button
      odxButton
      odxMenuItem
      (click)="exportFloorplanToPdf()"
      *ngIf="floorplanExportEnabled"
      data-testid="btn.exportFloorplanAsPdf"
    >
      <odx-icon name="file-pdf" alignLeft></odx-icon>
      <span i18n="@@projectDetailHeader.button.exportFloorplanPdf">Grundrissplan als PDF exportieren</span>
    </button>

    <button odxButton odxMenuItem [disabled]="!navigator.onLine" (click)="$event.stopPropagation(); openShareLinkModal()">
      <odx-icon name="link" alignleft></odx-icon>
      <span i18n="@@projectCard.button.shareProjectLink">Lesefreigabe erteilen</span>
    </button>
  </odx-menu>
</ng-template>

import { Injectable, OnDestroy } from "@angular/core";
import { ReadOnlyTokenStorage } from "@app/read-only-token.storage";
import { ProjectApiService } from "@domain/project/api/project-api.service";
import { environment } from "@environments/environment";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { catchError, filter, from, map, Observable, ReplaySubject, Subject, takeUntil, throwError } from "rxjs";

export interface UserInfo {
  id?: string;
}

export enum AuthErrorCode {
  WRONG_AUTH_LEVEL = "WRONG_AUTH_LEVEL",
  INVALID_LOGIN = "INVALID_LOGIN",
  OFFLINE = "OFFLINE",
}

export class AuthError extends Error {
  constructor(
    message: string,
    public code: AuthErrorCode,
  ) {
    super(message);
  }
}

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  private static readonly QUERY_PARAM_READ_ACCESS_TOKEN = "readToken";
  private storedRedirectUri?: string;
  private readonly _destroying$ = new Subject<void>();
  private readonly _userInfo$ = new ReplaySubject<UserInfo>(1);
  private readonly AUTH_CONFIG: AuthConfig = {
    issuer: environment.oktaUri,
    redirectUri: window.location.origin,
    clientId: environment.oktaClientId,
    responseType: "code",
    scope: "openid profile email offline_access Legacy",
    showDebugInformation: true,
  };

  constructor(
    private oauthService: OAuthService,
    private projectApiService: ProjectApiService,
    private readOnlyTokenStorage: ReadOnlyTokenStorage,
  ) {
    let queryParams = new URLSearchParams();
    if (window.location.hash.includes("?")) {
      queryParams = new URLSearchParams(
        window.location.hash.substring(window.location.hash.indexOf("?"), window.location.hash.length),
      );
    }

    if (queryParams.has(AuthService.QUERY_PARAM_READ_ACCESS_TOKEN)) {
      oauthService.revokeTokenAndLogout();
      this.readOnlyTokenStorage.set(queryParams.get(AuthService.QUERY_PARAM_READ_ACCESS_TOKEN)!);
      queryParams.delete(AuthService.QUERY_PARAM_READ_ACCESS_TOKEN);
      window.location.href = window.location.hash.substring(0, window.location.hash.indexOf("?") + 1) + queryParams.toString();
      this._userInfo$.next({});
    } else if (this.readOnlyTokenStorage.get()) {
      this._userInfo$.next({});
    } else {
      this.readOnlyTokenStorage.clear();
      this.initDraegerLogin();
    }
  }

  get userInfo$(): Observable<UserInfo> {
    return this._userInfo$.asObservable();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private initDraegerLogin() {
    this.storeRedirectUri();
    this.oauthService.configure(this.AUTH_CONFIG);
    this.oauthService.setupAutomaticSilentRefresh();

    from(this.oauthService.loadDiscoveryDocumentAndTryLogin())
      .pipe(
        catchError((error) => {
          console.error("Login error", error);
          return throwError(() => new AuthError("Login error: " + JSON.stringify(error), AuthErrorCode.INVALID_LOGIN));
        }),
        map(() => {
          if (this.oauthService.hasValidAccessToken()) {
            const claims = this.oauthService.getIdentityClaims();
            this.validateAuthLevel(claims);
            return { id: claims["sub"] };
          }

          if (!navigator.onLine) {
            throw new AuthError("Authorization not possible in offline mode", AuthErrorCode.OFFLINE);
          }

          this.oauthService.initLoginFlow(this.storedRedirectUri);
          return undefined;
        }),
        filter((userInfo) => !!userInfo),
        takeUntil(this._destroying$),
      )
      .subscribe({
        next: (userInfo) => {
          this._userInfo$.next(userInfo!);
          if (this.oauthService.state) {
            window.location.href = decodeURIComponent(this.oauthService.state);
          }
        },
        error: (error) => {
          this._userInfo$.error(error);
          this._userInfo$.complete();
        },
      });
  }

  private storeRedirectUri() {
    if (window.location.hash.split("#/").length > 1) {
      this.storedRedirectUri = window.location.href;
    }
  }

  private validateAuthLevel(claims: Record<string, any>) {
    const authLevel = claims["authLevel"] || "admin";

    if (authLevel) {
      if (authLevel === "admin") {
        return;
      }

      try {
        const authLevelJson = JSON.parse(<string>authLevel);
        if (authLevelJson.user !== "blocked") {
          return;
        }
      } catch (error) {
        console.error("authLevel could not be parsed as json", error);
      }
    }

    throw new AuthError("User is not permitted to use the app. Wrong auth level.", AuthErrorCode.WRONG_AUTH_LEVEL);
  }
}

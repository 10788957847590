<app-floorplan-item-details
  [title]="(gasWarningCenterName$ | async) || gasWarningCenter.config.name"
  [floorplanItem]="gasWarningCenter"
  [imageUrl]="imageUrl"
  [fallbackImageUrl]="fallbackImageUrl"
  [showDiscontinuedWarning]="(isDiscontinued$ | async) || false"
  (editClicked)="onEdit()"
>
  <app-floorplan-item-attachments class="feature" [attachmentIds]="gasWarningCenter.config.attachmentIds">
  </app-floorplan-item-attachments>

  <app-floorplan-gas-warning-center-detail-form
    [gasWarningCenter]="gasWarningCenter"
    [editEnabled]="editEnabled"
  ></app-floorplan-gas-warning-center-detail-form>
</app-floorplan-item-details>

import { Exclude, Type } from "class-transformer";
import { IsDate, IsEmail, IsString } from "class-validator";

export class Collaborator {
  @IsString()
  userId: string;

  @Exclude({ toPlainOnly: true })
  @IsEmail()
  email: string;

  @IsString()
  assignedRole: string;

  @IsDate()
  @Type(() => Date)
  invitationCompletedAt?: Date;

  @IsString()
  invitedBy?: string;

  constructor(userId: string, email: string, assignedRole: string, invitationCompletedAt?: Date, invitedBy?: string) {
    this.userId = userId;
    this.email = email;
    this.assignedRole = assignedRole;
    this.invitationCompletedAt = invitationCompletedAt;
    this.invitedBy = invitedBy;
  }
}

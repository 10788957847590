import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanGasWarningCenterCabinet } from "@domain/project/floorplan/floorplan-gas-warning-center-cabinet";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { Project } from "@domain/project/project";
import { Exclude, Expose, Type } from "class-transformer";
import { IsOptional, IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class FloorplanGasWarningCenter extends FloorplanProductItem<GasWarningCenterConfiguration> {
  @Exclude()
  private readonly _gasWarningCenter: GasWarningCenterConfiguration;

  @IsString()
  @Expose({ name: "gasWarningCenterId" })
  private readonly _gasWarningCenterId: string;

  @Type(() => FloorplanGasWarningCenterCabinet)
  @Expose({ name: "cabinet" })
  @IsOptional()
  private _cabinet?: FloorplanGasWarningCenterCabinet;

  constructor(
    floorplan: Floorplan,
    gasWarningCenter: GasWarningCenterConfiguration,
    id: string,
    x: number,
    y: number,
    size: number,
    cabinet?: FloorplanGasWarningCenterCabinet,
    notes?: string,
  ) {
    super(floorplan, gasWarningCenter, id, x, y, size, notes);
    this._gasWarningCenterId = gasWarningCenter?.id;
    this._gasWarningCenter = gasWarningCenter;
    this._cabinet = cabinet;
  }

  get cabinet(): FloorplanGasWarningCenterCabinet | undefined {
    return this._cabinet;
  }

  set cabinet(value: FloorplanGasWarningCenterCabinet) {
    this._cabinet = value;
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addGasWarningCenter(this.config, x, y, this.size, this.cabinet);
  }

  override delete() {
    this._floorplan.deleteGasWarningCenter(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.gasWarningCenters;
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshGasWarningCentersLock();
  }

  static create(
    floorplan: Floorplan,
    gasWarningCenter: GasWarningCenterConfiguration,
    x: number,
    y: number,
    size: number,
    cabinet?: FloorplanGasWarningCenterCabinet,
  ): FloorplanGasWarningCenter {
    return new FloorplanGasWarningCenter(floorplan, gasWarningCenter, uuidv4(), x, y, size, cabinet);
  }

  override getConfigFromProject(project: Project): GasWarningCenterConfiguration | undefined {
    return project.getGasWarningCenterById(this._gasWarningCenterId);
  }
}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { STORE_NAME_PRODUCTS } from "@app/indexed-db-config";
import { LastProductUpdateService } from "@domain/product/last-product-update.service";
import { FullProductType, Product, ProductType } from "@domain/product/product";
import { ProductMapperService } from "@domain/product/product-mapper.service";

import { ProductCountryService } from "@domain/product/product-country.service";
import { environment } from "@environments/environment";
import { ObservableInstanceMapper } from "@utils/observable-instance-mapper";
import { instanceToPlain } from "class-transformer";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { first, forkJoin, map, Observable, of, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public static readonly PRODUCTS_URL = environment.apiUrl + "/product/products";
  public static readonly PRODUCT_PROPERTIES_URL = environment.apiUrl + "/product-property/product-properties";
  private _filterCountry?: string;

  constructor(
    private http: HttpClient,
    private dbService: NgxIndexedDBService,
    private productMapperService: ProductMapperService,
    private productCountryService: ProductCountryService,
    private productUpdateService: LastProductUpdateService,
    @Inject(LOCALE_ID) private localeId: string,
  ) {}

  init(): Observable<Product[]> {
    this.productCountryService.newCountry$.subscribe(() => this.refreshAll().pipe(first()).subscribe());
    return this.loadAll().pipe(
      switchMap((products) => {
        if (!products.length || !this.checkDataRevisions(products)) {
          return this.refreshAll();
        }
        return of(products);
      }),
    );
  }

  getAvailableProducts(...productTypes: ProductType[]): Observable<Product[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Product>(STORE_NAME_PRODUCTS), Product).pipe(
      map((products: Product[]) =>
        products.filter(
          (product) => productTypes.includes(product.type) && product.isAvailable && this.isAvailableInCountry(product),
        ),
      ),
    );
  }

  getProductById(id: string): Observable<Product | undefined> {
    return ObservableInstanceMapper.valueToInstance(this.dbService.getByKey<Product>(STORE_NAME_PRODUCTS, id), Product).pipe(
      map((product) => (!product ? undefined : this.isAvailableInCountry(product) ? product : undefined)),
    );
  }

  getProductNameById(id: string): Observable<string | undefined> {
    return this.getProductById(id).pipe(map((product) => product?.getLocalizedName(this.localeId)));
  }

  getProductsByIds(ids: string[], productType: ProductType): Observable<Product[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.bulkGet<Product>(STORE_NAME_PRODUCTS, ids), Product).pipe(
      map((products: Product[]) =>
        products.filter((product) => product?.type === productType && this.isAvailableInCountry(product)),
      ),
    );
  }

  getProductNamesByIds(ids: string[], productType: ProductType): Observable<string[]> {
    return this.getProductsByIds(ids, productType).pipe(
      map((products) => products.map((product) => product.getLocalizedName(this.localeId))),
    );
  }

  getDiscontinuedProductIds(): Observable<string[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Product>(STORE_NAME_PRODUCTS), Product).pipe(
      map((products: Product[]) =>
        products.filter((product) => !product.isAvailable && this.isAvailableInCountry(product)).map((product) => product.id),
      ),
    );
  }

  getAllProductIds(): Observable<string[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Product>(STORE_NAME_PRODUCTS), Product).pipe(
      map((products) => products.filter((product) => this.isAvailableInCountry(product))),
      map((products: Product[]) => products.map((product) => product.id)),
    );
  }

  refreshAll(): Observable<Product[]> {
    const productRequests$ = new Array<Observable<{ language: string; country: string; products: Product[] }>>();
    this.productCountryService.countries.forEach((country) =>
      new Set<string>(["en-US", "de-DE", this.localeId]).forEach((language) => {
        productRequests$.push(
          this.loadProductsFromApi(language, country).pipe(
            map((products) => {
              return { products, language, country };
            }),
          ),
        );
      }),
    );
    return forkJoin(productRequests$).pipe(
      map((products) => this.mergeProductLists(products)),
      switchMap((products) => this.clearAll().pipe(switchMap(() => this.storeProductsInDB(products)))),
    );
  }

  getProductCountries(): Observable<string[]> {
    return this.loadProductsFromDB().pipe(
      map((products) => [...new Set<string>(products.flatMap((product) => product.countries))]),
    );
  }

  clearAll(): Observable<boolean> {
    return this.dbService.clear(STORE_NAME_PRODUCTS);
  }

  filter(products$: Observable<Product[]>, query: string): Observable<any> {
    return products$.pipe(map((products) => products.filter((product) => this.searchFilter(product, query))));
  }

  searchFilter(product: Product, query: string): boolean {
    return (
      product.getLocalizedName(this.localeId).toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0 ||
      (product.id.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0 && this.isAvailableInCountry(product))
    );
  }

  updateFilterCountry(country: string) {
    this._filterCountry = country;
  }

  private loadAll(): Observable<Product[]> {
    return this.dbService
      .count(STORE_NAME_PRODUCTS)
      .pipe(switchMap((count: number) => (count > 0 ? this.loadProductsFromDB() : of([]))));
  }

  private loadProductsFromApi(languageString: string, country: string): Observable<Product[]> {
    const productParams = new HttpParams().set("lang", languageString).set("country", country);
    const productPropertiesParams = new HttpParams().set("lang", languageString);
    return forkJoin([
      this.http.get(ProductService.PRODUCTS_URL, { params: productParams }),
      this.http.get(ProductService.PRODUCT_PROPERTIES_URL, { params: productPropertiesParams }),
    ]).pipe(
      map((results: any[]) => {
        const productsResponse = results[0];
        const productPropsResponse = results[1];

        return this.productMapperService.mapProductAndProperties(productsResponse, productPropsResponse);
      }),
    );
  }

  private loadProductsFromDB(): Observable<Product[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Product>(STORE_NAME_PRODUCTS), Product);
  }

  private mergeProductLists(
    productsByLanguageAndCountry: {
      language: string;
      country: string;
      products: Product[];
    }[],
  ): Product[] {
    const resultingProducts = new Map<string, Product>();

    productsByLanguageAndCountry.forEach((productByLanguageAndCountry) => {
      productByLanguageAndCountry.products.forEach((product) => {
        let resultingProduct = resultingProducts.get(product.id);
        if (!resultingProduct) {
          resultingProduct = product;
          resultingProducts.set(product.id, resultingProduct);
        }
        resultingProduct.addLocalizedName(productByLanguageAndCountry.language, product.name);
        resultingProduct.addCountry(productByLanguageAndCountry.country);
      });
    });
    return Array.from(resultingProducts.values());
  }

  private storeProductsInDB(data: Product[]): Observable<Product[]> {
    return this.dbService
      .bulkPut(
        STORE_NAME_PRODUCTS,
        data.map((product) => instanceToPlain(product)),
      )
      .pipe(
        switchMap(() => this.productUpdateService.saveLastUpdate()),
        map(() => data), // return added product instances not the plain ones from dbService.bulkAdd
      );
  }

  private isAvailableInCountry(product: Product) {
    if (!this._filterCountry) {
      return true;
    }
    return product.isAvailableInCountry(this._filterCountry);
  }

  private checkDataRevisions(products: Product[]) {
    return (
      this.checkForAlarmDeviceFullTypes(products) &&
      this.checkForLocalizedNames(products, this.localeId) &&
      this.checkForCountries(products)
    );
  }

  private checkForAlarmDeviceFullTypes(products: Product[]) {
    return products.some((product) => product.fullType == FullProductType.ALARMDEVICE_A24);
  }

  private checkForLocalizedNames(products: Product[], locale: string) {
    return products.every((product) => !!product.getLocalizedName(locale));
  }

  private checkForCountries(products: Product[]) {
    return products.every((product) => product.countries.length > 0);
  }
}

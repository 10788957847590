import { DangerArea } from "@domain/project/floorplan/zones/danger-area";
import { ExZoneKonva } from "@project/floorplanner/konva/ex-zone-konva";
import { FloorplanItemTextKonva } from "@project/floorplanner/konva/floorplan-item-text-konva";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { Group } from "konva/lib/Group";
import { Line } from "konva/lib/shapes/Line";
import { Rect } from "konva/lib/shapes/Rect";
import { Text } from "konva/lib/shapes/Text";

export class DangerAreaKonva {
  static init(dangerArea: DangerArea): Group {
    const group = new Group({
      x: dangerArea.x,
      y: dangerArea.y,
      height: dangerArea.height,
      width: dangerArea.width,
      rotation: dangerArea.rotation,
      draggable: !dangerArea.locked,
    });
    group.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, dangerArea);

    const rect = new Rect({
      x: 0,
      y: 0,
      height: dangerArea.height,
      width: dangerArea.width,
      opacity: 0.7,
      lineJoin: "bevel",
      stroke: "#700000",
      strokeWidth: 4,
      dash: [10, 2],
    });
    group.add(rect);

    const textPadding = 4;
    const positionId = FloorplanItemTextKonva.init(dangerArea);
    positionId.padding(textPadding);
    ExZoneKonva.setTextPosition(positionId, group);
    group.add(positionId);

    let lines = this.addDangerAreaHatch(group, rect.width(), rect.height());
    group.on("transform", () => {
      lines.forEach((shape) => shape.destroy());

      group.width(Math.max(5, group.width() * group.scaleX()));
      group.height(Math.max(5, group.height() * group.scaleY()));

      rect.width(group.width());
      rect.height(group.height());

      ExZoneKonva.setTextPosition(positionId, group);

      group.scaleX(1);
      group.scaleY(1);

      lines = this.addDangerAreaHatch(group, group.width(), group.height());
    });
    group.on("transformend", () =>
      dangerArea.updateForm(
        group.width() * group.scaleX(),
        group.height() * group.scaleY(),
        group.rotation(),
        group.x(),
        group.y(),
      ),
    );

    return group;
  }

  static refreshItemText(dangerArea: DangerArea, group: Group) {
    const text = group.getChildren()[1] as Text;
    text.text(`${dangerArea.positionId!} ${dangerArea.displayName}`);
    ExZoneKonva.setTextPosition(text, group);
  }

  private static addDangerAreaHatch(group: Group, width: number, height: number): Line[] {
    const lines: Line[] = [];
    for (let i = 10, j = 10; i <= width + height; i += 40, j += 40) {
      const line = new Line({
        opacity: 0.7,
        stroke: "#700000",
        strokeWidth: 4,
      });
      if (i >= height && j >= width) {
        line.points([i - height, height, width, j - width]);
      } else if (i >= height) {
        line.points([i - height, height, j, 0]);
      } else if (j >= width) {
        line.points([0, i, width, j - width]);
      } else {
        line.points([0, i, j, 0]);
      }
      lines.push(line);
      group.add(line);
    }
    return lines;
  }
}

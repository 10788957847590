<odx-modal-header>
  <odx-area-header i18n="@@invitation.modal.header">Einladung zur Kollaboration abschließen</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <div *ngIf="invitationResult$ | async as response; else loading">
    <p *ngIf="response.ok" i18n="@@invitation.message.success">
      Sie haben nun Zugriff auf das Projekt <b>{{ projectName }}</b
      >. Im Anschluss wird das Projekt aus der Cloud importiert.
    </p>
    <p *ngIf="response.status !== 200" i18n="@@invitation.message.failure">
      Es ist ein Fehler aufgetreten. Die Einladung wurde nicht abgeschlossen
    </p>
  </div>
  <ng-template #loading>
    <p i18n="@@invitation.message.processValidation">Der Status ihrer Einladung wird überprüft.</p>
    <p [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="500"></p>
  </ng-template>
</odx-modal-content>
<odx-modal-footer>
  <button odxButton variant="secondary" (click)="onConfirm()" i18n="@@modal.generalConfirmation.button.confirm">OK</button>
</odx-modal-footer>

import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { FileErrorModalComponent } from "@components/file-error-modal/file-error-modal.component";
import { ProjectService } from "@domain/project/project.service";
import { UserService } from "@domain/user/user.service";
import { ActionGroupComponent } from "@odx/angular/components/action-group";
import { AreaHeaderComponent, AreaHeaderContentDirective } from "@odx/angular/components/area-header";
import { ButtonComponent } from "@odx/angular/components/button";
import { IconComponent } from "@odx/angular/components/icon";
import { ModalService } from "@odx/angular/components/modal";
import { first, map, Observable, Subscription } from "rxjs";

@Component({
  selector: "app-files-overlay",
  standalone: true,
  imports: [IconComponent, ActionGroupComponent, AreaHeaderContentDirective, AreaHeaderComponent, ButtonComponent, UiKitModule],
  templateUrl: "./files-overlay.component.html",
  styleUrl: "./files-overlay.component.scss",
})
export class FilesOverlayComponent {
  @Output() file$ = new EventEmitter<Blob[]>();
  @Input() MAX_FILE_SIZE!: number;
  @Input() TOTAL_FILE_SIZE!: number;
  @Input() projectId?: string;
  createDocumentSubscriber: Subscription[] = [];
  protected readonly navigator = navigator;
  protected isReadOnlyUser$: Observable<boolean>;

  private readonly modalService = inject(ModalService);

  private readonly BLACK_LISTED_FILE_TYPES: string[] = [
    ".exe",
    ".bat",
    ".com",
    ".cmd",
    ".inf",
    ".ipa",
    ".osx",
    ".pif",
    ".run",
    ".wsh",
  ];

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
  }

  get offlineToolTipMessage() {
    return !navigator.onLine
      ? $localize`:@@collaboration.removeCollaborator:Diese Funktion ist nur im Online-Modus verfügbar.`
      : null;
  }

  onFileSelected(event: any) {
    const blacklistedFiles: File[] = this.checkForBlackListedTypes(event.target.files);
    const largeFiles: File[] = this.filterForLargeFiles(event.target.files);

    this.checkTotalFileSize(event.target.files).subscribe((totalFileSizeTooLarge) => {
      if (totalFileSizeTooLarge) {
        const errorMessage = $localize`:@@modal.fileUpload.totalFileSize.errorMessage:Die maximale Größe von Dateien pro Projekt von ${this.TOTAL_FILE_SIZE / Math.pow(2, 30)} GB wurde überschritten.`;
        this.openUploadErrorModal(errorMessage, event);
      } else if (blacklistedFiles.length > 0) {
        const unsupportedFiles = this.filterUnsupportedFileTypes(blacklistedFiles);
        const completeErrorMessage = $localize`:@@modal.fileUpload.blacklistedFiles.errorMessage:Das Dateiformat von ${unsupportedFiles} wird nicht unterstützt.`;
        this.openUploadErrorModal(completeErrorMessage, event);
      } else if (largeFiles.length > 0) {
        const tooLargeFiles = this.filterTooLargeFiles(largeFiles);
        const completeErrorMessage = $localize`:@@modal.fileUpload.largeFiles.errorMessage:Die maximale Dateigröße von ${this.MAX_FILE_SIZE / 1024 / 1024} MB wurde überschritten von ${tooLargeFiles}.`;
        this.openUploadErrorModal(completeErrorMessage, event);
      } else {
        if (this.projectId) {
          this.file$.emit(event.target.files);
          event.target.value = null;
        }
      }
    });
  }

  private openUploadErrorModal(errorMessage: string, event: any) {
    this.modalService
      .open(FileErrorModalComponent, {
        data: {
          text: errorMessage,
        },
      })
      .onClose$.subscribe(() => {
        event.target.value = null;
      });
  }

  private checkTotalFileSize(files: File[]) {
    return this.projectService.getMetadataById(this.projectId!).pipe(
      first(),
      map((metadata) => {
        let totalFileSize = metadata.totalFileSize;
        if (!totalFileSize) totalFileSize = 0;

        for (const file of files) {
          totalFileSize += file.size;
        }
        return totalFileSize > this.TOTAL_FILE_SIZE;
      }),
    );
  }

  private checkForBlackListedTypes(files: FileList) {
    const fileArray = Array.from(files);
    const unsupportedFiles: File[] = [];
    fileArray.forEach((file) => {
      if (this.isFileTypeOnBlackList(file)) {
        unsupportedFiles.push(file);
      }
    });
    return unsupportedFiles;
  }

  private isFileTypeOnBlackList(file: File) {
    return this.BLACK_LISTED_FILE_TYPES.some((suffix) => file.name.endsWith(suffix));
  }

  private filterForLargeFiles(files: File[]) {
    return [...files].filter((file: File) => file.size > this.MAX_FILE_SIZE); //Byte to megabyte
  }

  private filterTooLargeFiles(tooLargeFiles: File[]) {
    let fileNames: string = "";
    tooLargeFiles.forEach((file, index) => {
      if (tooLargeFiles.length - index === 1) {
        fileNames += `${file.name} `;
      } else {
        fileNames += `${file.name}, `;
      }
    });
    return fileNames.trim();
  }

  private filterUnsupportedFileTypes(unsupportedFiles: File[]) {
    let fileNames: string = "";
    unsupportedFiles.forEach((file, index) => {
      if (unsupportedFiles.length - index === 1) {
        fileNames += `${file.name} `;
      } else {
        fileNames += `${file.name}, `;
      }
    });
    return fileNames.trim();
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { StandardImageComponent } from "@components/standard-image/standard-image.component";
import { LocalizeProductNamePipe, Product, ProductType } from "@domain/product/product";
import { ProductImage } from "@domain/product/product-image";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";

@Component({
  selector: "app-product-search-item",
  templateUrl: "./product-search-item.component.html",
  styleUrl: "./product-search-item.component.scss",
  standalone: true,
  imports: [UiKitModule, StandardImageComponent, LocalizeProductNamePipe],
})
export class ProductSearchItemComponent implements OnInit {
  @Input({ required: true }) product!: Product;

  protected imageUrl!: string;
  protected fallbackImageUrl!: string;

  constructor() {}

  ngOnInit() {
    this.imageUrl = ProductImage.getSourceUrl(this.product.id);
    this.getFallbackImageUrl();
  }

  private getFallbackImageUrl() {
    switch (this.product.type) {
      case ProductType.GASWARNINGCENTER: {
        const gasWarningCenterName: string = GasWarningCenterConfiguration.getProductDataName(
          this.product.getLocalizedName("de-DE"),
        );
        this.fallbackImageUrl = ProductImage.getPlaceholderSourceUrl(gasWarningCenterName);
        break;
      }
      case ProductType.TRANSMITTER: {
        const transmitterName: string = TransmitterConfiguration.getProductDataName(this.product.getLocalizedName("de-DE"));
        this.fallbackImageUrl = ProductImage.getPlaceholderSourceUrl(transmitterName);
        break;
      }
      default: {
        this.fallbackImageUrl = "";
      }
    }
  }
}

import { Component } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ProjectService } from "@domain/project/project.service";
import { switchMap } from "rxjs";

@Component({
  selector: "app-project-read-access",
  standalone: true,
  imports: [RouterModule],
  template: "",
  styles: "",
})
export class ProjectReadAccessComponent {
  constructor(
    private projectService: ProjectService,
    private router: Router,
    activatedRoute: ActivatedRoute,
  ) {
    const projectId = activatedRoute.snapshot.queryParams["projectId"];
    projectService
      .clearAll()
      .pipe(
        switchMap(() => this.projectService.loadReadAccessProjectFromCloud(projectId)),
        switchMap((project) => this.projectService.createProject(project)),
      )
      .subscribe({
        next: (project) => {
          this.projectService.setReadAccessProjectId(project.id);
          this.router.navigate([`/projects/${project.id}`]);
        },
        error: (err) => console.error("Error in observable chain:", err),
      });
  }
}

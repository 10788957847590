import { Injectable } from "@angular/core";
import { PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
@Injectable({
  providedIn: "root",
})
export abstract class BaseContainer {
  public xPosition: number;
  public yPosition: number;
  public width: number;
  public height: number;

  constructor(xPosition: number, yPosition: number, width: number, height: number) {
    this.xPosition = xPosition;
    this.yPosition = yPosition;
    this.width = width;
    this.height = height;
  }

  public addFrame(pdf: jsPDF) {
    pdf.setLineWidth(PdfProperties.BORDER_WIDTH);
    pdf.setDrawColor(PdfProperties.BORDER_COLOR);
    pdf.setFillColor(PdfProperties.BACKGROUND_COLOR);
    pdf.rect(this.xPosition, this.yPosition, this.width, this.height);
  }
}

import { Injectable } from "@angular/core";
import { GasService } from "@domain/gas/gas.service";
import { Product, ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { Project } from "@domain/project/project";
import { OriginalAlarmDeviceConfiguration } from "@project/alarm-device-configuration/original-alarm-device-configuration";
import { OriginalGasWarningCenterConfiguration } from "@project/gas-warning-center-configuration/original-gas-warning-center-configuration";
import { OriginalPlasticSignConfiguration } from "@project/plastic-sign-configuration/original-plastic-sign-configuration";
import { OriginalSignalElementConfiguration } from "@project/signal-element-configuration/original-signal-element-configuration";
import { OriginalTransmitterConfiguration } from "@project/transmitter-configuration/original-transmitter-configuration";
import { Observable, first, forkJoin, map, of } from "rxjs";

@Injectable()
export class OriginalProductConfigurationFactory {
  constructor(
    private productService: ProductService,
    private gasService: GasService,
  ) {}

  createOriginalGasWarningCenterConfiguration(project: Project, configId: string) {
    const productConfiguration = project.getGasWarningCenterById(configId);
    if (!productConfiguration) {
      return of();
    }
    const originalGasWarningCenter$ = this.productService.getProductById(productConfiguration.productId);
    const originalAttachments$ = productConfiguration.attachmentIds.length
      ? this.productService.getProductsByIds(productConfiguration.attachmentIds, ProductType.ATTACHMENT)
      : of([]);
    return forkJoin([originalGasWarningCenter$, originalAttachments$]).pipe(
      first(),
      map((products) => new OriginalGasWarningCenterConfiguration(productConfiguration, ...products)),
    );
  }

  createOriginalTransmitterConfiguration(project: Project, configId: string) {
    const productConfiguration = project.getTransmitterById(configId);
    if (!productConfiguration) {
      return of();
    }
    const originalGas$ = productConfiguration.gasId ? this.gasService.getGasById(productConfiguration.gasId) : of(undefined);
    const originalTransmitter$ = this.productService.getProductById(productConfiguration.productId);
    const originalAttachments$ = productConfiguration.attachmentIds.length
      ? this.productService.getProductsByIds(productConfiguration.attachmentIds, ProductType.ATTACHMENT)
      : of([]);
    const originalSensor$ = productConfiguration.sensorId
      ? this.productService.getProductById(productConfiguration.sensorId)
      : of(undefined);

    return forkJoin([originalGas$, originalTransmitter$, originalAttachments$, originalSensor$]).pipe(
      first(),
      map((result) => new OriginalTransmitterConfiguration(productConfiguration, ...result)),
    );
  }

  createOriginalAlarmDeviceConfiguration(project: Project, configId: string) {
    const productConfiguration = project.getAlarmDeviceById(configId);
    if (!productConfiguration) {
      return of();
    }
    const originalAlarmDevices$: Observable<Product[]> = this.productService.getProductsByIds(
      productConfiguration.productIds,
      ProductType.ALARMDEVICE,
    );
    const originalAttachments$: Observable<Product[]> = productConfiguration.attachmentIds.length
      ? this.productService.getProductsByIds(productConfiguration.attachmentIds, ProductType.ATTACHMENT)
      : of([]);
    return forkJoin([originalAlarmDevices$, originalAttachments$]).pipe(
      first(),
      map((products) => new OriginalAlarmDeviceConfiguration(productConfiguration, ...products)),
    );
  }

  createOriginalPlasticSignConfiguration(project: Project, configId: string) {
    const productConfiguration = project.getPlasticSignById(configId);
    if (!productConfiguration) {
      return of();
    }
    return this.productService.getProductById(productConfiguration.productId).pipe(
      first(),
      map((product) => new OriginalPlasticSignConfiguration(productConfiguration, product)),
    );
  }

  createOriginalSignalElementConfiguration(project: Project, configId: string) {
    const productConfiguration = project.getSignalElementById(configId);
    if (!productConfiguration) {
      return of();
    }
    return this.productService.getProductById(productConfiguration.productId).pipe(
      first(),
      map((product) => new OriginalSignalElementConfiguration(productConfiguration, product)),
    );
  }
}

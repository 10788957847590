import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { ButtonComponent } from "@odx/angular/components/button";
import { ChipComponent, ChipListComponent, ChipListRowComponent } from "@odx/angular/components/chip";
import { FormFieldComponent, FormFieldControlDirective, FormFieldErrorDirective } from "@odx/angular/components/form-field";
import { IconComponent } from "@odx/angular/components/icon";
import { TooltipDirective } from "@odx/angular/components/tooltip";

@Component({
  selector: "app-form-email-chip-list",
  standalone: true,
  imports: [
    ButtonComponent,
    ChipComponent,
    TooltipDirective,
    FormFieldComponent,
    ChipListRowComponent,
    ChipListComponent,
    FormFieldControlDirective,
    ReactiveFormsModule,
    FormFieldErrorDirective,
    IconComponent,
  ],
  templateUrl: "./form-email-chip-list.component.html",
  styleUrl: "./form-email-chip-list.component.scss",
})
export class FormEmailChipListComponent implements OnInit {
  @Output() updatedMailList = new EventEmitter<string[]>();

  protected addedMails: string[] = [];

  form!: FormGroup;

  protected readonly tooltipAddEmail: string = $localize`:@@modal.invitePerson.addEmail:E-Mail-Adresse hinzufügen`;

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit() {
    this.form = this.rootFormGroup.control;
  }

  protected addChipToList() {
    const inputValue = this.form.get("value");
    this.form.markAllAsTouched();

    if (!inputValue) return;
    if (this.form.valid && inputValue.value.length) {
      this.addedMails.push(inputValue.value);
      inputValue.reset();
    }
    this.updatedMailList.emit(this.addedMails);
  }

  protected removeChipFromList(chip: string) {
    const index: number = this.addedMails.indexOf(chip, 0);
    if (index > -1) this.addedMails.splice(index, 1);
    this.updatedMailList.emit(this.addedMails);
  }
}

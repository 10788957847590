import { Injectable } from "@angular/core";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanWorkspace } from "@project/floorplanner/floorplan-workspace";
import { concatMap, from, map, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FloorplanWorkspaceItemService {
  public addItems(floorplan: Floorplan, floorplanWorkspace: FloorplanWorkspace): Observable<undefined> {
    floorplan.exZones.forEach((item) => floorplanWorkspace.addExZone(item, false));
    floorplan.dustExZones.forEach((item) => floorplanWorkspace.addDustExZone(item, false));
    floorplan.pipelines.forEach((item) => floorplanWorkspace.addPipeline(item, false));
    floorplan.airPaths.forEach((item) => floorplanWorkspace.addAirPath(item, false));
    floorplan.measurementLines.forEach((item) => floorplanWorkspace.addMeasurementLine(item, false));
    floorplan.dangerAreas.forEach((item) => floorplanWorkspace.addDangerArea(item, false));
    floorplan.floorplanTexts.forEach((item) => floorplanWorkspace.addText(item, false));
    const floorplanImages$ = from(floorplan.images).pipe(concatMap((image) => floorplanWorkspace.addImage(image, false)));
    floorplan.plasticSigns.forEach((item) => floorplanWorkspace.addPlasticSign(item, false));
    floorplan.plasticSignPlaceholders.forEach((item) => floorplanWorkspace.addPlasticSign(item, false));
    floorplan.signalElements.forEach((item) => floorplanWorkspace.addSignalElement(item, false));
    floorplan.signalElementPlaceholders.forEach((item) => floorplanWorkspace.addSignalElement(item, false));
    floorplan.transmitters.forEach((item) => floorplanWorkspace.addTransmitter(item, false));
    floorplan.transmitterPlaceholders.forEach((item) => floorplanWorkspace.addTransmitter(item, false));
    floorplan.gasWarningCenters.forEach((item) => floorplanWorkspace.addGasWarningCenter(item, false));
    floorplan.gasWarningCenterPlaceholders.forEach((item) => floorplanWorkspace.addGasWarningCenter(item, false));
    floorplan.alarmDevices.forEach((item) => floorplanWorkspace.addAlarmDevice(item, false));
    floorplan.alarmDevicePlaceholders.forEach((item) => floorplanWorkspace.addAlarmDevice(item, false));
    return !floorplan.images.length ? of(undefined) : floorplanImages$.pipe(map(() => undefined));
  }
}

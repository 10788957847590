<odx-modal-header>
  <odx-area-header i18n="@@modal.invitePerson.header1">Kollaboration</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p i18n="@@modal.invitePerson.header2">
    Laden Sie Kollegen ein, um das Projekt und alle seine Inhalte sehen und bearbeiten zu dürfen.
  </p>
  <p i18n="@@modal.invitePerson.header3"><strong>Nur Personen mit einem Drägerzugang können freigeschaltet werden.</strong></p>

  <ng-container [formGroup]="addMailFormGroup">
    <app-form-email-chip-list (updatedMailList)="onMailsChanged($event)"></app-form-email-chip-list>
  </ng-container>
</odx-modal-content>

<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@modal.invitePerson.dismiss">Abbrechen</button>
  <button
    odxButton
    variant="primary"
    [disabled]="addedMails.length === 0"
    (click)="onConfirm()"
    i18n="@@modal.invitePerson.confirm"
  >
    Einladungen senden
  </button>
</odx-modal-footer>

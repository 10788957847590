<div *ngIf="!editEnabled; else form">
  <hr *ngIf="transmitter.bmk || transmitter.sector || transmitter.installationHeight || transmitter.exProtection" />
  <div class="feature" *ngIf="transmitter.bmk">
    <p class="odx-text--small" i18n="@@floorplan.details.transmitter.bmk">BMK/Tag</p>
    <p>{{ transmitter.bmk }}</p>
  </div>

  <div class="feature" *ngIf="transmitter.sector">
    <p class="odx-text--small" i18n="@@floorplan.details.transmitter.sector">Bereich</p>
    <p>{{ transmitter.sector }}</p>
  </div>

  <div class="feature" *ngIf="transmitter.installationHeight">
    <p class="odx-text--small" i18n="@@floorplan.details.transmitter.installationHeight">Montagehöhe</p>
    <p>{{ transmitter.installationHeight }}</p>
  </div>

  <div class="feature" *ngIf="transmitter.exProtection" i18n="@@floorplan.details.transmitter.exPortection">
    <odx-checkbox [readonly]="true" [checked]="true"> Ex-Schutz </odx-checkbox>
  </div>
</div>

<ng-template #form>
  <hr />
  <form [formGroup]="editTransmitterForm" odxForm [readonly]="transmitter.locked">
    <odx-form-field i18n-label="@@editTransmitterForm.label.bmk" label="BMK">
      <input
        type="text"
        formControlName="bmk"
        odxFormFieldControl
        i18n-placeholder="@@editTransmitterForm.placeholder.bmk"
        placeholder="BMK"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@editTransmitterForm.label.sector" label="Bereich">
      <input
        type="text"
        formControlName="sector"
        odxFormFieldControl
        i18n-placeholder="@@editTransmitterForm.placeholder.sector"
        placeholder="Bereich"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@editTransmitterForm.label.installationHeight" label="Montagehöhe">
      <input
        type="text"
        formControlName="installationHeight"
        odxFormFieldControl
        i18n-placeholder="@@editTransmitterForm.placeholder.installationHeight"
        placeholder="Montagehöhe"
      />
    </odx-form-field>

    <odx-checkbox formControlName="exProtection" i18n="@@editTransmitterForm.label.exProtection"> Ex-Schutz </odx-checkbox>
  </form>
</ng-template>

<div odxLayout="flex gap-none" class="h-100 overflow-hidden flex-column">
  <app-project-detail-header
    [hideBackArrow]="hideBackArrow"
    [project]="project"
    (navigateBack)="navigateBack()"
  ></app-project-detail-header>

  <div odxLayout="flex gap-none" class="flex-column h-100 overflow-hidden">
    <odx-tab-bar class="tab-bar" [selectedIndex]="tabIndex" (selectedTabChanged)="tabIndex = $event.selectedIndex">
      <odx-tab-bar-item i18n="@@projectDetails.tabBar.tab1.name" data-testid="projectDetails.tab.floorplans"
        >Grundrisspläne
      </odx-tab-bar-item>
      <odx-tab-bar-item i18n="@@projectDetails.tabBar.tab2.name" data-testid="projectDetails.tab.images">Fotos </odx-tab-bar-item>
      <odx-tab-bar-item
        i18n="@@projectDetails.tabBar.tab7.name"
        data-testid="projectDetails.tab.documents"
        *ngIf="(isReadOnlyUser$ | async) === false"
        >Dateien
      </odx-tab-bar-item>
      <odx-tab-bar-item i18n="@@projectDetails.tabBar.tab3.name" data-testid="projectDetails.tab.productList"
        >Produktliste
      </odx-tab-bar-item>
      <odx-tab-bar-item i18n="@@projectDetails.tabBar.tab4.name" data-testid="projectDetails.tab.information"
        >Projektinformationen
      </odx-tab-bar-item>
      <odx-tab-bar-item
        *ngIf="(isReadOnlyUser$ | async) === false"
        i18n="@@projectDetails.tabBar.tab6.name"
        data-testid="projectDetails.tab.collaboration"
        >Kollaboration
      </odx-tab-bar-item>
      <odx-tab-bar-item i18n="@@projectDetails.tabBar.tab5.name" data-testid="projectDetails.tab.checklist"
        >Checkliste
      </odx-tab-bar-item>
    </odx-tab-bar>
    <div class="tab-content h-100" [hidden]="tabIndex !== 0">
      <app-floorplan-upload [projectData]="project"></app-floorplan-upload>
    </div>
    <div class="tab-content h-100" [hidden]="tabIndex !== 1">
      <app-image-upload [projectData]="project"></app-image-upload>
    </div>
    <div class="tab-content h-100" [hidden]="tabIndex !== 2" *ngIf="(isReadOnlyUser$ | async) === false">
      <app-files [projectData]="project"></app-files>
    </div>
    <div class="tab-content h-100" [hidden]="tabIndex !== setTabIndex(3, 1)">
      <app-project-products [projectData]="project"></app-project-products>
    </div>
    <div class="tab-content h-100 overflow-hidden" [hidden]="tabIndex !== setTabIndex(4, 1)">
      <app-project-information [projectData]="project"></app-project-information>
    </div>
    <div class="tab-content h-100 overflow-hidden" [hidden]="tabIndex !== 5" *ngIf="(isReadOnlyUser$ | async) === false">
      <app-project-collaboration [projectData]="project" [isHidden]="tabIndex !== 5"></app-project-collaboration>
    </div>
    <div class="tab-content h-100" [hidden]="tabIndex !== setTabIndex(6, 2)">
      <app-checklist [project]="project"></app-checklist>
    </div>
  </div>
</div>

<div odxLayout="flex gap-none" class="flex-column h-100 overflow-hidden">
  <odx-area-header class="product-header">
    <h5 class="odx-title-5" i18n="@@productList.header" data-testid="productList.header">Produktliste</h5>
    <odx-area-header-content></odx-area-header-content>
  </odx-area-header>
  <div odxLayout="grid gap-none" class="h-100 overflow-hidden">
    <div *ngIf="productListDataInitialized" odxLayout="7" class="container h-100 right-border scroll">
      <odx-accordion multiple="true" class="bottom-border">
        <odx-accordion-item *ngIf="floorplanGasWarningCenters.length || floorplanGasWarningCenterPlaceholders.length">
          <odx-accordion-item-title odxLayout="flex" class="space-between">
            <p i18n="@@global.gawWarningCenters" data-testid="productList.gasWarningCenters">Gaswarnzentralen</p>
            <p>
              {{ !project.showCostFlag ? "" : (gasWarningCenterSubtotal$ | async | currency: "EUR") }}
            </p>
          </odx-accordion-item-title>
          <app-product-table
            [productData]="gasWarningCenterData"
            [placeholderData]="gasWarningCenterPlaceholders"
            [showCosts]="project.showCostFlag"
          ></app-product-table>
        </odx-accordion-item>
        <odx-accordion-item *ngIf="floorplanTransmitters.length || floorplanTransmitterPlaceholders.length">
          <odx-accordion-item-title odxLayout="flex" class="space-between">
            <p i18n="@@global.transmitters" data-testid="productList.transmitters">Transmitter</p>
            <p>
              {{ !project.showCostFlag ? "" : (transmitterSubtotal$ | async | currency: "EUR") }}
            </p>
          </odx-accordion-item-title>
          <app-product-table
            [productData]="transmitterData"
            [placeholderData]="transmitterPlaceholders"
            [showCosts]="project.showCostFlag"
          ></app-product-table>
        </odx-accordion-item>
        <odx-accordion-item *ngIf="floorplanAlarmDevices.length || floorplanAlarmDevicePlaceholders.length">
          <odx-accordion-item-title odxLayout="flex" class="space-between">
            <p i18n="@@global.alarmDevices" data-testid="productList.alarmDevices">Alarmmittel</p>
            <p>
              {{ !project.showCostFlag ? "" : (alarmDeviceSubtotal$ | async | currency: "EUR") }}
            </p>
          </odx-accordion-item-title>
          <app-product-table
            [productData]="alarmDeviceData"
            [placeholderData]="alarmDevicePlaceholders"
            [showCosts]="project.showCostFlag"
          ></app-product-table>
        </odx-accordion-item>
        <odx-accordion-item *ngIf="floorplanSignalElements.length || floorplanSignalElementPlaceholders.length">
          <odx-accordion-item-title odxLayout="flex" class="space-between">
            <p i18n="@@global.signalElements" data-testid="productList.signalElements">Leuchttransparente</p>
            <p>
              {{ !project.showCostFlag ? "" : (signalElementSubtotal$ | async | currency: "EUR") }}
            </p>
          </odx-accordion-item-title>
          <app-product-table
            [productData]="signalElementsData"
            [placeholderData]="signalElementPlaceholders"
            [showCosts]="project.showCostFlag"
          ></app-product-table>
        </odx-accordion-item>
        <odx-accordion-item *ngIf="floorplanPlasticSigns.length || floorplanPlasticSignPlaceholders.length">
          <odx-accordion-item-title odxLayout="flex" class="space-between">
            <p i18n="@@global.plasticSigns" data-testid="productList.plasticSigns">Kunststoffschilder</p>
            <p>
              {{ !project.showCostFlag ? "" : (plasticSignSubtotal$ | async | currency: "EUR") }}
            </p>
          </odx-accordion-item-title>
          <app-product-table
            [productData]="plasticSignsData"
            [placeholderData]="plasticSignPlaceholders"
            [showCosts]="project.showCostFlag"
          ></app-product-table>
        </odx-accordion-item>
        <odx-accordion-item>
          <odx-accordion-item-title odxLayout="flex" class="space-between">
            <p i18n="@@global.services" data-testid="productList.services">Dienstleistungen</p>
            <p>
              {{ !project.showCostFlag ? "" : (calcServiceSubtotal() | currency: "EUR") }}
            </p>
          </odx-accordion-item-title>
          <table class="navinta-table">
            <thead>
              <td i18n="@@productList.servicesTable.column1.header" data-testid="productList.servicesTable.column1">Pos.</td>
              <td i18n="@@productList.servicesTable.column2.header" data-testid="productList.servicesTable.column2">
                Bezeichnung
              </td>
              <td i18n="@@productList.servicesTable.column3.header" data-testid="productList.servicesTable.column3">
                Sachnummer
              </td>
              <td i18n="@@productList.servicesTable.column4.header" data-testid="productList.servicesTable.column4">Preis</td>
            </thead>
            <tbody>
              <tr *ngIf="project.assembly.required">
                <td>
                  {{ getAssemblyPosition() }}
                </td>
                <td i18n="@@global.assembly">Montage</td>
                <td>1965124</td>
                <td>{{ project.assembly.costs | currency: "EUR" }}</td>
              </tr>
              <tr *ngIf="project.installation.required">
                <td>{{ getInstallationPosition() }}</td>
                <td i18n="@@global.installation">Inbetriebnahme</td>
                <td>1947222</td>
                <td>{{ project.installation.costs | currency: "EUR" }}</td>
              </tr>
              <tr *ngIf="project.documentation.required">
                <td>{{ getDocumentationPosition() }}</td>
                <td i18n="@@global.documentation">Dokumentation</td>
                <td>3721413</td>
                <td>{{ project.documentation.costs | currency: "EUR" }}</td>
              </tr>
              <tr *ngIf="project.engineering.required">
                <td>{{ getEngineeringPosition() }}</td>
                <td i18n="@@global.engineering">Engineering</td>
                <td>1907670</td>
                <td>{{ project.engineering.costs | currency: "EUR" }}</td>
              </tr>
              <tr *ngIf="project.additionalServices.required">
                <td>{{ getAdditionalServicesPosition() }}</td>
                <td i18n="@@global.additionalServices">Sonstige Dienstleistungen</td>
                <td></td>
                <td>
                  {{ project.additionalServices.costs | currency: "EUR" }}
                </td>
              </tr>
            </tbody>
          </table>
        </odx-accordion-item>
      </odx-accordion>
      <odx-list class="spacing-top">
        <div odxListItem>
          <div odxLayout="flex" class="space-between list-item">
            <p i18n="@@global.products" data-testid="productList.labelProducts">Produkte</p>
            <p>
              {{ !project.showCostFlag ? "" : (allProductsSubtotal$ | async | currency: "EUR") }}
            </p>
          </div>
        </div>
        <div odxListItem>
          <div odxLayout="flex" class="space-between list-item">
            <p i18n="@@global.services" data-testid="productList.labelServices">Dienstleistungen</p>
            <p>
              {{ !project.showCostFlag ? "" : (calcServiceSubtotal() | currency: "EUR") }}
            </p>
          </div>
        </div>
        <div odxListItem>
          <div odxLayout="flex" class="space-between list-item">
            <p i18n="@@productList.netSum" data-testid="productList.labelNetSum">Nettosumme</p>
            <p>
              {{ !project.showCostFlag ? "" : (projectTotalNetCosts$ | async | currency: "EUR") }}
            </p>
          </div>
        </div>
      </odx-list>
      <odx-inline-message i18n="@@productList.footer" data-testid="productList.footer"
        >Die Produktliste ist kein verbindliches Angebot</odx-inline-message
      >
    </div>
    <div odxLayout="5" class="container h-100 overflow-hidden">
      <h6 class="odx-subtitle-6" i18n="@@global.services" data-testid="productList.headerServices">Dienstleistungen</h6>
      <form
        odxForm
        [formGroup]="servicesForm"
        odxLayout="flex gap-none"
        class="flex-column scroll h-100"
        [readonly]="isReadOnlyUser$ | async"
      >
        <!-- Assembly -->

        <div odxLayout="flex" class="form-item-header">
          <odx-checkbox
            formControlName="assembly"
            #assemblyCheck
            i18n="@@global.assembly"
            data-testid="productList.checkboxAssembly"
            >Montage</odx-checkbox
          >
          <odx-form-field>
            <input
              placeholder="Preis in €"
              i18n-placeholder="@@productList.services.priceForm.placeholder"
              type="text"
              odxFormFieldControl
              (blur)="processValue($event, 'assemblyCosts')"
              #assemblyCosts
              data-testid="productList.inputAssembly"
            />
          </odx-form-field>
        </div>
        <odx-form-field label="Anmerkungen" i18n-label="@@productList.services.remarks.label">
          <textarea
            formControlName="assemblyNotes"
            placeholder="Anmerkungen"
            i18n-placeholder="@@productList.services.remarks.placeholder"
            odxFormFieldControl
            data-testid="productList.textareaAssembly"
          ></textarea>
        </odx-form-field>

        <!-- Installation -->

        <div odxLayout="flex" class="form-item-header">
          <odx-checkbox
            formControlName="installation"
            #installationCheck
            i18n="@@global.installation"
            data-testid="productList.checkboxInstallation"
            >Inbetriebnahme</odx-checkbox
          >
          <odx-form-field>
            <input
              placeholder="Preis in €"
              i18n-placeholder="@@productList.services.priceForm.placeholder"
              type="text"
              odxFormFieldControl
              (blur)="processValue($event, 'installationCosts')"
              #installationCosts
              data-testid="productList.inputInstallation"
            />
          </odx-form-field>
        </div>
        <odx-form-field label="Anmerkungen" i18n-label="@@productList.services.remarks.label">
          <textarea
            formControlName="installationNotes"
            placeholder="Anmerkungen"
            i18n-placeholder="@@productList.services.remarks.placeholder"
            odxFormFieldControl
            data-testid="productList.textareaInstallation"
          ></textarea>
        </odx-form-field>

        <!-- Documentation -->

        <div odxLayout="flex" class="form-item-header">
          <odx-checkbox
            formControlName="documentation"
            #documentationCheck
            i18n="@@global.documentation"
            data-testid="productList.checkboxDocumentation"
            >Dokumentation</odx-checkbox
          >
          <odx-form-field>
            <input
              placeholder="Preis in €"
              i18n-placeholder="@@productList.services.priceForm.placeholder"
              type="text"
              odxFormFieldControl
              (blur)="processValue($event, 'documentationCosts')"
              #documentationCosts
              data-testid="productList.inputDocumentation"
            />
          </odx-form-field>
        </div>
        <odx-form-field label="Anmerkungen" i18n-label="@@productList.services.remarks.label">
          <textarea
            placeholder="Anmerkungen"
            i18n-placeholder="@@productList.services.remarks.placeholder"
            formControlName="documentationNotes"
            odxFormFieldControl
            data-testid="productList.textareaDocumentation"
          ></textarea>
        </odx-form-field>

        <!-- Engineering -->

        <div odxLayout="flex" class="form-item-header">
          <odx-checkbox
            formControlName="engineering"
            #engineeringCheck
            i18n="@@global.engineering"
            data-testid="productList.checkboxEngineering"
            >Engineering</odx-checkbox
          >
          <odx-form-field>
            <input
              placeholder="Preis in €"
              i18n-placeholder="@@productList.services.priceForm.placeholder"
              type="text"
              odxFormFieldControl
              (blur)="processValue($event, 'engineeringCosts')"
              #engineeringCosts
              data-testid="productList.inputEngineering"
            />
          </odx-form-field>
        </div>
        <odx-form-field label="Anmerkungen" i18n-label="@@productList.services.remarks.label">
          <textarea
            formControlName="engineeringNotes"
            placeholder="Anmerkungen"
            i18n-placeholder="@@productList.services.remarks.placeholder"
            odxFormFieldControl
            data-testid="productList.textareaEngineering"
          ></textarea>
        </odx-form-field>

        <!-- Additional services -->

        <div odxLayout="flex" class="form-item-header">
          <odx-checkbox
            formControlName="additionalServices"
            #additionalServicesCheck
            i18n="@@global.additionalServices"
            data-testid="productList.checkboxAdditionalServices"
            >Sonstige Dienstleistungen</odx-checkbox
          >
          <odx-form-field>
            <input
              placeholder="Preis in €"
              i18n-placeholder="@@productList.services.priceForm.placeholder"
              type="text"
              odxFormFieldControl
              (blur)="processValue($event, 'additionalServicesCosts')"
              #additionalServicesCosts
              data-testid="productList.inputAdditionalServices"
            />
          </odx-form-field>
        </div>
        <odx-form-field label="Anmerkungen" i18n-label="@@productList.services.remarks.label">
          <textarea
            formControlName="additionalServicesNotes"
            placeholder="Anmerkungen"
            i18n-placeholder="@@productList.services.remarks.placeholder"
            odxFormFieldControl
            data-testid="productList.textareaAdditionalServices"
          ></textarea>
        </odx-form-field>
      </form>
    </div>
  </div>
</div>

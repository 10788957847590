import { Component, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { $localize } from "@angular/localize/init";
import { UiKitModule } from "@app/ui-kit.module";
import { Modal, ModalRef } from "@odx/angular/components/modal";

@Component({
  selector: "app-confirm-delete-project-modal",
  templateUrl: "./confirm-delete-project-modal.component.html",
  styleUrls: ["./confirm-delete-project-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule],
})
export class ConfirmDeleteProjectModalComponent implements Modal<any, boolean> {
  public readonly modalRef = inject(ModalRef<any, string>);
  private isUserProjectOwner = this.modalRef.data.isProjectOwner;

  formGroup = new FormGroup({
    confirmationCheckbox: new FormControl({
      value: false,
      disabled: !navigator.onLine || !this.isUserProjectOwner,
    }),
  });

  get offlineToolTipMessage() {
    return !navigator.onLine || !this.isUserProjectOwner
      ? $localize`:@@project.remove.fromCloud:Diese Funktion ist nur im Online-Modus verfügbar und ausschließlich dem Projekteigentümer vorbehalten.`
      : null;
  }

  onConfirm() {
    this.modalRef.close(this.formGroup.controls["confirmationCheckbox"].value);
  }

  protected readonly navigator = navigator;
}

import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Project } from "@domain/project/project";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import { ContainerProperties, ImagePaths, PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

@Injectable({
  providedIn: "root",
})
export class TitlePagePdfService {
  private static readonly HEADING_UPPER_HEADING_Y_POS = 545;
  private static readonly HEADING_MAIN_Y_POS = 699;
  private static readonly HEADING_LOCATION_Y_POS = 1031;

  private static readonly HEADING_PROJECT_Y_POS = 555;
  private static readonly HEADING_CUSTOMER_Y_POS = 992;
  private static readonly HEADING_CONTACT_Y_POS = 1429;
  private static readonly HEADING_RECIPIENT_Y_POS = 1866;

  private static readonly HEADING_1_FONT_SIZE = 150 * PdfProperties.POINT_TO_PIXEL_RATIO;
  private static readonly HEADING_2_FONT_SIZE = 96 * PdfProperties.POINT_TO_PIXEL_RATIO;
  private static readonly HEADING_3_FONT_SIZE = 76 * PdfProperties.POINT_TO_PIXEL_RATIO;
  private static readonly HEADING_4_FONT_SIZE = 64 * PdfProperties.POINT_TO_PIXEL_RATIO;
  private static readonly LOGO_NAVINTA_FONT_SIZE = 40 * PdfProperties.POINT_TO_PIXEL_RATIO;

  private static readonly LOGO_DRAEGER_HEIGHT = 72;
  private static readonly LOGO_DRAEGER_WIDTH = 150;
  private static readonly LOGO_DRAEGER_Y_POS = 284;
  private static readonly LOGO_NAVINTA_HEIGHT = 100;
  private static readonly LOGO_NAVINTA_WIDTH = 100;
  private static readonly LOGO_NAVINTA_Y_POS = 270;
  private static readonly LOGO_WATERMARK_WIDTH = 1141;
  private static readonly LOGO_WATERMARK_HEIGHT = 1022;
  private static readonly LOGO_WATERMARK_X_POS = 1605;
  private static readonly LOGO_WATERMARK_Y_POS = 1293;

  private static readonly LINE_COLOR = "#DDE5EE";
  private static readonly LINE_HEIGHT = 4;
  private static readonly LINE_STYLE = "FD";
  private static readonly LINE_LENGTH = 1200;
  private static readonly LINE_X_POS = 2173;
  private static readonly LINE_1_Y_POS = 948;
  private static readonly LINE_2_Y_POS = 1385;
  private static readonly LINE_3_Y_POS = 1822;

  private static readonly GAP_VERTICAL = 96;
  private static readonly RIGHT_COLUMN_X_POS = 2269;
  private static readonly RIGHT_COLUMN_WIDTH: number = TitlePagePdfService.LINE_LENGTH - 192;

  private static readonly TEXT_BOX_PROJECT_Y_POS = 673;
  private static readonly TEXT_BOX_CUSTOMER_Y_POS = 1110;
  private static readonly TEXT_BOX_CONTACT_Y_POS = 1547;
  private static readonly TEXT_BOX_RECIPIENT_Y_POS = 1984;

  private static readonly TEXT_FONT_SIZE = 40 * PdfProperties.POINT_TO_PIXEL_RATIO;

  private static readonly FILENAME_X_POS = 475;
  private static readonly FILENAME_Y_POS = 2108;
  private static readonly FILENAME_FONT_SIZE = 30 * PdfProperties.POINT_TO_PIXEL_RATIO;

  private static readonly TIMESTAMP_X_POS = 475;
  private static readonly TIMESTAMP_Y_POS = 2169;
  private static readonly TIMESTAMP_FONT_SIZE = 30 * PdfProperties.POINT_TO_PIXEL_RATIO;

  private static readonly VERSION_X_POS = 475;
  private static readonly VERSION_Y_POS = 2230;
  private static readonly VERSION_FONT_SIZE = 30 * PdfProperties.POINT_TO_PIXEL_RATIO;

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  generate(pdf: jsPDF, project: Project, fileName: string, exportDate: Date) {
    this.addFonts(pdf);
    pdf.setFont("DraegerPangea-Medium", "normal");
    pdf.setTextColor(PdfProperties.DRAEGERBLUE);
    this.addWatermark(pdf);
    this.addLogo(pdf);
    this.addContentLeftColumn(pdf, project, fileName, exportDate);
    this.addContentRightColumn(pdf, project);
  }

  private addWatermark(pdf: jsPDF) {
    pdf.addImage(
      ImagePaths.WATERMARK,
      "PNG",
      TitlePagePdfService.LOGO_WATERMARK_X_POS,
      TitlePagePdfService.LOGO_WATERMARK_Y_POS,
      TitlePagePdfService.LOGO_WATERMARK_WIDTH,
      TitlePagePdfService.LOGO_WATERMARK_HEIGHT,
    );
  }

  private addLogo(pdf: jsPDF) {
    pdf.addImage(
      ImagePaths.DRAEGERLOGO,
      "PNG",
      ContainerProperties.LEFT_X,
      TitlePagePdfService.LOGO_DRAEGER_Y_POS,
      TitlePagePdfService.LOGO_DRAEGER_WIDTH,
      TitlePagePdfService.LOGO_DRAEGER_HEIGHT,
    );
    pdf.addImage(
      ImagePaths.NAVINTALOGO,
      "PNG",
      ContainerProperties.LEFT_X + TitlePagePdfService.LOGO_DRAEGER_WIDTH + 24,
      TitlePagePdfService.LOGO_NAVINTA_Y_POS,
      TitlePagePdfService.LOGO_NAVINTA_WIDTH,
      TitlePagePdfService.LOGO_NAVINTA_HEIGHT,
    );
    pdf.setFontSize(TitlePagePdfService.LOGO_NAVINTA_FONT_SIZE);
    pdf.text(
      "Navinta",
      ContainerProperties.LEFT_X + TitlePagePdfService.LOGO_DRAEGER_WIDTH + TitlePagePdfService.LOGO_NAVINTA_WIDTH + 48,
      TitlePagePdfService.LOGO_DRAEGER_Y_POS + 16,
      { baseline: "top" },
    );
  }

  private addRightColumnHeading(pdf: jsPDF, heading: string, xPos: number, yPos: number) {
    pdf.setFontSize(TitlePagePdfService.HEADING_3_FONT_SIZE);
    pdf.text(heading, xPos, yPos, { baseline: "top" });
    pdf.setFontSize(TitlePagePdfService.TEXT_FONT_SIZE);
  }

  private addLine(pdf: jsPDF, xPos: number, yPos: number) {
    pdf.setLineWidth(TitlePagePdfService.LINE_HEIGHT);
    pdf.setDrawColor(TitlePagePdfService.LINE_COLOR);
    pdf.line(xPos, yPos, xPos + TitlePagePdfService.LINE_LENGTH, yPos, TitlePagePdfService.LINE_STYLE);
  }

  private addContentLeftColumn(pdf: jsPDF, project: Project, fileName: string, exportDate: Date) {
    pdf.setFontSize(TitlePagePdfService.HEADING_2_FONT_SIZE);
    const customerNameRows = this.truncateTextIfTooLong(
      pdf,
      project.customer.name!,
      TitlePagePdfService.LINE_X_POS - ContainerProperties.LEFT_X - TitlePagePdfService.GAP_VERTICAL,
    );
    this.addLeftColumnHeadline(
      customerNameRows,
      pdf,
      TitlePagePdfService.HEADING_UPPER_HEADING_Y_POS,
      TitlePagePdfService.HEADING_2_FONT_SIZE,
    );

    pdf.setFontSize(TitlePagePdfService.HEADING_1_FONT_SIZE);
    const projectNameRows = this.truncateTextIfTooLong(
      pdf,
      project.name,
      TitlePagePdfService.LINE_X_POS - ContainerProperties.LEFT_X - TitlePagePdfService.GAP_VERTICAL,
      4,
    );
    this.addLeftColumnHeadline(
      projectNameRows,
      pdf,
      TitlePagePdfService.HEADING_MAIN_Y_POS,
      TitlePagePdfService.HEADING_1_FONT_SIZE,
    );

    pdf.setFontSize(TitlePagePdfService.HEADING_4_FONT_SIZE);
    const locationRows = this.truncateTextIfTooLong(
      pdf,
      project.placeName!,
      TitlePagePdfService.LINE_X_POS - ContainerProperties.LEFT_X - TitlePagePdfService.GAP_VERTICAL,
      4,
    );
    const locationYPos =
      TitlePagePdfService.HEADING_LOCATION_Y_POS + (projectNameRows.length - 1) * TitlePagePdfService.HEADING_1_FONT_SIZE;
    this.addLeftColumnHeadline(locationRows, pdf, locationYPos, TitlePagePdfService.HEADING_4_FONT_SIZE);

    this.addFileName(pdf, fileName);
    this.addTimestamp(pdf, exportDate);
    if (project.version) this.addVersion(pdf, project.version);
  }

  private addLeftColumnHeadline(rows: string[], pdf: jsPDF, yPos: number, fontSize: number) {
    rows.forEach((row, index) => pdf.text(row, ContainerProperties.LEFT_X, yPos + index * fontSize, { baseline: "top" }));
  }

  private addProjectDetails(pdf: jsPDF, project: Project) {
    this.addRightColumnHeading(
      pdf,
      $localize`:@@global.project:Projekt`,
      TitlePagePdfService.RIGHT_COLUMN_X_POS,
      TitlePagePdfService.HEADING_PROJECT_Y_POS,
    );
    let textRows = [project.name || "", project.forzaId || "", project.placeName || "", project.notes || ""];
    textRows = textRows.map((row) => this.truncateTextIfTooLong(pdf, row, TitlePagePdfService.RIGHT_COLUMN_WIDTH)[0]);
    this.addTextRows(pdf, textRows, TitlePagePdfService.TEXT_BOX_PROJECT_Y_POS);
    this.addLine(pdf, TitlePagePdfService.LINE_X_POS, TitlePagePdfService.LINE_1_Y_POS);
  }

  private addCustomerDetails(pdf: jsPDF, project: Project) {
    this.addRightColumnHeading(
      pdf,
      $localize`:@@global.customer:Kunde`,
      TitlePagePdfService.RIGHT_COLUMN_X_POS,
      TitlePagePdfService.HEADING_CUSTOMER_Y_POS,
    );
    let location = "";
    if (project.customer.zipCode && project.customer.city) {
      location = project.customer.zipCode + " " + project.customer.city;
    } else if (project.customer.zipCode || project.customer.city) {
      location = project.customer.zipCode! || project.customer.city!;
    }
    let textRows = [project.customer.name || "", project.customer.customerNumber || "", project.customer.street || "", location];
    textRows = textRows.map((row) => this.truncateTextIfTooLong(pdf, row, TitlePagePdfService.RIGHT_COLUMN_WIDTH)[0]);
    this.addTextRows(pdf, textRows, TitlePagePdfService.TEXT_BOX_CUSTOMER_Y_POS);
    this.addLine(pdf, TitlePagePdfService.LINE_X_POS, TitlePagePdfService.LINE_2_Y_POS);
  }

  private createContactPersonName(project: Project) {
    let contactPersonName: string = "";
    if (project.contactPerson.firstname) {
      contactPersonName += project.contactPerson.firstname + " ";
    }
    if (project.contactPerson.lastname) {
      contactPersonName += project.contactPerson.lastname;
    }
    return contactPersonName;
  }

  private addContactPersonDetails(pdf: jsPDF, project: Project) {
    this.addRightColumnHeading(
      pdf,
      $localize`:@@global.contactPerson:Ansprechpartner`,
      TitlePagePdfService.RIGHT_COLUMN_X_POS,
      TitlePagePdfService.HEADING_CONTACT_Y_POS,
    );

    let textRows = [
      this.createContactPersonName(project),
      project.contactPerson.department || "",
      project.contactPerson.email || "",
      project.contactPerson.phoneNumber || "",
    ];
    textRows = textRows.map((row) => this.truncateTextIfTooLong(pdf, row, TitlePagePdfService.RIGHT_COLUMN_WIDTH)[0]);
    this.addTextRows(pdf, textRows, TitlePagePdfService.TEXT_BOX_CONTACT_Y_POS);
    this.addLine(pdf, TitlePagePdfService.LINE_X_POS, TitlePagePdfService.LINE_3_Y_POS);
  }

  private addGoodsRecipientDetails(pdf: jsPDF, project: Project) {
    this.addRightColumnHeading(
      pdf,
      $localize`:@@global.goodsRecipient:Warenempfänger`,
      TitlePagePdfService.RIGHT_COLUMN_X_POS,
      TitlePagePdfService.HEADING_RECIPIENT_Y_POS,
    );
    if (project.goodsRecipient.information) {
      const textRows = this.truncateTextIfTooLong(
        pdf,
        project.goodsRecipient.information,
        TitlePagePdfService.RIGHT_COLUMN_WIDTH,
        6,
      );
      this.addTextRows(pdf, textRows, TitlePagePdfService.TEXT_BOX_RECIPIENT_Y_POS);
    }
  }

  private addTextRows(pdf: jsPDF, textRows: string[], yPos: number) {
    textRows.forEach((row, index) =>
      pdf.text(row, TitlePagePdfService.RIGHT_COLUMN_X_POS, yPos + index * TitlePagePdfService.TEXT_FONT_SIZE, {
        baseline: "top",
      }),
    );
  }

  private addContentRightColumn(pdf: jsPDF, project: Project) {
    this.addProjectDetails(pdf, project);
    this.addCustomerDetails(pdf, project);
    this.addContactPersonDetails(pdf, project);
    this.addGoodsRecipientDetails(pdf, project);
  }

  private truncateTextIfTooLong(pdf: jsPDF, text: string, maxWidth: number, maxLines = 1) {
    let splitText: string[] = pdf.splitTextToSize(text, maxWidth);
    if (splitText.length > maxLines) {
      splitText = splitText.slice(0, maxLines);
      splitText[splitText.length - 1] = splitText[splitText.length - 1] + " ...";
    }
    return splitText;
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }

  private addFileName(pdf: jsPDF, fileName: string) {
    pdf.setFontSize(TitlePagePdfService.FILENAME_FONT_SIZE);
    pdf.setTextColor(PdfProperties.ODX_GREY);
    pdf.text($localize`:@@pdfExport.fileNamePrefix:Dateiname: `, ContainerProperties.LEFT_X, TitlePagePdfService.FILENAME_Y_POS, {
      baseline: "top",
    });
    pdf.text(fileName, TitlePagePdfService.FILENAME_X_POS, TitlePagePdfService.FILENAME_Y_POS, { baseline: "top" });
    pdf.setFontSize(TitlePagePdfService.TEXT_FONT_SIZE);
    pdf.setTextColor(PdfProperties.DRAEGERBLUE);
  }

  private addTimestamp(pdf: jsPDF, exportDate: Date) {
    pdf.setFontSize(TitlePagePdfService.TIMESTAMP_FONT_SIZE);
    pdf.setTextColor(PdfProperties.ODX_GREY);
    pdf.text(
      $localize`:@@pdfExport.timestampPrefix:Exportiert am: `,
      ContainerProperties.LEFT_X,
      TitlePagePdfService.TIMESTAMP_Y_POS,
      { baseline: "top" },
    );
    pdf.text(
      `${exportDate.toLocaleDateString(this.localeId)}, ${exportDate.toLocaleTimeString(this.localeId)}`,
      TitlePagePdfService.TIMESTAMP_X_POS,
      TitlePagePdfService.TIMESTAMP_Y_POS,
      {
        baseline: "top",
      },
    );
    pdf.setFontSize(TitlePagePdfService.TEXT_FONT_SIZE);
    pdf.setTextColor(PdfProperties.DRAEGERBLUE);
  }

  private addVersion(pdf: jsPDF, version: string) {
    pdf.setFontSize(TitlePagePdfService.VERSION_FONT_SIZE);
    pdf.setTextColor(PdfProperties.ODX_GREY);
    pdf.text($localize`:@@pdfExport.versionPrefix:Version: `, ContainerProperties.LEFT_X, TitlePagePdfService.VERSION_Y_POS, {
      baseline: "top",
    });
    pdf.text(version, TitlePagePdfService.VERSION_X_POS, TitlePagePdfService.VERSION_Y_POS, { baseline: "top" });
    pdf.setFontSize(TitlePagePdfService.TEXT_FONT_SIZE);
    pdf.setTextColor(PdfProperties.DRAEGERBLUE);
  }
}

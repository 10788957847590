import { Project } from "@domain/project/project";
import { Exclude, Expose } from "class-transformer";
import { IsDate, IsNumber, IsOptional, IsString, IsUUID } from "class-validator";

export class ProjectFile {
  @Exclude()
  private project?: Project;

  @IsString()
  @Expose({ name: "name" })
  public name: string;

  @IsUUID()
  @IsOptional()
  public readonly id?: string;

  @IsString()
  public readonly projectId: string;

  @IsNumber()
  public readonly size;

  @IsString()
  public readonly type: string;

  @IsDate()
  public readonly uploadedAt: Date;

  constructor(name: string, projectId: string, size: number, type: string, uploadedAt: Date) {
    this.name = name;
    this.projectId = projectId;
    this.size = size;
    this.type = type;
    this.uploadedAt = uploadedAt;
  }

  init(project: Project) {
    this.project = project;
  }
}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ReadOnlyTokenStorage {
  private static readonly STORAGE_KEY = "read_access_token";

  set(token: string) {
    sessionStorage.setItem(ReadOnlyTokenStorage.STORAGE_KEY, token);
  }

  get(): string | null {
    return sessionStorage.getItem(ReadOnlyTokenStorage.STORAGE_KEY);
  }

  clear() {
    sessionStorage.removeItem(ReadOnlyTokenStorage.STORAGE_KEY);
  }
}

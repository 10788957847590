<button
  odxButton
  [variant]="odxVariant"
  size="medium"
  class="menu-button"
  [ngStyle]="{ width: expanded ? '100%' : '36px' }"
  [odxTooltip]="title"
  [odxTooltipDisabled]="expanded"
  [odxTooltipOptions]="{ delayIn: 0 }"
>
  <div odxLayout="flex vertical-center" class="w-100">
    <odx-icon *ngIf="odxIcon" [name]="odxIcon!"></odx-icon>
    <img *ngIf="imageUrl" alt="" [src]="imageUrl" />
    <div *ngIf="expanded" odxLayout="flex gap-small">{{ title }}</div>
  </div>
</button>

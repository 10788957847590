<div *ngIf="!editEnabled; else form">
  <ng-container *ngIf="gasWarningCenter.cabinet as cabinet">
    <hr />
    <h6 class="odx-title-6">Schaltschrank</h6>

    <div class="feature" *ngIf="cabinet.maxHeight">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.maxHeight">Maximale Höhe</p>
      <p>{{ cabinet.maxHeight }}</p>
    </div>

    <div class="feature" *ngIf="cabinet.maxWidth">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.maxWidth">Maximale Breite</p>
      <p>{{ cabinet.maxWidth }}</p>
    </div>

    <div class="feature" *ngIf="cabinet.maxDepth">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.maxDepth">Maximale Tiefe</p>
      <p>{{ cabinet.maxDepth }}</p>
    </div>

    <div class="feature" *ngIf="cabinet.material">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.material">Material</p>
      <p>{{ cabinet.material }}</p>
    </div>

    <div class="feature" *ngIf="cabinet.doorstop">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.doorstop">Türausrichtung</p>
      <p>{{ cabinet.doorstop | localizeDoorstopType }}</p>
    </div>

    <div class="feature" *ngIf="cabinet.cableInlet">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.cableInlet">Kabeleingang</p>
      <p>{{ cabinet.cableInlet | localizeCableInletType }}</p>
    </div>

    <div class="feature" *ngIf="cabinet.outdoorInstallation">
      <odx-checkbox [readonly]="true" [checked]="true" i18n="@@floorplan.details.gasWarningCenter.outdoorInstallation">
        UV-geschützt/Außeninstallation
      </odx-checkbox>
    </div>

    <div class="feature" *ngIf="cabinet.minEnvironmentalTemperature">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.minEnvironmentalTemperature">
        Mindestumgebungstemperatur
      </p>
      <p>{{ cabinet.minEnvironmentalTemperature }}</p>
    </div>

    <div class="feature" *ngIf="cabinet.maxEnvironmentalTemperature">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.maxEnvironmentalTemperature">
        Mindestumgebungstemperatur
      </p>
      <p>{{ cabinet.maxEnvironmentalTemperature }}</p>
    </div>

    <div class="feature" *ngIf="cabinet.ipProtectionCategory">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.ipProtectionCategory">
        IP-Sicherheitskategorie
      </p>
      <p>{{ cabinet.ipProtectionCategory }}</p>
    </div>

    <div class="feature" *ngIf="cabinet.notes">
      <p class="odx-text--small" i18n="@@floorplan.details.gasWarningCenterCabinet.notes">Schaltschranknotizen</p>
      <p>{{ cabinet.notes }}</p>
    </div>
    <hr />
  </ng-container>
</div>

<ng-template #form>
  <hr />
  <h6 class="odx-title-6">Schaltschrank</h6>
  <form [formGroup]="editGasWarningCenterForm" odxForm [readonly]="gasWarningCenter.locked">
    <odx-form-field i18n-label="@@editGasWarningCenterCabinetForm.label.maxHeight" label="Maximale Höhe">
      <input
        type="text"
        formControlName="maxHeight"
        odxFormFieldControl
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.maxHeight"
        placeholder="Maximale Höhe"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@editGasWarningCenterCabinetForm.label.maxWidth" label="Maximale Breite">
      <input
        type="text"
        formControlName="maxWidth"
        odxFormFieldControl
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.maxWidth"
        placeholder="Maximale Breite"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@editGasWarningCenterCabinetForm.label.maxDepth" label="Maximale Tiefe">
      <input
        type="text"
        formControlName="maxDepth"
        odxFormFieldControl
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.maxDepth"
        placeholder="Maximale Tiefe"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@editGasWarningCenterCabinetForm.label.material" label="Material">
      <input
        type="text"
        formControlName="material"
        odxFormFieldControl
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.material"
        placeholder="Material"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@editGasWarningCenterCabinetForm.label.doorstop" label="Türausrichtung">
      <odx-select
        formControlName="doorstop"
        odxFormFieldControl
        placeholder="Keine Auswahl"
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.doorstop.noSelection"
      >
        <odx-select-option [value]="DoorstopType.LEFT" i18n="@@editGasWarningCenterCabinetForm.label.doorstop.left">
          Links
        </odx-select-option>
        <odx-select-option [value]="DoorstopType.RIGHT" i18n="@@editGasWarningCenterCabinetForm.label.doorstop.right">
          Rechts
        </odx-select-option>
      </odx-select>
    </odx-form-field>

    <odx-form-field i18n-label="@@editGasWarningCenterCabinetForm.label.cableInlet" label="Kabeleingang">
      <odx-select
        formControlName="cableInlet"
        odxFormFieldControl
        placeholder="Keine Auswahl"
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.cableInlet.noSelection"
      >
        <odx-select-option [value]="CableInletType.TOP" i18n="@@editGasWarningCenterCabinetForm.label.cableInlet.top">
          Oben
        </odx-select-option>
        <odx-select-option [value]="CableInletType.BOTTOM" i18n="@@editGasWarningCenterCabinetForm.label.cableInlet.bottom">
          Unten
        </odx-select-option>
        <odx-select-option [value]="CableInletType.LEFT" i18n="@@editGasWarningCenterCabinetForm.label.cableInlet.left">
          Links
        </odx-select-option>
        <odx-select-option [value]="CableInletType.RIGHT" i18n="@@editGasWarningCenterCabinetForm.label.cableInlet.right">
          Rechts
        </odx-select-option>
      </odx-select>
    </odx-form-field>

    <odx-checkbox
      class="checkbox-padding"
      formControlName="outdoorInstallation"
      i18n="@@editGasWarningCenterCabinetForm.label.outdoorInstallation"
    >
      UV-geschützt/Außeninstallation
    </odx-checkbox>

    <odx-form-field
      i18n-label="@@editGasWarningCenterCabinetForm.label.minEnvironmentalTemperature"
      label="Mindestumgebungstemperatur"
    >
      <input
        type="text"
        formControlName="minEnvironmentalTemperature"
        odxFormFieldControl
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.minEnvironmentalTemperature"
        placeholder="Mindestumgebungstemperatur"
      />
    </odx-form-field>

    <odx-form-field
      i18n-label="@@editGasWarningCenterCabinetForm.label.maxEnvironmentalTemperature"
      label="Höchstumgebungstemperatur"
    >
      <input
        type="text"
        formControlName="maxEnvironmentalTemperature"
        odxFormFieldControl
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.maxEnvironmentalTemperature"
        placeholder="Höchstumgebungstemperatur"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@editGasWarningCenterCabinetForm.label.ipProtectionCategory" label="IP-Sicherheitskategorie">
      <input
        type="text"
        formControlName="ipProtectionCategory"
        odxFormFieldControl
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.ipProtectionCategory"
        placeholder="IP-Sicherheitskategorie"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@editGasWarningCenterCabinetForm.label.notes" label="Schaltschranknotizen">
      <textarea
        formControlName="notes"
        odxFormFieldControl
        i18n-placeholder="@@editGasWarningCenterCabinetForm.placeholder.notes"
        placeholder="Notizen"
        class="textarea-large"
      ></textarea>
      <ng-template odxFormFieldError>
        <ng-container i18n="@@editGasWarningCenterCabinetForm.notes.maxLengthExceeded"
          >Maximale Zeichenanzahl überschritten</ng-container
        >
      </ng-template>
    </odx-form-field>
  </form>
  <hr />
</ng-template>

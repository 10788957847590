import { Component, Input, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ProductService } from "@domain/product/product.service";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanPlasticSign } from "@domain/project/floorplan/floorplan-plastic-sign";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";
import { ProjectStateService } from "@project/project-state.service";
import { Observable, map } from "rxjs";

@Component({
  selector: " app-plasticsign-notes",
  templateUrl: "./plastic-sign-notes.component.html",
  styleUrls: ["./plastic-sign-notes.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanItemDetailsComponent],
})
export class PlasticSignNotesComponent implements OnInit {
  @Input({ required: true }) set floorplanItem(item: FloorplanItem) {
    this.plasticSign = item as FloorplanPlasticSign;
  }

  protected plasticSignName$!: Observable<string>;
  protected plasticSign!: FloorplanPlasticSign;
  protected isDiscontinued$!: Observable<boolean>;
  protected isIncompleteConfiguration$!: Observable<boolean>;

  constructor(
    private productService: ProductService,
    private projectStateService: ProjectStateService,
  ) {}

  ngOnInit() {
    this.isDiscontinued$ = this.projectStateService.isConfigDiscontinued(this.plasticSign.config.id);
    this.isIncompleteConfiguration$ = this.projectStateService.isConfigIncomplete(this.plasticSign.config.id);
    this.plasticSignName$ = this.productService
      .getProductNameById(this.plasticSign.config.productId)
      .pipe(map((name) => name || this.plasticSign.config.name));
  }
}

import { Component, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { FilterProductComponent } from "@components/filter-product/filter-product.component";
import { ProductCardComponent } from "@components/product-card/product-card.component";
import { Product, ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { Project } from "@domain/project/project";
import { defer, filter, map, Observable, of } from "rxjs";

@Component({
  selector: "app-plastic-sign-selector",
  templateUrl: "./plastic-sign-selector.component.html",
  styleUrls: ["./plastic-sign-selector.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, FilterProductComponent, ProductCardComponent],
})
export class PlasticSignSelectorComponent {
  @Input({ required: true }) project!: Project;

  @Input() set originalPlasticSign(plasticSign: Product | undefined) {
    if (plasticSign && plasticSign.isAvailable) {
      this.updateSelectedPlasticSign(plasticSign);
    }
  }

  protected plasticSignForm = this.formBuilder.group({
    selectedPlasticSign: new FormControl<null | Product>(null, Validators.required),
  });

  @Output() formReady = of(this.plasticSignForm);

  @Output() plasticSignSelect$ = defer(() =>
    this.plasticSignForm.valueChanges.pipe(
      filter((formValue) => !!formValue.selectedPlasticSign),
      map((formValue) => {
        this.selectedPlasticSign = formValue.selectedPlasticSign!;
        return this.selectedPlasticSign;
      }),
    ),
  );

  protected filteredPlasticSigns$!: Observable<Product[]>;
  protected selectedPlasticSign?: Product;

  private plasticSigns$!: Observable<Product[]>;

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
  ) {
    this.collectPlasticSigns();
  }

  protected search(value: string) {
    this.filteredPlasticSigns$ = this.productService.filter(this.plasticSigns$, value);
  }

  protected updateSelectedPlasticSign(plasticSign: Product) {
    this.plasticSignForm.patchValue({ selectedPlasticSign: plasticSign });
  }

  private collectPlasticSigns() {
    this.plasticSigns$ = this.productService
      .getAvailableProducts(ProductType.PLASTICSIGN)
      .pipe(map((products) => products.filter((product) => product.type == ProductType.PLASTICSIGN && product.isAvailable)));
    this.filteredPlasticSigns$ = this.plasticSigns$;
  }
}

import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { ProjectCardComponent } from "@components/project-cards/project-card/project-card.component";
import { ProjectImportComponent } from "@components/project-import/project-import.component";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { environment } from "@environments/environment";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  standalone: true,
  imports: [UiKitModule, RouterModule, ProjectCardComponent, ProjectImportComponent],
  providers: [ProjectImportComponent],
})
export class HomeComponent {
  projects$: Observable<Project[]>;
  crmUrl = environment.crmUrl;

  constructor(private projectService: ProjectService) {
    this.projects$ = this.loadProjects();
  }

  private loadProjects(): Observable<Project[]> {
    return this.projectService.projects$.pipe(
      map((projects) => projects.sort((a, b) => b.lastModified!.getTime() - a.lastModified!.getTime())),
    );
  }
}

import { Injectable } from "@angular/core";
import { Project } from "@domain/project/project";
import { HalfPageContainer } from "@pdf/helpers/container/half-page/half-page-container";
import { HalfPageContainerService } from "@pdf/helpers/container/half-page/half-page-container.service";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import { ContainerProperties, FontNames, Headlines, PdfProperties, TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PdfProjectInformationService {
  private headlines = new Headlines();

  constructor(private containerService: HalfPageContainerService) {}

  generate(pdf: jsPDF, project: Project): Observable<{ pdf: jsPDF; container: HalfPageContainer }> {
    return new Observable((observer) => {
      this.addFonts(pdf);
      pdf.setFont(FontNames.DRAEGER_PANGEA, "normal");
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);
      const container = new HalfPageContainer();
      if (project.notes) {
        this.addProjectNotes(pdf, container, project.notes);
      }
      observer.next({ pdf, container });
      observer.complete();
    });
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }

  private addProjectNotes(pdf: jsPDF, container: HalfPageContainer, notes: string) {
    const pageHeadline = this.headlines.PROJECT_INFORMATION;
    this.containerService.addOnNewPage(pdf, container, pageHeadline);
    container.xPosition += TableProperties.PADDING_X;
    container.yPosition += TableProperties.HEADLINE_OFFSET_Y;
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(PdfProperties.TABLE_OF_CONTENTS_FONT_SIZE_LARGE);
    pdf.text(this.headlines.PROJECT_NOTES, container.xPosition, container.yPosition, { baseline: "top" });
    container.yPosition += TableProperties.PADDING_64;
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    const text = pdf.splitTextToSize(notes, ContainerProperties.WIDTH - 2 * TableProperties.PADDING_X);
    pdf.text(text, container.xPosition, container.yPosition, { baseline: "top" });
  }
}

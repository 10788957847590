import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { AirPath } from "@domain/project/floorplan/air-path";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanAlarmDevice } from "@domain/project/floorplan/floorplan-alarm-device";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanPlasticSign } from "@domain/project/floorplan/floorplan-plastic-sign";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { FloorplanSignalElement } from "@domain/project/floorplan/floorplan-signal-element";
import { FloorplanState } from "@domain/project/floorplan/floorplan-state";
import { FloorplanText } from "@domain/project/floorplan/floorplan-text";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { MeasurementLine } from "@domain/project/floorplan/measurement-line";
import { Pipeline } from "@domain/project/floorplan/pipeline";
import { DangerArea } from "@domain/project/floorplan/zones/danger-area";
import { DustExZone } from "@domain/project/floorplan/zones/dust-ex-zone";
import { ExZone } from "@domain/project/floorplan/zones/ex-zone";
import { Point } from "@utils/point";
import { Observable } from "rxjs";

export abstract class FloorplanWorkspace {
  abstract get selectedItemChanged$(): Observable<FloorplanItem>;

  abstract init(floorplanState: Floorplan, container: HTMLDivElement): Observable<void>;

  abstract getCenterPoint(): Point;

  abstract getPointerPosition(): Point;

  abstract getBorderDimensions(): { width: number; height: number };

  abstract getStageDimensions(): { width: number; height: number };

  abstract setScale(factor: number): void;

  abstract setStagePosition(point: Point): void;

  abstract setLockState(floorplanItem: FloorplanItem): void;

  abstract setVisibilityState(floorplanState: FloorplanState): void;

  abstract addExZone(exZone: ExZone, select: boolean): void;

  abstract addDustExZone(dustExZone: DustExZone, select: boolean): void;

  abstract addDangerArea(dangerArea: DangerArea, select: boolean): void;

  abstract addImage(floorplanImage: FloorplanImage, select: boolean): Observable<void>;

  abstract addMeasurementLine(measurementLine: MeasurementLine, select: boolean): void;

  abstract addText(text: FloorplanText, select: boolean): void;

  abstract addAirPath(airPath: AirPath, select: boolean): void;

  abstract addPipeline(pipeline: Pipeline, select: boolean): void;

  abstract addTransmitter(transmitter: FloorplanTransmitter | FloorplanTransmitterPlaceholder, select: boolean): void;

  abstract addAlarmDevice(alarmDevice: FloorplanAlarmDevice | FloorplanPlaceholder, select: boolean): void;

  abstract addGasWarningCenter(
    gasWarningCenter: FloorplanGasWarningCenter | FloorplanGasWarningCenterPlaceholder,
    select: boolean,
  ): void;

  abstract addSignalElement(signalElement: FloorplanSignalElement | FloorplanPlaceholder, select: boolean): void;

  abstract addPlasticSign(plasticSign: FloorplanPlasticSign | FloorplanPlaceholder, select: boolean): void;

  abstract deleteItem(floorplanItem: FloorplanItem): void;

  abstract deselect(): void;

  abstract refreshMeasurementLine(floorplanItem: FloorplanItem): void;

  abstract refreshItemText(floorplanItem: FloorplanItem): void;

  abstract refreshDisplayName(floorplanItem: FloorplanItem): void;

  abstract refreshWarningSignVisibility(
    floorplanItem:
      | FloorplanProductItem<ProductConfiguration>
      | FloorplanTransmitterPlaceholder
      | FloorplanPlaceholder
      | FloorplanGasWarningCenterPlaceholder,
    visible: boolean,
  ): void;

  abstract exportFloorplanToPngUrl(): string;

  abstract destroy(): void;
}

import { NgForOf, NgIf } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { ConfirmModalComponent } from "@components/confirm-modal/confirm-modal.component";
import { FilesOverlayComponent } from "@components/files-overlay/files-overlay.component";
import { ProjectDetailFileCardComponent } from "@components/project-cards/project-detail-file-card/project-detail-file-card.component";
import { Project } from "@domain/project/project";
import { ProjectFile } from "@domain/project/project-file";
import { ProjectService } from "@domain/project/project.service";
import { InlineMessageComponent } from "@odx/angular/components/inline-message";
import { ModalOptions, ModalService } from "@odx/angular/components/modal";
import { map, switchMap } from "rxjs";

@Component({
  selector: "app-files",
  standalone: true,
  imports: [FilesOverlayComponent, NgForOf, NgIf, ProjectDetailFileCardComponent, InlineMessageComponent],
  templateUrl: "./files.component.html",
  styleUrl: "./files.component.scss",
})
export class FilesComponent implements OnInit {
  @Input() set projectData(project: Project) {
    this.project = project;
    this.files = [];
  }

  private readonly modalService = inject(ModalService);

  project!: Project;
  files: ProjectFile[] = [];

  protected readonly MAX_FILE_SIZE = 50 * 1024 * 1024;
  protected readonly TOTAL_FILE_SIZE = Math.pow(2, 30);

  constructor(private projectService: ProjectService) {}

  ngOnInit(): void {
    if (this.project.cloudId) this.refreshProjectFiles(this.project.cloudId).subscribe();
  }

  onFileUploaded(blobs: Blob[]) {
    this.uploadFiles(blobs)
      .pipe(
        switchMap((metadata) => {
          return this.projectService.updateLocalMetadata(metadata);
        }),
        map(() => {
          if (this.project.cloudId) {
            return this.refreshProjectFiles(this.project.cloudId).subscribe();
          }
          return;
        }),
      )
      .subscribe();
  }

  onDownload(file: ProjectFile) {
    if (this.project.cloudId && file.id) {
      this.downloadFile(file);
    }
  }

  onDelete(file: ProjectFile) {
    if (this.project.cloudId && file.id) {
      this.deleteFile(this.project.cloudId, file.id);
    }
  }

  private deleteFile(cloudId: string, fileId: string) {
    const options: Partial<ModalOptions> = {
      data: {
        text: $localize`:@@modal.deleteFile:Wollen Sie das Dokument wirklich löschen?`,
      },
    };
    this.modalService
      .open(ConfirmModalComponent, options)
      .onClose$.pipe(
        switchMap(() =>
          this.projectService
            .removeFile(cloudId, fileId)
            .pipe(
              switchMap((metadata) =>
                this.projectService
                  .updateLocalMetadata(metadata)
                  .pipe(switchMap(() => (this.project.cloudId ? this.refreshProjectFiles(this.project.cloudId) : []))),
              ),
            ),
        ),
      )
      .subscribe();
  }

  private downloadFile(file: ProjectFile) {
    return this.projectService.downloadFile(file);
  }

  private uploadFiles(blobs: Blob[]) {
    return this.projectService.addFiles(blobs, this.project.cloudId);
  }

  private refreshProjectFiles(cloudId: string) {
    return this.projectService.loadFiles(cloudId).pipe(
      map((response) => {
        return (this.files = response);
      }),
    );
  }

  protected readonly Math = Math;
  protected readonly navigator = navigator;
}

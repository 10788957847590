import { Component } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { LocalizeProductTypePipe, ProductType } from "@domain/product/product";
import { Modal, ModalRef } from "@odx/angular/components/modal";

@Component({
  selector: "app-select-placeholder-modal",
  standalone: true,
  imports: [UiKitModule, FormsModule, ReactiveFormsModule, LocalizeProductTypePipe],
  templateUrl: "./select-placeholder-modal.component.html",
  styleUrl: "./select-placeholder-modal.component.scss",
})
export class SelectPlaceholderModalComponent implements Modal<ProductType[], ProductType> {
  public readonly modalRef: ModalRef<ProductType[], ProductType>;

  protected readonly ProductType = ProductType;
  protected productTypeForm: FormGroup;

  protected productTypes: ProductType[];

  constructor(formBuilder: FormBuilder, modalRef: ModalRef<ProductType[], ProductType>) {
    this.modalRef = modalRef;
    this.productTypes = modalRef.data;
    this.productTypeForm = formBuilder.group({
      productType: [this.productTypes[0], [Validators.required]],
    });
  }

  onConfirm() {
    this.modalRef.close(this.productTypeForm.value.productType);
  }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { ProjectService } from "@domain/project/project.service";
import { UserService } from "@domain/user/user.service";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectReadAccessGuard implements CanActivate {
  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userService.user$.pipe(
      map((user) => {
        if (!user.readOnlyAccess) {
          return true;
        }
        const projectId = this.projectService.getReadAccessProjectId();
        if (!projectId) {
          return false;
        }
        if (this.isProjectsRoute(route) && route.params["id"] === projectId) {
          return true;
        }
        this.router.navigate([`/projects/${projectId}`]);
        return false;
      }),
    );
  }

  private isProjectsRoute(route: ActivatedRouteSnapshot) {
    const index = route.routeConfig?.path?.indexOf("projects");
    return index != undefined && index > -1;
  }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { ProductService } from "@domain/product/product.service";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { Observable, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectResolver {
  constructor(
    private projectService: ProjectService,
    private productService: ProductService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Project | undefined> {
    return this.projectService.getById(route.params["id"]).pipe(
      tap((project) => {
        if (!project) {
          this.router.navigate([""]);
        }
        this.productService.updateFilterCountry(project!.country);
      }),
    );
  }
}

import { CommonModule } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { CloudProjectsOdxTableComponent } from "@components/cloud-projects-odx-table/cloud-projects-odx-table.component";
import { ProjectImportComponent } from "@components/project-import/project-import.component";
import { ProjectApiService } from "@domain/project/api/project-api.service";
import { LoadingSpinnerDirective } from "@odx/angular/components/loading-spinner";
import { ModalContentDirective, ModalFooterComponent, ModalHeaderComponent, ModalRef } from "@odx/angular/components/modal";
import { catchError, EMPTY, finalize, Observable, of, tap } from "rxjs";

@Component({
  selector: "app-process-invitation-modal",
  standalone: true,
  imports: [
    UiKitModule,
    ModalHeaderComponent,
    ModalContentDirective,
    ModalFooterComponent,
    CommonModule,
    CloudProjectsOdxTableComponent,
    LoadingSpinnerDirective,
  ],
  providers: [ProjectImportComponent],
  templateUrl: "./process-invitation-modal.component.html",
  styleUrl: "./process-invitation-modal.component.scss",
})
export class ProcessInvitationModalComponent implements OnInit {
  private readonly modalRef = inject(ModalRef);
  invitationResult$: Observable<HttpResponse<any>> = EMPTY;

  data = { projectId: "", invitationId: "" };
  projectName = "";
  readonly PROJECT_TO_IMPORT_KEY = "projectToImport";

  constructor(
    private router: Router,
    private apiService: ProjectApiService,
    private projectImportComponent: ProjectImportComponent,
  ) {}

  ngOnInit(): void {
    this.data = this.modalRef.data;
    if (!this.data.projectId) {
      throw new Error("cloudId is undefined");
    }
    if (!this.data.invitationId) {
      throw new Error("invitationId is undefined");
    }
    this.invitationResult$ = this.apiService.completeInvitation(this.data.projectId, this.data.invitationId).pipe(
      tap({
        next: (response) => {
          sessionStorage.setItem("projectToImport", this.data.projectId);
          this.projectName = response.body.name;
        },
        error: (err) => console.error("HTTP Error:", err),
      }),
      catchError((error) => {
        console.error("Caught Error:", error);
        return of(
          new HttpResponse({
            body: null,
            status: error.status || 500,
            statusText: "Error",
            url: error.url || "",
          }),
        );
      }),
      finalize(() => {
        console.log("Invitation completed.");
      }),
    );
  }

  async onConfirm() {
    const projectId = sessionStorage.getItem(this.PROJECT_TO_IMPORT_KEY);
    if (projectId) {
      this.projectImportComponent.importProjectAfterInvitationComplete(projectId).subscribe();
      sessionStorage.removeItem(this.PROJECT_TO_IMPORT_KEY);
    }
    await this.router.navigate([""]);
  }
}

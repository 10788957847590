import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { IconKonva } from "@project/floorplanner/konva/icon-konva";
import { MonitoringAreaKonva } from "@project/floorplanner/konva/monitoring-area-konva";
import Konva from "konva";
import { Group } from "konva/lib/Group";

export interface TransmitterKonvaShapes {
  transmitterGroup: Konva.Group;
  transmitter: Konva.Image;
  monitoringArea: Konva.Circle;
}

export class TransmitterKonva {
  static init(
    transmitter: FloorplanTransmitter | FloorplanTransmitterPlaceholder,
    icon: Konva.Image,
    warningSignIcon: Konva.Image,
  ): TransmitterKonvaShapes {
    const group = new Konva.Group({
      id: transmitter.id,
    });
    group.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, transmitter);

    const circle = MonitoringAreaKonva.init(transmitter);
    circle.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, transmitter);
    group.add(circle);

    const iconGroup = IconKonva.init(transmitter, icon, warningSignIcon, (icon) =>
      MonitoringAreaKonva.setPosition(circle, icon.x(), icon.y(), icon.width() * icon.scaleX()),
    );
    MonitoringAreaKonva.setPosition(circle, icon.x(), icon.y(), icon.width() * icon.scaleX());
    group.add(iconGroup);

    return {
      transmitterGroup: group,
      transmitter: icon,
      monitoringArea: circle,
    };
  }

  static refreshItemText(
    transmitter: FloorplanTransmitter | FloorplanTransmitterPlaceholder | FloorplanGasWarningCenterPlaceholder,
    group: Group,
  ) {
    const iconGroup = group.getChildren()[1] as Group;
    IconKonva.refreshItemText(transmitter, iconGroup);
  }

  static setMonitoringAreaVisibility(transmitterGroup: Konva.Group, visible: boolean) {
    transmitterGroup.getChildren()[0].visible(visible);
  }

  static refreshWarningSignVisibility(transmitterGroup: Group, visible: boolean) {
    const iconGroup = transmitterGroup.getChildren()[1] as Group;
    IconKonva.refreshWarningSignVisibility(iconGroup, visible);
  }
}

<ng-container *ngIf="dataToDisplay.length > 0; else noProjectsFound">
  <div>
    <table
      odxTable
      [data]="dataToDisplay"
      [headerData]="headerData"
      (sorted)="sorted($event)"
      (filtered)="filter($event)"
      aria-describedby="cloud-project-selection"
    >
      <thead>
        <th></th>
      </thead>
      <tr
        odx-table-row
        *ngFor="let tableRow of dataToDisplay"
        [ngClass]="{ selected: variant === 'import' && selected === tableRow.cloudProjectMetadata }"
        (click)="onClickRow(tableRow.cloudProjectMetadata)"
      >
        <td odx-table-cell>{{ tableRow.displayName }}</td>
        <td odx-table-cell [odxTooltip]="convertTimestampToDate(tableRow.cloudProjectMetadata.lastModifiedOn)">
          {{ tableRow.lastModifiedOn }}
        </td>
        <td *ngIf="variant === 'import'" odx-table-cell></td>
        <td *ngIf="variant === 'delete'" class="icon-column" odx-table-cell (click)="onClickIcon(tableRow.cloudProjectMetadata)">
          <odx-icon name="delete"></odx-icon>
        </td>
      </tr>
    </table>
  </div>
  <div>
    <odx-paginator
      [pageSize]="tableSize"
      [length]="total"
      [pageIndex]="pageIndex"
      (pageChanged)="pageChanged($event)"
      [getRangeContentFn]="getRangeContentFn"
      [getSummaryContentFn]="getSummaryContentFn"
    ></odx-paginator>
  </div>
</ng-container>
<ng-template #noProjectsFound>
  <span class="odx-text" i18n="@@cloudProjectsTable.noProjectsFound">Keine Projekte gefunden</span>
</ng-template>

import { Component, EventEmitter, inject, OnDestroy, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { CameraModalComponent } from "@components/image-upload-overlay/camera-modal/camera-modal.component";
import { ProjectImage } from "@domain/project/project-image";
import { UserService } from "@domain/user/user.service";
import { ModalService } from "@odx/angular/components/modal";
import { DataUrlMapperService } from "@utils/data-url-mapper.service";
import { forkJoin, map, Observable, Subscription } from "rxjs";

@Component({
  selector: "app-image-upload-overlay",
  templateUrl: "./image-upload-overlay.component.html",
  styleUrls: ["./image-upload-overlay.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class ImageUploadOverlayComponent implements OnDestroy {
  private readonly modalService = inject(ModalService);

  @Output() image$ = new EventEmitter<ProjectImage[]>();

  createImagesSubscriber: Subscription[] = [];
  protected isReadOnlyUser$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private dataUrlMapperService: DataUrlMapperService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
  }

  ngOnDestroy(): void {
    this.createImagesSubscriber.forEach((subsciption) => subsciption.unsubscribe());
  }

  onFileSelected(event: any) {
    this.createImagesSubscriber.push(
      this.createImages(event.target.files).subscribe((projectImages) => {
        event.target.value = null; // must be set to be able to upload the same file multiple times
        this.image$.emit(projectImages);
      }),
    );
  }

  openCamera() {
    const modalRef = this.modalService.open(CameraModalComponent);

    modalRef.onClose$.subscribe((imageFile: File) => {
      this.createImagesSubscriber.push(
        this.createImages([imageFile]).subscribe((projectImages) => this.image$.emit(projectImages)),
      );
    });
  }

  private createImages(files: File[]): Observable<ProjectImage[]> {
    return forkJoin(
      [...files].map((file) =>
        this.dataUrlMapperService.fileToDataURL(file).pipe(map((fileUrl) => ProjectImage.create(fileUrl, file.name))),
      ),
    );
  }
}

import { NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import {
  CableInletType,
  DoorstopType,
  FloorplanGasWarningCenterCabinet,
  LocalizeCableInletTypePipe,
  LocalizeDoorstopTypePipe,
} from "@domain/project/floorplan/floorplan-gas-warning-center-cabinet";
import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { ReadonlyController } from "@odx/angular";
import { CheckboxComponent } from "@odx/angular/components/checkbox";
import {
  FormDirective,
  FormFieldComponent,
  FormFieldControlDirective,
  FormFieldErrorDirective,
} from "@odx/angular/components/form-field";
import { SelectComponent, SelectOptionComponent } from "@odx/angular/components/select";

@Component({
  selector: "app-floorplan-gas-warning-center-detail-form",
  standalone: true,
  imports: [
    NgIf,
    CheckboxComponent,
    ReadonlyController,
    FormDirective,
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldControlDirective,
    SelectComponent,
    SelectOptionComponent,
    FormFieldErrorDirective,
    LocalizeDoorstopTypePipe,
    LocalizeCableInletTypePipe,
  ],
  templateUrl: "./floorplan-gas-warning-center-detail-form.component.html",
  styleUrl: "./floorplan-gas-warning-center-detail-form.component.scss",
})
export class FloorplanGasWarningCenterDetailFormComponent implements OnInit {
  @Input({ required: true }) gasWarningCenter!: FloorplanGasWarningCenter | FloorplanGasWarningCenterPlaceholder;
  @Input() editEnabled: boolean = false;

  gasWarningCenterCabinet?: FloorplanGasWarningCenterCabinet;
  editGasWarningCenterForm!: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.gasWarningCenter.cabinet) {
      this.gasWarningCenterCabinet = this.gasWarningCenter.cabinet;
    } else {
      this.gasWarningCenterCabinet = new FloorplanGasWarningCenterCabinet();
    }
    this.initForm();
  }

  private initForm() {
    this.editGasWarningCenterForm = this.formBuilder.group({
      maxHeight: [this.gasWarningCenterCabinet!.maxHeight],
      maxWidth: [this.gasWarningCenterCabinet!.maxWidth],
      maxDepth: [this.gasWarningCenterCabinet!.maxDepth],
      material: [this.gasWarningCenterCabinet!.material],
      doorstop: [this.gasWarningCenterCabinet!.doorstop],
      cableInlet: [this.gasWarningCenterCabinet!.cableInlet],
      outdoorInstallation: [this.gasWarningCenterCabinet!.outdoorInstallation],
      minEnvironmentalTemperature: [this.gasWarningCenterCabinet!.minEnvironmentalTemperature],
      maxEnvironmentalTemperature: [this.gasWarningCenterCabinet!.maxEnvironmentalTemperature],
      ipProtectionCategory: [this.gasWarningCenterCabinet!.ipProtectionCategory],
      notes: [this.gasWarningCenterCabinet!.notes, Validators.maxLength(2000)],
    });

    this.editGasWarningCenterForm.valueChanges.subscribe(() => {
      if (!this.editGasWarningCenterForm.valid || this.editGasWarningCenterForm.pristine) return;
      this.update();
    });
  }

  private update() {
    if (!this.gasWarningCenterCabinet) {
      this.gasWarningCenterCabinet = new FloorplanGasWarningCenterCabinet();
    }

    this.gasWarningCenterCabinet.maxHeight = this.editGasWarningCenterForm.value.maxHeight;
    this.gasWarningCenterCabinet.maxWidth = this.editGasWarningCenterForm.value.maxWidth;
    this.gasWarningCenterCabinet.maxDepth = this.editGasWarningCenterForm.value.maxDepth;
    this.gasWarningCenterCabinet.material = this.editGasWarningCenterForm.value.material;
    this.gasWarningCenterCabinet.doorstop = this.editGasWarningCenterForm.value.doorstop;
    this.gasWarningCenterCabinet.cableInlet = this.editGasWarningCenterForm.value.cableInlet;
    this.gasWarningCenterCabinet.outdoorInstallation = this.editGasWarningCenterForm.value.outdoorInstallation;
    this.gasWarningCenterCabinet.minEnvironmentalTemperature = this.editGasWarningCenterForm.value.minEnvironmentalTemperature;
    this.gasWarningCenterCabinet.maxEnvironmentalTemperature = this.editGasWarningCenterForm.value.maxEnvironmentalTemperature;
    this.gasWarningCenterCabinet.ipProtectionCategory = this.editGasWarningCenterForm.value.ipProtectionCategory;
    this.gasWarningCenterCabinet.notes = this.editGasWarningCenterForm.value.notes;

    this.gasWarningCenter.cabinet = this.gasWarningCenterCabinet;
  }

  protected readonly DoorstopType = DoorstopType;
  protected readonly CableInletType = CableInletType;
}

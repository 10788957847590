import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Expose } from "class-transformer";
import { IsEnum, IsNumber } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export enum DustExZoneType {
  ZONE_20 = "ZONE_20",
  ZONE_21 = "ZONE_21",
  ZONE_22 = "ZONE_22",
}

export class DustExZone extends FloorplanItem {
  @IsNumber()
  @Expose({ name: "width" })
  private _width: number;

  @IsNumber()
  @Expose({ name: "height" })
  private _height: number;

  @IsNumber()
  @Expose({ name: "rotation" })
  private _rotation: number;

  @IsEnum(DustExZoneType)
  public dustExZoneType: DustExZoneType;

  constructor(
    floorplan: Floorplan,
    id: string,
    exZoneType: DustExZoneType,
    x: number,
    y: number,
    width: number,
    height: number,
    rotation: number,
  ) {
    super(floorplan, id, x, y);
    this._width = width;
    this._height = height;
    this._rotation = rotation;
    this.dustExZoneType = exZoneType;
  }

  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }

  get rotation(): number {
    return this._rotation;
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addDustExZone(this.dustExZoneType, x, y, this._width, this._height, this._rotation);
  }

  override delete() {
    this._floorplan.deleteDustExZone(this);
  }

  updateForm(width: number, height: number, rotation: number, x: number, y: number) {
    this._width = width;
    this._height = height;
    this._rotation = rotation;
    this.updatePosition(x, y);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.exZones;
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshExZonesLock();
  }

  static create(
    floorplan: Floorplan,
    dustExZoneType: DustExZoneType,
    x: number,
    y: number,
    width: number,
    height: number,
    rotation: number,
  ) {
    return new DustExZone(floorplan, uuidv4(), dustExZoneType, x, y, width, height, rotation);
  }
}

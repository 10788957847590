import { Injectable, OnDestroy } from "@angular/core";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { AirPath } from "@domain/project/floorplan/air-path";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanAlarmDevice } from "@domain/project/floorplan/floorplan-alarm-device";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanPlasticSign } from "@domain/project/floorplan/floorplan-plastic-sign";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { FloorplanSignalElement } from "@domain/project/floorplan/floorplan-signal-element";
import { FloorplanState } from "@domain/project/floorplan/floorplan-state";
import { FloorplanText } from "@domain/project/floorplan/floorplan-text";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { MeasurementLine } from "@domain/project/floorplan/measurement-line";
import { Pipeline } from "@domain/project/floorplan/pipeline";
import { DangerArea } from "@domain/project/floorplan/zones/danger-area";
import { DustExZone } from "@domain/project/floorplan/zones/dust-ex-zone";
import { ExZone } from "@domain/project/floorplan/zones/ex-zone";
import { FloorplanWorkspace } from "@project/floorplanner/floorplan-workspace";
import { AirPathKonva } from "@project/floorplanner/konva/air-path-konva";
import { DangerAreaKonva } from "@project/floorplanner/konva/danger-area-konva";
import { DustExZoneKonva } from "@project/floorplanner/konva/dust-ex-zone";
import { ExZoneKonva } from "@project/floorplanner/konva/ex-zone-konva";
import { FloorplanKonva } from "@project/floorplanner/konva/floorplan-konva";
import { IconKonva } from "@project/floorplanner/konva/icon-konva";
import { ImageFactoryKonva, ImageType } from "@project/floorplanner/konva/image-factory-konva";
import { ImageKonva } from "@project/floorplanner/konva/image-konva";
import { MeasurementLineKonva } from "@project/floorplanner/konva/measurement-line-konva";
import { PipelineKonva } from "@project/floorplanner/konva/pipeline-konva";
import { TransformerKonva } from "@project/floorplanner/konva/transformer-konva";
import { TransmitterKonva } from "@project/floorplanner/konva/transmitter-konva";
import { Point } from "@utils/point";
import { ClassConstructor } from "class-transformer";
import Konva from "konva";
import { Observable, Subject, map, of, switchMap } from "rxjs";

@Injectable()
export class FloorplanWorkspaceKonvaAdapter implements FloorplanWorkspace, OnDestroy {
  static readonly OBJECT_KEY = "floorplanObject";

  private temporaryDisabledNodes: Konva.Node[] = [];

  private floorplanLayer = new Konva.Layer();
  private itemLayer = new Konva.Layer();

  private transformer = new TransformerKonva();

  private stage!: Konva.Stage;
  private floorplanShape!: Konva.Shape;
  private floorplanBorderShape!: Konva.Rect;

  private imageGroup = new Konva.Group();
  private floorplanTextGroup = new Konva.Group();
  private exZoneGroup = new Konva.Group();
  private dustExZoneGroup = new Konva.Group();
  private dangerAreaGroup = new Konva.Group();
  private measurementLineGroup = new Konva.Group();
  private airPathGroup = new Konva.Group();
  private transmitterGroup = new Konva.Group();
  private alarmDeviceGroup = new Konva.Group();
  private gasWarningCenterGroup = new Konva.Group();
  private signalElementGroup = new Konva.Group();
  private plasticSignGroup = new Konva.Group();
  private pipelineGroup = new Konva.Group();
  private monitoringAreaVisible = false;

  private selectedNode?: Konva.Node;
  private _selectedItemChanged$ = new Subject<FloorplanItem | null>();

  private lastDist: any = 0;
  private lastCenter: any = null;

  private nodesByFloorplanItem = new Map<FloorplanItem, Konva.Node>();
  private imageFactory!: ImageFactoryKonva;

  init(floorplan: Floorplan, container: HTMLDivElement): Observable<void> {
    Konva.hitOnDragEnabled = true;

    const floorplanObservable = new Observable<Konva.Shape>((observer) => {
      if (floorplan.fileUrl) {
        Konva.Image.fromURL(floorplan.fileUrl, (floorplanImage: Konva.Image) => {
          observer.next(floorplanImage);
          observer.complete();
        });
      } else {
        const emptyFloorplan = FloorplanKonva.initEmptyPlan();
        observer.next(emptyFloorplan);
        observer.complete();
      }
    });

    return floorplanObservable.pipe(
      map((floorplanShape: Konva.Shape) => this.initFloorplan(floorplan, floorplanShape, container)),
      switchMap(() => ImageFactoryKonva.create()),
      map((imageFactory) => {
        this.imageFactory = imageFactory;
      }),
      switchMap(() => of(undefined)),
    );
  }

  ngOnDestroy(): void {
    this._selectedItemChanged$.complete();
    this.stage.destroy();
    this.imageFactory?.destroy();
  }

  destroy() {
    this.ngOnDestroy();
  }

  getBorderDimensions(): { width: number; height: number } {
    return { width: this.floorplanBorderShape.width(), height: this.floorplanBorderShape.height() };
  }

  get selectedItemChanged$(): Observable<any> {
    return this._selectedItemChanged$.asObservable();
  }

  setLockState(floorplanItem: FloorplanItem) {
    const node = this.nodesByFloorplanItem.get(floorplanItem);
    if (node) {
      this.setNodeLockState(node, floorplanItem.locked);
    }
  }

  setVisibilityState(floorplanState: FloorplanState) {
    this.monitoringAreaVisible = floorplanState.monitoringAreasVisible;
    this.setGroupVisibility(this.exZoneGroup, ExZone, floorplanState.exZonesVisible);
    this.setGroupVisibility(this.dustExZoneGroup, DustExZone, floorplanState.dustExZonesVisible);
    this.setGroupVisibility(this.imageGroup, FloorplanImage, floorplanState.imagesVisible);
    this.setGroupVisibility(this.floorplanTextGroup, FloorplanText, floorplanState.floorplanTextsVisible);
    this.setGroupVisibility(this.dangerAreaGroup, DangerArea, floorplanState.dangerAreasVisible);
    this.setGroupVisibility(this.measurementLineGroup, MeasurementLine, floorplanState.measurementLinesVisible);
    this.setGroupVisibility(this.airPathGroup, AirPath, floorplanState.airPathsVisible);
    this.setGroupVisibility(this.transmitterGroup, FloorplanTransmitter, floorplanState.transmittersVisible);
    this.setGroupVisibility(this.alarmDeviceGroup, FloorplanAlarmDevice, floorplanState.alarmDevicesVisible);
    this.setGroupVisibility(this.gasWarningCenterGroup, FloorplanGasWarningCenter, floorplanState.gasWarningCentersVisible);
    this.setGroupVisibility(this.signalElementGroup, FloorplanSignalElement, floorplanState.signalElementsVisible);
    this.setGroupVisibility(this.plasticSignGroup, FloorplanPlasticSign, floorplanState.plasticSignsVisible);
    this.setGroupVisibility(this.pipelineGroup, Pipeline, floorplanState.pipelinesVisible);
    this.setMonitoringAreaVisibility(this.monitoringAreaVisible);
  }

  addExZone(exZone: ExZone, select: boolean) {
    const node = ExZoneKonva.init(exZone);
    this.initKonvaElement(node, select);
    this.addItem(exZone, node, this.exZoneGroup);
  }

  addDustExZone(dustExZone: DustExZone, select: boolean) {
    const node = DustExZoneKonva.init(dustExZone);
    this.initKonvaElement(node, select);
    this.addItem(dustExZone, node, this.dustExZoneGroup);
  }

  addDangerArea(dangerArea: DangerArea, select: boolean): void {
    const node = DangerAreaKonva.init(dangerArea);
    this.initKonvaElement(node, select);
    this.addItem(dangerArea, node, this.dangerAreaGroup);
  }

  addImage(floorplanImage: FloorplanImage, select: boolean): Observable<void> {
    return new Observable((subscriber) => {
      Konva.Image.fromURL(floorplanImage.projectImage.fileUrl, (img: any) => {
        if (!img) {
          subscriber.error(new Error("Failed to load image from URL"));
          return;
        }
        const imgGroup = ImageKonva.init(floorplanImage, img);
        this.initKonvaElement(img, select);
        imgGroup.on("dragend", () => this.updateAfterDrag(imgGroup));
        this.addItem(floorplanImage, imgGroup, this.imageGroup);
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  addMeasurementLine(measurementLine: MeasurementLine, select: boolean): void {
    const node = MeasurementLineKonva.init(measurementLine);
    this.initKonvaElement(node, select);
    this.addItem(measurementLine, node, this.measurementLineGroup);
  }

  addText(text: FloorplanText, select: boolean): void {
    this.initIconElement(text, this.imageFactory.createImage(ImageType.TEXT_ICON), this.floorplanTextGroup, select);
  }

  addAirPath(airPath: AirPath, select: boolean): void {
    const node = AirPathKonva.init(airPath);
    this.initKonvaElement(node, select);
    this.addItem(airPath, node, this.airPathGroup);
  }

  addPipeline(pipeline: Pipeline, select: boolean): void {
    const node = PipelineKonva.init(pipeline);
    this.initKonvaElement(node, select);
    node.on("transformend", () => {
      this.transformer.reset(node); // reset transformers so that it fits to positionId
    });
    this.pipelineGroup.add(node);
    this.addItem(pipeline, node, this.pipelineGroup);
  }

  addTransmitter(transmitter: FloorplanTransmitter | FloorplanTransmitterPlaceholder, select: boolean): void {
    const icon = this.imageFactory.createImage(
      transmitter instanceof FloorplanTransmitter ? ImageType.TRANSMITTER_ICON : ImageType.TRANSMITTER_PLACEHOLDER_ICON,
    );
    const warningSignIcon = this.imageFactory.createImage(ImageType.WARNING_SIGN_ICON);
    const shapes = TransmitterKonva.init(transmitter, icon, warningSignIcon);
    this.initKonvaElement(shapes.transmitter, select);
    this.initKonvaElement(shapes.monitoringArea);
    TransmitterKonva.setMonitoringAreaVisibility(shapes.transmitterGroup, this.monitoringAreaVisible);
    this.addItem(transmitter, shapes.transmitterGroup, this.transmitterGroup);
  }

  addAlarmDevice(alarmDevice: FloorplanAlarmDevice | FloorplanPlaceholder, select: boolean): void {
    const icon = this.imageFactory.createImage(
      alarmDevice instanceof FloorplanAlarmDevice ? ImageType.ALARM_DEVICE_ICON : ImageType.ALARM_DEVICE_PLACEHOLDER_ICON,
    );
    this.initIconElement(alarmDevice, icon, this.alarmDeviceGroup, select);
  }

  addGasWarningCenter(gasWarningCenter: FloorplanGasWarningCenterPlaceholder | FloorplanGasWarningCenter, select: boolean): void {
    const icon = this.imageFactory.createImage(
      gasWarningCenter instanceof FloorplanGasWarningCenter
        ? ImageType.GAS_WARNING_CENTER_ICON
        : ImageType.GAS_WARNING_CENTER_PLACEHOLDER_ICON,
    );

    this.initIconElement(gasWarningCenter, icon, this.gasWarningCenterGroup, select);
  }

  addSignalElement(signalElement: FloorplanPlaceholder | FloorplanSignalElement, select: boolean): void {
    const icon = this.imageFactory.createImage(
      signalElement instanceof FloorplanSignalElement ? ImageType.SIGNAL_ELEMENT_ICON : ImageType.SIGNAL_ELEMENT_PLACEHOLDER_ICON,
    );
    this.initIconElement(signalElement, icon, this.signalElementGroup, select);
  }

  addPlasticSign(plasticSign: FloorplanPlaceholder | FloorplanPlasticSign, select: boolean): void {
    const icon = this.imageFactory.createImage(
      plasticSign instanceof FloorplanPlasticSign ? ImageType.PLASTIC_SIGN_ICON : ImageType.PLASTIC_SIGN_PLACEHOLDER_ICON,
    );
    this.initIconElement(plasticSign, icon, this.plasticSignGroup, select);
  }

  deleteItem(floorplanItem: FloorplanItem) {
    const node = this.nodesByFloorplanItem.get(floorplanItem);
    if (node) {
      node.destroy();
      if (this.selectedNode?.getAttr("floorplanObject") === floorplanItem) {
        this.deselect();
      }
      this.nodesByFloorplanItem.delete(floorplanItem);
    }
  }

  refreshMeasurementLine(measurementLine: MeasurementLine): void {
    const group = this.nodesByFloorplanItem.get(measurementLine) as Konva.Group;
    MeasurementLineKonva.refreshText(measurementLine, group);
  }

  refreshDisplayName(floorplanItem: FloorplanItem) {
    this.refreshItemText(floorplanItem);
  }

  refreshItemText(floorplanItem: FloorplanItem): void {
    const node = this.nodesByFloorplanItem.get(floorplanItem);
    if (!node) {
      return;
    }

    if (floorplanItem instanceof ExZone) {
      ExZoneKonva.refreshItemText(floorplanItem, node as Konva.Group);
    } else if (floorplanItem instanceof DustExZone) {
      DustExZoneKonva.refreshItemText(floorplanItem, node as Konva.Group);
    } else if (floorplanItem instanceof DangerArea) {
      DangerAreaKonva.refreshItemText(floorplanItem, node as Konva.Group);
    } else if (floorplanItem instanceof MeasurementLine) {
      MeasurementLineKonva.refreshItemText(floorplanItem, node as Konva.Group);
    } else if (floorplanItem instanceof AirPath) {
      AirPathKonva.refreshItemText(floorplanItem, node as Konva.Group);
    } else if (floorplanItem instanceof Pipeline) {
      PipelineKonva.refreshItemText(floorplanItem, node as Konva.Group);
    } else if (floorplanItem instanceof FloorplanImage) {
      ImageKonva.refreshItemText(floorplanItem, node as Konva.Group);
    } else if (floorplanItem instanceof FloorplanTransmitter || floorplanItem instanceof FloorplanTransmitterPlaceholder) {
      TransmitterKonva.refreshItemText(floorplanItem, node as Konva.Group);
    } else if (
      floorplanItem instanceof FloorplanProductItem ||
      floorplanItem instanceof FloorplanPlaceholder ||
      floorplanItem instanceof FloorplanGasWarningCenterPlaceholder ||
      floorplanItem instanceof FloorplanText
    ) {
      IconKonva.refreshItemText(floorplanItem, node as Konva.Group);
    }
  }

  deselect(): void {
    this.selectedNode = undefined;
    this._selectedItemChanged$.next(null);
    this.transformer.clear();
  }

  getPointerPosition(): Point {
    return this.floorplanShape.getRelativePointerPosition()!;
  }

  exportFloorplanToPngUrl(): string {
    const { scaleX, scaleY, stageHeight, stageWidth, stageX, stageY, dataURL } = this.setStageToInitialSizeForExport();
    this.resetStageAfterExport(scaleX, scaleY, stageHeight, stageWidth, stageX, stageY);
    return dataURL;
  }

  getStageDimensions(): { width: number; height: number } {
    return { width: this.stage.width(), height: this.stage.height() };
  }

  getCenterPoint(): Point {
    const centerX = this.stage.container().offsetWidth / this.stage.scaleX() / 2 - this.stage.x() / this.stage.scaleX();
    const centerY = this.stage.container().offsetHeight / this.stage.scaleY() / 2 - this.stage.y() / this.stage.scaleY();

    return new Point(centerX, centerY);
  }

  setScale(factor: number) {
    this.stage.scale({ x: factor, y: factor });
  }

  setStagePosition(point: Point) {
    this.stage.position(point);
  }

  refreshWarningSignVisibility(
    floorplanItem: FloorplanProductItem<ProductConfiguration> | FloorplanTransmitterPlaceholder | FloorplanPlaceholder,
    visible: boolean,
  ) {
    const node = this.nodesByFloorplanItem.get(floorplanItem);

    if (!node || !(node instanceof Konva.Group)) {
      return;
    }

    if (floorplanItem instanceof FloorplanTransmitter || floorplanItem instanceof FloorplanTransmitterPlaceholder) {
      TransmitterKonva.refreshWarningSignVisibility(node, visible);
    } else {
      IconKonva.refreshWarningSignVisibility(node, visible);
    }
  }

  getNode(floorplanItem: FloorplanItem) {
    return this.nodesByFloorplanItem.get(floorplanItem);
  }

  private setMonitoringAreaVisibility(visible: boolean): boolean {
    this.transmitterGroup
      .getChildren()
      .forEach((transmitter) => TransmitterKonva.setMonitoringAreaVisibility(transmitter as Konva.Group, visible));
    return visible;
  }

  private initFloorplan(floorplan: Floorplan, floorplanShape: Konva.Shape, container: HTMLDivElement) {
    this.floorplanBorderShape = FloorplanKonva.initBorder(floorplanShape);
    floorplan.floorplanState.updateBorderShape(this.getBorderDimensions());
    FloorplanKonva.setFloorplanInCenterOfBorder(floorplanShape, this.floorplanBorderShape);
    this.floorplanShape = floorplanShape;

    this.initStage(floorplan.floorplanState, container);
    this.initResizeHandler();
    this.initStageTapHandler();
    this.initStageDragHandler(floorplan.floorplanState);
    this.initStageWheelHandler(floorplan.floorplanState);
    this.initStageTouchHandler(floorplan.floorplanState);

    this.initFloorplanLayer(floorplanShape, this.floorplanBorderShape);
    this.initItemLayer(floorplan.floorplanState);
  }

  private initStage(floorplanState: FloorplanState, container: HTMLDivElement) {
    this.stage = new Konva.Stage({
      container: container,
      scaleX: floorplanState.scale,
      scaleY: floorplanState.scale,
      x: floorplanState.x,
      y: floorplanState.y,
      width: container.clientWidth,
      height: container.clientHeight,
      draggable: true,
    });
  }

  private initFloorplanLayer(floorplanShape: Konva.Shape, floorplanBorderShape: Konva.Rect) {
    this.floorplanLayer.add(floorplanBorderShape);
    this.floorplanLayer.add(floorplanShape);
    this.stage.add(this.floorplanLayer);
  }

  private resetStageAfterExport(
    scaleX: number,
    scaleY: number,
    stageHeight: number,
    stageWidth: number,
    stageX: number,
    stageY: number,
  ) {
    this.stage.scaleX(scaleX);
    this.stage.scaleY(scaleY);
    this.stage.height(stageHeight);
    this.stage.width(stageWidth);
    this.stage.x(stageX);
    this.stage.y(stageY);
  }

  private setStageToInitialSizeForExport() {
    this.deselect();
    const { width: exportWidth, height: exportHeight } = this.getBorderDimensions();

    // The Stage must be set on initial and full width and height, to render all elements correct.
    const scaleX = this.stage.scaleX();
    const scaleY = this.stage.scaleY();
    const stageHeight = this.stage.height();
    const stageWidth = this.stage.width();
    const stageX = this.stage.x();
    const stageY = this.stage.y();

    this.stage.scaleX(1);
    this.stage.scaleY(1);
    this.stage.height(exportHeight);
    this.stage.width(exportWidth);
    this.stage.x(0);
    this.stage.y(0);

    // prevent export from showing inner half of border
    this.floorplanBorderShape.strokeEnabled(false);

    // cover edges of stage which cause black border on export to dataUrl
    this.floorplanBorderShape.fill("#ffffff");
    this.floorplanBorderShape.fillEnabled(true);

    const dataURL = this.stage.toDataURL({
      pixelRatio: 1,
      x: this.floorplanBorderShape.x(),
      y: this.floorplanBorderShape.y(),
      width: exportWidth,
      height: exportHeight,
    });

    this.floorplanBorderShape.strokeEnabled(true);
    this.floorplanBorderShape.fillEnabled(false);

    return { scaleX, scaleY, stageHeight, stageWidth, stageX, stageY, dataURL };
  }

  private setGroupVisibility<T>(group: Konva.Group, cls: ClassConstructor<T>, visible: boolean): void {
    if (group.visible() === visible) {
      return;
    }

    group.visible(visible);
    if (!visible && this.selectedNode?.getAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY) instanceof cls) {
      this.transformer.clear();
    }
  }

  private setNodeLockState(element: Konva.Node, locked: boolean): void {
    if (!element) return;

    if (element.getAttrs().draggable !== undefined) {
      element.setAttr("draggable", !locked);
    }

    if (this.selectedNode === element) {
      this.transformer.reset(this.selectedNode);
    }

    if (element instanceof Konva.Group) {
      element.getChildren().forEach((child) => this.setNodeLockState(child, locked));
    }
  }

  private initItemLayer(floorplanState: FloorplanState) {
    // Sets the order of layers, bottom to top.
    this.itemLayer.add(this.exZoneGroup);
    this.itemLayer.add(this.dustExZoneGroup);
    this.itemLayer.add(this.dangerAreaGroup);
    this.itemLayer.add(this.imageGroup);
    this.itemLayer.add(this.pipelineGroup);
    this.itemLayer.add(this.transmitterGroup);
    this.itemLayer.add(this.gasWarningCenterGroup);
    this.itemLayer.add(this.alarmDeviceGroup);
    this.itemLayer.add(this.signalElementGroup);
    this.itemLayer.add(this.plasticSignGroup);
    this.itemLayer.add(this.measurementLineGroup);
    this.itemLayer.add(this.airPathGroup);
    this.itemLayer.add(this.floorplanTextGroup);

    this.setVisibilityState(floorplanState);

    this.transformer.transformers.forEach((transformer) => this.itemLayer.add(transformer));

    this.stage.add(this.itemLayer);
  }

  private initIconElement(
    floorplanItem:
      | FloorplanPlaceholder
      | FloorplanTransmitterPlaceholder
      | FloorplanProductItem<ProductConfiguration>
      | FloorplanText
      | FloorplanGasWarningCenterPlaceholder,
    icon: Konva.Image,
    group: Konva.Group,
    select: boolean = false,
  ) {
    const node = IconKonva.init(floorplanItem, icon, this.imageFactory.createImage(ImageType.WARNING_SIGN_ICON));
    this.initKonvaElement(icon, select);
    this.addItem(floorplanItem, node, group);
  }

  private initKonvaElement(node: Konva.Node, select: boolean = false) {
    node.on("mouseenter", () => (this.stage.container().style.cursor = "grab"));
    node.on("mouseleave", () => (this.stage.container().style.cursor = "default"));
    node.on("mousedown", () => (this.stage.container().style.cursor = "grabbing"));
    node.on("mouseup", () => (this.stage.container().style.cursor = "grab"));
    node.on("click tap", () => this.selectNode(node));
    node.on("dragend", () => this.updateAfterDrag(node));

    if (select) {
      this.selectNode(node);
    }
  }

  private initStageTouchHandler(floorplanState: FloorplanState) {
    //pinch and zoom gestures is a slightly different implementation since we need to find the
    //point between the two fingers and scale accordingly
    this.stage.on("touchmove", (event) => {
      event.evt.preventDefault();
      const touch1 = event.evt.touches[0];
      const touch2 = event.evt.touches[1];

      if (touch1 && touch2) {
        // if the stage was under Konva's drag&drop
        // we need to stop it, and implement our own pan logic with two pointers
        // this drag refers to the whole stage
        if (this.stage.isDragging()) {
          this.stage.stopDrag();
        }
        // this makes all node of the layer non-draggable temporarily
        this.temporaryDisabledNodes = this.itemLayer.children
          .filter((node) => node.draggable())
          .map((node) => node.draggable(false));

        //deselect all shapes to prevent moving while pinching/zooming, which could also have side effects
        this.transformer.clear();

        const containerPos = this.stage.container().getBoundingClientRect();

        const p1 = new Point(touch1.clientX - containerPos.x, touch1.clientY - containerPos.y);
        const p2 = new Point(touch2.clientX - containerPos.x, touch2.clientY - containerPos.y);

        if (!this.lastCenter) {
          this.lastCenter = this.getCenter(p1, p2);
          return;
        }
        const newCenter = this.getCenter(p1, p2);

        const dist = this.getDistance(p1, p2);
        if (!this.lastDist) {
          this.lastDist = dist;
        }
        const scale = this.stage.scaleX() * (dist / this.lastDist);

        if (scale > 5 || scale < 0.2) {
          return;
        }
        // local coordinates of center point
        const pointTo = new Point(
          (newCenter.x - this.stage.x()) / this.stage.scaleX(),
          (newCenter.y - this.stage.y()) / this.stage.scaleX(),
        );

        this.stage.scaleX(scale);
        this.stage.scaleY(scale);

        // calculate new position of the stage
        const dx = newCenter.x - this.lastCenter.x;
        const dy = newCenter.y - this.lastCenter.y;

        const newPos = new Point(newCenter.x - pointTo.x * scale + dx, newCenter.y - pointTo.y * scale + dy);

        this.stage.position(newPos);

        this.lastDist = dist;
        this.lastCenter = newCenter;
      }

      floorplanState.updateScaleAndPosition(this.stage.scaleX(), this.stage.x(), this.stage.y());
    });
    this.stage.on("touchend", () => {
      this.lastDist = 0;
      this.lastCenter = null;
      // now we need to re-enable KonvaJS drag&drop
      this.temporaryDisabledNodes.map((node) => node.draggable(true));
    });
  }

  private initStageWheelHandler(floorplanState: FloorplanState) {
    //wheel is mapped to multitouch trackpads e.g. on the MacBook pro, so we can use it to zoom
    this.stage.on("wheel", (e) => {
      // stop default scrolling
      e.evt.preventDefault();
      if (this.stage.isDragging()) {
        this.stage.stopDrag();
      }

      const zoomIn = e.evt.deltaY < 0; // how to scale? Zoom in? Or zoom out?

      let referencePoint = new Point(0, 0);
      const pointer = this.stage.getPointerPosition();
      if (pointer) referencePoint = new Point(pointer.x, pointer.y);

      if (zoomIn) {
        floorplanState.zoomIn(referencePoint);
      } else {
        floorplanState.zoomOut(referencePoint);
      }
    });
  }

  private initStageDragHandler(floorplanState: FloorplanState) {
    this.stage.on("dragend", (e) => {
      if (e.target === this.stage) {
        floorplanState.updatePosition(this.stage.x(), this.stage.y());
      }
    });
  }

  private initStageTapHandler() {
    this.stage.on("click tap", (e) => {
      if (e.target === this.stage || e.target === this.floorplanShape) {
        this.deselect();
        return;
      }
    });
  }

  private initResizeHandler() {
    window.addEventListener("resize", () => {
      this.stage.width(this.stage.container().clientWidth);
      this.stage.height(this.stage.container().clientHeight);
    });
  }

  private getCenter(p1: any, p2: any) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  private getDistance(p1: Point, p2: Point) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  private updateAfterDrag(node: Konva.Node) {
    const floorplanItem = node.getAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY) as FloorplanItem;
    floorplanItem.updatePosition(node.x(), node.y());
  }

  private selectNode(node: Konva.Node) {
    this.selectedNode = node;
    this._selectedItemChanged$.next(node.getAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY));
    this.transformer.reset(node);
  }

  private addItem(floorplanItem: FloorplanItem, node: Konva.Shape | Konva.Group, group: Konva.Group) {
    group.add(node);
    this.nodesByFloorplanItem.set(floorplanItem, node);
  }
}

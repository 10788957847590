import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { UserService } from "@domain/user/user.service";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-checklist-item",
  templateUrl: "./checklist-item.component.html",
  styleUrls: ["./checklist-item.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule],
})
export class ChecklistItemComponent {
  protected isReadOnlyUser$: Observable<boolean>;

  @Input()
  label!: string;

  @Input()
  groupName!: string;

  constructor(private userService: UserService) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
  }
}

import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from "@app/auth.service";
import { MasterDataInitStatus, MasterDataService } from "@app/master-data.service";
import { DeviceSizeValidationService } from "@domain/device-validation/device-size-validation.service";
import { UserService } from "@domain/user/user.service";
import { Observable, filter, first, map, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppGuard implements CanActivate {
  constructor(
    private deviceSizeValidationService: DeviceSizeValidationService,
    private authService: AuthService,
    private userService: UserService,
    private masterDataService: MasterDataService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.deviceSizeValidationService.showHint().pipe(
      switchMap(() => this.authService.userInfo$),
      switchMap(() => this.userService.user$),
      switchMap(() => this.masterDataService.initStatus$),
      filter((initStatus: MasterDataInitStatus) => initStatus === MasterDataInitStatus.SUCCESS),
      first(),
      map(() => true),
    );
  }
}

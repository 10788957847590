<app-floorplan-product-menu
  [title]="tooltipTextPlasticSigns"
  [hasConfigurations]="floorplan.project.plasticSigns.length > 0"
  [visible]="floorplan.floorplanState.plasticSignsVisible"
  [locked]="floorplan.floorplanState.plasticSignsLocked"
  (visibilityUpdate)="floorplan.floorplanState.plasticSignsVisible = $event"
  (lockUpdate)="floorplan.floorplanState.plasticSignsLocked = $event"
  (addPlaceholderToFloorplan)="addPlaceholder()"
  configPath="plastic-sign"
>
  <div *ngFor="let plasticSign of floorplan.project.plasticSigns">
    <hr />
    <app-configured-product
      [productConfiguration]="plasticSign"
      [numberOfFloorplanItems]="floorplan.countPlacedPlasticSigns(plasticSign)"
      (add)="addPlasticSign(plasticSign)"
      (delete)="deletePlasticSign(plasticSign)"
      [subtitlePrefix]="
        plasticSign.text && plasticSign.textColor && plasticSign.backgroundColor
          ? plasticSign.text + ', ' + plasticSign.textColor + ', ' + plasticSign.backgroundColor
          : ''
      "
      editPath="plastic-sign"
      imageUrl="./assets/kunststoffschild.svg"
    >
    </app-configured-product>
  </div>
</app-floorplan-product-menu>

import { DBConfig } from "ngx-indexed-db";

const storeNameProducts = "products";
const storeNameProjects = "projects";
const storeNameMetadata = "metadata";
const storeNameGases = "gases";
const storeNameLastProductUpdate = "lastProductUpdate";

export const INDEXED_DB_CONFIG: DBConfig = {
  name: "Navinta",
  version: 4,
  objectStoresMeta: [
    {
      store: storeNameProducts,
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [{ name: "name", keypath: "name", options: { unique: false } }],
    },
    {
      store: storeNameProjects,
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [{ name: "name", keypath: "name", options: { unique: false } }],
    },
    {
      store: storeNameMetadata,
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: storeNameGases,
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: storeNameLastProductUpdate,
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [],
    },
  ],
};

export const STORE_NAME_PRODUCTS = storeNameProducts;
export const STORE_NAME_PROJECTS = storeNameProjects;
export const STORE_NAME_METADATA = storeNameMetadata;
export const STORE_NAME_GASES = storeNameGases;
export const STORE_NAME_LAST_PRODUCT_UPDATE = storeNameLastProductUpdate;

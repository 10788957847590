<div odxlayout="flex gap-none" class="flex-column w-100 container">
  <div odxLayout="flex" class="space-between">
    <h6 class="odx-title-6">{{ menuType | localizeFloorplanMenuType }}</h6>
    <button odxButton class="background-transparent flex-0" (click)="onClose()">
      <odx-icon name="close"></odx-icon>
    </button>
  </div>

  <hr class="w-100" />

  <ng-container [ngSwitch]="menuType">
    <app-floorplan-main-menu *ngSwitchCase="FloorplanMenuType.MAIN_SETTINGS" [activeFloorplan]="activeFloorplan">
    </app-floorplan-main-menu>
    <app-floorplan-product-search-menu *ngSwitchCase="FloorplanMenuType.PRODUCT_SEARCH"> </app-floorplan-product-search-menu>
    <app-floorplan-zone-menu *ngSwitchCase="FloorplanMenuType.ZONE"></app-floorplan-zone-menu>
    <app-floorplan-gas-warning-center-menu
      *ngSwitchCase="FloorplanMenuType.GAS_WARNING_CENTER"
    ></app-floorplan-gas-warning-center-menu>
    <app-floorplan-transmitter-menu *ngSwitchCase="FloorplanMenuType.TRANSMITTER"></app-floorplan-transmitter-menu>
    <app-floorplan-signal-menu *ngSwitchCase="FloorplanMenuType.SIGNAL_ELEMENT"></app-floorplan-signal-menu>
    <app-floorplan-plastic-sign-menu *ngSwitchCase="FloorplanMenuType.PLASTIC_SIGN"></app-floorplan-plastic-sign-menu>
    <app-floorplan-alarm-device-menu *ngSwitchCase="FloorplanMenuType.ALARM_DEVICE"></app-floorplan-alarm-device-menu>
    <app-floorplan-image-menu *ngSwitchCase="FloorplanMenuType.IMAGE"></app-floorplan-image-menu>
    <app-floorplan-pipeline-menu *ngSwitchCase="FloorplanMenuType.PIPELINE"></app-floorplan-pipeline-menu>
    <app-floorplan-air-path-menu *ngSwitchCase="FloorplanMenuType.AIR_PATH"></app-floorplan-air-path-menu>
    <app-floorplan-notes-menu *ngSwitchCase="FloorplanMenuType.TEXT"></app-floorplan-notes-menu>
    <app-floorplan-measurement-line-menu *ngSwitchCase="FloorplanMenuType.MEASUREMENT_LINE"></app-floorplan-measurement-line-menu>
  </ng-container>
</div>

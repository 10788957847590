<div *ngIf="!editEnabled; then card; else contentBox"></div>

<ng-template #card>
  <odx-card class="container" (interact)="onClick()" variant="launch-tile-centered">
    <odx-menu *ngIf="(isReadOnlyUser$ | async) === false">
      <button *ngIf="fileType" odxButton odxMenuItem (click)="$event.stopPropagation(); onDownload()">
        <odx-icon name="download" alignleft></odx-icon>
        <span i18n="@@global.download">Download</span>
      </button>
      <button *ngIf="!fileType" odxButton odxMenuItem (click)="$event.stopPropagation(); onEdit()">
        <odx-icon name="edit" alignleft></odx-icon>
        <span i18n="@@global.edit">Bearbeiten</span>
      </button>
      <button odxButton odxMenuItem (click)="$event.stopPropagation(); onDelete()">
        <odx-icon name="delete" alignleft></odx-icon>
        <span i18n="@@global.delete">Löschen</span>
      </button>
    </odx-menu>
    <div odxLayout="flex" class="content flex-column">
      <h6 class="odx-title-6 card-title">{{ fileName }}</h6>
      <!-- prettier-ignore-attribute style -->
      <div class="file-wrapper" style="background-image: url({{fileUrl}});">
        <div *ngIf="!fileUrl && !fileType" class="file-placeholder h-100" i18n="@@projectDetails.emptyFloorplanName">
          Leerer Grundrissplan
        </div>
        <div *ngIf="fileType" class="icon-container"><odx-icon size="xlarge" [name]="setIcon()"></odx-icon></div>
      </div>
    </div>
  </odx-card>
</ng-template>

<ng-template #contentBox>
  <odx-content-box class="file-content-box">
    <odx-area-header>
      <h6 class="odx-title-6 card-title">{{ fileName }}</h6>
      <odx-area-header-content *ngIf="(isReadOnlyUser$ | async) === false">
        <odx-action-group>
          <button odxButton (click)="onDelete()">
            <odx-icon name="delete"></odx-icon>
          </button>
        </odx-action-group>
      </odx-area-header-content>
    </odx-area-header>
    <!-- prettier-ignore-attribute style -->
    <div class="file-wrapper" style="background-image: url({{fileUrl}});">
      <div *ngIf="!fileUrl" class="file-placeholder h-100" i18n="@@projectDetails.emptyFloorplanName">Leerer Grundrissplan</div>
      <div *ngIf="fileType" class="icon-container"><odx-icon size="xlarge" [name]="setIcon()"></odx-icon></div>
    </div>
    <form odxForm [formGroup]="editForm" class="form-content">
      <odx-form-field i18n-label="@@projectDetails.emptyFloorplanNameForm.label" label="Name" odxLayout="6">
        <input
          formControlName="name"
          odxFormFieldControl
          placeholder="Name"
          i18n-placeholder="@@projectDetails.emptyFloorplanNameForm.placeholder"
          data-testid="input.floorplanName"
        />
      </odx-form-field>
      <button
        odxButton
        variant="primary"
        type="submit"
        class="save-button"
        (click)="$event.stopPropagation(); onSubmit()"
        i18n="@@global.save"
      >
        Speichern
      </button>
    </form>
  </odx-content-box>
</ng-template>

import { Component, Input, OnDestroy, Output } from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { FilterProductComponent } from "@components/filter-product/filter-product.component";
import { Product, ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { Project } from "@domain/project/project";
import { untilDestroyed } from "@odx/angular/utils";
import { GasWarningCenterCardComponent } from "@project/gas-warning-center-configuration/gas-warning-center-card/gas-warning-center-card.component";
import { GasWarningCenterType } from "@project/gas-warning-center-configuration/gas-warning-center-type";
import { BehaviorSubject, combineLatest, defer, map, of, ReplaySubject } from "rxjs";

@Component({
  selector: "app-gas-warning-center-selector",
  templateUrl: "./gas-warning-center-selector.component.html",
  styleUrls: ["./gas-warning-center-selector.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, FilterProductComponent, GasWarningCenterCardComponent],
})
export class GasWarningCenterSelectorComponent implements OnDestroy {
  @Input({ required: true }) project!: Project;

  @Input({ required: true }) set type(type: GasWarningCenterType | undefined) {
    this.gasWarningCenterType = type;
    this.gasWarningCenterForm.reset();
    if (this.gasWarningCenterType) {
      this.collectGasWarningCenters(this.gasWarningCenterType);
    }
  }

  @Input() set originalGasWarningCenter(originalGasWarningCenter: Product | undefined) {
    if (originalGasWarningCenter && originalGasWarningCenter.isAvailable) {
      this.select(originalGasWarningCenter);
    }
  }

  @Output() gasWarningCenterSelect$ = defer(() =>
    this.gasWarningCenterForm.valueChanges.pipe(
      this.takeUntilDestroyed(),
      map((formValue) => formValue.selectedGasWarningCenter!),
    ),
  );

  protected gasWarningCenterForm = this.formBuilder.group({
    selectedGasWarningCenter: new FormControl<null | Product>(null, Validators.required),
  });

  @Output()
  formReady = of(this.gasWarningCenterForm);

  protected gasWarningCenterType: GasWarningCenterType | undefined;
  protected readonly filteredGasWarningCenters$ = new ReplaySubject<Product[]>(1);

  private readonly gasWarningCenters$ = new ReplaySubject<Product[]>(1);
  private readonly takeUntilDestroyed = untilDestroyed();
  private readonly searchValue$ = new BehaviorSubject<string | null>(null);

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
  ) {
    combineLatest([this.searchValue$, this.gasWarningCenters$]).subscribe(([searchValue, gasWarningCenters]) => {
      if (searchValue) {
        gasWarningCenters = gasWarningCenters.filter((product) => this.productService.searchFilter(product, searchValue));
      }
      this.filteredGasWarningCenters$.next(gasWarningCenters);
    });
  }

  ngOnDestroy() {
    this.gasWarningCenters$.complete();
    this.filteredGasWarningCenters$.complete();
    this.searchValue$.complete();
  }

  protected search(value: string) {
    this.searchValue$.next(value);
  }

  protected select(gasWarningCenter: Product) {
    this.gasWarningCenterForm.patchValue({
      selectedGasWarningCenter: gasWarningCenter,
    });
  }

  private collectGasWarningCenters(type: GasWarningCenterType) {
    this.productService
      .getAvailableProducts(ProductType.GASWARNINGCENTER)
      .pipe(
        map((products) => {
          if (type === null) {
            return products;
          }
          return products.filter((product) => type.isMatchingProduct(product.getLocalizedName("de-DE")));
        }),
      )
      .subscribe((gasWarningCenters) => this.gasWarningCenters$.next(gasWarningCenters));
  }
}

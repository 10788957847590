<form odxForm [formGroup]="form">
  <odx-chip-list>
    <odx-chip-list-row>
      @for (mail of addedMails; track $index) {
        <odx-chip [removable]="true" [size]="'small'" [variant]="'highlight'" (remove)="removeChipFromList(mail)">
          {{ mail }}
        </odx-chip>
      }
    </odx-chip-list-row>
  </odx-chip-list>
  <odx-form-field label="E-Mail-Adresse" i18n-label="@@modal.invitePerson.form.mail.label">
    <input
      odxFormFieldControl
      formControlName="value"
      i18n-placeholder="@@modal.invitePerson.form.mail.placeholder"
      placeholder="E-Mail"
      (keyup.enter)="addChipToList()"
    />
    <button
      class="add-button"
      odxButton
      variant="primary"
      (click)="addChipToList()"
      i18n="@@modal.invitePerson.add"
      [odxTooltip]="tooltipAddEmail"
    >
      <odx-icon name="plus"></odx-icon>
    </button>

    <ng-template odxFormFieldError="pattern">
      <ng-container i18n="@@modal.invitePerson.errorMessage.invalidEmail">Bitte eine korrekte E-Mail eintragen</ng-container>
    </ng-template>
  </odx-form-field>
</form>

import { Pipe, PipeTransform } from "@angular/core";
import { Expose } from "class-transformer";
import { IsBoolean, IsOptional, IsString } from "class-validator";

export class FloorplanGasWarningCenterCabinet {
  @IsString()
  @IsOptional()
  @Expose({ name: "maxHeight" })
  private _maxHeight?: string;

  @IsString()
  @IsOptional()
  @Expose({ name: "maxWidth" })
  private _maxWidth?: string;

  @IsString()
  @IsOptional()
  @Expose({ name: "maxDepth" })
  private _maxDepth?: string;

  @IsString()
  @IsOptional()
  @Expose({ name: "material" })
  private _material?: string;

  @IsString()
  @IsOptional()
  @Expose({ name: "doorstop" })
  private _doorstop?: DoorstopType;

  @IsString()
  @IsOptional()
  @Expose({ name: "cableInlet" })
  private _cableInlet?: CableInletType;

  @IsBoolean()
  @IsOptional()
  @Expose({ name: "outdoorInstallation" })
  private _outdoorInstallation?: boolean;

  @IsString()
  @IsOptional()
  @Expose({ name: "minEnvironmentalTemperature" })
  private _minEnvironmentalTemperature?: string;

  @IsString()
  @IsOptional()
  @Expose({ name: "maxEnvironmentalTemperature" })
  private _maxEnvironmentalTemperature?: string;

  @IsString()
  @IsOptional()
  @Expose({ name: "ipProtectionCategory" })
  private _ipProtectionCategory?: string;

  @IsString()
  @IsOptional()
  @Expose({ name: "notes" })
  private _notes?: string;

  get maxHeight(): string | undefined {
    return this._maxHeight;
  }

  set maxHeight(value: string) {
    this._maxHeight = value;
  }

  get maxWidth(): string | undefined {
    return this._maxWidth;
  }

  set maxWidth(value: string) {
    this._maxWidth = value;
  }

  get maxDepth(): string | undefined {
    return this._maxDepth;
  }

  set maxDepth(value: string) {
    this._maxDepth = value;
  }

  get material(): string | undefined {
    return this._material;
  }

  set material(value: string) {
    this._material = value;
  }

  get doorstop(): DoorstopType | undefined {
    return this._doorstop;
  }

  set doorstop(value: DoorstopType) {
    this._doorstop = value;
  }

  get cableInlet(): CableInletType | undefined {
    return this._cableInlet;
  }

  set cableInlet(value: CableInletType) {
    this._cableInlet = value;
  }

  get outdoorInstallation(): boolean | undefined {
    return this._outdoorInstallation;
  }

  set outdoorInstallation(value: boolean) {
    this._outdoorInstallation = value;
  }

  get minEnvironmentalTemperature(): string | undefined {
    return this._minEnvironmentalTemperature;
  }

  set minEnvironmentalTemperature(value: string) {
    this._minEnvironmentalTemperature = value;
  }

  get maxEnvironmentalTemperature(): string | undefined {
    return this._maxEnvironmentalTemperature;
  }

  set maxEnvironmentalTemperature(value: string) {
    this._maxEnvironmentalTemperature = value;
  }

  get ipProtectionCategory(): string | undefined {
    return this._ipProtectionCategory;
  }

  set ipProtectionCategory(value: string) {
    this._ipProtectionCategory = value;
  }

  get notes(): string | undefined {
    return this._notes;
  }

  set notes(value: string) {
    this._notes = value;
  }
}

export enum DoorstopType {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum CableInletType {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

@Pipe({
  standalone: true,
  name: "localizeDoorstopType",
})
export class LocalizeDoorstopTypePipe implements PipeTransform {
  private readonly doorstopTypeLocalizedStringMap = {
    [DoorstopType.LEFT]: $localize`:@@gasWarningCenterCabinet.doorstopType.left:Links`,
    [DoorstopType.RIGHT]: $localize`:@@gasWarningCenterCabinet.doorstopType.right:Rechts`,
  };

  transform(value: DoorstopType): string {
    return this.doorstopTypeLocalizedStringMap[value];
  }
}

@Pipe({
  standalone: true,
  name: "localizeCableInletType",
})
export class LocalizeCableInletTypePipe implements PipeTransform {
  private readonly cableInletTypeLocalizedStringMap = {
    [CableInletType.TOP]: $localize`:@@gasWarningCenterCabinet.cableInletType.top:Oben`,
    [CableInletType.BOTTOM]: $localize`:@@gasWarningCenterCabinet.cableInletType.bottom:Unten`,
    [CableInletType.LEFT]: $localize`:@@gasWarningCenterCabinet.cableInletType.left:Links`,
    [CableInletType.RIGHT]: $localize`:@@gasWarningCenterCabinet.cableInletType.right:Rechts`,
  };

  transform(value: CableInletType): string {
    return this.cableInletTypeLocalizedStringMap[value];
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanMenuButtonComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-button/floorplan-menu-button.component";
import { FloorplanMenuItemComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-item.component";
import { FloorplanMenuType, LocalizeFloorplanMenuTypePipe } from "@project/floorplanner/floorplan-menu/floorplan-menu-type";

@Component({
  selector: "app-floorplan-menu",
  templateUrl: "./floorplan-menu.component.html",
  styleUrl: "./floorplan-menu.component.scss",
  standalone: true,
  imports: [UiKitModule, FloorplanMenuButtonComponent, FloorplanMenuItemComponent, LocalizeFloorplanMenuTypePipe],
})
export class FloorplanMenuComponent implements OnInit {
  @Input({ required: true }) activeFloorplan!: Floorplan;

  protected readonly FloorplanMenuType = FloorplanMenuType;
  protected expanded: boolean = false;
  protected selectedMenu?: FloorplanMenuType;

  protected readonly textHideMenu: string = $localize`:@@floorplan.menu.hideMenu:Menü einklappen`;
  protected readonly textShowMenu: string = $localize`:@@floorplan.menu.showMenu:Menü ausklappen`;

  constructor(private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    const selectedGroup = this.activeRoute.snapshot.queryParamMap.get("selectedGroup");
    if (!selectedGroup) {
      return;
    }
    this.selectedMenu = +selectedGroup as FloorplanMenuType;
  }

  close() {
    this.selectedMenu = undefined;
  }

  open(selectedItem: FloorplanMenuType) {
    this.selectedMenu = selectedItem;
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }
}

import { Expose } from "class-transformer";

export class User {
  @Expose()
  country!: string;

  @Expose()
  language!: string;

  readOnlyAccess!: boolean;

  constructor() {}
}

import { Component, OnInit } from "@angular/core";
import { AuthError, AuthErrorCode, AuthService } from "@app/auth.service";
import { MasterDataInitStatus, MasterDataService } from "@app/master-data.service";
import { catchError, map, Observable, of } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  protected readonly AuthError = AuthErrorCode;
  protected readonly MasterDataInitStatus = MasterDataInitStatus;

  protected readonly errorMessageLoadingProductData: string[] = [
    $localize`:@@app.errorMessage.loadingProductData.text:Versuchen Sie es später nochmal. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte Ihren Administrator.`,
  ];
  protected readonly navintaTeamEmailLink: string =
    "<b><a href=mailto:navinta@draeger.com?subject=Zugang%20zu%20Navinta>navinta@draeger.com</a></b>";
  protected readonly errorMessageNoAccess: string[] = [
    $localize`:@@app.errorMessage.noAccess.text1:Aktuell befinden wir uns noch in der Testphase und der Zugang zur Anwendung ist eingeschränkt.`,
    $localize`:@@app.errorMessage.noAccess.text2:Sollten Sie Fragen haben oder den Wunsch nach Zugang zu Navinta, zögern Sie bitte nicht, sich unter ${this.navintaTeamEmailLink}:navintaTeamEmailLink: an das Navinta-Team zu wenden. Wir bemühen uns, Ihre Anliegen so schnell wie möglich zu bearbeiten.`,
    "",
    $localize`:@@app.errorMessage.noAccess.text3:Danke für Ihr Verständnis und Ihre Unterstützung.`,
    "",
    $localize`:@@app.errorMessage.noAccess.text4:Beste Grüße,`,
    $localize`:@@app.errorMessage.noAccess.text5:Ihr Navinta-Team`,
  ];
  protected readonly errorMessageInvalidLogin: string[] = [
    $localize`:@@app.errorMessage.invalidLogin.text:Es gibt Probleme mit Ihrem Benutzer-Login. Bitte kontaktieren Sie Ihren Administrator.`,
  ];
  protected readonly errorMessageOffline: string[] = [
    $localize`:@@app.errorMessage.offline.text:Derzeit sind Sie Offline, so dass ein Login nicht möglich ist. Bitte verbinden Sie Ihr Gerät mit dem Internet, um fortzufahren.`,
  ];

  isIframe = false;
  authError$?: Observable<AuthError | null>;
  initStatus$?: Observable<MasterDataInitStatus>;

  constructor(
    private authService: AuthService,
    private masterDataService: MasterDataService,
  ) {}

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    // wait to prevent from showing the loader when data initialization is fast
    setTimeout(() => {
      this.initStatus$ = this.masterDataService.initStatus$;
      this.authError$ = this.authService.userInfo$.pipe(
        map(() => null),
        catchError((error) => of(error)),
      );
    }, 500);
  }
}

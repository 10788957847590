import { DustExZone, DustExZoneType } from "@domain/project/floorplan/zones/dust-ex-zone";
import { ExZoneKonva } from "@project/floorplanner/konva/ex-zone-konva";
import { FloorplanItemTextKonva } from "@project/floorplanner/konva/floorplan-item-text-konva";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import Konva from "konva";
import { Group } from "konva/lib/Group";
import { Rect } from "konva/lib/shapes/Rect";
import { Text } from "konva/lib/shapes/Text";

export class DustExZoneKonva {
  static init(dustExZone: DustExZone): Group {
    let color: string = "";
    let dotColorRGB: number[] = [];
    switch (dustExZone.dustExZoneType) {
      case DustExZoneType.ZONE_20:
        color = "red";
        dotColorRGB = [255, 0, 0];
        break;
      case DustExZoneType.ZONE_21:
        color = "orange";
        dotColorRGB = [255, 165, 0];

        break;
      case DustExZoneType.ZONE_22:
        color = "yellow";
        dotColorRGB = [255, 227, 0];
        break;
    }

    const group = new Group({
      x: dustExZone.x,
      y: dustExZone.y,
      height: dustExZone.height,
      width: dustExZone.width,
      rotation: dustExZone.rotation,
      draggable: !dustExZone.locked,
    });

    const dotPattern = new Image();
    dotPattern.onload = () => {
      let dotPatternImage = new Konva.Image({
        image: dotPattern,
      });

      dotPatternImage = this.setDotColor(dotPatternImage, dotColorRGB);

      rectPattern.fillPatternImage(dotPatternImage.toCanvas());
      rectPattern.fillPatternScale({ x: 0.25, y: 0.25 });
    };
    dotPattern.src = "../../assets/dust-ex-zone-pattern.svg";

    const rectBg = new Rect({
      x: 0,
      y: 0,
      height: dustExZone.height,
      width: dustExZone.width,
      fill: color,
      opacity: 0.1,
    });

    const rectPattern = new Rect({
      x: 0,
      y: 0,
      height: dustExZone.height,
      width: dustExZone.width,
    });

    const textPadding = 4;
    const positionId = FloorplanItemTextKonva.init(dustExZone);
    positionId.padding(textPadding);
    ExZoneKonva.setTextPosition(positionId, group);

    group.on("transform", () => {
      group.width(Math.max(5, group.width() * group.scaleX()));
      group.height(Math.max(5, group.height() * group.scaleY()));

      rectPattern.width(group.width());
      rectPattern.height(group.height());

      rectBg.width(group.width());
      rectBg.height(group.height());

      ExZoneKonva.setTextPosition(positionId, group);

      group.scaleX(1);
      group.scaleY(1);
    });

    group.on("transformend", () =>
      dustExZone.updateForm(
        group.width() * group.scaleX(),
        group.height() * group.scaleY(),
        group.rotation(),
        group.x(),
        group.y(),
      ),
    );

    group.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, dustExZone);
    group.add(rectPattern);
    group.add(rectBg);
    group.add(positionId);
    return group;
  }

  static refreshItemText(dustExZone: DustExZone, group: Group) {
    const text = group.getChildren()[2] as Text;
    text.text(`${dustExZone.positionId!} ${dustExZone.displayName}`);
    ExZoneKonva.setTextPosition(text, group);
  }

  private static setDotColor(image: Konva.Image, color: number[]): Konva.Image {
    image.cache();
    image.filters([Konva.Filters.RGBA]);
    image.red(color[0]);
    image.green(color[1]);
    image.blue(color[2]);
    image.alpha(0.3);

    //The filter only takes effect if the image is added to a layer and drawn
    const tempLayer = new Konva.Layer();
    tempLayer.add(image);
    tempLayer.draw();

    return image;
  }
}

import { Component, inject, Input, OnChanges } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfirmModalComponent } from "@components/confirm-modal/confirm-modal.component";
import { DeleteButtonComponent } from "@components/delete-button/delete-button.component";
import { InvitePersonToProjectModalComponent } from "@components/invite-person-to-project-modal/invite-person-to-project-modal.component";
import { ProjectApiService } from "@domain/project/api/project-api.service";
import { Collaborator } from "@domain/project/collaborator";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { UserService } from "@domain/user/user.service";
import { IconComponent } from "@odx/angular/components/icon";
import { ModalService, ModalSize } from "@odx/angular/components/modal";
import { TableCellDirective, TableComponent, TableHeaderCell, TableRowDirective } from "@odx/angular/components/table";
import { ReadAccessModalService } from "@utils/modals/read-access-modal.service";
import { ToastHelper } from "@utils/toast-helper.service";
import { filter, map, Observable, switchMap } from "rxjs";
import { LocalizeDatePipe } from "src/locale/localize-date";
import { LocalizeTimePipe } from "src/locale/localize-time";

@Component({
  selector: "app-project-collaboration",
  standalone: true,
  imports: [
    TableRowDirective,
    TableComponent,
    TableCellDirective,
    IconComponent,
    UiKitModule,
    LocalizeDatePipe,
    LocalizeTimePipe,
    DeleteButtonComponent,
  ],
  templateUrl: "./project-collaboration.component.html",
  styleUrl: "./project-collaboration.component.scss",
})
export class ProjectCollaborationComponent implements OnChanges {
  @Input() set projectData(project: Project) {
    this.project = project;
  }

  @Input() isHidden!: boolean;

  protected project!: Project;
  protected collaborators: Collaborator[] = [];
  protected projectOwner!: Collaborator;
  protected readonly navigator = navigator;
  protected isReadOnlyUser$: Observable<boolean>;

  private readonly modalService = inject(ModalService);

  protected headerData: TableHeaderCell[] = [
    { name: "mail", title: $localize`:@@collaborationTable.column1.header:E-Mail-Adresse` },
    { name: "invitedBy", title: $localize`:@@collaborationTable.column2.header:Eingeladen von` },
    { name: "invitationCompletetAt", title: $localize`:@@collaborationTable.column1.header:Hinzugefügt am` },
    { name: "delete", title: "" },
  ];

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private toastHelper: ToastHelper,
    private apiService: ProjectApiService,
    private readAccessModalService: ReadAccessModalService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
  }
  get offlineToolTipMessage() {
    return !navigator.onLine
      ? $localize`:@@collaboration.removeCollaborator:Diese Funktion ist nur im Online-Modus verfügbar.`
      : null;
  }

  ngOnChanges() {
    if (this.project.cloudId) {
      this.getCollaborators();
    } else {
      console.warn("Project has no cloudId");
    }
  }

  getCollaborators() {
    if (this.project.cloudId)
      this.projectService.loadCollaborators(this.project.cloudId).subscribe((collaborators) => {
        this.collaborators = collaborators;
        this.filterCollaboratorTable();
      });
  }

  filterCollaboratorTable() {
    this.collaborators.sort((a, b) => a.email.localeCompare(b.email));
    this.projectOwner = this.collaborators.find((user) => user.assignedRole === "OWNER")!;
    this.collaborators = this.collaborators.filter((user) => user.assignedRole !== "OWNER");
  }

  deleteCollaborator(collaboratorId: string, collaboratorEmail: string) {
    this.modalService
      .open(ConfirmModalComponent, {
        data: {
          text: $localize`:@@modal.deleteCollaborator:Wollen Sie den Kollaborator ${collaboratorEmail} wirklich löschen?`,
        },
      })
      .onClose$.subscribe(() => {
        this.projectService.removeCollaboratorFromProject(this.project.cloudId, collaboratorId).subscribe({
          next: () => {
            this.getCollaborators();
          },
          error: (error) => {
            console.error("Error:", error);
          },
        });
      });
  }

  protected openModal() {
    this.modalService
      .open(InvitePersonToProjectModalComponent, {
        size: ModalSize.MEDIUM,
      })
      .onClose$.pipe(
        filter((value) => !!value && !!this.project?.cloudId),
        switchMap((value) => this.apiService.inviteCollaborators(value, this.project.cloudId)),
      )
      .subscribe((response) => {
        const errors = response
          .filter((res: { success: boolean; message: string; to?: string }) => {
            return !res.success;
          })
          .map((res: { success: boolean; message: string; to?: string }) => res.to);

        if (errors.length >= 1) {
          this.toastHelper.error(
            $localize`:@@toast.invitation.error:Einladung konnte nicht verschickt werden an: ` + errors.join(", "),
          );
        } else {
          this.toastHelper.success($localize`:@@toast.invitation.successful:Einladung wurde erfolgreich verschickt`);
        }
      });
  }

  openShareLinkModal() {
    this.readAccessModalService.openModal(this.project);
  }
}

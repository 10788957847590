<div>
  <h6 class="odx-title-6" i18n="@@global.dustExZones">Staub-Ex-Zonen</h6>

  <app-floorplan-menu-item-buttons>
    <button
      odxButton
      class="dust-ex-twenty dust-ex-background"
      (click)="addDustExZone(20)"
      [odxTooltip]="tooltipTextAddDustExZone0"
      [odxTooltipOptions]="{ position: 'bottom-start' }"
    >
      <odx-icon name="plus"></odx-icon>
    </button>

    <button
      odxButton
      class="dust-ex-twenty-one dust-ex-background"
      (click)="addDustExZone(21)"
      [odxTooltip]="tooltipTextAddDustExZone1"
      [odxTooltipOptions]="{ position: 'bottom-start' }"
    >
      <odx-icon name="plus"></odx-icon>
    </button>
    <button
      odxButton
      class="dust-ex-twenty-two dust-ex-background"
      (click)="addDustExZone(22)"
      [odxTooltip]="tooltipTextAddDustExZone2"
      [odxTooltipOptions]="{ position: 'bottom-start' }"
    >
      <odx-icon name="plus"></odx-icon>
    </button>
    <app-toggle-visibility-button
      [visible]="floorplanState.dustExZonesVisible"
      (toggleEvent)="floorplanState.dustExZonesVisible = $event"
    >
    </app-toggle-visibility-button>
    <app-toggle-lock-button [locked]="floorplanState.dustExZonesLocked" (toggleEvent)="floorplanState.dustExZonesLocked = $event">
    </app-toggle-lock-button>
  </app-floorplan-menu-item-buttons>
</div>

import { Component, inject } from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ButtonComponent } from "@odx/angular/components/button";
import { ModalRef } from "@odx/angular/components/modal";
import { TableCellDirective, TableComponent, TableHeaderCell, TableRowDirective } from "@odx/angular/components/table";
import { LocalizeDatePipe } from "src/locale/localize-date";
import { LocalizeTimePipe } from "../../locale/localize-time";

@Component({
  selector: "app-project-version-conflict-modal",
  standalone: true,
  imports: [
    ButtonComponent,
    UiKitModule,
    ReactiveFormsModule,
    TableComponent,
    TableRowDirective,
    TableCellDirective,
    LocalizeDatePipe,
    LocalizeTimePipe,
  ],
  templateUrl: "./project-version-conflict-modal.component.html",
  styleUrl: "./project-version-conflict-modal.component.scss",
})
export class ProjectVersionConflictModalComponent {
  public readonly modalRef = inject(ModalRef<any, any>);

  protected versionConflictForm = this.formBuilder.nonNullable.group({
    versionConflict: new FormControl<any | null>(null, Validators.required),
  });

  constructor(private formBuilder: FormBuilder) {}

  protected headerData: TableHeaderCell[] = [
    { name: "name", title: $localize`:@@modal.versionConflict.column1.header:Name` },
    { name: "date", title: $localize`:@@modal.versionConflict.column2.header:Datum, Uhrzeit` },
  ];

  onConfirm() {
    this.versionConflictForm.markAllAsTouched();
    if (this.versionConflictForm.valid) this.modalRef.close(this.versionConflictForm.value.versionConflict);
  }
}

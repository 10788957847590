import { NgModule } from "@angular/core";
import { CoreModule } from "@odx/angular";
import { AccordionModule } from "@odx/angular/components/accordion";
import { AreaHeaderModule } from "@odx/angular/components/area-header";
import { BarModule } from "@odx/angular/components/bar";
import { ButtonComponent } from "@odx/angular/components/button";
import { ButtonGroupComponent } from "@odx/angular/components/button-group";
import { CardModule } from "@odx/angular/components/card";
import { CheckboxModule } from "@odx/angular/components/checkbox";
import { ChipComponent, ChipListComponent, ChipListRowComponent } from "@odx/angular/components/chip";
import { ContentBoxModule } from "@odx/angular/components/content-box";
import { DropdownModule } from "@odx/angular/components/dropdown";
import {
  FormDirective,
  FormFieldComponent,
  FormFieldControlDirective,
  FormFieldErrorDirective,
  FormFieldHintDirective,
  FormFieldLabelDirective,
  FormFieldModule,
  FormGroupComponent,
  provideFormFieldConfig,
} from "@odx/angular/components/form-field";
import { HeaderModule } from "@odx/angular/components/header";
import { IconComponent, provideIconConfig } from "@odx/angular/components/icon";
import { InlineMessageComponent } from "@odx/angular/components/inline-message";
import { ListModule } from "@odx/angular/components/list";
import { LoadingSpinnerModule } from "@odx/angular/components/loading-spinner";
import { MenuModule } from "@odx/angular/components/menu";
import {
  ModalContentDirective,
  ModalDirective,
  ModalFooterComponent,
  ModalHeaderComponent,
  ModalModule,
} from "@odx/angular/components/modal";
import { NavigationBackComponent } from "@odx/angular/components/navigation-back";
import { RadioGroupModule } from "@odx/angular/components/radio-group";
import { SelectModule } from "@odx/angular/components/select";
import { SwitchModule } from "@odx/angular/components/switch";
import { TabBarModule } from "@odx/angular/components/tab-bar";
import { ToastModule } from "@odx/angular/components/toast";
import { TooltipDirective, provideTooltipConfig } from "@odx/angular/components/tooltip";
import { WizardModule } from "@odx/angular/components/wizard";
import { of } from "rxjs";

@NgModule({
  imports: [
    HeaderModule,
    AreaHeaderModule,
    ButtonComponent,
    ChipListComponent,
    ChipComponent,
    ChipListRowComponent,
    IconComponent,
    DropdownModule,
    FormFieldHintDirective,
    FormFieldComponent,
    FormGroupComponent,
    FormFieldLabelDirective,
    FormFieldErrorDirective,
    FormFieldControlDirective,
    FormDirective,
    TabBarModule,
    LoadingSpinnerModule,
    AccordionModule,
    InlineMessageComponent,
    SwitchModule,
    ModalModule,
    BarModule,
    SelectModule,
    ListModule,
    CardModule,
    MenuModule,
    ContentBoxModule,
    ToastModule,
    SelectModule,
    ListModule,
    CheckboxModule,
    RadioGroupModule,
    FormFieldModule,
    NavigationBackComponent,
    TooltipDirective,
    ButtonGroupComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalContentDirective,
    ModalDirective,
    WizardModule,
  ],
  exports: [
    CoreModule,
    HeaderModule,
    AreaHeaderModule,
    ButtonComponent,
    ChipListComponent,
    ChipComponent,
    ChipListRowComponent,
    IconComponent,
    DropdownModule,
    FormFieldHintDirective,
    FormFieldLabelDirective,
    FormFieldComponent,
    FormGroupComponent,
    FormDirective,
    SwitchModule,
    FormFieldErrorDirective,
    TabBarModule,
    LoadingSpinnerModule,
    AccordionModule,
    InlineMessageComponent,
    ModalModule,
    BarModule,
    SelectModule,
    ListModule,
    CardModule,
    MenuModule,
    ToastModule,
    ContentBoxModule,
    SelectModule,
    ListModule,
    CheckboxModule,
    RadioGroupModule,
    FormFieldControlDirective,
    NavigationBackComponent,
    TooltipDirective,
    ButtonGroupComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalContentDirective,
    ModalDirective,
    WizardModule,
  ],
  providers: [
    provideFormFieldConfig({
      errors: {
        required: () => of($localize`:@@app.errorMessage.formFieldRequired:Dieses Feld ist erforderlich`),
        minlength: ({ actualLength, requiredLength }) =>
          $localize`:@@app.errorMessage.minLength:Dieses Feld muss mindestens ${requiredLength}:requiredMinLength: Zeichen haben, hat jedoch ${actualLength}:currentLength:`,
      },
    }),
    provideIconConfig({
      defaultIconSet: "core",
    }),
    provideTooltipConfig({
      position: "top",
    }),
  ],
})
export class UiKitModule {}

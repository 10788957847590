import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonComponent } from "@odx/angular/components/button";
import { IconComponent } from "@odx/angular/components/icon";
import { TooltipDirective } from "@odx/angular/components/tooltip";

@Component({
  selector: "app-edit-button",
  standalone: true,
  imports: [ButtonComponent, IconComponent, TooltipDirective],
  templateUrl: "./edit-button.component.html",
  styleUrl: "./edit-button.component.scss",
})
export class EditButtonComponent {
  @Input({ required: true }) disabled!: boolean;
  @Output() edit = new EventEmitter();

  protected editEnabled = false;

  protected readonly tooltipTextEditItem: string = $localize`:@@floorplan.button.editItem:Zusätzliche Optionen hinzufügen`;
  protected readonly tooltipTextCloseEditItem: string = $localize`:@@floorplan.button.closeEditItem:Bearbeiten beenden`;

  protected onClickEdit() {
    this.editEnabled = !this.editEnabled;
    this.edit.emit();
  }
}

<div odxLayout="flex gap-small" class="w-100 h-100">
  <div odxLayout="flex gap-none" class="flex-column project-menu">
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="expanded ? textHideMenu : textShowMenu"
      odxVariant="ghost"
      [odxIcon]="expanded ? 'chevron-left' : 'chevron-right'"
      (click)="expanded = !expanded"
      data-testid="floorplanMenu.btn.collapseMenu"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.MAIN_SETTINGS | localizeFloorplanMenuType"
      odxVariant="ghost"
      odxIcon="tile-menu"
      (click)="open(FloorplanMenuType.MAIN_SETTINGS)"
      data-testid="floorplanMenu.btn.mainMenu"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.PRODUCT_SEARCH | localizeFloorplanMenuType"
      odxVariant="ghost"
      odxIcon="search"
      (click)="open(FloorplanMenuType.PRODUCT_SEARCH)"
      data-testid="floorplanMenu.btn.productSearch"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.ZONE | localizeFloorplanMenuType"
      imageUrl="../../assets/zonen_nicht_odx.svg"
      (click)="open(FloorplanMenuType.ZONE)"
      data-testid="floorplanMenu.btn.mainMenu"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.GAS_WARNING_CENTER | localizeFloorplanMenuType"
      imageUrl="../../assets/gaswarnanlage.svg"
      (click)="open(FloorplanMenuType.GAS_WARNING_CENTER)"
      data-testid="floorplanMenu.btn.gasWarningCenters"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.TRANSMITTER | localizeFloorplanMenuType"
      imageUrl="../../assets/transmitter.svg"
      (click)="open(FloorplanMenuType.TRANSMITTER)"
      data-testid="floorplanMenu.btn.transmitters"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.SIGNAL_ELEMENT | localizeFloorplanMenuType"
      imageUrl="../../assets/leuchttransparente.svg"
      (click)="open(FloorplanMenuType.SIGNAL_ELEMENT)"
      data-testid="floorplanMenu.btn.signalElements"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.PLASTIC_SIGN | localizeFloorplanMenuType"
      imageUrl="../../assets/kunststoffschild.svg"
      (click)="open(FloorplanMenuType.PLASTIC_SIGN)"
      data-testid="floorplanMenu.btn.plasticSigns"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.ALARM_DEVICE | localizeFloorplanMenuType"
      imageUrl="../../assets/alarmmittel_odx.svg"
      (click)="open(FloorplanMenuType.ALARM_DEVICE)"
      data-testid="floorplanMenu.btn.alarmDevices"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.IMAGE | localizeFloorplanMenuType"
      odxIcon="camera"
      (click)="open(FloorplanMenuType.IMAGE)"
      data-testid="floorplanMenu.btn.images"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.PIPELINE | localizeFloorplanMenuType"
      imageUrl="../../assets/rohrleitungen_nicht_odx.svg"
      (click)="open(FloorplanMenuType.PIPELINE)"
      data-testid="floorplanMenu.btn.pipelines"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.AIR_PATH | localizeFloorplanMenuType"
      odxIcon="arrow-right"
      (click)="open(FloorplanMenuType.AIR_PATH)"
      data-testid="floorplanMenu.btn.airPaths"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.TEXT | localizeFloorplanMenuType"
      imageUrl="../../assets/notizen_odx.svg"
      (click)="open(FloorplanMenuType.TEXT)"
      data-testid="floorplanMenu.btn.notes"
    >
    </app-floorplan-menu-button>
    <app-floorplan-menu-button
      [expanded]="expanded"
      [title]="FloorplanMenuType.MEASUREMENT_LINE | localizeFloorplanMenuType"
      imageUrl="../../assets/bemassungen_nicht_odx.svg"
      (click)="open(FloorplanMenuType.MEASUREMENT_LINE)"
      data-testid="floorplanMenu.btn.measurements"
    >
    </app-floorplan-menu-button>
  </div>
  <div>
    <app-floorplan-menu-item
      *ngIf="selectedMenu !== undefined && activeFloorplan"
      [activeFloorplan]="activeFloorplan"
      [menuType]="selectedMenu"
      (close$)="close()"
    ></app-floorplan-menu-item>
  </div>
</div>

<odx-modal-header>
  <odx-area-header i18n="@@floorplan.menu.selectPlaceholderCategory.header">Produktkategorie auswählen</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p i18n="@@floorplan.menu.selectPlaceholderCategory">
    Bitte wählen Sie die Produktkategorie aus, für die Sie einen Platzhalter erstellen möchten.
  </p>
  <form odxForm [formGroup]="productTypeForm">
    <odx-form-field>
      <odx-select formControlName="productType" odxFormFieldControl>
        <odx-select-option [value]="productType" *ngFor="let productType of productTypes">
          {{ productType | localizeProductType }}
        </odx-select-option>
      </odx-select>
    </odx-form-field>
  </form>
</odx-modal-content>
<odx-modal-footer>
  <button odxButton variant="primary" (click)="onConfirm()">OK</button>
</odx-modal-footer>

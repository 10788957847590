import { EventEmitter, Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ProductCountryService {
  public newCountry$ = new EventEmitter<string>();
  public countries: string[] = [];

  public init(countries: string[]) {
    this.countries = countries;
  }

  selectCountry(country: string): string[] {
    if (!this.countries.includes(country)) {
      this.countries.push(country);
      this.newCountry$.emit(country);
    }
    return this.countries;
  }
}

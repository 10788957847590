import { Component, inject } from "@angular/core";
import { AreaHeaderComponent } from "@odx/angular/components/area-header";
import { ButtonComponent } from "@odx/angular/components/button";
import { ModalContentDirective, ModalFooterComponent, ModalHeaderComponent, ModalRef } from "@odx/angular/components/modal";

@Component({
  selector: "app-file-format-modal",
  standalone: true,
  imports: [AreaHeaderComponent, ButtonComponent, ModalContentDirective, ModalFooterComponent, ModalHeaderComponent],
  templateUrl: "./file-error-modal.component.html",
  styleUrl: "./file-error-modal.component.scss",
})
export class FileErrorModalComponent {
  public readonly modalRef = inject(ModalRef<any, string>);

  onConfirm() {
    this.modalRef.close("confirmed");
  }
}

import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { AirPath } from "@domain/project/floorplan/air-path";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanAlarmDevice } from "@domain/project/floorplan/floorplan-alarm-device";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import { FloorplanGasWarningCenterPlaceholder } from "@domain/project/floorplan/floorplan-gas-warning-center-placeholder";
import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanPlasticSign } from "@domain/project/floorplan/floorplan-plastic-sign";
import { FloorplanSignalElement } from "@domain/project/floorplan/floorplan-signal-element";
import { FloorplanText } from "@domain/project/floorplan/floorplan-text";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { MeasurementLine } from "@domain/project/floorplan/measurement-line";
import { Pipeline } from "@domain/project/floorplan/pipeline";
import { DangerArea } from "@domain/project/floorplan/zones/danger-area";
import { DustExZone } from "@domain/project/floorplan/zones/dust-ex-zone";
import { ExZone } from "@domain/project/floorplan/zones/ex-zone";
import { AlarmDeviceNotesComponent } from "@project/floorplanner/floorplan-additional-notes/alarm-device-notes/alarm-device-notes.component";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";
import { GasWarningCenterNotesComponent } from "@project/floorplanner/floorplan-additional-notes/gas-warning-center-notes/gas-warning-center-notes.component";
import { ImageNotesComponent } from "@project/floorplanner/floorplan-additional-notes/image-notes/image-notes.component";
import { MeasurementLineNotesComponent } from "@project/floorplanner/floorplan-additional-notes/measurement-line-notes/measurement-line-notes.component";
import { PipelineNotesComponent } from "@project/floorplanner/floorplan-additional-notes/pipeline-notes/pipeline-notes.component";
import { PlaceholderNotesComponent } from "@project/floorplanner/floorplan-additional-notes/placeholder-notes/placeholder-notes.component";
import { PlasticSignNotesComponent } from "@project/floorplanner/floorplan-additional-notes/plastic-sign-notes/plastic-sign-notes.component";
import { SignalNotesComponent } from "@project/floorplanner/floorplan-additional-notes/signal-notes/signal-notes.component";
import { TransmitterNotesComponent } from "@project/floorplanner/floorplan-additional-notes/transmitter-notes/transmitter-notes.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { Observable, tap } from "rxjs";

interface ItemType {
  measurementLine: boolean;
  image: boolean;
  transmitter: boolean;
  gasWarningCenter: boolean;
  signalElement: boolean;
  alarmDevice: boolean;
  plasticSign: boolean;
  pipeline: boolean;
  placeholder: boolean;
  defaultItem: boolean;
}

@Component({
  selector: "app-floorplan-additional-notes",
  templateUrl: "./floorplan-additional-notes.component.html",
  styleUrls: ["./floorplan-additional-notes.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    AlarmDeviceNotesComponent,
    GasWarningCenterNotesComponent,
    FloorplanItemDetailsComponent,
    ImageNotesComponent,
    MeasurementLineNotesComponent,
    PipelineNotesComponent,
    PlaceholderNotesComponent,
    PlasticSignNotesComponent,
    SignalNotesComponent,
    TransmitterNotesComponent,
  ],
})
export class FloorplanAdditionalNotesComponent implements OnInit {
  @Input({ required: true }) floorplan!: Floorplan;

  protected selectedItem$!: Observable<FloorplanItem | null>;
  protected title?: string;
  protected itemType?: ItemType;

  constructor(
    private floorplanService: FloorplanService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.resetItemType();
    this.selectedItem$ = this.floorplanService.selectedItem$.pipe(
      tap((selectedItem) => {
        const itemType = this.resetItemType();
        if (!selectedItem) {
          return;
        }

        // detect changes so that active notes component from same type is destroyed and newly created
        this.cdRef.detectChanges();

        this.updateItemType(selectedItem, itemType);
      }),
    );
  }

  private updateItemType(selectedItem: FloorplanItem, itemType: ItemType) {
    if (
      selectedItem instanceof FloorplanPlaceholder ||
      selectedItem instanceof FloorplanTransmitterPlaceholder ||
      selectedItem instanceof FloorplanGasWarningCenterPlaceholder
    ) {
      itemType.placeholder = true;
    } else if (selectedItem instanceof MeasurementLine) {
      itemType.measurementLine = true;
    } else if (selectedItem instanceof FloorplanImage) {
      itemType.image = true;
    } else if (selectedItem instanceof FloorplanTransmitter) {
      itemType.transmitter = true;
    } else if (selectedItem instanceof FloorplanGasWarningCenter) {
      itemType.gasWarningCenter = true;
    } else if (selectedItem instanceof FloorplanSignalElement) {
      itemType.signalElement = true;
    } else if (selectedItem instanceof FloorplanAlarmDevice) {
      itemType.alarmDevice = true;
    } else if (selectedItem instanceof FloorplanPlasticSign) {
      itemType.plasticSign = true;
    } else if (selectedItem instanceof Pipeline) {
      itemType.pipeline = true;
    } else {
      itemType.defaultItem = true;
      this.setTitle(selectedItem);
    }
  }

  private setTitle(selectedItem: FloorplanItem): void {
    if (selectedItem instanceof ExZone) {
      const exZoneType = selectedItem.exZoneType.charAt(selectedItem.exZoneType.length - 1);
      this.title = $localize`:@@floorplan.details.exZone.header:Ex-Zone ${exZoneType}:exZoneType:`;
    } else if (selectedItem instanceof DustExZone) {
      const dustExZoneType = selectedItem.dustExZoneType.split("_")[1];
      this.title = $localize`:@@floorplan.details.dustExZone.header:Staub-Ex-Zone ${dustExZoneType}:dustExZoneType:`;
    } else if (selectedItem instanceof DangerArea) {
      this.title = $localize`:@@floorplan.details.dangerArea.header:Gefahrenbereich`;
    } else if (selectedItem instanceof AirPath) {
      this.title =
        selectedItem.direction == "EXHAUST_AIR"
          ? $localize`:@@floorplan.details.exhaustAir.header:Abluftweg`
          : $localize`:@@floorplan.details.supplyAir.header:Zuluftweg`;
    } else if (selectedItem instanceof FloorplanText) {
      this.title = $localize`:@@floorplan.details.notes.header:Notiz`;
    }
  }

  private resetItemType(): ItemType {
    this.itemType = {
      measurementLine: false,
      image: false,
      transmitter: false,
      gasWarningCenter: false,
      signalElement: false,
      alarmDevice: false,
      plasticSign: false,
      pipeline: false,
      placeholder: false,
      defaultItem: false,
    };
    return this.itemType;
  }
}

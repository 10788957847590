import { BaseContainer } from "@pdf/helpers/container/base-container";
import { ActiveSide } from "@pdf/helpers/container/half-page/active-side";
import { ContainerProperties } from "@pdf/pdf-properties";

export class HalfPageContainer extends BaseContainer {
  public activeSide: ActiveSide = ActiveSide.LEFT;

  constructor() {
    super(ContainerProperties.LEFT_X, ContainerProperties.Y, ContainerProperties.WIDTH, ContainerProperties.HEIGHT);
  }

  public toggleActiveSide() {
    if (this.activeSide === ActiveSide.LEFT) {
      this.activeSide = ActiveSide.RIGHT;
      this.xPosition = ContainerProperties.RIGHT_X;
    } else {
      this.activeSide = ActiveSide.LEFT;
      this.xPosition = ContainerProperties.LEFT_X;
    }
    this.yPosition = ContainerProperties.Y;
  }

  public resetActiveSideToLeft() {
    this.activeSide = ActiveSide.LEFT;
    this.xPosition = ContainerProperties.LEFT_X;
    this.yPosition = ContainerProperties.Y;
  }
}

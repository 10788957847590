<app-floorplan-item-details
  [title]="(transmitterName$ | async) || transmitter.config.name"
  [floorplanItem]="transmitter"
  [imageUrl]="imageUrl"
  [fallbackImageUrl]="fallbackImageUrl"
  [showDiscontinuedWarning]="(isDiscontinued$ | async) || false"
  [showIncompleteWarning]="(isIncompleteConfiguration$ | async) || false"
  (editClicked)="onEdit()"
>
  <div class="feature" *ngIf="gasName$ | async; let gasName">
    <p class="odx-text--small" i18n="@@floorplan.details.transmitter.gasType">Gasart</p>
    <p>{{ gasName }}</p>
  </div>

  <div class="feature" *ngIf="sensorName$ | async; let sensorName">
    <p class="odx-text--small" i18n="@@global.sensor">Sensor</p>
    <p>{{ sensorName }}</p>
  </div>

  <app-floorplan-item-attachments class="feature" [attachmentIds]="transmitter.config.attachmentIds">
  </app-floorplan-item-attachments>

  <app-floorplan-item-detail-transmitter-form
    [transmitter]="transmitter"
    [editEnabled]="editEnabled"
  ></app-floorplan-item-detail-transmitter-form>
</app-floorplan-item-details>

import { Component, EventEmitter, inject, Input, Optional, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ExportProductListService } from "@components/project-detail-header/export-product-list.service";
import { ProjectVersionModalComponent } from "@components/project-version-modal/project-version-modal.component";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { UserService } from "@domain/user/user.service";
import { ModalService } from "@odx/angular/components/modal";
import { PdfService } from "@pdf/pdf.service";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { ReadAccessModalService } from "@utils/modals/read-access-modal.service";
import { ToastHelper } from "@utils/toast-helper.service";
import { map, Observable } from "rxjs";
import { LocalizeDatePipe } from "src/locale/localize-date";
import { LocalizeTimePipe } from "src/locale/localize-time";

@Component({
  selector: "app-project-detail-header",
  templateUrl: "./project-detail-header.component.html",
  styleUrls: ["./project-detail-header.component.scss"],
  standalone: true,
  imports: [UiKitModule, LocalizeDatePipe, LocalizeTimePipe],
})
export class ProjectDetailHeaderComponent {
  @Input({ required: true }) project!: Project;
  @Input() activeFloorplan?: Floorplan;
  @Input() hideBackArrow?: boolean = false;
  @Output() navigateBack = new EventEmitter<void>();

  private readonly modalService = inject(ModalService);

  protected readonly navigator = navigator;
  protected floorplanExportEnabled: boolean = false;
  protected isReadOnlyUser$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private toastHelper: ToastHelper,
    private exportProjectService: ExportProductListService,
    private pdfService: PdfService,
    private readAccessModalService: ReadAccessModalService,
    @Optional() private floorplanService: FloorplanService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
    this.floorplanExportEnabled = !!this.floorplanService;
  }

  exportProjectToFile(project: Project) {
    this.projectService.exportProjectToFile(project);
    this.toastHelper.success(
      $localize`:@@toast.projectExport.exportSuccessful:Projekt '${project.name}:projectName:' wurde erfolgreich auf ihren Computer exportiert`,
    );
  }

  exportProductList(project: Project) {
    this.exportProjectService.export(project);
  }

  exportProjectToPdf(project: Project) {
    this.modalService.open(ProjectVersionModalComponent, { data: { project: project } }).onClose$.subscribe();
  }

  exportFloorplanToPdf() {
    if (!this.activeFloorplan) {
      return;
    }
    this.pdfService.exportFloorplanToPdf(this.activeFloorplan).subscribe(({ pdf, fileName }) => pdf.save(fileName));
  }

  onNavigateBack() {
    this.navigateBack.emit();
  }

  openShareLinkModal() {
    this.readAccessModalService.openModal(this.project);
  }
}

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { UserService } from "@domain/user/user.service";
import { FloorplanMenuComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu.component";
import { User } from "@sentry/angular";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-project-detail-file-card",
  templateUrl: "./project-detail-file-card.component.html",
  styleUrls: ["./project-detail-file-card.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, FloorplanMenuComponent],
})
export class ProjectDetailFileCardComponent {
  @Input() fileUrl!: string;
  @Input() fileName!: string;
  @Input() fileType?: string;

  @Output() delete$ = new EventEmitter();
  @Output() download$ = new EventEmitter();
  @Output() submit$ = new EventEmitter<FormGroup>();
  @Output() click$ = new EventEmitter();

  @ViewChild("submitButton")
  submitButton?: ElementRef;

  editForm!: FormGroup;
  editEnabled = false;

  protected user!: User;
  protected isReadOnlyUser$: Observable<boolean>;

  protected iconList = [
    { type: "application/pdf", icon: "file-pdf" },
    { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", icon: "file-excel" },
    { type: "text/csv", icon: "file-csv" },
    { type: "application/vnd.ms-powerpoint", icon: "file-ppt" },
    { type: "video/x-ms-wmv", icon: "file-wmv" },
    { type: "application/msword", icon: "file-word" },
    { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", icon: "file-word" },
    { type: "application/x-zip-compressed", icon: "file-zip" },
    { type: "application/zip", icon: "file-zip" },
    { type: "application/zip-compressed", icon: "file-zip" },
    { type: "image/png", icon: "file-png" },
    { type: "image/jpeg", icon: "file-jpg" },
    { type: "image/svg+xml", icon: "file-svg" },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) {
    this.isReadOnlyUser$ = this.userService.user$.pipe(map((user) => user.readOnlyAccess));
    this.editForm = formBuilder.group({
      name: [null, Validators.required],
    });
  }

  onEdit() {
    this.editForm.patchValue({ name: this.fileName });
    this.editEnabled = true;
  }

  onDelete() {
    this.delete$.emit();
  }

  onSubmit() {
    if (!this.editForm.valid) {
      return;
    }
    this.submit$.emit(this.editForm);
    this.editEnabled = false;
  }

  onClick() {
    this.click$.emit();
  }

  onDownload() {
    this.download$.emit();
  }

  protected setIcon() {
    const filteredType = this.iconList.find((item) => item.type === this.fileType);
    return filteredType !== undefined ? filteredType.icon : "file-others";
  }
}

import { Injectable } from "@angular/core";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { ActiveSide } from "@pdf/helpers/container/half-page/active-side";
import { FontNames, PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { HalfPageContainer } from "./half-page-container";

@Injectable({
  providedIn: "root",
})
export class HalfPageContainerService {
  public add(
    pdf: jsPDF,
    container: HalfPageContainer,
    headline: string,
    tableOfContents?: TableOfContents,
    showHeadline?: boolean,
  ) {
    container.toggleActiveSide();
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
    if (container.activeSide === ActiveSide.LEFT) {
      pdf.addPage();
      if (tableOfContents) {
        tableOfContents.appendToTableOfContents(headline);
      }
      pdf.text(headline, container.xPosition, PdfProperties.HEADLINE_Y, { baseline: "top" });
    }
    if (showHeadline) {
      pdf.text(headline, container.xPosition, PdfProperties.HEADLINE_Y, { baseline: "top" });
    }
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
    container.addFrame(pdf);
  }

  public next(pdf: jsPDF, container: HalfPageContainer, headline: string, tableOfContents?: TableOfContents) {
    container.toggleActiveSide();
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
    if (container.activeSide === ActiveSide.LEFT) {
      pdf.setPage(pdf.getNumberOfPages() + 1);
      if (tableOfContents) {
        tableOfContents.appendToTableOfContents(headline);
      }
      pdf.text(headline, container.xPosition, PdfProperties.HEADLINE_Y, { baseline: "top" });
    }
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
    container.addFrame(pdf);
  }

  public addOnCurrentPage(pdf: jsPDF, container: HalfPageContainer, headline: string, tableOfContents?: TableOfContents) {
    if (tableOfContents) {
      tableOfContents.appendToTableOfContents(headline);
    }
    container.resetActiveSideToLeft();
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
    pdf.text(headline, container.xPosition, PdfProperties.HEADLINE_Y, { baseline: "top" });
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
    container.addFrame(pdf);
  }

  public addOnNewPage(pdf: jsPDF, container: HalfPageContainer, headline: string, tableOfContests?: TableOfContents) {
    pdf.addPage();
    this.addOnCurrentPage(pdf, container, headline, tableOfContests);
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanAirPathMenuComponent } from "@project/floorplanner/floorplan-menu/air-path-menu/floorplan-air-path-menu.component";
import { FloorplanAlarmDeviceMenuComponent } from "@project/floorplanner/floorplan-menu/alarm-device-menu/floorplan-alarm-device-menu.component";
import { FloorplanMenuType, LocalizeFloorplanMenuTypePipe } from "@project/floorplanner/floorplan-menu/floorplan-menu-type";
import { FloorplanGasWarningCenterMenuComponent } from "@project/floorplanner/floorplan-menu/gas-warning-center-menu/floorplan-gas-warning-center-menu.component";
import { FloorplanImageMenuComponent } from "@project/floorplanner/floorplan-menu/image-menu/floorplan-image-menu.component";
import { FloorplanMainMenuComponent } from "@project/floorplanner/floorplan-menu/main-menu/floorplan-main-menu.component";
import { FloorplanMeasurementLineMenuComponent } from "@project/floorplanner/floorplan-menu/measurement-line-menu/floorplan-measurement-line-menu.component";
import { FloorplanNotesMenuComponent } from "@project/floorplanner/floorplan-menu/notes-menu/floorplan-notes-menu.component";
import { FloorplanPipelineMenuComponent } from "@project/floorplanner/floorplan-menu/pipeline-menu/floorplan-pipeline-menu.component";
import { FloorplanPlasticSignMenuComponent } from "@project/floorplanner/floorplan-menu/plastic-sign-menu/floorplan-plastic-sign-menu.component";
import { FloorplanProductSearchMenuComponent } from "@project/floorplanner/floorplan-menu/product-search-menu/floorplan-product-search-menu.component";
import { FloorplanSignalMenuComponent } from "@project/floorplanner/floorplan-menu/signal-menu/floorplan-signal-menu.component";
import { FloorplanTransmitterMenuComponent } from "@project/floorplanner/floorplan-menu/transmitter-menu/floorplan-transmitter-menu.component";
import { FloorplanZoneMenuComponent } from "@project/floorplanner/floorplan-menu/zone-menu/floorplan-zone-menu.component";

@Component({
  selector: "app-floorplan-menu-item",
  templateUrl: "./floorplan-menu-item.component.html",
  styleUrls: ["./floorplan-menu-item.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    FloorplanAirPathMenuComponent,
    FloorplanAlarmDeviceMenuComponent,
    FloorplanGasWarningCenterMenuComponent,
    FloorplanImageMenuComponent,
    FloorplanMainMenuComponent,
    FloorplanMeasurementLineMenuComponent,
    FloorplanNotesMenuComponent,
    FloorplanPipelineMenuComponent,
    FloorplanPlasticSignMenuComponent,
    FloorplanSignalMenuComponent,
    FloorplanTransmitterMenuComponent,
    FloorplanZoneMenuComponent,
    LocalizeFloorplanMenuTypePipe,
    FloorplanProductSearchMenuComponent,
  ],
})
export class FloorplanMenuItemComponent {
  @Input({ required: true }) activeFloorplan!: Floorplan;
  @Input({ required: true }) menuType!: FloorplanMenuType;
  @Output() close$ = new EventEmitter();

  protected readonly FloorplanMenuType = FloorplanMenuType;

  onClose() {
    this.close$.emit();
  }
}

import { formatCurrency } from "@angular/common";
import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

interface RowData {
  position: string;
  quantity: string;
  name: string;
  id: string;
  productCosts: number;
  totalCosts: number;
  showWarning: boolean;
}

export class ProductListProductRow extends DefaultRow {
  readonly position: string;
  readonly quantity: string;
  readonly name: string[];
  readonly id: string;
  readonly productCosts: string;
  readonly totalCosts: string;
  readonly isPlaceholder: boolean = false;
  readonly showWarning: boolean;

  constructor(pdf: jsPDF, localeId: string, columns: TableColumn[], productRowData: RowData) {
    super();
    this.position = productRowData.position;
    this.quantity = productRowData.quantity;
    this.name = this.splitText(pdf, columns[2].width, productRowData.name);
    this.id = productRowData.id;
    this.productCosts = this.formatCosts(productRowData.productCosts, localeId);
    this.totalCosts = this.formatCosts(productRowData.totalCosts, localeId);
    this.showWarning = productRowData.showWarning;
    this._textHeight = this.name.length * TableProperties.TEXT_HEIGHT;
  }

  private formatCosts(costs: number, localeId: string) {
    return formatCurrency(costs, localeId, "€");
  }

  private splitText(pdf: jsPDF, width: number, text: string): string[] {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    return pdf.splitTextToSize(text, width);
  }
}

import { Injectable } from "@angular/core";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { FlexContainer } from "@pdf/helpers/container/image-container/flex-container";
import { FontNames, PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

@Injectable({
  providedIn: "root",
})
export class FlexContainerService {
  public add(
    pdf: jsPDF,
    container: FlexContainer,
    headline: string,
    showHeadline: boolean,
    addNewPage: boolean,
    tableOfContents?: TableOfContents,
  ) {
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
    if (addNewPage) pdf.addPage();
    if (tableOfContents) tableOfContents.appendToTableOfContents(headline);
    if (showHeadline) pdf.text(headline, container.xPosition, PdfProperties.HEADLINE_Y, { baseline: "top" });
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
    container.addFrame(pdf);
  }
}

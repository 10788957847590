<div>
  <app-filter-product (search)="search($event)"></app-filter-product>
</div>

<div>
  <h6 *ngIf="searchValue.length" class="odx-title-6" i18n="@@floorplan.header.searchResults">Suchergebnisse</h6>
  <div *ngIf="filteredProducts.length > 0 || !searchValue.length; else noProducts">
    <div *ngFor="let product of filteredProducts">
      <hr />
      <app-product-search-item [product]="product" (click)="addProductToFloorplan(product)"></app-product-search-item>
    </div>
  </div>

  <ng-template #noProducts>
    <div class="no-products-container">
      <div class="no-products-message" i18n="@@floorplan.productSearch.noResults">Keine Suchergebnisse</div>
      <button odxButton (click)="$event.stopPropagation(); openSelectPlaceholderMenu()">
        Mit einem Platzhalter weiterarbeiten
      </button>
    </div>
  </ng-template>
</div>

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-toggle-visibility-button",
  templateUrl: "./toggle-visibility-button.component.html",
  styleUrl: "./toggle-visibility-button.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class ToggleVisibilityButtonComponent {
  @Input({ required: true }) visible!: boolean;
  @Output() toggleEvent = new EventEmitter<boolean>();

  protected readonly tooltipTextShowCategory: string = $localize`:@@floorplan.button.showCategory:Kategorie einblenden`;
  protected readonly tooltipTextHideCategory: string = $localize`:@@floorplan.button.hideCategory:Kategorie ausblenden`;

  toggleVisibility() {
    this.visible = !this.visible;
    this.toggleEvent.emit(this.visible);
  }
}

import { Component, inject } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { CloudProjectsOdxTableComponent } from "@components/cloud-projects-odx-table/cloud-projects-odx-table.component";
import { ProjectApiService } from "@domain/project/api/project-api.service";
import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { ProjectService } from "@domain/project/project.service";
import { ModalRef } from "@odx/angular/components/modal";
import { forkJoin, Observable } from "rxjs";

@Component({
  templateUrl: "./cloud-project-select-modal.component.html",
  styleUrls: ["./cloud-project-select-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule, CloudProjectsOdxTableComponent],
})
export class CloudProjectSelectModalComponent {
  private readonly modalRef = inject(ModalRef<any, string>);

  cloudProjects$: Observable<CloudProjectMetadata[]>;
  selectedProject?: CloudProjectMetadata;

  constructor(
    private projectService: ProjectService,
    private apiService: ProjectApiService,
  ) {
    this.cloudProjects$ = projectService.loadCloudProjectsMetadata();
  }

  onConfirm() {
    if (!this.selectedProject) {
      return;
    }
    const cloudId = this.selectedProject.id;

    return forkJoin({
      project: this.projectService.loadProjectFromCloud(cloudId),
      metadata: this.apiService.getMetadata(cloudId),
    }).subscribe({
      next: ({ project: project, metadata: metadata }) => {
        this.modalRef.close({ project: project, metadata: metadata });
      },
    });
  }
}
